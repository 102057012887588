import React, {useEffect, useState}from 'react'
import './HistoryDetail.css'
import {Row, Col, Alert, message} from 'antd'
import {useLocation, useParams} from "react-router-dom";
import { connect } from 'react-redux';
import copy_icon from '../../Asset/Icon/feather_copy_icon.svg'
import cashbackIcon from '../../Asset/Icon/feather_alert-circle.svg'
import currencyFormatter from 'currency-formatter';
import moment from 'moment'
import {FooterOrderDetailExpired, FooterOrderDetailNonExpired, FooterRePayment} from './FooterOrderDetail';
import {HistoryDetailLoading} from '../skeletonLoading/loadingTag'
import {NavbarWithMenuIcon} from '../Properties/Navbar/Navbar'
import {fetchDataHistoryOrderDetail} from '../../redux/ActionCreators/historyOrderDetailActions'
import HtmlParser from 'react-html-parser';

function HistoryDetail({ orderData, fetchDataHistoryOrderDetail}) {
    // let {id} = useParams();
    let location = useLocation();
    var id = window.location.pathname.split("/")[2];

    useEffect(() => {
        fetchDataHistoryOrderDetail("/customer/gethistorydetail", `{"order_id":"${id}", "order_code":"${id}"}`);

    }, [])

    if(orderData.loading){
        return(
            <div style={{marginTop: 80}}>
                <HistoryDetailLoading/>
            </div>
        )
    }else{

    function AlertSnackbar(messages){
        message.error({
            content: messages,
            className: 'snackbar-success',
            duration: 3
        });
    };

    function copyVAnumber() {
        var copyText = orderDetail.tord_pay_ref_id;
        navigator.clipboard.writeText(copyText);

        AlertSnackbar("Nomor Virtual Account berhasil tersalin");
    }
    
    const orderDetail = orderData.getOrderDetail;
    const dateTrx = moment.unix(orderDetail.tord_crtdt).utc();
    const dateShip = moment.unix(orderDetail.tord_delivery_date).utc();

    let totalPrice = currencyFormatter.format(orderDetail.tord_total_price, { code: 'IDR' });
    let shippingPrice = currencyFormatter.format(orderDetail.tord_ongkir, { code: 'IDR' });
    let packagingPrice = currencyFormatter.format(orderDetail.tord_packaging_price, { code: 'IDR' });
    let adminPrice = currencyFormatter.format(orderDetail.tord_pay_fee, { code: 'IDR' });
    let grandTotalPrice = currencyFormatter.format(orderDetail.tord_grand_total, { code: 'IDR' });
    let diskonPrice = currencyFormatter.format(orderDetail.tord_discount_amount, { code: 'IDR' });

    let searchParams = new URLSearchParams(window.location.search);
    let isFromCheckout = searchParams.get("fromcheckout");

    return (
        <div>
            <NavbarWithMenuIcon link={isFromCheckout === 'true' ? '/' : '/back'}/>
            <div style={{marginTop: 70}} className='body-detail'>            
                <span className='span-title-head'>Detail Pesanan</span>
                <br/>
                <br/>
                <Row style={{marginBottom: 10}} className='row-order-detail'>
                    <Col span={12}>
                        <p className='title-orderDetail'>Status</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data'>{orderDetail.mordstat_desc}</p>
                    </Col>                
                </Row>
                <hr className='hr-orderdetail'/>
                <Row style={{marginBottom: 10}} className='row-order-detail'>
                    <Col span={12}>
                        <p className='title-orderDetail'>Tanggal Transaksi</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data'>{moment(dateTrx._d).format("DD MMM YYYY HH:mm")} WIB</p>
                    </Col>                
                </Row>
                <hr className='hr-orderdetail'/>
                <Row style={{marginBottom: 10}} className='row-order-detail'>
                    <Col span={12}>
                        <p className='title-orderDetail'>Tanggal Pengiriman</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data'>{moment(dateShip._d).format("DD MMM YYYY")}</p><br/>
                        <p className='title-orderDetail title-data'>{orderDetail.timeframe_name}</p>
                    </Col>                
                </Row>
                <hr className='hr-orderdetail'/>
                <Row>
                    <Col span={24}>
                            <span className='title-orderDetail'>No. Invoice</span>                        
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className='title-data' style={{float: 'left'}}>{orderDetail.tord_code}</span>
                    </Col>
                </Row>
                <br/>
                <hr className='hr-row'/>
                <Row>
                    <Col span={12}>
                        <p className='title-head-2' style={{float: 'left'}}>Informasi Pembayaran</p>
                    </Col>
                </Row>
                <Row style={{marginBottom: 10}}>
                    <Col span={12}>
                        <p className='title-orderDetail data-va'>Metode Pembayaran</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-va'>{orderDetail.mpaytype_name}</p>
                    </Col>                
                </Row>
                {orderDetail.tord_pay_type_id === 100 ? 
                <Row style={{marginBottom: 10}}>
                    <Col span={12}>
                        <p className='title-orderDetail data-va' >Nomor Akun Virtual</p>
                    </Col>
                    <Col span={12} className='va-col'>
                        <div className='vanumber' onClick={() => copyVAnumber()}>
                            <span>{orderDetail.tord_pay_ref_id}</span>
                            <img src={copy_icon} alt=""/>
                        </div>
                    </Col>                
                </Row> : null}
                <hr/>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price'>Total Belanja ({orderDetail.order_items.length} item)</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price'>{totalPrice}</p>
                    </Col>
                </Row>
                {
                    orderDetail.tord_promo_type_id === 102 ? 
                    <Row>
                        <Col span={12}>
                            <div style={{float: 'left'}}>
                                <p className='title-orderDetail data-price promo-text'>Diskon</p><br/>
                                <p className='title-orderDetail data-price promo-text'>Kode Promosi:{orderDetail.tord_promo_code}</p>
                            </div>
                        </Col>
                        <Col span={12}>
                            <p className='title-orderDetail title-data data-price promo-text'>-{currencyFormatter.format(orderDetail.tord_discount_amount, { code: 'IDR' })}</p>
                        </Col>
                    </Row> : null
                }
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price'>Biaya Kirim</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price'>{orderDetail.tord_ongkir === 0 ? <p>Gratis</p> : <span>{shippingPrice}</span>}</p>
                    </Col>
                </Row>
                {
                    orderDetail.tord_promo_type_id === 101 ? 
                    <Row>
                        <Col span={12}>
                            <div style={{float: 'left'}}>
                                <p className='title-orderDetail data-price promo-text'>Potongan Ongkir</p><br/>
                                <p className='title-orderDetail data-price promo-text'>Kode Promosi:{orderDetail.tord_promo_code}</p>
                            </div>
                        </Col>
                        <Col span={12}>
                            <p className='title-orderDetail title-data data-price promo-text'>-{currencyFormatter.format(orderDetail.tord_discount_amount, { code: 'IDR' })}</p>
                        </Col>
                    </Row> : [orderDetail.tord_promo_type_id === 103 ? 
                    <>
                        <Row>
                            <Col span={12}>
                                <div style={{float: 'left'}}>
                                    <p className='title-orderDetail data-price promo-text'>Potongan Ongkir</p><br/>
                                    <p className='title-orderDetail data-price promo-text'>Kode Promosi:{orderDetail.tord_promo_code}</p>
                                </div>
                            </Col>
                            <Col span={12}>
                                <p className='title-orderDetail title-data data-price promo-text'>-{currencyFormatter.format(orderDetail.tord_discount_amount, { code: 'IDR' })}</p>
                            </Col>
                        </Row>
                    </> : null]
                }
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price'>Biaya Packaging</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price'>{orderDetail.tord_packaging_price === 0 ? <p>Gratis</p> : <span>{packagingPrice}</span>}</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price'>Biaya Administrasi</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price'>{adminPrice}</p>
                    </Col>
                </Row>
                <hr/>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'left'}}>Total Belanja</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'right'}}>{grandTotalPrice}</p>
                    </Col>
                </Row>
                <br/>
                {
                    orderDetail.cashback !== null ? [
                        (orderDetail.cashback.mcb_mcbtype_id === 101 ? 
                            <Row>
                                <Col span={24}>
                                    <Alert className='alert-cashback' message={HtmlParser(orderDetail.cashback.cashback_value)} type="success" showIcon icon={<><img alt="" src={cashbackIcon} style={{position: 'absolute', top: 5, left: 4}}/></>}/>
                                </Col>
                            </Row>
                    : null )]
                : null
                }
                <br/>
                <hr className='hr-row'/>
                <Row>
                    <Col span={12}>
                        <p className='title-head-2' style={{float: 'left'}}>Daftar Pesanan</p>
                    </Col>
                </Row>
                {
                    orderDetail.order_items.map(detail => {
                    return(
                        <Row style={{paddingRight: 5}}>
                            <Col span={6}>
                                {/* <img src={listhistory.order_items[0].torditem_prod_image} alt="" style={{width: 48, height: 48}}/> */}
                                {
                                    detail.mprod_mstatstok_id === 2 ? 
                                    <>
                                    <div className='sold-out-product-history-order'>
                                        <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                                            <div className="imgProduk">    
                                                    <><img src={detail.torditem_prod_image} alt="" style={{width: 48, height: 48}} /><span className='text-sold-out'>Habis</span> </>                                                        
                                            </div>
                                        </Row>
                                    </div>
                                    </> : 
                                    <>
                                        <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                                            <div className="imgProduk">    
                                                <img src={detail.torditem_prod_image} alt="" style={{width: 48, height: 48}} />                                                   
                                            </div>
                                        </Row>
                                    </>
                                }
                            </Col>
                            <Col span={10}>
                                <span className='product-title leftFloat'>{detail.torditem_prod_name}</span><br/>
                                <span className='product-subtitle leftFloat'>{detail.torditem_berat} {detail.msatberat_name}</span>
                            </Col>
                            <Col span={8}>
                                <span className='product-qty rightFloat'>{detail.torditem_qty} {detail.mstatjual_name}</span>
                                <br/>
                                {
                                    detail.mprodpromo_price_value !== null ? 
                                    <div className='disc-product' style={{float: 'right'}}>
                                        <span className="product-price" style={{marginRight: 10, color: '#848484', textDecoration: 'line-through'}}>{ currencyFormatter.format(detail.torditem_harga_satuan, { code: 'IDR' }) }</span>
                                        <span className="product-price">
                                            {currencyFormatter.format(detail.mprodpromo_price_value, { code: 'IDR' })} / {detail.mstatjual_name}
                                        </span>
                                    </div>
                                     : <span className="product-price rightFloat">{currencyFormatter.format(detail.torditem_harga_satuan, { code: 'IDR' })} / {detail.mstatjual_name}</span>
                                }                            
                            </Col>                
                        </Row>
                        
                    )
                    })
                }
                {
                    orderDetail.cashback !== null ? [
                        (orderDetail.cashback.mcb_mcbtype_id === 102 ? 
                            <Row>
                                <Col span={24}>
                                    <Alert className='alert-cashback' message={HtmlParser(orderDetail.cashback.cashback_value)} type="success" showIcon icon={<><img alt="" src={cashbackIcon} style={{position: 'absolute', top: 5, left: 4}}/></>}/>
                                </Col>
                            </Row>
                    : null )]
                : null
                }
                <hr className='hr-row'/>
                <Row>
                    <Col span={12}>
                        <p className='title-head-2' style={{float: 'left'}}>Daftar Penerima</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price' style={{float:'left'}}>Nama</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'left'}}>{orderDetail.tord_penerima_nama}</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price' style={{float:'left'}}>No. Handphone</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'left'}}>{orderDetail.tord_penerima_hp}</p>
                    </Col>
                </Row>
                <hr className='hr-row'/>
                <Row>
                    <Col span={12}>
                        <p className='title-head-2' style={{float: 'left'}}>Daftar Pengiriman</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price' style={{float:'left'}}>Kurir Pengiriman</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'left'}}>{orderDetail.mexpedisi_name}</p>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <p className='title-orderDetail data-price' style={{float:'left'}}>Alamat Pengiriman</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'left', textAlign: 'left'}}>{orderDetail.tord_pengiriman_alamat}</p>
                    </Col>
                </Row>
                <Row style={{paddingBottom: '20%'}}>
                    <Col span={12}>
                        <p className='title-orderDetail data-price' style={{float:'left'}}>Catatan</p>
                    </Col>
                    <Col span={12}>
                        <p className='title-orderDetail title-data data-price' style={{float:'left'}}>{orderDetail.tord_pengiriman_catatan}</p>
                    </Col>
                </Row>
                {
                    orderDetail.tord_status_id === 2 ? [
                        (orderDetail.tord_pay_type_id === 101 || orderDetail.tord_pay_type_id === 104 ? <>
                            <FooterRePayment paymentId={orderDetail.tord_pay_type_id} orderId={id} linkGopay={orderDetail.tord_pay_type_id === 101 ? orderDetail.link_payment_HTTP : null}/>
                        </> : <FooterOrderDetailNonExpired /> )
                    ] : orderDetail.tord_status_id === 9 || orderDetail.tord_status_id === 7 || orderDetail.tord_status_id === 10 ? 
                    <FooterOrderDetailExpired orderId={id} statStok={orderDetail.order_items[0].mprod_mstatstok_id} orderItems={orderDetail.order_items}/> : <FooterOrderDetailNonExpired/>
                
                }
            </div>
        </div>

      )
    }
}

const mapStateToProps = state => {
    return{
        orderData : state.historyOrderDetailReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchDataHistoryOrderDetail: (url, data) => dispatch(fetchDataHistoryOrderDetail(url, data))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps)
(HistoryDetail)
