import { IS_LOADING_MOSTSEARCHPROD_HOME } from "../../ActionType/actionTypes";

export function isLoadingMostSearchProdHome(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_MOSTSEARCHPROD_HOME, 
            payload: {
                isLoadingMostSearchProdHome: param
            }
        })
    }
}