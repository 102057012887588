import React from 'react'
import ForgotPasswordPage from '../components/ForgotPasswordPage/ForgotPassword'
import {NavbarCloseOnly} from '../components/Properties/Navbar/Navbar'
function ForgotPassword() {
    return(
        <div className="App container body-content mobile-view">
            <NavbarCloseOnly link="/login"/>
            <ForgotPasswordPage/>
        </div>
    )
}

export default ForgotPassword