import { IS_LOADING_ABOUT_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingAboutHome: true
}

const isLoadingAboutReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_ABOUT_HOME) {
        return { ...state, isLoadingAboutHome: action.payload.isLoadingAboutHome }
    } else {
        return state
    }
}

export default isLoadingAboutReducer