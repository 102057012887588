import React from 'react'
import {NavbarWithArrowBack} from '../components/Properties/Navbar/Navbar'
import Product from '../components/ProdukPage/ProdukOther';

function ProductOther() {
    return (
        <div className="container body-content mobile-view">
            <NavbarWithArrowBack link="/"/>
            <Product key={Math.random()}/>    
        </div>
    )
}

export default ProductOther
