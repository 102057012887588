import React from 'react'
import { Link, useHistory} from 'react-router-dom'
import {Row, Col, Layout} from 'antd';
const {Footer} = Layout
function FooterVA({order_id}) {
    let history = useHistory();

    function navigateToOrderDetail(){
        history.push(`/RiwayatPesananDetail/${order_id}`)
    }

    return (
        <Layout style={{marginTop: '28%', marginLeft: '-10px'}}>
            <Footer className="footerLayout" style={{fontFamily: 'Montserrat', height: 70}}>
                <Row>                
                    <Col span={24} style={{padding: '15px 4px'}}>
                        <div style={{color: "#fff", backgroundColor: 'transparent'}} className="spanCartLink" onClick={() => navigateToOrderDetail()}>
                            <button className="btnBuy" style={{height: '60px'}}>
                                <span className="spanCart">
                                    Cek Status Pembayaran
                                </span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    )
}

export default FooterVA
