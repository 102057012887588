import React, {useEffect, useState} from 'react'
import {Tooltip, Row, Col, Space, Modal, Layout, message} from 'antd'
import {Form} from 'react-bootstrap'
import './DeliveryMethod.css'
import './PaymentMethodModal.css'
import {getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import encryptData from '../../function/encryptData'
import axios from 'axios';
import Moment from 'react-moment';
import 'moment/locale/id';
import { useHistory, useLocation } from 'react-router-dom'
import moment from 'moment';
import DatePicker from 'react-mobile-datepicker';
import $ from 'jquery'
import calendarIcon from '../../Asset/Icon/feather_calendar.svg'
import ActivateIcon from '../../Asset/Icon/activate-icon.svg'
import { checkoutUserSession } from '../../utils/setSessionUser'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import currencyFormatter from 'currency-formatter';
import {BaseURL, headersWithToken, headersWithoutToken} from '../../function/helpers'
import infoIcon from '../../Asset/Icon/info_icon.svg'
import { CardDeliveryMethod } from '../skeletonLoading/loadingTag'

const {Footer} = Layout
function DeliveryMethod() {
    const [getCustomHolidayDate, setgetCustomHolidayDate] = useState([]);
    const [listEkspedisi, setListEkspedisi] = useState([]);
    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(false);
    const [fieldDisabled, setFieldDisabled] = useState(true);
    const [datePickerValue, setDatePickerValue] = useState(null);
    const [value, setValue] = useState({id: 0, name : '', price: 0});
    const [dateValue, setDateValue] = useState('');
    const [alertSnackbar, setalertSnackbar] = useState(false);
    const [listTimeFrame, setListTimeFrame] = useState([]);
    const [timeFrameValue, setTimeFrameValue] = useState({id: 0, name : ''});
    const [radioButtonDate, setRadioButtonDate] = useState(false);
    const[loadingEkspedisi, setLoadingEkspedisi] = useState(false)
    const handleClose = () => {
        setalertSnackbar(false);
      };

    let history = useHistory();
    let access_token = getToken();
    let arrConvert = [];
    let location = useLocation();

    let ekspedisi_id = checkoutUserSession.getEkspedisiId();;
    let ekspedisi_name = checkoutUserSession.getNameEkspedisi();
    let dateShippingCustom = checkoutUserSession.getDateShipping();
    let shippingPrice = checkoutUserSession.getShippingPrice();
    let timeFrameId = checkoutUserSession.getTimeFrame();

    moment.locale('id');
 
    var timeStampCustomDates = formattedDate(getCustomHolidayDate.date_kirim_custom);
    var formattedDates = moment(timeStampCustomDates).format("dddd, DD MMMM yyyy");
    var timeStampCustomDated = formattedDate(getCustomHolidayDate.date_kirim);
    var formattedDated = moment(timeStampCustomDated).format("dddd, DD MMMM yyyy");

    let radioDefaultDate = $('#rdDatePengiriman').is(':checked');
    let radioCustomDate = $('#radio_customdate').is(':checked');

    useEffect(() => {   
        getHolidayDate();
        setLoadingEkspedisi(true)      
        getListEkspedisi(location.state.datekirim, 1);
        if(dateShippingCustom !== '' && timeFrameId !== ''){
            if(moment(dateShippingCustom).isAfter(getCustomHolidayDate.date_kirim)){
                setFieldDisabled(true);
            }else{
                setFieldDisabled(false);
            }
        }

    },[]);

    if(loading){
        return (
            <LoadingModal/>
        )
    }else{
          if(!loading){
            for(var i = 0; i < getCustomHolidayDate.list_holiday_date.length; i++){
                var dt = getCustomHolidayDate.list_holiday_date[i].date;
                var convDt = formattedDate(dt);
                var finalConv = moment(convDt).format('DD MMMM YYYY');
                arrConvert.push(finalConv);
            }
          } 
    }

    function formattedDate(dt){
        var date = new Date(dt * 1000).toDateString();
        return date;
    }

    function AlertSnackbar(messages){
        message.error({
            content: messages,
            className: 'snackbar-error',
            duration: 3
        });
      };
    

    function enableFieldDisabled(){ 
        let isChecked = $('#radio_customdate').is(':checked');
        if(isChecked){
            let date;
            setLoadingEkspedisi(true) 
            setFieldDisabled(false);
            if(isSameDate){
                date = formattedDates;
            }else{
                if(dateShippingCustom === ''){
                    date = formattedDates;
                }else{
                    date = moment(dateShippingCustom, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY');
                }
            }
            getListEkspedisi(moment(date, "dddd, DD MMMM yyyy").format("YYYY-MM-DD"), 1);
            let tgl = moment(date, "dddd, DD MMMM yyyy").format("DD MMMM YYYY");
            let momt = moment(tgl).isAfter(formattedDates);
            if(momt){
                setDateValue(tgl);
            }else{
                setDateValue(moment(date, "dddd, DD MMMM yyyy").format("YYYY-MM-DD"));
            }
        }else{

        }
    }

    function disableFieldDisabled(){
        let isChecked = $('#rdDatePengiriman').is(':checked');
        if(isChecked){
            setDatePickerValue(null);
            setFieldDisabled(true);
            getListEkspedisi(moment(formattedDated, "dddd, DD MMMM yyyy").format("YYYY-MM-DD"), 1);
            setLoadingEkspedisi(true) 
            setDateValue(moment(formattedDated, "dddd, DD MMMM yyyy").format("YYYY-MM-DD"));
        }else{

        }
    }

        function onClosed(){
        setVisible(false);
    }

    function openCustomDate(){
        setVisible(true);
        setFieldDisabled(false);
    }

    
    function chooseTgl(date) {
        setLoadingEkspedisi(true) 
        getListEkspedisi(date, 1);
        $('#inpFieldDate').val(date);
        setVisible(false);
    }
    
    function acceptDate(newValue){  
        var date = new Date()
        var newDate = moment(newValue, "ddd, dd MMM yyyy HH':'mm':'ss 'GMT'").format('DD MMMM yyyy');
        var minDate = moment(minDatePicker).format('DD MMMM yyyy');
        var maxDate = moment(maxDatePicker).format('DD MMMM yyyy');
        var dateRn = moment(date, "ddd, dd MMM yyyy HH':'mm':'ss 'GMT'").format('DD MMMM yyyy');
        var myobj = arrConvert.find(obj => obj === newDate);
        if(dateRn === newDate){
            AlertSnackbar('Maaf, tanggal pengiriman tidak boleh sama dengan hari ini');
            return
        }
        if(myobj !== undefined){
            AlertSnackbar('Maaf, tanggal yang anda pilih berada di daftar libur kami')
            return
        }
        if(moment(newDate).isBefore(minDate)){
            AlertSnackbar('Maaf, tanggal yang anda pilih tidak dapat kurang dari tanggal minimal');
            return
        }
        if(moment(newDate).isAfter(maxDate)){
            AlertSnackbar('Maaf, tanggal yang anda pilih tidak dapat lebih dari tanggal maksimal');
            return
        }
        setDateValue(moment(newDate, "DD MMMM YYYY").format("YYYY-MM-DD"));
        setDatePickerValue(moment(newDate, "DD MMMM YYYY").format("YYYY-MM-DD"));
        chooseTgl(newValue);
        $('#radio_customdate').prop("checked", true);
    }

    function chooseTimeFrame(id, value, isAvailable){
        if(isAvailable){
            let allEl = $('.time-frame-box');
            allEl.removeClass('activated_border');
            $('.timeframe-iconActivated').attr('src', '');
    
            $('#timeFrameBox_'+id).addClass('activated_border');
            $('#timeFrameIcon_'+id).attr('src', ActivateIcon); 
    
            setTimeFrameValue({id: id, name : value});
        }
    }

    function activateBox(id, price, name){
        let timeframe;
        let allEl = $('#list-ekspedisi .box');
        allEl.removeClass('activated_border');
        $('.iconActivated').attr('src', '');
        var dt;
        if(datePickerValue === null){
            if(ekspedisi_id !== 0){
                let tgl = moment(dateShippingCustom).format("dddd, DD MMMM YYYY");
                let momt = moment(tgl).isAfter(formattedDates);
                if(momt){                    
                    dt = dateValue
                }else{
                    dt = dateValue
                }
            }else{
                dt = dateValue;
            }
        }else{
            dt = datePickerValue;
        }

        if(fieldDisabled){
            getListEkspedisi(location.state.datekirim, 2, id)
        }else{
            getListEkspedisi(dateValue, 2, id)
        }

        if(timeframe !== undefined){
            timeframe = listEkspedisi.find(q => q.expedisi_id === id);
            setListTimeFrame(timeframe.timeframe)
            $('#timeFrameBox_'+timeframe.timeframe[0].id).addClass('activated_border');
            $('#timeFrameIcon_'+timeframe.timeframe[0].id).attr('src', ActivateIcon); 
        }else{
            setListTimeFrame([])
        }

        setDateValue(dt);
        setValue({id:id, name: name, price: price});
        
        $('#box_'+id).addClass('activated_border');
        $('#iconActivated'+id).attr('src', ActivateIcon); 
        
        
    }

    function backToPageCheckout() {
        checkoutUserSession.setTimeFrame("");
        if(value.id === 102){
            getDepoList();
        }else{
            if(location.state.fromSelfPickup){
                checkoutUserSession.setAddress("")
                checkoutUserSession.setAddressId(0)
                checkoutUserSession.setAddressName("")
                checkoutUserSession.setReceiverMobile("")
                checkoutUserSession.setReceiverName("")
            }            
        }
        var myobj = arrConvert.find(obj => obj === dateValue);
        if(myobj === undefined){
            checkoutUserSession.setEkspedisiId(value.id);
            checkoutUserSession.setNameEkspedisi(value.name);
            checkoutUserSession.setDateShipping(dateValue);  

            let isNotExist = listTimeFrame.find(q => q.is_available === true);

            if(value.id === 102 || listTimeFrame.length === 0 || isNotExist === undefined){
                checkoutUserSession.setTimeFrame("-1");
            }else{                
                checkoutUserSession.setTimeFrame(timeFrameValue);  
            }     
            if(value.price === 0){
                checkoutUserSession.setShippingPrice(-1);
            }else{
                checkoutUserSession.setShippingPrice(value.price);
            }
            
            history.push({pathname: '/checkout'});
            
        }else{
            AlertSnackbar('Maaf, tanggal yang anda pilih berada di daftar libur kami')
            setalertSnackbar(true);
        }
    }

    async function getHolidayDate() {
        const signature = base64Sign("/checkout/getcustomdateholidayexpedisi");            
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/checkout/getcustomdateholidayexpedisi',{data:""},{headers:headers}).then(data=>{
            setgetCustomHolidayDate(data.data.response_data.results);
            var timeStampCustomDate = formattedDate(data.data.response_data.results.date_kirim);
            var formattedDateDateKirim = moment(timeStampCustomDate).format("dddd, DD MMMM yyyy")
            if(ekspedisi_id !== 0){
                if(dateShippingCustom === ''){
                    setDateValue(formattedDateDateKirim);
                }else{
                    setDateValue(dateShippingCustom);
                }
            }else{
                setDateValue(moment(formattedDateDateKirim, "dddd, DD MMMM yyyy").format("YYYY-MM-DD"));
            }
            setLoading(false);
        }).catch(err => console.log(err));

    }

    async function getListEkspedisi(date, flag, id) {
        var dateConv = moment(date).format('YYYY-MM-DD');
        let timeFrameData;
        const signature = base64Sign("/checkout/getlistexpedisibydate"); 
        const dataParams = encryptData(`{"address_id":${location.state.address_id}, "date_kirim": "${dateConv}"}`);           
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/checkout/getlistexpedisibydate',{data:dataParams},{headers:headers}).then(data=>{
            setListEkspedisi(data.data.response_data.results);            
            setLoadingEkspedisi(false)
            var datas = data.data.response_data.results[0];
            // if(flag === 1){
                if(ekspedisi_id !== 0){                                                       
                    if(flag !== 2){
                        timeFrameData = data.data.response_data.results.find(q => q.expedisi_id === ekspedisi_id);
                        $('#box_'+ekspedisi_id).addClass('activated_border');
                        $('#iconActivated'+ekspedisi_id).attr('src', ActivateIcon);
                        if(timeFrameId !== ''){
                            setValue({id:timeFrameData.expedisi_id, name: timeFrameData.nama, price: timeFrameData.price}); 
                        }else{
                            if(location.state.fromSelfPickup){
                                setValue({id:102, name: "Ambil Sendiri", price: 0});
                            }else{
                                setValue({id:datas.expedisi_id, name: datas.nama, price: datas.price});
                            }
                        }
                    }else{
                        timeFrameData = data.data.response_data.results.find(q => q.expedisi_id === id);
                        $('#box_'+id).addClass('activated_border');
                        $('#iconActivated'+id).attr('src', ActivateIcon);
                        setValue({id:timeFrameData.expedisi_id, name: timeFrameData.nama, price: timeFrameData.price}); 
                    }
                }else{                    
                    if(flag !== 2){
                        timeFrameData = data.data.response_data.results.find(q => q.expedisi_id === datas.expedisi_id);
                        $('#box_'+datas.expedisi_id).addClass('activated_border');
                        $('#iconActivated'+datas.expedisi_id).attr('src', ActivateIcon);
                        if(timeFrameId !== ''){
                            setValue({id:timeFrameData.expedisi_id, name: timeFrameData.nama, price: timeFrameData.price}); 
                        }else{
                            setValue({id:datas.expedisi_id, name: datas.nama, price: datas.price}); 
                        }
                    }else{
                        timeFrameData = data.data.response_data.results.find(q => q.expedisi_id === id);
                        $('#box_'+id).addClass('activated_border');
                        $('#iconActivated'+id).attr('src', ActivateIcon); 
                        setValue({id:timeFrameData.expedisi_id, name: timeFrameData.nama, price: timeFrameData.price}); 
                    }
                }            
            let firstIndexTimeFrame = timeFrameData.timeframe.find(q => q.is_available === true);
            let idIsExist = timeFrameData.timeframe.find(q => q.id === timeFrameId.id);
            setListTimeFrame(timeFrameData.timeframe);           
            setLoading(false)
            if(timeFrameData.timeframe.length !== 0){
                setTimeFrameValue({id:firstIndexTimeFrame.id, name: firstIndexTimeFrame.name});
                // if(dateShippingCustom !== formattedDated){setFieldDisabled(true)}
                if(idIsExist === undefined){
                    $('#timeFrameBox_'+firstIndexTimeFrame.id).addClass('activated_border');
                    $('#timeFrameIcon_'+firstIndexTimeFrame.id).attr('src', ActivateIcon);
                }else{
                    if(timeFrameId !== ''){
                        $('#timeFrameBox_'+timeFrameId.id).addClass('activated_border');
                        $('#timeFrameIcon_'+timeFrameId.id).attr('src', ActivateIcon); 
                    }else{
                        $('#timeFrameBox_'+firstIndexTimeFrame.id).addClass('activated_border');
                        $('#timeFrameIcon_'+firstIndexTimeFrame.id).attr('src', ActivateIcon); 
                    }
                }
            }
        }).catch(err => console.log(err));

    }

    async function getDepoList() {
        const signature = base64Sign("/checkout/getdepolist");        
        const headers = headersWithToken(signature, access_token);        
        axios
        .post(BaseURL + '/checkout/getdepolist',{data:null},{headers:headers}).then(data=>{
            var datas = data.data.response_data.results[0];            
            checkoutUserSession.setAddress(datas.address);
            checkoutUserSession.setAddressId(datas.address_id);
            checkoutUserSession.setAddressIsDefault(datas.address_is_default);
            checkoutUserSession.setLat(datas.address_lat);
            checkoutUserSession.setLng(datas.address_lng);
            checkoutUserSession.setAddressName(datas.address_name);
            checkoutUserSession.setReceiverMobile(datas.receiver_mobile);
            checkoutUserSession.setReceiverName(datas.receiver_name);
            setLoading(false)
        }).catch(err => console.log(err));

    }
    
    var maxKirim = formattedDate(getCustomHolidayDate.max_date_kirim_custom);
    var minKirim = formattedDate(getCustomHolidayDate.min_date_kirim_custom);
    var maxDatePicker = maxKirim
    var minDatePicker = minKirim

    const monthMap = {
    '1': 'Januari',
    '2': 'Februari',
    '3': 'Maret',
    '4': 'April',
    '5': 'Mei', 
    '6': 'Juni',
    '7': 'Juli',
    '8': 'Agustus',
    '9': 'September',
    '10': 'October',
    '11': 'November',
    '12': 'Desember',
    };
     
    const dateConfig = {        
        'date': {
            format: 'DD',
            caption: 'Day',
            step: 1,
        },
        'month': {
            format: value => monthMap[value.getMonth() + 1],
            caption: 'Mon',
            step: 1,
        },
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        }
    };

    var isgreater = moment(timeStampCustomDated).format("DD MMMM YYYY");
    var isgreater2 = moment(dateShippingCustom).format("DD MMMM YYYY");
    let isgreatesRes = moment(isgreater2).isSameOrAfter(isgreater);
    let isgreat = moment(isgreater2).isSameOrBefore(dateValue)
    let isSameDate = moment(isgreater2).isSame(isgreater);
    let form = moment(dateValue).format("DD MMMM YYYY");
    let iss = moment(isgreater2).isSameOrAfter(form);

    var isNotAvailable = listTimeFrame.find(q => q.is_available === false);
    // // console.log(dateShippingCustom,moment(timeStampCustomDated).format("DD MMMM YYYY"));
    // console.log(dateValue)
    return (
        <div className="container-delivery-method">
            <div className="div-title">
                <h3 className="title-delivery">
                    Metode Pengiriman
                </h3>
            </div>
            <section className="main" style={{minHeight : 0}}>                
                <label className="title-delivery" style={{textAlign: 'center'}}>Atur Tanggal Pengiriman</label>
                <div>
                    <form>
                        <table className="tblDeliveryDate">
                            <tbody>
                                <tr>
                                    <td className="dateDelivery" style={{width: '70%'}}><Moment format="dddd, DD MMMM yyyy">{formattedDate(getCustomHolidayDate.date_kirim)}</Moment></td>
                                    <td style={{textAlign: 'right', paddingRight: 20}}>
                                    <input type="radio" name="radio" id="rdDatePengiriman" defaultChecked={dateShippingCustom === '' ? true : isSameDate ? true : false} onClick={radioDefaultDate ? null : () => disableFieldDisabled()}/>
                                    </td>
                                </tr>                               
                                {/* <tr> */}
                                {
                                    radioDefaultDate ? 
                                    (loadingEkspedisi ? <></> : 
                                        [listTimeFrame.length === 0 ? <></> : 
                                        <>
                                        <div className='time-frame-section'>
                                            <span style={{fontFamily: 8}}>Pilih Waktu Pengiriman</span>                                    
                                            <div style={{flexWrap: 'wrap', placeContent: 'center', width: '115%', marginTop: -25}}>
                                                {
                                                    listTimeFrame.map((item, index) => {
                                                        return(
                                                        <div className="time-frame-box" key={item.id} id={"timeFrameBox_"+item.id} onClick={() => chooseTimeFrame(item.id, item.name, item.is_available)} style={item.is_available ? null : {backgroundColor: '#F4F4F4'}}>                                        
                                                            <p style={item.is_available ? {fontWeight: 600} : {fontWeight: 600, color: '#848484'}} className='time-frame-time'>
                                                                {item.name}                                        
                                                            </p>
                                                            <div style={{float: 'right', marginRight: 25}}>
                                                                <img src="" className="timeframe-iconActivated" style={{marginTop: -20}} alt=""  id={"timeFrameIcon_"+item.id}/>
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                }
                                            </div>                                            
                                        </div>
                                        <tr>
                                            {
                                                isNotAvailable !== undefined ? <td colSpan={2}><span className="title-hint" style={{marginLeft: 15}}><img src={infoIcon} alt=""/> Slot waktu kirim yang penuh tidak dapat dipilih</span></td> : null
                                            }
                                        </tr>
                                        </>]
                                        ) : <></>
                                }
                                {/* </tr> */}
                               
                                <tr>
                                    <td style={{width: '76%'}}>
                                        <div className="custom-tooltip">
                                            <span style={{verticalAlign: 'super'}}>Custom</span>
                                            <i className="information">
                                                <Tooltip placement="right" title="Jadwal pengiriman hingga 3 bulan kedepan">
                                                    <img src={infoIcon} className="imgTooltip" alt=""/>
                                                </Tooltip>
                                            </i>
                                        </div>
                                        <div style={{marginLeft: '23px'}}>
                                            <Form>
                                                <Form.Group>
                                                <Form.Control value={
                                                    ekspedisi_id === 0 ? 
                                                        datePickerValue === null ? 
                                                            formattedDates : 
                                                            moment(datePickerValue, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY')
                                                        : (datePickerValue === null ? [isSameDate ? formattedDates  : (dateShippingCustom === '' ? formattedDates : moment(dateShippingCustom, "YYYY-MM-DD").format("dddd, DD MMMM YYYY")) ]
                                                    : moment(datePickerValue, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY'))} 
                                                    id="inpFieldDate" onClick={() => openCustomDate()} />                                                                                    
                                                </Form.Group>
                                                <div className="div-calendar" >
                                                    <span>
                                                        <img src={calendarIcon} alt=""/>
                                                    </span>
                                                </div> 
                                            </Form>
                                        </div>                                      
                                    </td>
                                    <td style={{textAlign: 'right', paddingRight: 20}}>
                                        <input type="radio" name="radio" id="radio_customdate" onClick={radioCustomDate ? null : () => enableFieldDisabled()} defaultChecked={dateShippingCustom !== '' ? isSameDate ? false : true : false}/>
                                    </td>
                                </tr>
                                {
                                    radioCustomDate ? 
                                    (loadingEkspedisi ? null : 
                                        [listTimeFrame.length === 0 ? null : 
                                        <>
                                        <div className='time-frame-section'>
                                            <span style={{fontFamily: 8}}>Pilih Waktu Pengiriman</span>                                    
                                            <div style={{flexWrap: 'wrap', placeContent: 'center', width: '115%', marginTop: -25}}>
                                                {
                                                    listTimeFrame.map((item, index) => {
                                                        return(
                                                        <div className="time-frame-box" key={item.id} id={"timeFrameBox_"+item.id} onClick={() => chooseTimeFrame(item.id, item.name, item.is_available)} style={item.is_available ? null : {backgroundColor: '#F4F4F4'}}>                                        
                                                            <p style={item.is_available ? {fontWeight: 600} : {fontWeight: 600, color: '#848484'}} className='time-frame-time'>
                                                                {item.name}                                        
                                                            </p>
                                                            <div style={{float: 'right', marginRight: 25}}>
                                                                <img src="" className="timeframe-iconActivated" style={{marginTop: -20}} alt=""  id={"timeFrameIcon_"+item.id}/>
                                                            </div>
                                                        </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        <tr>
                                            {
                                                isNotAvailable !== undefined ? <td colSpan={2}><span className="title-hint" style={{marginLeft: 15}}><img src={infoIcon} alt=""/> Slot waktu kirim yang penuh tidak dapat dipilih</span></td> : null
                                            }
                                        </tr>
                                        </>]
                                        ) : <></>
                                }                                
                            </tbody>
                        </table>
                    </form>
                </div>
            </section>
            <br/>
            <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
            <section className="main" style={{padding: '0px 10px 0px', minHeight: 'calc(80vh - 90px)'}}>
                <label className="title-delivery" style={{textAlign: 'center'}}>Pilih Ekspedisi</label> 
                    <div id="list-ekspedisi">
                        <Row>
                            <Col span={24}>
                                {
                                loadingEkspedisi ? <CardDeliveryMethod/> : 
                                listEkspedisi.map(ekspedisi => {
                                    return (
                                    <div className="box" id={"box_"+ekspedisi.expedisi_id} onClick={() => activateBox(ekspedisi.expedisi_id, ekspedisi.price, ekspedisi.nama)}>
                                        <div style={{float: 'left'}}>
                                            <b>{ekspedisi.nama}({ekspedisi.price === 0 ? <b>Gratis</b> : <b>{currencyFormatter.format(ekspedisi.price, {code: 'IDR'})}</b>})</b>
                                            <br/>
                                            <br/>
                                            <span style={{fontSize: 12}}>{ekspedisi.expedisi_id === 102 ? <span>Batas Pengambilan</span> : <span>Akan tiba</span>} </span><span style={{fontSize: 12}} className="lbl-arrdate">
                                                {
                                                    ekspedisi_id === 0 ? 
                                                        datePickerValue === null ?  moment(dateValue, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY')  
                                                        : moment(datePickerValue, 'YYYY-MM-DD').format('dddd, DD MMMM YYYY') 
                                                    : 
                                                    isgreatesRes ? [isgreat ? 
                                                        moment(dateValue, "YYYY-MM-DD").format("dddd, DD MMMM YYYY") : ( iss ? moment(dateValue, "YYYY-MM-DD").format("dddd, DD MMMM YYYY")  : moment(dateValue, "YYYY-MM-DD").format("dddd, DD MMMM YYYY"))] 
                                                    : moment(dateValue, "YYYY-MM-DD").format("dddd, DD MMMM YYYY")
                                                }
                                            </span>
                                        </div>
                                        <div style={{float: 'right'}}>
                                                <img src="" id={"iconActivated"+ekspedisi.expedisi_id} className="iconActivated" alt=""/>
                                        </div>
                                        <div>
    
                                        </div>
                                    </div>
                                    )
                                })
                            }
                            </Col>
                        </Row>
                    </div>
            </section>
            <br/>
            <br/>
            <br/>
            <div className="div-modal-delivery-method">
                <Modal
                    title="Tanggal Pengiriman"
                    visible={visible}
                    // onOk={closedModal}
                    onCancel={onClosed}
                    className="modalAddCart modal-delivery-method" 
                    style={{borderRadius: 15}}
                    footer={null}  
                    >
                    <table style={{width: '100%', margin: '0 auto'}} className="tbl-delivery-method">
                        <tbody>
                            <tr> 
                                <td>
                                <DatePicker
                                    value={new Date()}
                                    isOpen={false}
                                    isPopup={false}
                                    onSelect={acceptDate}
                                    onCancel={null}
                                    cancelText=""
                                    showHeader={false}
                                    dateFormat={["DD", "MM", "YYYY"]}
                                    confirmText="Pilih Tanggal"
                                    dateConfig={dateConfig}
                                    showFooter={true}
                                    theme="default"
                                    className="datePickerDeliveryMethod"
                                    style={{marginBottom: '112px'}}
                                    min={new Date(minDatePicker)}
                                    max={new Date(maxDatePicker)}
                                />
                                </td>
                            </tr>                     
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                </Modal>
            </div>

            <div className="div-modal-onError">
                {alertSnackbar ? (
                        <Modal className="modal-on" type="error" closable afterClose={handleClose} style={{left: '3%'}}/>
                    ) : null}
            </div>

            <Layout style={{marginTop: 60}}>
                <Footer className="footerLayout" style={{fontFamily: 'Montserrat', height: 50, marginLeft: -15, marginTop: 30}}>
                    <button className="btnKeranjang" style={{width: '100%'}} onClick={() => backToPageCheckout()}>Pilih</button>
                </Footer>
            </Layout>            
        </div>
    )
}

export default DeliveryMethod
