import React from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu, Row, Col } from 'antd'
import arrowBackHeader from '../Asset/Icon/back_header.svg'
import iconFeatherMenu from '../Asset/Icon/logo/feather_menu.svg'

const { Header } = Layout

function Header2() {

    function goToBack() {
        window.location.href = 'javascript:history.go(-1)';
    }

    return (
        <div>
            <Layout>
                <Header style={{ padding: 0, position: 'fixed', zIndex: 1, height: 49, boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.05)" }}>
                    <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']}>
                        <Row style={{ width: 480 }}>
                            <Col span={12} style={{ paddingLeft: 5, marginTop: -5 }}>
                                <img onClick={goToBack} src={arrowBackHeader} alt="arrowBack" />
                            </Col>
                            <Col span={12} style={{ marginTop: -5, paddingLeft: 180 }}>
                                <Link to="/menu">
                                    <img src={iconFeatherMenu} alt="menu" height='60px' />
                                </Link>
                            </Col>
                        </Row>
                    </Menu>
                </Header>
            </Layout>
        </div>
    )
}

export default Header2
