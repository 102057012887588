import React, {useEffect} from 'react'
import './ProcessTopUp.css'
import {Collapse, message} from  'antd'
import { useLocation } from 'react-router-dom';
import {fetchEzeeTunaiBankDetail} from '../../redux/ActionCreators/EzeeTunai/ezeeTunaiGetBankDetailActions'
import { connect } from 'react-redux'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import ReactHtmlParser from 'react-html-parser';
import salinIcon from '../../Asset/Icon/salin_icon.png'

const { Panel } = Collapse;

function ProcessTopUp({ bankDetail, fetchEzeeTunaiBankDetail}) {
    
    useEffect(() => {
        fetchEzeeTunaiBankDetail('/ezeetunai/topupbankdetail', `{"bank_id": ${bankId}}`)
    } ,[])

    let location = useLocation();
    let bankId = location.state.bankId;
    let isCheckout = location.state.bankId;

    if(bankDetail.loading){
        return(
            <LoadingModal/>
        )
    }

    function AlertSnackbar(messages){
        message.error({
            content: messages,
            className: 'snackbar-success',
            duration: 3
        });
      };

    function copyVAnumber() {
        var copyText = bankDetail.data.prefix+bankDetail.data.code;
        navigator.clipboard.writeText(copyText);

        AlertSnackbar("Nomor Virtual Account berhasil tersalin");
      }

    return (
        <div style={{paddingTop: 50, marginLeft: 15, marginRight: 15}}>
            <div style={{textAlign: 'center', paddingTop: '10%'}}>
                <span className='head-2'>Akun Virtual BCA</span>
                <br/>
                <img src={bankDetail.data.bank_logo} alt="" style={{marginBottom: 20, marginTop: '10%', width: 155}}/>
                <br/>
                <p style={{fontSize: 13, color: 'grey', fontWeight: 'bold'}}>Nomor Akun Virtual BCA</p>                
                <p className='textValue' style={{fontSize: 18, fontWeight: 600, display: 'inline-block', letterSpacing: -2}}>{bankDetail.data.prefix}{bankDetail.data.code}</p>
                <div>
                    <img src={salinIcon} onClick={() => copyVAnumber()} alt="" style={{height: 30, cursor: 'pointer'}}/>
                </div>
            </div>
            <hr className='hrTopUp'/>
            <div className='topup-instruction'>
                <span className='head-2' style={{fontSize: 16}}>Panduan Pembayaran</span>
                <Collapse defaultActiveKey={[1]} expandIconPosition='right' className='collapse-virtual-acc' bordered={false} accordion>
                    {
                        bankDetail.data.howtopay.map((item, index) => {
                            return(
                            <Panel header={item.mpayinst_name} key={item.mpayinst_id}>
                                <table className='table-content' key={index}>
                                    <tbody key={index}>
                                        {
                                            item.payment_instruction.map((datas, index) => {
                                                return(
                                                    <tr className='tr-table-height' key={index}>
                                                        <td style={{verticalAlign: 'top'}}>{index + 1}.</td>
                                                        <td style={{paddingLeft: 6, verticalAlign:'top'}}>{ReactHtmlParser(datas)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Panel>                                
                            )
                        })
                    }
                </Collapse>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return{
        bankDetail : state.ezeeTunaiGetBankDetailReducer
    }
}

const mapDispatchToProps = dispatch => {
    return{
        fetchEzeeTunaiBankDetail : (url, data) => dispatch(fetchEzeeTunaiBankDetail(url, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProcessTopUp)
