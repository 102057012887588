import {FETCH_DATA_PRODUCTLISTLAYER2} from '../ActionType/actionTypes'

const initialState = {
    prodListsLayer2: []
}

const productListsLayer2Reducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_PRODUCTLISTLAYER2) {
        return { ...state, prodListsLayer2: action.payload.prodListsLayer2}
    } else {
        return state
    }
}

export default productListsLayer2Reducer