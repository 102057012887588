import React, {useState, useEffect}from 'react'
import { Alert, message} from 'antd'
import './PromoCode.css'
import { Link } from 'react-router-dom'
import { useHistory, useLocation} from 'react-router-dom'
import {checkoutUserSession, getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import discountIcon from '../../Asset/Icon/discount-icon.svg'
import FlatOngkirIcon from '../../Asset/Icon/flat_ongkir_icon.svg'
import warningIcon from '../../Asset/Icon/icon-warning-text.svg'
import $ from 'jquery'
import encryptData from '../../function/encryptData';
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import {BaseURL, headersWithToken} from '../../function/helpers'
import ErrorList from 'antd/lib/form/ErrorList'

function PromoCode() {
    const [promoList, setPromoList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [promoCode, setPromoCode] = useState('');
    const [errorInfo, setErrorinfo] = useState('');
    const [promoCodeIsUsed, setPromoCodeIsUsed] = useState(false);
    const [promoDetail, setPromoDetail] = useState({type : '', name : '', price : 0, priceAfPromo : 0, priceBfPromo : 0});    
    const [visible, setVisible] = useState(false);
    const handleClose = () => {
      setVisible(false);
    };
    let access_token = getToken();
    let history = useHistory();
    let location = useLocation();
    var datas;

    var addressId = location.state.addressId;
    var expedisiId = location.state.expedisiId;
    var paymentId = location.state.paymentId;
    var promoCodeId = location.state.promoCodeId;
    var isUsed = location.state.isUsed;
    var ekspedisi = location.state.ekspedisi;
    let promoCodeIdUsed = checkoutUserSession.getPromoId();

    useEffect(() => {
        getPromoList();
        if(promoCodeId !== 0){
            setPromoCodeIsUsed(true);
        }
        if(isUsed){
            setPromoCodeIsUsed(true);
        }
    },[])


    function errorPromoCode(messages){
        message.error({
            content: messages,
            className: 'checkoutSnackbarError',
            duration: 3
        });
      };

    if(paymentId === 0){
        paymentId = -1
    }

    async function getPromoList() {
        const signature = base64Sign("/promo/getlist");            
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/promo/getlist',{data:""},{headers:headers}).then(data=>{
            setPromoList(data.data.response_data.results);                   
            setLoading(false)
        }).catch(err => console.log(err));
    }

    async function usePromoAPI(promo_code, flag) {
        try{
            const signature = base64Sign("/promo/usepromo"); 
            const dataParams = encryptData(`{"promo_code":"${promo_code}", "address_id": ${addressId}, "expedisi_id": ${expedisiId}, "payment_id": ${paymentId}}`);           
            const headers = headersWithToken(signature, access_token);
            const data = await axios.post(BaseURL + '/promo/usepromo',{data: dataParams},{headers : headers});
            if(data.status === 200){
                datas = data.data.response_data.results;            
                setPromoDetail({type : datas.mpromotype_name, name : datas.mpromcod_name, price : datas.total_promo_amount, priceAfPromo : datas.total_bayar, priceBfPromo : datas.total_transaksi});                         
                checkoutUserSession.setPromoId(datas.mpromcod_id);
                checkoutUserSession.setPromoType(datas.mpromotype_name);
                checkoutUserSession.setPromoTypeId(datas.mpromcod_promo_type_id);
                checkoutUserSession.setPromoName(datas.mpromcod_code);
                checkoutUserSession.setPromoPrice(datas.total_promo_amount);
                checkoutUserSession.settotalPriceAfterPromo(datas.total_bayar);
                checkoutUserSession.settotalPriceBeforePromo(datas.total_transaksi); 
                history.push('/checkout', {isPromoCodeUsed : true, addrId : addressId, ekspedisi : ekspedisi});   
            }else{
                if(data.response.status === 400){
                    // if(data.response.data.response_data.error_id === '0169'){
                        if(flag === 0){
                            $('#txt-promo-code').css('border', '1px solid red');   
                            setErrorinfo(data.response.data.response_message);
                        }else if(flag === 1){
                            errorPromoCode(data.response.data.response_message);
                        }  
                    // } 
                }
            }
            setLoading(false)
        }catch(err){
            console.log(err)
        }
    }

    if(loading){
        return(
            <LoadingModal/>
        )
    }

    function UsePromoCodeAction(promo_code){
        usePromoAPI(promo_code, 1);
    }
    
    function unusePromoCode(id){
        checkoutUserSession.setPromoId(0);
        checkoutUserSession.setPromoTypeId(0);
        checkoutUserSession.setPromoType('');
        checkoutUserSession.setPromoName('');
        checkoutUserSession.setPromoPrice(0);
        checkoutUserSession.settotalPriceAfterPromo(0);
        checkoutUserSession.settotalPriceBeforePromo(0);
        setPromoCodeIsUsed(false);
    }

    function UsePromoByTyping(){
        var txtpromocode = $('#txt-promo-code').val();
        usePromoAPI(txtpromocode, 0);
    }

    return (
        <div>
            <section>
                <div className="text-center">
                    <span className="promocode-head2" style={{fontWeight: 600, fontSize: 18, color: '#393939'}}>Lebih Hemat Pakai Promo</span> 
                </div>
                <br/>
                <br/>
                <div className="promo-code">
                    <span className="sub-titles">Masukkan Kode Promo</span>
                    <input type="text" id="txt-promo-code" onChange={(e) => setPromoCode(e.target.value)}></input>
                    <input type="button" onClick={() => UsePromoByTyping()} id="btn-promo-code" value="Gunakan" className={promoCode.length < 2 ? 'disabled' : ''}></input>
                    <br/>
                    <span className="promocode-example">Contoh: GR4T1S0NGK1R</span>
                    {errorInfo !== '' ? 
                        <div className="error-info" id="error-element-id">
                            <img src={warningIcon} alt="warning" style={{width: 14, height: 14, marginLeft: 15}}/>&nbsp;
                            <span className="error-info-title">{errorInfo}</span>
                        </div> : null}
                </div>
            </section>
            <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
            <section className="promocode-list-section">
                <div className="promocode-list">
                    <h5 className="title-promo">Pilih Promo</h5>
                    {
                        promoList.map(promo => {
                            return(
                            <div className="box">
                                <table>
                                    <tbody>
                                        <tr>
                                            <td className="">
                                                <e className="icon discount-icon">
                                                    <img src={promo.mpromcod_promo_type_id === 102 ? discountIcon : FlatOngkirIcon} style={{float: 'left'}} alt=""/>
                                                </e>    
                                            </td>
                                            <td className="info-promo">
                                                <h4>{promo.mpromcod_name}</h4>
                                                <p>Valid until {promo.mpromcod_period_to_format}</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <Link to={{ pathname: '/promo-code-detail', state: { guid: promo.mpromcod_guid, addressId: addressId, expedisiId : expedisiId, paymentId: paymentId, isUsed : promoCodeIsUsed}}} style={{backgroundColor: 'transparent', border: 'unset'}}><span className="link_">Lihat Detail</span></Link>
                                {
                                    promoCodeIsUsed && promo.mpromcod_id === promoCodeIdUsed? [
                                        (promoCodeIdUsed === promo.mpromcod_id ? <button className="used-promo" onClick={() => unusePromoCode(promo.mpromcod_code)}>Batal</button> : <button className="use-promo" onClick={() => UsePromoCodeAction(promo.mpromcod_code)}>Gunakan</button>)
                                    ] : <button className="use-promo" onClick={() => UsePromoCodeAction(promo.mpromcod_code)}>Gunakan</button>
                                }
                            </div>
                            )
                        })
                    }
                </div>
                {/* {visible ? (
                    <Alert message={errorInfo} type="error" closable afterClose={handleClose} />
                ) : null} */}
            </section>
        </div>
    )
}

export default PromoCode
