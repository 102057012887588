import React from 'react'
import TopUpEzeeTunaiHeadOffice from '../../components/EzeeTunai/TopUpEzeeTunaiHeadOffice'
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'

function TopUpHeadOffice() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link="/back"/>
            <TopUpEzeeTunaiHeadOffice/>
        </div>
    )
}

export default TopUpHeadOffice
