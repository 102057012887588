import React from 'react'
import {NavbarWithArrowBack} from '../components/Properties/Navbar/Navbar'
import Product from '../components/ProdukPage/Produk';

function Produk() {
    return (
        <div className="container body-content mobile-view">
            <Product key={Math.random()}/>     
        </div>
    )
}

export default Produk
