import React from 'react'
import { Col, Layout, Row } from 'antd'
import { Link } from 'react-router-dom'
import ezTunaiLinked from '../../Asset/Icon/ezeeTunai/EWallet_linked.svg'
import { getToken } from '../../utils/setSessionUser'
import axios from 'axios'
import { BaseURL, headersWithToken } from '../../function/helpers'
import base64Sign from '../../function/base64Sign'
import encryptData from '../../function/encryptData'
import { useEffect } from 'react'

const {Footer} = Layout

function EzeeTunaiBinded() {

    const access_token = getToken()

    async function checkStatusBinding() {
        try {
            const signature = base64Sign("/EzeeTunaiSnap/CheckStatusBinding"); 
            // const params = encryptData(`{"mcust_mobile": "${mobile}"}`)
            const headers = headersWithToken(signature, access_token);
            const statusBinding = await axios.post(BaseURL + "/EzeeTunaiSnap/CheckStatusBinding", {data: ''}, {headers: headers})
            console.log(statusBinding, 'statusBinding');
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        checkStatusBinding()
    }, [])
    

    return (
        <div className="container body-content mobile-view d-flex justify-content-center align-items-center text-center">
            <div>
                <img src={ezTunaiLinked} width='auto' height='auto' />
                <div style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700, width: 328, marginTop: 19 }}>
                    Akun ezeetunai anda Telah Berhasil Terhubung
                </div>
            </div>
            <Layout style={{ display: 'contents' }}>
                <Footer className="footerLayout" style={{fontFamily: 'Exo', height: 70}}>
                    <Row>
                        <Col style={{paddingLeft: 8, paddingTop: 10}}>
                            <button onClick={'#'} style={{
                                    display: 'flex',
                                    width: 435,
                                    height: 48,
                                    padding: '12px 24px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    alignSelf: 'stretch',
                                    borderRadius: 6,
                                    border: '0.6px solid var(--palet-pengembangan-shades-hitam-80, #383838)',
                                    background: 'var(--palet-gradient-gold, linear-gradient(180deg, #F1D3AC 0%, #E5AE66 100%))'
                                }}
                            >
                                <span className="spanCart">
                                    <Link to={'/checkout'} style={{color: "#000000", backgroundColor: 'transparent'}} className="spanCartLink">KEMBALI KE LAMAN PEMBAYARAN</Link>
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
        </div>
    )
}

export default EzeeTunaiBinded