import {FETCH_DATA_PRODUCTLIST} from '../ActionType/actionTypes'

const initialState = {
    prodLists: []
}

const productListsReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_PRODUCTLIST) {
        return { ...state, prodLists: action.payload.prodLists}
    } else {
        return state
    }
}

export default productListsReducer