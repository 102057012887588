import { FETCH_DATA_HISTORYORDER_DETAIL, IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS } from '../ActionType/actionTypes'

const initialState = {
    getOrderDetail: [],
    loading : true
}

const historyOrderDetailReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST : 
        return{
            ...state,
            loading : true
        }
        case FETCH_USERS_SUCCESS : 
        return{ 
            ...state,           
            loading : false,
            getOrderDetail: action.payload
        }
        case IS_NEW_LOADING : 
        return{ 
            ...state,           
            loading : true
        }
        default : return state
    }
}

export default historyOrderDetailReducer
