import React from 'react'
import {NavbarCloseOnly} from '../components/Properties/Navbar/Navbar'
import PromoCodePage from '../components/Checkout/PromoCode'
function PromoCode() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link="/checkout"/>
            <PromoCodePage/>
        </div>
    )
}

export default PromoCode
