import { IS_LOADING_PRODUCTLIST2_CAT } from "../../ActionType/actionTypes";

export function isLoadingProductList2(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_PRODUCTLIST2_CAT, 
            payload: {
                isLoadingProductList2: param
            }
        })
    }
}