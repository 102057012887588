import { FETCH_DATA_RECOMENDPROD_HOME } from "../ActionType/actionTypes"

const initialState = {
    recomProds: []
}

const recomProdsReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_RECOMENDPROD_HOME) {
        return { ...state, recomProds: action.payload.recomProds }
    } else {
        return state
    }
}

export default recomProdsReducer