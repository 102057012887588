import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'
import './About.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAbout } from '../../redux/ActionCreators/aboutActions'
import { BannerLoading } from '../skeletonLoading/loadingTag'
import iconWhatsappGrey from '../../Asset/Icon/whatsapp_grey.svg'
import iconInstagramGreen from '../../Asset/Icon/Instagram_green.svg'
import iconFBGreen from '../../Asset/Icon/FB_green.svg'

function About() {

    const about = useSelector(state => state.aboutReducer.about)
    const isLoadingAboutHome = useSelector(state => state.isLoadingAboutReducer.isLoadingAboutHome)
    // const isLoading = useSelector(state => state.isLoadingReducer.isLoading)
    const dispatch = useDispatch()

    function instagramHandler() {
        window.open("https://www.instagram.com/ezeepasar/", "_blank")
    }

    function fbHandler() {
        window.open("https://www.facebook.com/ezeePasar/", "_blank")
    }

    useEffect(() => {
        dispatch( fetchDataAbout("/dashboard/aboutezeepasar") )
    }, [dispatch])

    return (
        <div style={{ marginTop: -15 }}>
            <Row>
                {
                    (isLoadingAboutHome) ?
                    <BannerLoading /> :
                    <img src={about.image_home} alt="" width="100%" height="100%" />
                }
            </Row>
            <Row style={{ textAlign: 'center' }}>
                <Col span={24} style={{ marginTop: 20 }}>
                    <span className="head-about">{about.title_header}</span>
                </Col>
                <Col span={24}>
                    <p className="about-ezee">
                        {about.desc_home}
                    </p>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: 10 }}>
                <Link to="/TentangKami">
                    <button className="btn-pesan" style={{ width: 'auto', borderRadius: 8, paddingTop: 10, paddingBottom: 10, paddingLeft: 15, paddingRight: 15, fontFamily: 'sans-serif' }}>
                        Pelajari Lebih
                    </button>
                </Link>
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: 40 }}>
                <Col span={24} style={{ marginBottom: 20 }}>
                    <span className="head-about">
                        Informasi & Pemesanan
                    </span>
                </Col>
                <Col span={24}>
                    <span>
                        <img src={iconWhatsappGrey} alt="" style={{ marginRight: 8 }} />
                        <span style={{ color: '#848484' }}>
                            +62817844931
                        </span>
                    </span>
                </Col>
            </Row>
            <Row style={{ justifyContent: 'center', marginTop: 40 }}>
                <Col span={24}>
                    <span className="head-about">Informasi Terkini</span>
                </Col>
                <Col span={12} style={{ cursor: 'pointer' }} onClick={instagramHandler}>
                    <span style={{ color: '#3DB54A', fontSize: 14, fontWeight: 'bold', fontFamily: 'Montserrat' }}>
                        <img src={iconInstagramGreen} alt="" width="70px" />
                        ezeePasar
                    </span>
                </Col>
                <Col span={12} style={{ cursor: 'pointer' }} onClick={fbHandler}>
                <span style={{ color: '#3DB54A', fontSize: 14, fontWeight: 'bold', fontFamily: 'Montserrat' }}>
                        <img src={iconFBGreen} alt="" width="70px" />
                        ezeePasar
                    </span>
                </Col>
            </Row>
        </div>
    )
}

export default About
