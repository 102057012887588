import { IS_LOADING_PRODUCT_OTHER } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingProductOther: true
}

const isLoadingProductOther = (state = initialState, action) => {
    if (action.type === IS_LOADING_PRODUCT_OTHER) {
        return { ...state, isLoadingProductOther: action.payload.isLoadingProductOther }
    } else {
        return state
    }
}

export default isLoadingProductOther