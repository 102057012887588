import React from 'react'
import {NavbarCloseOnly} from '../components/Properties/Navbar/Navbar'
import NotificationDetailPage from '../components/Notification/NotificationDetail'

function NotificationDetail() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link="/Notification"/>
            <NotificationDetailPage/>
        </div>
    )
}

export default NotificationDetail
