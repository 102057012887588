import React from 'react'
import Header from '../components/Checkout/Navbar'
import DeliveryMethodPage from '../components/Checkout/DeliveryMethod'
import { NavbarCloseOnly } from '../components/Properties/Navbar/Navbar'
function DeliveryMethod() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link="/checkout"/>
            <DeliveryMethodPage/>
        </div>
    )
}

export default DeliveryMethod
