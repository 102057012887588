import { 
    FETCH_BANKLIST_DETAIL_EZEETUNAI, 
    IS_NEW_LOADING, 
    FETCH_USERS_REQUEST
 } from "../../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../../function/base64Sign";
import { getToken } from "../../../utils/setSessionUser";
import encryptData from "../../../function/encryptData";
import {BaseURL} from '../../../function/helpers'

export const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const backToLoading = () => {
    return {
        type: IS_NEW_LOADING
    }
}

const fetchBankDetailSuccess = datas => {
    return{
        type: FETCH_BANKLIST_DETAIL_EZEETUNAI,
        payload: datas
    }
}

    export const fetchEzeeTunaiBankDetail = (url, data) => {
        return (dispatch) => {
            dispatch(fetchUsersRequest())
            const dataParams = encryptData(data);
            const signature = base64Sign(url)
            const access_token = getToken()
            const headers = {
                'Content-Type' : 'application/json',
                'sign' : signature,
                'access_token' : access_token
            }

            axios.post(BaseURL + url, {data: dataParams}, {headers: headers})
                .then(bankDetail => {
                    dispatch(fetchBankDetailSuccess(bankDetail.data.response_data.results))
                },
                    dispatch(backToLoading())
                ).catch(err => {
                    console.log(err);
                })
            }
        }