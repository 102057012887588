import React from 'react'
import HistoryDetailPage from '../components/HistoryOrderPage/HistoryDetail'

function HistoryDetail() {
    return (
        <div className="App container body-content mobile-view">
            <HistoryDetailPage/>
        </div>
    )
}

export default HistoryDetail
