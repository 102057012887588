import { Modal } from 'antd'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import base64Sign from '../../function/base64Sign'
import encryptData from '../../function/encryptData'
import { fetchDataAddressLists } from '../../redux/ActionCreators/getAddressListsActions'
import { getToken, checkoutUserSession } from '../../utils/setSessionUser'
import checked_tab from '../../Asset/Icon/checked_tab.svg'
import './ListAlamatKirim.css'
import $ from 'jquery'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import {BaseURL, headersWithToken} from '../../function/helpers';
import mapGreen from '../../Asset/Icon/map-green.svg'
import spinnerLoading1s from '../../Asset/Icon/spinner-1s-58px.svg'
import iconMapGreen from '../../Asset/Icon/map-green.svg'
import {NavbarWithMenuIcon} from '../../components/Properties/Navbar/Navbar'
import ErrorPaymentModal from '../../components/PopUpModal/CheckoutPage/ErrorPaymentModal'
import moment from 'moment'

function ListAlamatKirim() {
    const history = useHistory()
    const dispatch = useDispatch()
    const getAddressLists = useSelector(state => state.getAddressListsReducer.getAddressLists)
    const isLoading = useSelector(state => state.isLoadingReducer.isLoading)
    const [isVisible, setIsVisible] = useState(false)
    const [isVisibleLoading, setIsVisibleLoading] = useState(false)
    const [addressId, setAddressId] = useState(null)
    const [isError, setIsError] = useState({status : false, message : ''});
    const [isErrorAddr, setIsErrorAddr] = useState(true);

    let location = useLocation();
    // console.log(location.state, 'location');
    let isFromCheckout;
    if (location.state.isFromCheckout) {isFromCheckout = true}else{isFromCheckout = false}

    useEffect(() => {
        dispatch( fetchDataAddressLists("/customer/getaddresslist") )
    }, [dispatch])

    if(location.state !== undefined){
        if(location.state.addressisChanged === true){
            $('.background-tab').removeClass('background-tab');
            $('.border-tab').removeClass('border-tab');
            $('.checked-tab').remove();
            $('#addrSec'+location.state.addressId).addClass('background-tab');
            $('#rows'+location.state.addressId).addClass('border-tab');
            $('#checked'+location.state.addressId).append(`<img class="checked-tab" src=${checked_tab} alt="checked-tab" />`);
        }
    }

    function handleButtonAddAddress() {
        history.push({pathname: "/FormAddress", state: { lat: -6.1753398, lng: 106.8268628, isFromCheckout: isFromCheckout }})
    }

    function openModalDelete(addressId ,e) {
        if(isFromCheckout){
            e.stopPropagation();
        }
        setIsVisible(true)
        setAddressId(addressId)
    }

    function handleDeleteButton(addressId) {
        setIsVisible(false)
        setIsVisibleLoading(true)
        const param = encryptData(`{ "address_id": ${addressId} }`)
        const signature = base64Sign("/customer/deleteaddress")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + "/customer/deleteaddress", {data: param}, {headers: headers})
            .then(deletedAddress => {
                if (deletedAddress.data.response_data.results.message === "Data Berhasil Dihapus") {
                    setIsVisibleLoading(false)
                    window.location.reload();
                }
            })
            .catch(error => console.log(error))
    }

    function handleUpdatedButton(addressId, e) {
        if(isFromCheckout){
            e.stopPropagation();
        }
        history.push({pathname: "/FormAddress", state: { addressId: addressId, isFromCheckout: isFromCheckout }})
    }

    function closeModal() {
        setIsVisible(false)
    }

    async function getListEkspedisi(id) {
        var dateConv = moment(location.state.shippingDate).format("yyyy-MM-DD");
        const signature = base64Sign("/checkout/getshippingpricebyaddress"); 
        const access_token = getToken()
        const dataParams = encryptData(`{"addrs_id":${id}}`);           
        const headers = headersWithToken(signature, access_token);
        const res = await axios
        .post(BaseURL + '/checkout/getshippingpricebyaddress',{data:dataParams},{headers:headers}).then(data=>{
            if(data.status === 200){
                const datas = data.data.response_data.results;
                let timeframe;
                if(datas.expedisi[0].timeframe.length > 0){
                    timeframe = datas.expedisi[0].timeframe.find(q => q.is_available === true);
                }else{
                    timeframe = ''
                }
                checkoutUserSession.setEkspedisiId(datas.expedisi[0].expedisi_id);
                checkoutUserSession.setNameEkspedisi(datas.expedisi[0].nama);
                checkoutUserSession.setDateShipping(datas.date_kirim)
                checkoutUserSession.setShippingPrice(datas.expedisi[0].price);
                checkoutUserSession.setTimeFrame({id : timeframe.id, name : timeframe.name});
                return true
            }else{
                if(data.response.status === 400){
                    let error = data.response.data.response_data;
                    if(error !== null){
                        if(error.error_id === "0022"){
                            setIsError({status : true, message : data.response.data.response_message});
                        }
                    }
                }
                return false
            }
        }).catch(err => console.log(err)); 
        return res
    }

    function chooseAddress(address,id,name,isDefault,mobile,receiver){
        $('.background-tab').removeClass('background-tab');
        $('.border-tab').removeClass('border-tab');
        $('.checked-tab').remove();
        $('#addrSec'+id).addClass('background-tab');
        $('#rows'+id).addClass('border-tab');
        $('#checked'+id).append(`<img class="checked-tab" src=${checked_tab} alt="checked-tab" />`);
        getListEkspedisi(id).then((res)=>{
            if(res){
                checkoutUserSession.setAddress(address);
                checkoutUserSession.setAddressId(id);
                checkoutUserSession.setAddressName(name);
                checkoutUserSession.setAddressIsDefault(isDefault);
                checkoutUserSession.setReceiverMobile(mobile);
                checkoutUserSession.setReceiverName(receiver);
                setTimeout(() => {
                    history.push('/checkout', {addressIsChanged : true, addressId : id, ekspedisi: location.state.ekspedisi});
                }, 200);
            }else{
                return
            }
        });

    }

    if (isLoading) {
        return(
            <LoadingModal />
        )
    }

    return (
        <div>
            <NavbarWithMenuIcon link={isFromCheckout ? "/checkout" : (location.state.isAddOrEditData && location.state.isFromSearchAddress) ? "/backMin5" : (location.state.isAddOrEditData && !location.state.isFromSearchAddress) ? "/backMin3" : "/back"} />
            <div className="main top_page" style={{ marginTop: 60, zIndex: 0, marginLeft: 0 }}>
                <h5 className="title" style={{ marginBottom: 30 }}>
                    Alamat Kirim
                </h5>
                {
                    (getAddressLists.length === 0) ?
                    <div>
                        <div style={{ textAlign: 'center', marginTop: 98 }}>
                            <img src={mapGreen} alt="" width="95px" height="85px" />
                            <p style={{ fontSize: 18, fontWeight: 600, fontFamily: "Montserrat", marginTop: 14, marginBottom: 10 }}>
                                Belum ada alamat kirim
                            </p>
                            <p style={{ font: 'normal normal 13px/18px Open Sans', width: '70%', margin: '14px auto 0px' }}>
                                Semua alamat kirim anda akan ditampilkan disini. Yuk, 
                                tambah alamat agar mudah kirim produk
                            </p>
                            <button onClick={handleButtonAddAddress} className="btn btn-lg" id="btnAddAddress" style={{ width: '85%', height: 34, marginTop: 56, borderRadius: 6, color: '#ffffff', backgroundColor: '#5cb85c', borderColor: '#4cae4c' }}>
                                Tambah Alamat Baru
                            </button>
                        </div>
                    </div> :
                    <>
                        {
                            !isFromCheckout ? 
                                (getAddressLists.map((addressList, idx) => {
                                    return(
                                        <>
                                            <div key={idx} className='list_address_sec'>
                                                <div className='rows'>
                                                    <div style={{paddingTop: 30}}>
                                                        <h5 className='title' style={{marginLeft: 'unset'}}>{addressList.address_name}</h5>
                                                        <article>
                                                            <p className='content-2'>{addressList.receiver_name}</p>
                                                            <p className='content-2'>{addressList.receiver_mobile}</p>
                                                            <p className='content'>
                                                                {addressList.address}
                                                            </p>
                                                        </article>
                                                    </div>
                                                    <article style={{textAlign: 'right', margin: '1.5em 10px 1em -5px'}}>
                                                        {
                                                            (addressList.address_is_default) &&
                                                            <button className='a-main'>Utama</button>
                                                        }
                                                        <button onClick={() => {openModalDelete(addressList.address_id)}} className='a-del'>Hapus</button>
                                                        <button onClick={() => handleUpdatedButton(addressList.address_id)} className='a-updated'>Ubah</button>
                                                    </article>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })) :
                                getAddressLists.map((addressList, idx) => {
                                    return(
                                        <>                                       
                                            <div key={idx} className={addressList.address_is_default ? (location.state.addressisChanged ? 'list_address_sec background-tab' : 'list_address_sec background-tab') : 'list_address_sec'} id={"addrSec"+addressList.address_id} onClick={() => chooseAddress(addressList.address, addressList.address_id, addressList.address_name, addressList.address_is_default, addressList.receiver_mobile, addressList.receiver_name)}>
                                                <div className={addressList.address_is_default ? (location.state.addressisChanged ? 'rows border_tab' : 'rows border_tab') : 'rows'} id={'rows'+addressList.address_id}>
                                                    <div style={{paddingTop: 30}}>
                                                        <h5 className='title' style={{marginLeft: 'unset'}}>{addressList.address_name} {addressList.address_is_default ? (location.state.addressisChanged ? <span style={{float: 'right'}} id={"checked"+addressList.address_id}><img alt="" className='checked-tab' src={checked_tab} style={{marginTop: -15}}/></span> : <span style={{float: 'right'}} id={"checked"+addressList.address_id}><img alt="" className='checked-tab' src={checked_tab} style={{marginTop: -15}}/></span>) : <span style={{float: 'right'}} id={"checked"+addressList.address_id}></span>}</h5>
                                                        <article>
                                                            <p className='content-2'>{addressList.receiver_name}</p>
                                                            <p className='content-2'>{addressList.receiver_mobile}</p>
                                                            <p className='content'>
                                                                {addressList.address}
                                                            </p>
                                                        </article>
                                                    </div>
                                                    <article style={{textAlign: 'right', margin: '1.5em 10px 1em -5px'}}>
                                                        {
                                                            (addressList.address_is_default) &&
                                                            <button className='a-main'>Utama</button>
                                                        }
                                                        <button onClick={(e) => {openModalDelete(addressList.address_id, e)}} className='a-del'>Hapus</button>
                                                        <button onClick={(e) => handleUpdatedButton(addressList.address_id, e)} className='a-updated'>Ubah</button>
                                                    </article>
                                                </div>
                                            </div>
                                        </>
                                    )
                                })
                        }
                        <div style={{ textAlign: 'center' }}>
                            <input onClick={handleButtonAddAddress} type="button" className='btn_green_border' id='btnAddAddress' value="Tambah Alamat Baru" style={{ width: '100%' }} />
                        </div>
                    </>
                }
            </div>
            <Modal
                visible={isVisible}
                onCancel={closeModal}
                footer={null}
                className="modalVefiedaddress"
            >
                <div style={{ marginTop: 20 }}>
                    <img src={iconMapGreen} alt="" width="95px" height="85px" />
                </div>
                <div style={{ marginTop: 5, fontSize: 18, fontWeight: 600, marginBottom: 5, color: "#393939" }}>
                    Hapus Alamat Kirim Ini?
                </div>
                <p style={{ font: 'normal normal 13px/18px Open Sans', width: '70%', margin: '0px auto' }}>
                    Kamu akan menghapus alamat kirim ini dan alamat tidak akan ditampilkan kembali di daftar alamat
                </p>
                <div>
                    <div id='delete_addr' className='row' style={{ marginLeft: -9, marginRight: 'inherit', marginTop: 'inherit' }}>
                        <div onClick={() => {handleDeleteButton(addressId)}} className='col-12' style={{ padding: 0, marginTop: 35 }}>
                            <button className='btn btn-lg btn-success' style={{ backgroundColor: '#3DB54A', marginLeft: 20 }}>Hapus</button>
                        </div>
                        <div onClick={closeModal} className='col-12' style={{ padding: 0, marginTop: 5 }}>
                            <button className='btn btn-lg btn-success' style={{ backgroundColor: 'white', color: 'black', border: 'none', marginLeft: 20 }}>Kembali</button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                visible={isVisibleLoading}
                onCancel={closeModal}
                footer={null}
                className="modalVefiedaddressLoading"
            >
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#848484" }}>
                    Sedang dalam proses
                </div>
                <div style={{ fontSize: 10 }}>
                    Kami sedang memproses.
                </div>
                <div style={{ fontSize: 10 }}>
                    Mohon menunggu.
                </div>
                <div style={{ marginTop: 20 }}>
                    <img src={spinnerLoading1s} alt="" width="58px" height="58px" />
                </div>
            </Modal>
            <ErrorPaymentModal isOpen={isError.status} onClosed={() => setIsError({status: false, message: ''})} data={isError.message} isListAddr={true}/>
        </div>
    )
}

export default ListAlamatKirim
