import { IS_LOADING_PRODUCTLIST1_CAT } from "../../ActionType/actionTypes";

export function isLoadingProductList1(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_PRODUCTLIST1_CAT, 
            payload: {
                isLoadingProductList1: param
            }
        })
    }
}