import React, { useEffect } from 'react'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import { useDispatch, useSelector } from 'react-redux';
import { fetchNotifikasiBrowser } from '../redux/ActionCreators/notifikasiBrowserActions';
import './NotifikasiBrowser.css'
import axios from 'axios';
import encryptData from '../function/encryptData';
import base64Sign from '../function/base64Sign';
import { getToken } from '../utils/setSessionUser';
import { IS_LOADING } from '../redux/ActionType/actionTypes'
import { Spin } from 'antd';
import {BaseURL} from '../function/helpers'

function NotifikasiBrowser() {

    const notifikasiBrowser = useSelector(state => state.notifikasiBrowserReducer.notifikasiBrowser)
    const isLoading = useSelector(state => state.isLoadingReducer.isLoading)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch( fetchNotifikasiBrowser("/profile/listnotification") )
    }, [dispatch])

    function handleSwitch(firebaseTypeGroupId, isChecked) {
        const param = encryptData(`{"firebasetypegroup_id" : ${firebaseTypeGroupId}, "status": ${!isChecked}}`)
        const signature = base64Sign("/profile/updatenotification")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + "/profile/updatenotification", {data: param}, {headers})
            .then(newNotif => {
                if (newNotif.data.response_data.results.message === "Update customer notification success") {
                    dispatch({
                        type: IS_LOADING,
                        payload: {
                            isLoading: true
                        }
                    })
                    dispatch( fetchNotifikasiBrowser("/profile/listnotification") )
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className="container body-content mobile-view">
            <NavbarWithMenuIcon link="/back" />
            <div className="row" style={{ padding: '0px 9px', zIndex: 0, paddingTop: 70}}>
                <p style={{ color: '#393939', fontSize: 15, fontFamily: 'Montserrat', fontWeight: 600, padding: '0px 0px 0px 14px', margin: '0px 0px 10px 12px' }}>
                    Pengaturan Notifikasi
                </p>
                <div style={{ fontFamily: 'Montserrat', marginTop: 35 }}>
                    <div style={{ height: 'auto' }}>
                        <div className="row" style={{ margin: '-6px 0px', padding: 1, fontSize: 12, color: '#848484' }}>
                            {
                                (isLoading) ?
                                <div className="spin-load" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Spin size="large" />
                                </div> :
                                notifikasiBrowser.map(notif => {
                                    return(
                                        <>
                                            <div key={notif.mcustnotification_id} className="col-9" style={{ textAlign: 'left' }}>
                                                <span style={{ fontSize: 14, fontWeight: 600, color: '#393939' }}>
                                                    { notif.mfirebasetypegroup_name }
                                                </span>
                                                <br />
                                                <span style={{ fontSize: 10, fontWeight: 'normal', color: '#848484' }}>
                                                    { notif.mfirebasetypegroup_desc }
                                                </span>
                                            </div>
                                            <div className="col-3" style={{ textAlign: 'right' }}>
                                                <div style={{ marginTop: 15 }}>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={notif.mcustnotification_is_enable} onChange={() => (handleSwitch(notif.mcustnotification_firebasetypegroup_id, notif.mcustnotification_is_enable))} />
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            <hr style={{ width: '88%', borderColor: 'black', marginLeft: 15, marginTop: 15, marginBottom: 15 }} />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NotifikasiBrowser
