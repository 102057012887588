import { useState, useEffect } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import encryptData from '../function/encryptData'
import base64Sign from '../function/base64Sign';
import { getToken } from '../utils/setSessionUser';
import {BaseURL} from '../function/helpers'

function useFetch(params, url) {

    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)
    let history = useHistory();
    var access_token = getToken();

    useEffect(() => {
        async function fetchData() {
            try {
                const param = encryptData(params)
                const signature = base64Sign(url);
                const headers = 
                {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token
                }
                const dataProds = await axios.post(BaseURL + url,{data: param},{headers : headers});
                setData(dataProds.data.response_data.results)
                setLoading(false)
            } catch (error) {
                console.log(error.response);
                // if (error.response.data.response_message === "Token Expired" || error.response.data.response_message === "Token not valid") {
                //     history.push('/login');
                // }
            }
        }
        // setTimeout(() => {
            fetchData()
        // });
    }, [access_token, history, params, url])

    return [ data, loading ]
}

function useFetchWithoutData(url) {
    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)
    let history = useHistory();
    var access_token = getToken();
    
    useEffect(() => {
        async function fetchData() {
            try {
                const signature = base64Sign(url);
                const headers = 
                {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token,
                }
                const dataProds = await axios.post(BaseURL + url,{data: ''},{headers : headers})
                setData(dataProds.data.response_data.results)
                setLoading(false)
               
            } catch (error) {
                console.log(error.data);
                // if (error.response.data.response_message === "Token Expired" || error.response.data.response_message=== "Token not valid") {
                //     history.push('/login');
                // }
            }
        }
        // setTimeout(() => {
            fetchData()
        // });
    }, [access_token, history, url])

    return [ data, loading ]
}

function useFetchWithoutToken(params, url) {

    const [ data, setData ] = useState([])
    const [ loading, setLoading ] = useState(true)

    useEffect(() => {
        async function fetchData() {
            try {
                const param = encryptData(params)
                const signature = base64Sign(url);
                const headers = 
                {
                'Content-Type':'application/json',
                'sign' : signature
                }
                const dataProds = await axios.post(BaseURL + url,{data: param},{headers : headers});
                setData(dataProds.data.response_data.results)
                setLoading(false)
                // console.log(dataProds);
            } catch (error) {
                console.log(error);                
            }
        }
        // setTimeout(() => {
            fetchData()
        // }, 3000);
    }, [params, url])

    return [ data, loading ]
}

export { useFetch, useFetchWithoutData, useFetchWithoutToken }
