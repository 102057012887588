import { FETCH_PRODUCT_SEARCH, FETCH_USERS_REQUEST, IS_NEW_LOADING} from "../ActionType/actionTypes";

const initialState = {
    products: [],
    loading: true
}

const productSearchReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST :
            return{
                ...state,
                loading : true
            }
        case FETCH_PRODUCT_SEARCH :
            return{
                ...state,
                loading: false,
                products: action.payload
            }
        case IS_NEW_LOADING : 
        return {
            ...state,
            loading: true
        }
        default : return state
    }
}

export default productSearchReducer