import { IS_LOADING_PROMOLIST_HOME } from "../../ActionType/actionTypes";

export function isLoadingPromoProdsHome(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_PROMOLIST_HOME, 
            payload: {
                isLoadingPromoProdsHome: param
            }
        })
    }
}