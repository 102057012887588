import {FETCH_DATA_WISHLISTS_PROFILE} from '../ActionType/actionTypes'

const initialState = {
    wishLists: []
}

const wishListsReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_WISHLISTS_PROFILE) {
        return { ...state, wishLists: action.payload.wishLists}
    } else {
        return state
    }
}

export default wishListsReducer