import React, {useEffect}from 'react'
import {Row, Col} from 'antd'
import {Link} from 'react-router-dom'
import './UnavailableProducts.css'
import { useSelector, useDispatch  } from 'react-redux';
import { fetchCategory } from '../../redux/ActionCreators/categoryActions'

function UnavailableProducts() {
    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch( fetchCategory("/dashboard/getcategory") )
    } , [dispatch])

    return (
        <div className="main unavailable-cart">
            {
                (!isLoadingCategoryHome) &&
                <>
                <Row>
                    <Col span={24} style={{textAlign: 'center', marginTop: 50}}>
                        <img src="https://wwwd.ezeepasar.com/ezPasar1/Images/empty_cartV2.png" alt="" style={{width: 97, height: 113, marginTop: 50, placeContent: 'center'}}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} style={{textAlign: 'center', marginTop: 30}}>
                        <span className="mainText">
                            Wah, Keranjang Belanjamu Kosong
                        </span>
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <span className="text-1">Saat ini keranjang belanjamu masih kosong.</span>
                    </Col>
                    <Col span={24} style={{textAlign: 'center'}}>
                        <span className="text-1">Yuk, belanja produk di ezeePasar!</span>
                    </Col>
                    <Col span={24} style={{textAlign: 'center', marginTop: 20}}>
                        <Link to={{pathname : `/ProductListCategory/${categories[0].mcat_id}`}}><button className="btn-ez-cart">Belanja Sekarang</button></Link>
                    </Col>
                </Row>
                </>
            }
        </div>
    )
}

export default UnavailableProducts
