import React from 'react';
import {Row, Col, Modal} from 'antd'
import {Link} from 'react-router-dom'

function ErrorPromoCode({errorInfo, isOpen, onClosed, address_id}) {
    function closedModal(){
        onClosed();
    }
  return (
    <Modal 
        visible={isOpen}
        className="modalAddCart modalPaymentMethod" 
        style={{borderRadius: 15, zIndex: 99999}}  
        footer={null}
        onClosed={onClosed}
        closable={false}
    >
        <div style={{textAlign: 'center', paddingTop: 20}}>
            {/* <img src={noInternetImg} alt="" /> */}
        </div>
        <br/>
            <Row>
                <Col span={24}>
                    <div style={{textAlign: 'center', marginLeft: 15, marginRight: 15}}>
                        <h4 style={{fontSize: 18}}><b>Promo Gagal Digunakan</b></h4>
                        <span className='err-info-promocode'>
                            {errorInfo}                               
                        </span>
                        <br/><br/>
                        <button className='modal-button-cashback' onClick={onClosed}>
                            <span className='spanCart'>
                                Oke
                            </span>
                        </button>
                    </div>
                </Col>
            </Row>
    </Modal>
  )
}

export default ErrorPromoCode;
