import React from 'react'
import HistoryOrderPage from '../components/HistoryOrderPage/HistoryOrder'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'

function HistoryOrder() {
    return (
        <div className="App container body-content mobile-view">
            <NavbarWithMenuIcon link='/back'/>
            <HistoryOrderPage/>
        </div>
    )
}

export default HistoryOrder
