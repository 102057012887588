import React, {useState, useEffect}from 'react'
import {Row, Col, Button, Modal} from 'antd'
import './PromoCodeDetail.css'
import {useLocation} from 'react-router-dom'
import encryptData from '../../function/encryptData';
import {checkoutUserSession, getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import iconCarbonTime from '../../Asset/Icon/promo-detail-carbonTime.svg'
import iconCarbonMoney from '../../Asset/Icon/promo-detail-carbon_money.svg'
import axios from 'axios';
import FooterPromoCodeDetail from './FooterPromoCodeDetail';
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import {BaseURL} from '../../function/helpers'
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'

function PromoCodeDetail() {

    const [promoDetail, setPromoDetail] = useState('');
    const [loading, setLoading] = useState(true);

    let location = useLocation();
    let access_token = getToken();

    useEffect(() => {
        getPromoDetail();
    }, [])

    async function getPromoDetail() {
        try {
            const signature = base64Sign("/promo/detail");  
            const dataParams = encryptData(`{"guid":"${location.state.guid}"}`)          
            const headers =
            {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
            }
            const datapromoDetail = await axios.post(BaseURL + "/promo/detail",{data: dataParams},{headers : headers})
            setPromoDetail(datapromoDetail.data.response_data.results)
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    if(loading){
        return(
            <LoadingModal/>
        )
    }

    const contentStyle = {
        width: '100%',
        height: 'auto',
        marginTop: '60px'
    }
    // pathname: '/promo-code-detail', state: { guid: promo.mpromcod_guid, addressId: addressId, expedisiId : expedisiId, paymentId: paymentId}

    const loct = {
        pathname: '/promocode',
        state: { addressId: location.state.addressId, expedisiId : location.state.expedisiId, paymentId: location.state.paymentId, promoCodeId : promoDetail.mpromcod_id, isUsed: location.state.isUsed}
      }

    return (
        <div>
            <NavbarCloseOnly link={loct}/>
            <div>
                <img src={promoDetail.mpromcod_banner_url} alt="" style={contentStyle}/>
            </div>
            <div className="div-promocode-detail" style={{marginLeft: 25, marginRight: 25}}>
                <h4>{promoDetail.mpromcod_name}</h4>
                <div className="info-header">
                    <div className="left">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{width: 30}}>
                                        <img src={iconCarbonTime} alt=""/>
                                    </td>
                                    <td>
                                        <span>Berlaku Hingga</span>
                                        <h6>{promoDetail.mpromcod_period_to_format}</h6>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="right">
                        <table>
                            <tbody>
                                <tr>
                                <td style={{width: 30}}>
                                        <img src={iconCarbonMoney} alt=""/>
                                    </td>
                                    <td>
                                        { promoDetail.mpromcod_min_purchase === 0 ? <p>Tanpa minimal pembelian</p> : <p>Rp{promoDetail.mpromcod_min_purchase}</p>}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            <div className="content">
                <p>{promoDetail.mpromcod_desc}</p>
            </div>

            <h4 style={{marginTop: 20}}>Syarat & Ketentuan</h4>            
                <div>
                    <ol>
                    {promoDetail.mpromcod_snk.map(snk => {
                        return(
                            <li>{snk}</li>)                    
                        })}
                    </ol>
                </div>                
            </div>
            <FooterPromoCodeDetail promo_code={promoDetail.mpromcod_code} address_id={location.state.addressId} expedisiId={location.state.expedisiId} promoCodeId={promoDetail.mpromcod_id} paymentId={location.state.paymentId} isUsed={location.state.isUsed}/>
        </div>
    )
}

export default PromoCodeDetail
