import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import ReduxThunk from 'redux-thunk'
import bannersReducer from "./reducers/bannersReducer";
import categoriesReducer from "./reducers/categoriesReducer";
import promoProdsReducer from "./reducers/promoProdsReducer";
import newProdsReducer from "./reducers/newProdsReducer";
import mostBuyProdsReducer from "./reducers/mostBuyProdsReducer";
import recomProdsReducer from "./reducers/recomProdsReducer";
import mostSearchProdsReducer from "./reducers/mostSearchProdsReducer";
import profileDetailReducer from "./reducers/profileDetailReducer";
import getCartListsReducer from "./reducers/getCartListsReducer"
import isLoadingReducer from "./reducers/isLoadingReducer";
import getCategoryList1Reducer from "./reducers/getCategoryList1Reducer";
import getCategoryList2Reducer from "./reducers/getCategoryList2Reducer";
import productListsReducer from "./reducers/productListsReducer";
import wishListsReducer from "./reducers/wishListsReducer";
import notifikasiBrowserReducer from "./reducers/notifikasiBrowserReducer";
import aboutReducer from "./reducers/aboutReducer";
import promoPageReducer from "./reducers/promoPageReducer";
import getCatLayer2List1Reducer from "./reducers/getCatLayer2List1Reducer";
import getCatLayer2List2Reducer from "./reducers/getCatLayer2List2Reducer";
import prodListsLayer2Reducer from "./reducers/productListLayer2Reducer";
import getAddressListsReducer from "./reducers/getAddressListsReducer";
import getOtherProductReducer from "./reducers/getOtherProductReducer";
import historyOrderDetailReducer from "./reducers/historyOrderDetailReducer";
import productOtherReducer from "./reducers/productOtherReducer";
import ezeeTunaiReducer from "./reducers/ezeeTunaiReducer";
import ezeeTunaiGetBankListReducer from "./reducers/EzeeTunai/ezeeTunaiGetBankListReducer";
import ezeeTunaiGetSuccessActivationReducer from './reducers/EzeeTunai/ezeeTunaiGetSuccessActivationReducer'
import ezeeTunaiGetBankDetailReducer from "./reducers/EzeeTunai/ezeeTunaiGetBankDetailReducer";
import ezeeTunaiGetBalanceReducer from "./reducers/EzeeTunai/ezeeTunaiGetBalanceReducer";
import productSearchReducer from "./reducers/productSearchReducer";
import isLoadingBannerReducer from "./reducers/Loading/isLoadingBannerReducer";
import isLoadingCategoryReducer from "./reducers/Loading/isLoadingCategoryReducer";
import isLoadingPromoProdsReducer from "./reducers/Loading/isLoadingPromoProdsReducer";
import isLoadingNewProdReducer from "./reducers/Loading/isLoadingNewProdReducer";
import isLoadingMostBuyProdReducer from "./reducers/Loading/isLoadingMostBuyProdReducer";
import isLoadingRecomendProdReducer from "./reducers/Loading/isLoadingRecomendProdReducer";
import isLoadingMostSearchProdReducer from './reducers/Loading/isLoadingMostSearchProdReducer'
import isLoadingAboutReducer from './reducers/Loading/isLoadingAboutHomeReducer'
import isLoadingPromoPageReducer from "./reducers/Loading/isLoadingPromoPageReducer";
import isLoadingProfileDetailReducer from "./reducers/Loading/isLoadingProfileDetailReducer";
import isLoadingGetCatList1Reducer from "./reducers/Loading/isLoadingGetCatList1Reducer";
import isLoadingGetCatList2Reducer from "./reducers/Loading/isLoadingGetCatList2Reducer";
import isLoadingProductList1Reducer from "./reducers/Loading/isLoadingProductList1Reducer";
import isLoadingGetCatLayer2List1Reducer from "./reducers/Loading/isLoadingGetCatLayer2List1Reducer";
import isLoadingGetCatLayer2List2Reducer from "./reducers/Loading/isLoadingGetCatLayer2List2Reducer";
import isLoadingProductList2Reducer from "./reducers/Loading/isLoadingProductList2Reducer";
import isLoadingWishlistReducer from "./reducers/Loading/isLoadingWishlistReducer";
import isLoadingProductOtherReducer from "./reducers/Loading/isLoadingProductOther";

const reducers = combineReducers({
    bannersReducer,
    categoriesReducer,
    promoProdsReducer,
    newProdsReducer,
    mostBuyProdsReducer,
    recomProdsReducer,
    mostSearchProdsReducer,
    profileDetailReducer,
    getCartListsReducer,
    isLoadingReducer,
    getCategoryList1Reducer,
    getCategoryList2Reducer,
    productListsReducer,
    wishListsReducer,
    notifikasiBrowserReducer,
    aboutReducer,
    promoPageReducer,
    getCatLayer2List1Reducer,
    getCatLayer2List2Reducer,
    prodListsLayer2Reducer,
    getAddressListsReducer,
    getOtherProductReducer,
    historyOrderDetailReducer,
    productOtherReducer,
    ezeeTunaiReducer,
    ezeeTunaiGetBalanceReducer,
    ezeeTunaiGetBankListReducer,
    ezeeTunaiGetBankDetailReducer,
    ezeeTunaiGetSuccessActivationReducer,
    productSearchReducer,
    isLoadingBannerReducer,
    isLoadingCategoryReducer,
    isLoadingPromoProdsReducer,
    isLoadingNewProdReducer,
    isLoadingMostBuyProdReducer,
    isLoadingRecomendProdReducer,
    isLoadingMostSearchProdReducer,
    isLoadingAboutReducer,
    isLoadingPromoPageReducer,
    isLoadingProfileDetailReducer,
    isLoadingGetCatList1Reducer,
    isLoadingGetCatList2Reducer,
    isLoadingProductList1Reducer,
    isLoadingGetCatLayer2List1Reducer,
    isLoadingGetCatLayer2List2Reducer,
    isLoadingProductList2Reducer,
    isLoadingWishlistReducer,
    isLoadingProductOtherReducer
})

const store = createStore(reducers, composeWithDevTools(applyMiddleware(ReduxThunk)))

export default store