import {IS_LOADING} from '../ActionType/actionTypes'

const initialState = {
    isLoading: true
}

const isLoadingReducer = (state=initialState, action) => {
    if (action.type === IS_LOADING) {
        return { ...state, isLoading: action.payload.isLoading}
    } else {
        return state
    }
}

export default isLoadingReducer