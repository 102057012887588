import {FETCH_DATA_OTHERPRODUCTLIST} from '../ActionType/actionTypes';

const initialState = {
    getOtherProduct: []
}

const getOtherProductReducer = (state=initialState, action) => {
    if(action.type === FETCH_DATA_OTHERPRODUCTLIST){
        return{
            ...state, getOtherProduct: action.payload.getOtherProduct
        }
    }else{
        return state
    }
}

export default getOtherProductReducer; 