import React from 'react'
import './NotificationDetail.css'
import {useLocation} from 'react-router-dom'
import {Row, Col} from 'antd'
import FooterNotificationDetail from './FooterNotificationDetail'

function NotificationDetail() {

    let location = useLocation();
    let data = location.state.promoDetail;
    // console.log(data);

    const contentStyle = {
        width: '100%',
        height: 'auto',
    }

    return (
        <div style={{paddingTop: 70}}>
            <div>
                <div>
                    <img src={data[0].imgLink} alt="" style={contentStyle} />
                </div>
                <Row style={{ marginRight: 15, marginLeft: 15}}>
                    <Col span={24}>
                        <div className="title-notif">
                            {data[0].title}
                        </div>
                    </Col>
                </Row>
                <Row style={{ marginRight: 15, marginLeft: 15}}>
                    <div className="spanHtml">
                        {data[0].message}
                    </div>
                </Row>
            </div>
            <FooterNotificationDetail title="Ini Belum Dari API Karena Gaada Props nya"/>
        </div>
    )
}

export default NotificationDetail
