import { FETCH_DATA_CATEGORY_HOME } from "../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../function/base64Sign";
import { getToken } from "../../utils/setSessionUser";
import {BaseURL} from '../../function/helpers'
import { isLoadingCategoryHome } from "./Loading/isLoadingCategoryActions";

export const fetchCategory = (url) => {
    return( dispatch ) => {
        const signature = base64Sign(url);
        const access_token = getToken()
        const headers = 
        {
        'Content-Type':'application/json',
        'sign' : signature,
        'access_token' : access_token
        }
        axios.post(BaseURL + url,
            {data: ''},
            {headers : headers}
        )
            .then( data => {
                dispatch({
                    type: FETCH_DATA_CATEGORY_HOME,
                    payload: {
                        categories: data.data.response_data.results
                    }
                })
                dispatch(isLoadingCategoryHome(false))
            } )
            .catch(error => {
                console.log(error);
            })
    }
}