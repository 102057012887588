import { FETCH_DATA_PROMOPAGE_MENU } from '../ActionType/actionTypes'

const initialState = {
    promoPage: []
}

const promoPageReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_PROMOPAGE_MENU) {
        return { ...state, promoPage: action.payload.promoPage }
    } else {
        return state
    }
}

export default promoPageReducer
