export const FETCH_DATA_BANNER_HOME = 'FETCH_DATA_BANNER_HOME'
export const FETCH_DATA_CATEGORY_HOME = 'FETCH_DATA_CATEGORY_HOME'
export const FETCH_DATA_PROMOLIST_HOME = 'FETCH_DATA_PROMOLIST_HOME'
export const FETCH_DATA_NEWPROD_HOME = 'FETCH_DATA_NEWPROD_HOME'
export const FETCH_DATA_MOSTBUYPROD_HOME = 'FETCH_DATA_MOSTBUYPROD_HOME'
export const FETCH_DATA_RECOMENDPROD_HOME = 'FETCH_DATA_RECOMENDPROD_HOME'
export const FETCH_DATA_MOSTSEARCHPROD_HOME = 'FETCH_DATA_MOSTSEARCHPROD_HOME'
export const FETCH_DATA_ABOUT_HOME = 'FETCH_DATA_ABOUT_HOME'
export const FETCH_DATA_PROMOPAGE_MENU = 'FETCH_DATA_PROMOPAGE_MENU'
export const FETCH_DATA_PROFILE_DETAIL = 'FETCH_DATA_PROFILE_DETAIL'
export const FETCH_DATA_GETCARTLIST_CART = 'FETCH_DATA_GETCARTLIST_CART'
export const IS_LOADING = 'IS_LOADING'
export const IS_NEW_LOADING = 'IS_NEW_LOADING'
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_DATA_GETCATEGORYLIST1_CAT= 'FETCH_DATA_GETCATEGORYLIST1_CAT'
export const FETCH_DATA_GETCATEGORYLIST2_CAT= 'FETCH_DATA_GETCATEGORYLIST2_CAT'
export const FETCH_DATA_GETCATLAYER2LIST1_CAT= 'FETCH_DATA_GETCATLAYER2LIST1_CAT'
export const FETCH_DATA_GETCATLAYER2LIST2_CAT= 'FETCH_DATA_GETCATLAYER2LIST2_CAT'
export const FETCH_DATA_PRODUCTLIST= 'FETCH_DATA_PRODUCTLIST'
export const FETCH_DATA_PRODUCTLISTLAYER2= 'FETCH_DATA_PRODUCTLISTLAYER2'
export const FETCH_DATA_WISHLISTS_PROFILE= 'FETCH_DATA_WISHLISTS_PROFILE'
export const FETCH_DATA_NOTIFIKASIBROWSER_PROFILE= 'FETCH_DATA_NOTIFIKASIBROWSER_PROFILE'
export const FETCH_DATA_ADDRESSLISTS_PROFILE= 'FETCH_DATA_ADDRESSLISTS_PROFILE'
export const FETCH_DATA_OTHERPRODUCTLIST = 'FETCH_DATA_OTHERPRODUCTLIST'
export const FETCH_DATA_HISTORYORDER_DETAIL = 'FETCH_DATA_HISTORYORDER_DETAIL'
export const FETCH_PRODUCTS_OTHER_SUCCESS = 'FETCH_PRODUCTS_OTHER_SUCCESS'
export const FETCH_EZEETUNAI_BALANCE = 'FETCH_EZEETUNAI_BALANCE'
export const FETCH_BANKLIST_EZEETUNAI = 'FETCH_BANKLIST_EZEETUNAI'
export const FETCH_EZEETUNAI_SUCCESS_ACTIVATION = 'FETCH_EZEETUNAI_SUCCESS_ACTIVATION'
export const FETCH_BANKLIST_DETAIL_EZEETUNAI = 'FETCH_BANKLIST_DETAIL_EZEETUNAI'
export const FETCH_PRODUCT_SEARCH = 'FETCH_PRODUCT_SEARCH'

// Loading
export const IS_LOADING_BANNER_HOME = 'IS_LOADING_BANNER_HOME'
export const IS_LOADING_CATEGORY_HOME = 'IS_LOADING_CATEGORY_HOME'
export const IS_LOADING_PROMOLIST_HOME = 'IS_LOADING_PROMOLIST_HOME'
export const IS_LOADING_NEWPROD_HOME = 'IS_LOADING_NEWPROD_HOME'
export const IS_LOADING_MOSTBUYPROD_HOME = 'IS_LOADING_MOSTBUYPROD_HOME'
export const IS_LOADING_RECOMENDPROD_HOME = 'IS_LOADING_RECOMENDPROD_HOME'
export const IS_LOADING_MOSTSEARCHPROD_HOME = 'IS_LOADING_MOSTSEARCHPROD_HOME'
export const IS_LOADING_ABOUT_HOME = 'IS_LOADING_ABOUT_HOME'
export const IS_LOADING_PROMOPAGE_MENU = 'IS_LOADING_PROMOPAGE_MENU'
export const IS_LOADING_PROFILE_DETAIL = 'IS_LOADING_PROFILE_DETAIL'
export const IS_LOADING_GETCATLIST1_CAT = 'IS_LOADING_GETCATLIST1_CAT'
export const IS_LOADING_GETCATLIST2_CAT = 'IS_LOADING_GETCATLIST2_CAT'
export const IS_LOADING_PRODUCTLIST1_CAT = 'IS_LOADING_PRODUCTLIST1_CAT'
export const IS_LOADING_GETCATLAYER2LIST1_CAT = 'IS_LOADING_GETCATLAYER2LIST1_CAT'
export const IS_LOADING_GETCATLAYER2LIST2_CAT = 'IS_LOADING_GETCATLAYER2LIST2_CAT'
export const IS_LOADING_PRODUCTLIST2_CAT = 'IS_LOADING_PRODUCTLIST2_CAT'
export const IS_LOADING_WISHLISTS_PROFILE = 'IS_LOADING_WISHLISTS_PROFILE'
export const IS_LOADING_PRODUCT_OTHER = 'IS_LOADING_PRODUCT_OTHER'
    