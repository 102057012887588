import { Row, Spin, Modal, message } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {NavbarWithArrowBack} from '../components/Properties/Navbar/Navbar'
import { fetchWishLists } from '../redux/ActionCreators/wishListActions'
import './WishList.css'
import encryptData from '../function/encryptData'
import base64Sign from '../function/base64Sign'
import { getToken } from '../utils/setSessionUser'
import axios from 'axios'
import {BaseURL} from '../function/helpers'
import iconWishlistKosong from '../Asset/Icon/wishlist_kosong.png'
import iconRemoveWishlist from '../Asset/Icon/icon_remove_wishlist.png'
import iconAddWhislistToCartDisabled from '../Asset/Icon/add_whislist_to_cart_disabled.png'
import iconAddWhislistToCart from '../Asset/Icon/add_whislist_to_cart.png'
import iconSpinnerLoading1s from '../Asset/Icon/spinner-1s-58px.svg'
import wishlistImageConfirm from '../Asset/Icon/wishlist_image_confirm.png'
import { fetchCategory } from '../redux/ActionCreators/categoryActions'

function Wishlist() {
    const history = useHistory()
    const dispatch = useDispatch()
    const wishLists = useSelector(state => state.wishListsReducer.wishLists)
    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingWishlist = useSelector(state => state.isLoadingWishlistReducer.isLoadingWishlist)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)
    const [isVisible, setIsVisible] = useState(false)
    const [isVisibleDelete, setIsVisibleDelete] = useState(false);
    const [product_id, setProduct_id] = useState(0);

    useEffect(() => {
        dispatch( fetchWishLists( "/profile/listwishlist" ) )
        dispatch( fetchCategory("/dashboard/getcategory") )
    }, [dispatch])

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }

    function handleClickButton(catId) {
        history.push(`/ProductListCategory/${catId}`)
    }

    function modalCancelHandler() {
        setIsVisible(false)
        setIsVisibleDelete(false)
    }

    function successMessage() {
        message.success({
            content: "Produk telah ditambahkan ke keranjang",
            className: 'snackbar-success-wishlist',
            duration: 4
        })
    }

    function deletedMessage() {
        message.success({
            content: "Produk berhasil dihapus dari wishlist",
            className: 'snackbar-success-wishlist',
            duration: 4
        })
    }

    function openModalDelete(productId) {
        setIsVisibleDelete(true)
        setProduct_id(productId)
    }

    function deleteWishlist(productId) {
        setIsVisible(true)
        const param = encryptData(`{ "product_id": ${productId} }`)
        const signature = base64Sign("/profile/deletewishlist")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + "/profile/deletewishlist", {data: param}, {headers: headers})
            .then(deleted => {
                if (deleted.data.response_data.error_id === "0000") {
                    setIsVisible(false)
                    setIsVisibleDelete(false)
                    deletedMessage()
                    dispatch( fetchWishLists( "/profile/listwishlist" ) )
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    function addToCartHandle(productId) {
        setIsVisible(true)
        const param = encryptData(`{ "product_id": ${productId}, "quantity": 1 }`)
        const signature = base64Sign("/cart/addcart")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + "/cart/addcart", {data: param}, {headers: headers})
            .then(added => {
                if (added.data.response_code === 200) {
                    setIsVisible(false)
                    successMessage()
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className="container body-content mobile-view">
            <NavbarWithArrowBack link="/back" />
            {
                (isLoadingWishlist && isLoadingCategoryHome) ?
                <>
                    <Row style={{ justifyContent: 'center', paddingLeft: 9, paddingRight: 9, zIndex: 0, marginTop: 34 }}>
                        <p style={{ color: "#393939", fontSize: 18, fontWeight: 600, padding: "13px 0px 8px", marginTop: 50 }}>
                            Wishlist
                        </p>
                    </Row>
                    <div className="spin-load" style={{ display: 'flex', justifyContent: 'center' }}>
                        <Spin size="large" />
                    </div>
                </> :
                (wishLists.length === 0) ?
                <Row style={{ justifyContent: 'center', paddingLeft: 9, paddingRight: 9, zIndex: 0, marginTop: 34 }}>
                    <p style={{ color: "#393939", fontSize: 18, fontWeight: 600, padding: "13px 0px 8px", marginTop: 50 }}>
                        Wishlist
                    </p>
                    <Row style={{ paddingLeft: 23, paddingRight: 20, zIndex: 0 }}>
                        <div>
                            <div style={{ textAlign: 'center', marginTop: 40 }}>
                                <img src={iconWishlistKosong} alt="" width="98px" height="108px" />
                                <p style={{ marginTop: 13, color: '#393939', fontWeight: 600, fontSize: 16, }}>
                                    Belum ada Wishlist
                                </p>
                                <p style={{ fontFamily: "Open Sans", color: '#848484', fontSize: 13, fontWeight: 'normal', padding: '10px 55px', marginTop: -15 }}>
                                    Semua wishlist Anda akan ditampilkan disini. Yuk, temukan produk favoritmu di ezeePasar.
                                </p>
                                <button onClick={() => handleClickButton(categories[0].mcat_id)} className="btn ez-btn-green" style={{ width: '80%', height: 50, marginTop: 15, borderRadius: 8, fontWeight: 'bold', fontSize: 14, alignItems: 'center' }}>
                                    Cari Produk
                                </button>
                            </div>
                        </div>
                    </Row>
                </Row> :
                <>
                    <Row style={{ justifyContent: 'center', paddingLeft: 9, paddingRight: 9, zIndex: 0, marginTop: 34 }}>
                        <p style={{ color: "#393939", fontSize: 18, fontWeight: 600, padding: "13px 0px 8px", marginTop: 50 }}>
                            Wishlist
                        </p>
                    </Row>
                    <div className="row" style={{ paddingLeft: 23, paddingRight: 20, zIndex: 0, margin: 'unset' }}>
                        <div style={{ padding: 'unset' }}>
                            {
                                wishLists.map((wishList, idx) => {
                                    return (
                                        <>
                                            <div key={idx} className="row" style={{ margin: '5px 0px 0px 0px', padding: '0px 10px 10px 0px' }}>
                                                <div className="col-3" style={{ textAlign: 'center', fontWeight: 600, paddingRight: 0, paddingLeft: 0 }}>
                                                    <img src={wishList.product_image} alt="" width="58px" height="58px" />
                                                    {
                                                        (wishList.product_stat_stock_id === 2) &&
                                                        <div className="overlayimages">Habis</div>
                                                    }
                                                </div>
                                                <div className="col-5" style={{ textAlign: 'left', fontWeight: 600, paddingRight: 0, paddingLeft: 0 }}>
                                                    <p style={{ fontSize: 12, fontWeight: 'normal', color: '#393939', margin: '0px 25px 3px 0px', fontFamily: 'Open Sans' }}>
                                                        {wishList.product_name}
                                                    </p>
                                                    <p style={{ fontSize: 10, fontWeight: 'normal', letterSpacing: '-0.02em', color: '#848484', marginBottom: 2 }}>
                                                        {wishList.product_weight}{wishList.product_weight_name}
                                                    </p>
                                                    {
                                                        (wishList.product_promo_price) ?
                                                        <>
                                                            <p style={{ fontSize: 11, textDecorationLine: 'line-through', fontWeight: 'normal', color: '#848484', margin: '3px 25px -3px 0px', fontFamily: 'Open Sans' }}>
                                                                {convertToRupiah(wishList.product_price)}
                                                            </p>
                                                            <span style={{ fontSize: 12, fontWeight: 600, letterSpacing: '-0.02em', color: '#F79226' }}>
                                                                {convertToRupiah(wishList.product_promo_price)} /{wishList.product_unit_name}
                                                            </span>
                                                        </> :
                                                        <span style={{ fontSize: 12, fontWeight: 600, letterSpacing: '-0.02em', color: '#F79226' }}>
                                                            {convertToRupiah(wishList.product_price)} /{wishList.product_unit_name}
                                                        </span>
                                                    }
                                                </div>
                                                <div className="col-4" style={{ marginTop: -10, color: '#848484', fontSize: 14, fontWeight: 600, padding: 0 }}>
                                                    <div className="row" style={{ marginLeft: 5 }}>
                                                        <div className="col-6" style={{ textAlign: 'center' }}>
                                                            <img onClick={() => {openModalDelete(wishList.product_id)}} src={iconRemoveWishlist} alt="" style={{ marginTop: 1.7, position: 'absolute', width: 20, height: 23 }} />
                                                        </div>
                                                        <div className="col-6" style={{ height: 26, borderLeft: '2px solid #E5E5E5' }}>
                                                            {
                                                                (wishList.product_stat_stock_id === 2) ?
                                                                <img src={iconAddWhislistToCartDisabled} alt="" style={{ marginLeft: 1, position: 'absolute', width: 35, height: 25 }} /> :
                                                                <img onClick={() => {addToCartHandle(wishList.product_id)}} src={iconAddWhislistToCart} alt="" style={{ marginLeft: 1, position: 'absolute', width: 35, height: 25 }} />
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr style={{ width: '96.5%', height: 0.5, borderColor: '#E5E5E5', marginTop: 6, marginBottom: 15, marginLeft: 5 }} />
                                        </>
                                    )
                                })
                            }
                        </div>
                    </div>
                </>
            }
            <Modal
                visible={isVisible}
                onCancel={modalCancelHandler}
                footer={null}
                className="modalVefiedWishlist"
            >
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#848484" }}>
                    Sedang dalam proses
                </div>
                <div style={{ fontSize: 10 }}>
                    Kami sedang memproses.
                </div>
                <div style={{ fontSize: 10 }}>
                    Mohon menunggu.
                </div>
                <div style={{ margin: '20px 0' }}>
                    <img src={iconSpinnerLoading1s} alt="" width="58px" height="58px" />
                </div>
            </Modal>
            <Modal
                visible={isVisibleDelete}
                onCancel={modalCancelHandler}
                footer={null}
                className="modalVefiedWishlistDelete"
            >
                <div style={{ marginTop: 20 }}>
                    <img src={wishlistImageConfirm} alt="" width="95px" height="85px" />
                </div>
                <div style={{ marginTop: 5, fontSize: 18, fontWeight: 600, marginBottom: 5, color: "#393939" }}>
                    Hapus Produk dari Wishlist?
                </div>
                <p style={{ font: 'normal normal 13px/18px Open Sans', width: '70%', margin: '0px auto' }}>
                    Kamu akan menghapus produk dari wishlist dan produk tidak akan ditampilkan kembali di daftar wishlist
                </p>
                <div>
                    <div id='delete_addr' className='row' style={{ marginLeft: -9, marginRight: 'inherit', marginTop: 'inherit' }}>
                        <div onClick={() => {deleteWishlist(product_id)}} className='col-12' style={{ padding: 0, marginTop: 35 }}>
                            <button className='btn btn-lg btn-success' style={{ backgroundColor: '#3DB54A', marginLeft: 20, borderRadius: 4, borderColor: 'unset', fontSize: 12 }}>Hapus</button>
                        </div>
                        <div onClick={modalCancelHandler} className='col-12' style={{ padding: 0, marginTop: 5 }}>
                            <button className='btn btn-lg btn-success' style={{ backgroundColor: 'unset', color: '#848484', fontSize: 12, border: 'none', marginLeft: 20 }}>Kembali</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default Wishlist
