import { IS_LOADING_NEWPROD_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingNewProdHome: true
}

const isLoadingNewProdReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_NEWPROD_HOME) {
        return { ...state, isLoadingNewProdHome: action.payload.isLoadingNewProdHome }
    } else {
        return state
    }
}

export default isLoadingNewProdReducer