import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import FooterWA from '../components/HomePage/FooterSide'
import cariProduk from '../Asset/Icon/cari_produk.svg'
import RegisterAtauLogin from '../Asset/Icon/Register atau Login.svg'
import KonfirmasiData from '../Asset/Icon/Konfirmasi Data.svg'
import ProsesPembayaran from '../Asset/Icon/Proses Pembayaran.svg'
import SelesaikanPemesanan from '../Asset/Icon/Selesaikan Pemesanan.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategory } from '../redux/ActionCreators/categoryActions'
import { useHistory } from 'react-router-dom'

function HowToShop({isWebview}) {

    const dispatch = useDispatch()
    const history = useHistory()
    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)

    useEffect(() => {
        dispatch( fetchCategory("/dashboard/getcategory") )
    }, [dispatch])
    
    function handleClickProduct(catId) {
        history.push(`/ProductListCategory/${catId}`)
    }

    return (
        <div className="container body-content mobile-view" style={{ height: 1283.600, overflowX: 'hidden' }}>
            {isWebview ? null : <NavbarWithMenuIcon link="/back"/>}
            <FooterWA />
            <div style={{ textAlign: 'center', marginTop: 20 }}>
                <span className="head-2" style={{ fontWeight: 700 }}>Cara Berbelanja</span>
            </div>
            <Row style={{ marginTop: 40 }}>
                <Col span={12} style={{ textAlign: 'right', marginLeft: -10 }}>
                    <img src={cariProduk} alt="cariProduk" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>
                    <span style={{ color: '#393939', fontSize: 15, fontWeight: 600 }}>Cari Produk</span>
                    <br />
                    <div style={{ fontSize: 10, marginTop: 5, color: '#848484' }}>
                        <span>Temukan produk yang</span>
                        <br />
                        <span>yang diinginkan dan masukan ke</span>
                        <br />
                        <span>keranjang belanja dengan</span>
                        <br />
                        <span>menekan Beli sekarang</span>
                        <br />
                        <span>atau icon keranjang.</span>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col span={12} style={{ textAlign: 'right', marginLeft: -10 }}>
                    <img src={RegisterAtauLogin} alt="register" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>
                    <span style={{ color: '#393939', fontSize: 15, fontWeight: 600 }}>Registrasi/Login</span>
                    <br />
                    <div style={{ fontSize: 10, marginTop: 5, color: '#848484' }}>
                        <span>Bisa menggunakan akun</span>
                        <br />
                        <span>Facebook, Google, Email</span>
                        <br />
                        <span>atau nomor telepon.</span>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col span={12} style={{ textAlign: 'right', marginLeft: -10 }}>
                    <img src={KonfirmasiData} alt="confirmData" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>
                    <span style={{ color: '#393939', fontSize: 15, fontWeight: 600 }}>Konfirmasi Data</span>
                    <br />
                    <div style={{ fontSize: 10, marginTop: 5, color: '#848484' }}>
                        <span>Lengkapi informasi</span>
                        <br />
                        <span>penerima dan alamat</span>
                        <br />
                        <span>pengiriman.</span>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col span={12} style={{ textAlign: 'right', marginLeft: -10 }}>
                    <img src={ProsesPembayaran} alt="payment" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>
                    <span style={{ color: '#393939', fontSize: 15, fontWeight: 600 }}>
                        Proses
                        <br />
                        Pembayaran
                    </span>
                    <br />
                    <div style={{ fontSize: 10, marginTop: 5, color: '#848484' }}>
                        <span>Pilih metode pembayaran</span>
                        <br />
                        <span>yang diinginkan.</span>
                    </div>
                </Col>
            </Row>
            <Row style={{ marginTop: 40 }}>
                <Col span={12} style={{ textAlign: 'right', marginLeft: -10 }}>
                    <img src={SelesaikanPemesanan} alt="finish" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', paddingLeft: 30 }}>
                    <span style={{ color: '#393939', fontSize: 15, fontWeight: 600 }}>Selesaikan Pemesanan</span>
                    <br />
                    <div style={{ fontSize: 10, marginTop: 5, color: '#848484' }}>
                        <span>Pemesanan selesai dan akan</span>
                        <br />
                        <span>diproses kirim segera setelah</span>
                        <br />
                        <span>pembayaran diterima.</span>
                    </div>
                </Col>
            </Row>
            <div style={{ marginTop: 60, textAlign: 'center' }}>
                <span style={{ fontSize: 11, color: '#848484' }}>
                    Mudah kan?
                    <br />
                    Selamat Berbelanja!
                </span>
            </div>
            {
                isLoadingCategoryHome ?
                <div style={{ marginTop: 30, textAlign: 'center' }}>
                    <button className="btn-pesan" style={{ borderRadius: 8, height: 48, width: '80%' }}>Lihat Produk</button>
                </div> :
                <div style={{ marginTop: 30, textAlign: 'center' }}>
                    <button onClick={() => handleClickProduct(categories[0].mcat_id)} className="btn-pesan" style={{ borderRadius: 8, height: 48, width: '80%' }}>Lihat Produk</button>
                </div>
            }
        </div>
    )
}

export default HowToShop
