import { FETCH_DATA_ABOUT_HOME } from '../ActionType/actionTypes'

const initialState = {
    about: {}
}

const aboutReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_ABOUT_HOME) {
        return { ...state, about: action.payload.about }
    } else {
        return state
    }
}

export default aboutReducer
