import { FETCH_DATA_PRODUCTLIST } from "../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../function/base64Sign";
import encryptData from "../../function/encryptData";
import { getToken } from "../../utils/setSessionUser";
import {BaseURL} from '../../function/helpers'
import { isLoadingProductList1 } from "./Loading/isLoadingProductList1Actions";

export const fetchProductLists = (params, url) => {
    return( dispatch ) => {
        const param = encryptData(params)
        const signature = base64Sign(url);
        const access_token = getToken()
            const headers = 
            {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
            }
        axios.post(BaseURL + url,
            {data: param},
            {headers : headers}
        )
            .then( data => {
                dispatch({
                    type: FETCH_DATA_PRODUCTLIST,
                    payload: {
                        prodLists: data.data.response_data.results
                    }
                })
                dispatch(isLoadingProductList1(false))
            } )
            .catch(error => {
                console.log(error);
            })
    }
}