import React from 'react';
import headerImg from '../../Asset/Icon/ezeeTunai/HowToActivation/header.png';
import step1Img from '../../Asset/Icon/ezeeTunai/HowToActivation/step1.png';
import step2Img from '../../Asset/Icon/ezeeTunai/HowToActivation/step2.png';
import step3Img from '../../Asset/Icon/ezeeTunai/HowToActivation/step3.png';
import step4Img from '../../Asset/Icon/ezeeTunai/HowToActivation/step4.png';

function HowToEzeeTunaiAct() {
  return (
    <div style={{paddingTop: 70, textAlign: 'center', marginLeft: 25, marginRight: 25}}>
        <img src={headerImg} alt=''/>
        <img src={step1Img} alt=''/>
        <img src={step2Img} alt=''/>
        <img src={step3Img} alt=''/>
        <img src={step4Img} alt=''/>
        {/* <a href="https://play.google.com/store/apps/details?id=com.ezee.main&hl=in&gl=US" target={"_blank"} rel="noopener noreferrer"><button className='ez-btn-green-bottom' style={{marginTop: 35, marginBottom: 25}}>Aktivasi ezeeTunai</button></a> */}
    </div>
  );
}

export default HowToEzeeTunaiAct;
