import React from 'react'
import styled from 'styled-components'
import { Col, Card, Row, Skeleton} from 'antd'
import './loadingTag.css'

const SkeletonPulse = styled.div`
    display: inline-block;
    width: 100%;
    height: 100%;
    background: linear-gradient(-90deg, #F0F0F0 0%, #F8F8F8 50%, #F0F0F0 100%);
    background-size: 400% 400%;
    animation: pulse 1.2s ease-in infinite;

    @keyframes pulse {
        0% {
            background-position: 0% 0%;
        }
        100% {
            background-position: -140% 0%;
        }
    }
`
const ImageBanner = styled.div`
    width: 480px;
    height: 251px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.31);
`
const ImageCategory = styled.div`
    width: 80px;
    height: 80px;
    box-shadow: 0px 2px 6px -1px rgba(0, 0, 0, 0.31);
`
const CategoryTitle = styled.div`
    width: 80px;
    height: 10px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`
const CategoryTab = styled.div`
    white-space: nowrap;
    height: 36px;
    border: 1px solid #a5a5a5;
    border-radius: 20px;
    margin: 10px 5px 8px 5px;
    color: #a5a5a5;
    text-align: center;
    padding: 6px 12px 0 20px;
`
const ImageCategoryTab = styled.div`
    width: 28px;
    height: 28px;
    margin-top: -3px;
    margin-left: -18px;
    margin-right: 5px;
`
const CategorySpanTab = styled.div`
    width: 58px;
    height: 15px;
    margin-top: 5px;
    color: #a5a5a5;
`
const TitleBig = styled.div`
    width: 180px;
    height: 40px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`
// const HeadTitle = styled.div`
//     width: 180px;
//     height: 30px;
//     border-radius: 8px;
//     margin-top: 5px;
//     color: #a5a5a5;
// `
const ImageProdList = styled.div`
    width: 100%;
    height: 185px;
    border-radius: 8px;
    align-items: center;
`
const ImageCard = styled.div`
    width: 105px;
    height: 80px;
    border-radius: 8px;
    align-items: center;
`
const Title = styled.div`
    width: 90px;
    height: 10px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`
const TitleLong = styled.div`
    width: 100%;
    height: 10px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`

const TitleMedium = styled.div`
    width: 88px;
    height: 18px;
    border-radius: 8px;
    margin-right: 12px;
`
const SoldTag = styled.div`
    width: 80px;
    height: 10px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`
const StockTag = styled.div`
    width: 40px;
    height: 10px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`
const PriceTag = styled.div`
    width: 100px;
    height: 10px;
    border-radius: 8px;
    margin-top: 5px;
    color: #a5a5a5;
`
const ButtonCard = styled.div`
    width: 100px;
    height: 25px;
    margin-top: 10px;
    color: #a5a5a5;
    border: 1px #a5a5a5;
    box-sizing: border-box;
    width: 95%;
    padding: 0px;
`

const ButtonCardLong = styled.div`
    width: 100%;
    height: 25px;
    margin-top: 10px;
    color: #a5a5a5;
    border: 1px #a5a5a5;
    box-sizing: border-box;
    padding: 0px;
    border-radius: 30px;
`

const ImgProduct = styled.div`
    border-radius: 8px; 
    width: 80px;
    height: 80px;
`

const QtyProduct = styled.div`
border-radius: 8px;
    width: 120px;
    height: 35px;
`

const TotalPriceModal = styled.div`
    width: 120px;
    height: 35px;
    border-radius: 8px;
    float: left;
`

const ButtonAddModal = styled.div`
    width: 200px;
    height: 35px;
    border-radius: 8px;
    float: right;
`

const ImageProduct = styled.div`
    width: 480px;
    height: 300px;
`

const Wishlist = styled.div`
    width: 40px;
    height: 40px;
`

const BantuanWa = styled.div`
    width: 450px;
    height: 56px;
    max-width: 100%;
`

const RadioButton = styled.div`
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: inline-block;
`

const ImgPaymentMethod = styled.div`
    border-radius: 8px; 
    width: 45px;
    height: 45px;
`

const HeaderCard = styled.div`
    width: 100%;
    height: 38px;
    padding-top: -35px
`

const CardDeliveryExpedisi = styled.div`
    border-radius: 8px;
    padding: 15px 10px;
    margin-top: 1.5em;
    cursor: pointer;
    height: 74px;
    border: 2px solid #E5E5E5;
`

export const BannerLoading = () => {
    return (
        <ImageBanner>
            <SkeletonPulse />
        </ImageBanner>
    )
}

export const CategoryLoading = () => {
    return (
        <Col className="items">
            <ImageCategory>
                <SkeletonPulse />
            </ImageCategory>
            <CategoryTitle>
                <SkeletonPulse />
            </CategoryTitle>
            <CategoryTitle>
                <SkeletonPulse />
            </CategoryTitle>
        </Col>
    )
}

export const CategoryTabLoading = () => {
    return (
        <>
            <div style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll', paddingTop: 10 }}>
                <CategoryTab style={{ marginTop: 0, marginLeft: 15 }}>
                    <ImageCategoryTab>
                        <SkeletonPulse style={{ borderRadius: 50 }} />
                    </ImageCategoryTab>
                    <CategorySpanTab style={{ marginTop: -23, marginLeft: 15 }}>
                        <SkeletonPulse style={{ borderRadius: 8 }} />
                    </CategorySpanTab>
                </CategoryTab>
                <CategoryTab style={{ marginTop: 0, marginLeft: 5 }}>
                    <ImageCategoryTab>
                        <SkeletonPulse style={{ borderRadius: 50 }} />
                    </ImageCategoryTab>
                    <CategorySpanTab style={{ marginTop: -23, marginLeft: 15 }}>
                        <SkeletonPulse style={{ borderRadius: 8 }} />
                    </CategorySpanTab>
                </CategoryTab>
                <CategoryTab style={{ marginTop: 0, marginLeft: 5 }}>
                    <ImageCategoryTab>
                        <SkeletonPulse style={{ borderRadius: 50 }} />
                    </ImageCategoryTab>
                    <CategorySpanTab style={{ marginTop: -23, marginLeft: 15 }}>
                        <SkeletonPulse style={{ borderRadius: 8 }} />
                    </CategorySpanTab>
                </CategoryTab>
                <CategoryTab style={{ marginTop: 0, marginLeft: 5 }}>
                    <ImageCategoryTab>
                        <SkeletonPulse style={{ borderRadius: 50 }} />
                    </ImageCategoryTab>
                    <CategorySpanTab style={{ marginTop: -23, marginLeft: 15 }}>
                        <SkeletonPulse style={{ borderRadius: 8 }} />
                    </CategorySpanTab>
                </CategoryTab>
            </div>
        </>
    )
}

export const TitleProdListLoading = () => {
    return (
        <div style={{ marginTop: 20, marginLeft: -10, marginBottom: 15 }}>
            <TitleBig>
                <SkeletonPulse style={{ borderRadius: 8 }} />
            </TitleBig>
        </div>
    )
}

export const ProdListLoading = () => {
    return (
        <Col span={9} className="item-product-list" style={{ paddingBottom: 20, marginTop: 25, marginLeft: 20, marginRight: 20, marginBottom: 15, width: '46%', float: 'left' }}>
            <div className="box-produk" style={{width: '100%', height: 311, marginLeft: 0}}>
                <ImageProdList>
                    <SkeletonPulse />
                </ImageProdList>
                <TitleLong style={{ marginBottom: 5 }}>
                    <SkeletonPulse />
                </TitleLong>
                <TitleLong>
                    <SkeletonPulse />
                </TitleLong>
                <StockTag style={{ marginBottom: 10 }}>
                    <SkeletonPulse />
                </StockTag>
                <PriceTag>
                    <SkeletonPulse />
                </PriceTag>
                <SoldTag style={{ marginTop: 10 }}>
                    <SkeletonPulse />
                </SoldTag>
                <ButtonCardLong style={{ marginTop: 20 }}>
                    <SkeletonPulse />
                </ButtonCardLong>
            </div>
        </Col>
    )
}

export const CardProdLoading = () => {
    return (
        <>
            <Card
                style={{ width: 130, minHeight: 230, borderRadius: 8, padding: 11, margin: 2, marginRight: 8, boxShadow: '0px 0px 8px rgb(0 0 0 / 15%)' }}
            >
                <ImageCard>
                    <SkeletonPulse />
                </ImageCard>
                <Title style={{ marginBottom: 15 }}>
                    <SkeletonPulse />
                </Title>
                <PriceTag style={{ marginBottom: 15 }}>
                    <SkeletonPulse />
                </PriceTag>
                <StockTag style={{ marginBottom: 15 }}>
                    <SkeletonPulse />
                </StockTag>
                <PriceTag style={{ marginBottom: 15 }}>
                    <SkeletonPulse />
                </PriceTag>
                <ButtonCard style={{ marginTop: 15 }}>
                    <SkeletonPulse />
                </ButtonCard>
            </Card>
        </>
    )
}

export const ModalAddProductLoading = () => {
    return(
        <>
            <table style={{width: '90%', margin: '0 auto', }}>
                    <tbody>
                        <tr>
                            <td className="prodImg" style={{width: 100}}>
                                <ImgProduct>
                                    <SkeletonPulse style={{borderRadius: 5}}/>
                                </ImgProduct>
                            </td>
                            <td style={{verticalAlign: 'inherit', float: 'left', marginTop: 12}}>
                                <Title>
                                    <SkeletonPulse style={{borderRadius: 5}}/>
                                </Title>
                                <Title>
                                    <SkeletonPulse style={{borderRadius: 5}}/>
                                </Title>
                                <Title>
                                    <SkeletonPulse style={{borderRadius: 5}}/>
                                </Title>
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{whiteSpace: 'nowrap', float: 'right'}}>
                                <QtyProduct>
                                    <SkeletonPulse style={{borderRadius: 5}}/>
                                </QtyProduct>
                                {/* {counter < 2 ? <img src="https://wwwd.ezeepasar.com/Images/feather_minus-circle-disable.svg" alt="" style={{ marginBottom: 3, marginRight: 1 }} id="imgMinus" onClick={minusCart} /> : <img src="https://wwwd.ezeepasar.com/Images/feather_minus-circle-enable.svg" alt="" style={{ marginBottom: 3, marginRight: 1 }} id="imgMinus" onClick={minusCart} />}
                                <InputNumber type="number" className="inpQuantity" min={1} id="inpCartField" value={counter} onKeyUp={() => countPrice()}/>
                                <img src="https://wwwd.ezeepasar.com/Images/feather_plus-circle-enable.svg" alt="" style={{ float: 'right', marginLeft: 5 }} id="imgPlus" onClick={plusCart} /> */}
                            </td>
                        </tr>
                        <tr id={"alertMaxQty"} style={{visibility: 'hidden'}}>
                            {/* <td colSpan={3} style={{padding: '10px 0'}} className="sp-danger"><img src={warningIcon} alt=""/>  Produk telah mencapai batas pembelian.</td> */}
                        </tr>
                    </tbody>
                </table>
        </>
    )
}

export const ModalAddProductFooterLoading = () => {
    return(
        <>
            <TotalPriceModal>
                <SkeletonPulse/>
            </TotalPriceModal>
            <ButtonAddModal>
                <SkeletonPulse/>
            </ButtonAddModal>
        </>
    )
}

export const ProductPageDetailLoading = () => {
    return(
        <>
            <div style={{ maxWidth: 480, marginLeft: 15, marginRight: 15 }}>
                <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                    <div className="imgProduk">
                        <ImageProduct>
                            <SkeletonPulse  style={{borderRadius: 5}}/>
                        </ImageProduct>
                        {/* <img src={prodDetail.product_image} alt="" className="produk" /> */}
                    </div>

                </Row>
                <Row style={{ marginBottom: 20 }}>
                    <Col span={16}>
                        <Row>
                            <TitleBig>
                                <SkeletonPulse  style={{borderRadius: 5}}/>
                            </TitleBig>
                            {/* <span className="head2">{prodDetail.product_name}</span> */}
                        </Row>
                        <Row>
                        <Title>
                                <SkeletonPulse  style={{borderRadius: 5}}/>
                            </Title>
                            {/* <span className="text-6">{prodDetail.product/_weight} {prodDetail.product_weight_name}</span> */}
                        </Row>
                        <Row style={{marginTop: 10}}>
                            <TitleBig>
                                <SkeletonPulse  style={{borderRadius: 5}}/>
                            </TitleBig>
                            {/* <span className="price-product">{convertToRupiah(prodDetail.product_price)} / {prodDetail.product_unit_name}</span> */}
                        </Row>
                    </Col>
                    <Col span={8}>
                        <Row>
                            <div className="wishlist" style={{marginTop: 5, float: 'right'}}>
                                <Wishlist>
                                    <SkeletonPulse  style={{borderRadius: 5}}/>
                                </Wishlist>
                            </div>
                        </Row>
                        <Row>
                            <div className="quantity" style={{marginTop: 61}}>
                                <QtyProduct>
                                    <SkeletonPulse  style={{borderRadius: 5}}/>
                                </QtyProduct>
                                {/* {counter < 2 ? <img src="https://wwwd.ezeepasar.com/Images/feather_minus-circle-disable.svg" alt="" style={{ float: 'left' }} id="imgMinus" onClick={minusCart} /> : <img src="https://wwwd.ezeepasar.com/Images/feather_minus-circle-enable.svg" alt="" style={{ float: 'left' }} id="imgMinus" onClick={minusCart} />}
                                <InputNumber type="number" className="inpQuantity" min={1} id="inpCartField" value={counter}/>
                                <img src="https://wwwd.ezeepasar.com/Images/feather_plus-circle-enable.svg" alt="" style={{ float: 'right', marginLeft: 5 }} id="imgPlus" onClick={plusCart} /> */}
                            </div>
                        </Row>
                    </Col>
                </Row>
                <div className="" style={{}}>
                    <Row>
                        <Col span={24}>
                            <BantuanWa>
                                <SkeletonPulse  style={{borderRadius: 5}}/>
                            </BantuanWa>
                        </Col>
                    </Row>
                </div>
            </div>
        </>
    )
}

export const TitleProdOtherLoading = () => {
    return (
        <div>
            <TitleBig style={{marginBottom: 30}}>
                <SkeletonPulse style={{borderRadius: 5}}/>
            </TitleBig>
            <TitleLong style={{marginTop: -25, marginBottom: 20}}>
                <SkeletonPulse style={{borderRadius: 5}}/>
            </TitleLong>
        </div>
    )
}

export const ModalPaymentMethodLoading = () => {
    return(
        <>
        <tr style={{marginLeft: 15}}>
            <td style={{width: 35, lineHeight: 5}}>
                <ImgPaymentMethod>
                    <SkeletonPulse style={{borderRadius: '8px'}}/>
                </ImgPaymentMethod>
            </td>
            <td style={{width: 240, paddingLeft: 23}}>
                <TitleBig style={{width: '100%'}}>
                    <SkeletonPulse />
                </TitleBig>
            </td>
            <td style={{float: 'right', marginTop: 9}}>
                <RadioButton>
                    <SkeletonPulse style={{borderRadius: '50%'}}/>
                </RadioButton>
            </td>
        </tr>
        <tr style={{marginLeft: 15}}>
            <td style={{width: 35, lineHeight: 5}}>
                <ImgPaymentMethod>
                    <SkeletonPulse style={{borderRadius: '8px'}}/>
                </ImgPaymentMethod>
            </td>
            <td style={{width: 240, paddingLeft: 23}}>
                <TitleBig style={{width: '100%'}}>
                    <SkeletonPulse/>
                </TitleBig>
            </td>
            <td style={{float: 'right', marginTop: 9}}>
                <RadioButton>
                    <SkeletonPulse style={{borderRadius: '50%'}}/>
                </RadioButton>
            </td>
        </tr>  
        <tr style={{marginLeft: 15}}>
            <td style={{width: 35, lineHeight: 5}}>
                <ImgPaymentMethod>
                    <SkeletonPulse style={{borderRadius: '8px'}}/>
                </ImgPaymentMethod>
            </td>
            <td style={{width: 240, paddingLeft: 23}}>
                <TitleBig style={{width: '100%'}}>
                    <SkeletonPulse/>
                </TitleBig>
            </td>
            <td style={{float: 'right', marginTop: 9}}>
                <RadioButton>
                    <SkeletonPulse style={{borderRadius: '50%'}}/>
                </RadioButton>
            </td>
        </tr>    
        </>
    )
}

export const HeadTitlePage = () => {
    return(
        <>
        <TitleBig style={{margin: '0 auto'}}>
            <SkeletonPulse style={{borderRadius: 8}}/>
        </TitleBig>
        </>
    )
}

export const CardHistoryOrder = () => {
    return(
        <>
            <Card className='skeletonCard' style={{ width: '100%', minHeight: 230, borderRadius: 8, padding: 11, marginRight: 8, boxShadow: '0px 0px 8px rgb(0 0 0 / 15%)'}}>                
                <HeaderCard>
                    <SkeletonPulse />
                </HeaderCard>
                <div style={{paddingLeft: 15, paddingRight: 15}}>
                    <Row>
                        <Col span={12}>
                            <Title style={{float: 'left'}}>
                                <SkeletonPulse/>
                            </Title>
                        </Col>
                        <Col span={12}>
                            <Title style={{float: 'right'}}>
                                <SkeletonPulse/>
                            </Title>
                        </Col>
                    </Row> 
                    <hr style={{color: "grey", marginTop: 30}}/>
                    <Row>
                        <Col span={6}>
                            <ImgPaymentMethod>
                                <SkeletonPulse/>
                            </ImgPaymentMethod>
                        </Col>
                        <Col span={18}>
                            <TitleMedium>
                                <SkeletonPulse/>
                            </TitleMedium>
                        </Col>
                    </Row>
                    <hr style={{color: "grey", marginTop: 30}}/>
                    <Row>
                        <Col span={12}>
                            <TitleMedium style={{float: 'left'}}>
                                <SkeletonPulse/>
                            </TitleMedium>
                            <br/>
                            <TitleMedium style={{float: 'left', marginBottom: 10}}>
                                <SkeletonPulse/>
                            </TitleMedium>
                        </Col>
                        <Col span={12}>
                            <TitleMedium style={{float: 'right'}}>
                                <SkeletonPulse/>
                            </TitleMedium>
                        </Col>
                    </Row> 
                </div>                            
            </Card>
            <br/>
        </>
    )
}

export const HistoryDetailLoading = () => {
    return(
        <div style={{paddingLeft: 15, paddingRight: 15}}>
            <TitleBig style={{margin: '0 auto'}}>
                <SkeletonPulse/>
            </TitleBig>
            <br/>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <hr style={{color: "grey", marginTop: 30}}/>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <hr style={{color: "grey", marginTop: 30}}/>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <hr style={{color: "grey", marginTop: 30}}/>
            <Row>
                <Col span={24}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <Row style={{marginTop: 7}}>
                <Col span={24}>
                    <TitleLong style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </TitleLong>
                </Col>
            </Row>
            <hr style={{background: "#F4F4F4", marginTop: 30, marginLeft: -15, marginRight: -15, height: 8}}/>
            <Row>
                <Col span={12}>
                    <TitleMedium style={{float: 'left', width: 150}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </TitleMedium>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <br/>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <div className='boxVaNumber'>
                        <TitleMedium style={{float: 'left', width: 170}}>
                            <SkeletonPulse style={{borderRadius: 6}}/>
                        </TitleMedium>
                    </div>
                </Col>
            </Row>
            <hr style={{color: "grey", marginTop: 30}}/>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left', width: 125}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left', width: 90}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left', width: 103}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <Row>
                <Col span={12}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
                <Col span={12}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </Title>
                </Col>
            </Row>
            <hr style={{background: "#F4F4F4", marginTop: 30, marginLeft: -15, marginRight: -15, height: 8}}/>
            <Row>
                <Col span={12}>
                    <TitleMedium style={{float: 'left', width: 150}}>
                        <SkeletonPulse style={{borderRadius: 6}}/>
                    </TitleMedium>
                </Col>
            </Row>
            <Row style={{marginTop: 10}}>
                <Col span={6} style={{paddingLeft: 25}}>
                    <ImgPaymentMethod>
                        <SkeletonPulse/>
                    </ImgPaymentMethod>
                </Col>
                <Col span={10}>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse/>
                    </Title><br/>
                    <Title style={{float: 'left'}}>
                        <SkeletonPulse/>
                    </Title>
                </Col>
                <Col span={8}>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse/>
                    </Title><br/>
                    <Title style={{float: 'right'}}>
                        <SkeletonPulse/>
                    </Title>
                </Col>
            </Row>
        </div>
    )
}

export const EzeeTunaiCardBalanceLoading = () => {
    return (
        <div>
            <Row style={{ padding: '14px 21px', borderRadius: 8 }}>
                <Col span={4}>
                    <ImgPaymentMethod style={{marginBottom: 30}}>
                        <SkeletonPulse style={{borderRadius: 5}}/>
                    </ImgPaymentMethod>
                </Col>
                <Col span={20}>
                    <div style={{marginBottom: 10}}>
                        <Title style={{float: 'left', marginTop: -5}}>
                            <SkeletonPulse style={{borderRadius: 5}}/>
                        </Title>
                        <br/>
                        <TitleMedium style={{float: 'left', marginTop: -3}}>
                            <SkeletonPulse style={{borderRadius: 5}}/>
                        </TitleMedium>                        
                    </div>
                    <div>
                        <TitleMedium style={{float: 'right', marginTop: -20, height: 30}}>
                            <SkeletonPulse style={{borderRadius: 5}}/>
                        </TitleMedium>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export const CardProdOtherLoading = () => {
    return(
        <>
            <div>
                <ImgProduct style={{width: '100%', height: 165}}>
                    <SkeletonPulse/>
                </ImgProduct>
                <TitleMedium style={{marginTop: 10}}>
                    <SkeletonPulse/>
                </TitleMedium>
                <br/>
                <Title>
                    <SkeletonPulse/>
                </Title>
                <TitleLong style={{width: '70%'}}>
                    <SkeletonPulse/>
                </TitleLong>
                <ButtonAddModal style={{width: '100%', height: 35, marginTop: 20}}>
                    <SkeletonPulse style={{borderRadius: 50}}/>
                </ButtonAddModal>
            </div>
        </>
    )
}

export const CardDeliveryMethod = () => {
    let expedisiCard = [1,2,3]
    return(
        <>
            <div>
                {
                    expedisiCard.map(dt => {
                        return(
                            <>
                            <CardDeliveryExpedisi style={{width: '100%'}}>
                                <Title style={{marginBottom: 15}}>
                                    <SkeletonPulse style={{height: '20px', marginBottom: 15, borderRadius: 5}}/>
                                </Title>
                                <TitleLong style={{width: '60%'}}>
                                    <SkeletonPulse style={{height: '20px', borderRadius: 5}} />
                                </TitleLong>
                            </CardDeliveryExpedisi>
                            </>
                        )
                    })
                }
            </div>
        </>
    )
}

export const BannerPromoLoading = () => {
    let titleDetail = [1,2,3,4,5,6,7,8,9,10]
    return(
        <>
            <div style={{ maxWidth: 480, marginLeft: 15, marginRight: 15 }}>
                <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                    <div className="imgProduk">
                        <ImageProduct>
                            <SkeletonPulse  style={{borderRadius: 5}}/>
                        </ImageProduct>
                    </div>
                </Row>
                <br/>
                <TitleLong style={{height: 25, width: '50%'}}>
                    <SkeletonPulse/>
                </TitleLong>
                <br/>
                {
                    titleDetail.map(dt => {
                        return(
                            <TitleLong style={{width: '100%'}}>
                                <SkeletonPulse/>
                            </TitleLong>
                        )
                    })
                }
            </div>
        </>
    )
}