import { FETCH_DATA_ADDRESSLISTS_PROFILE, IS_LOADING } from "../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../function/base64Sign";
import { getToken } from "../../utils/setSessionUser";
import {BaseURL} from '../../function/helpers'

export const fetchDataAddressLists = (url) => {
    return( dispatch ) => {
        const signature = base64Sign(url);
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL +url,
            {data: ""},
            {headers : headers}
        )
            .then( addressLists => {
                dispatch({
                    type: FETCH_DATA_ADDRESSLISTS_PROFILE,
                    payload: {
                        getAddressLists: addressLists.data.response_data.results
                    }
                })
                dispatch({
                    type: IS_LOADING,
                    payload: {
                        isLoading: false
                    }
                })
            } )
            .catch(error => {
                console.log(error);
            })
    }
}