import React, { useState, useEffect } from 'react'
import { Row, Col, Button, InputNumber, message} from 'antd'
import { useLocation, useParams, useHistory, Link} from "react-router-dom";
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import { getToken } from '../../utils/setSessionUser';
import './Produk.css';
import OtherProduct from '../HomePage/OtherProduct';
import $ from 'jquery'
import Footer from './FooterProduk';
import {ProductPageDetailLoading} from '../skeletonLoading/loadingTag'
import warningIcon from '../../Asset/Icon/warning-cart.png';
import {nFormatter, BaseURL, headersWithToken, headersWithoutToken} from '../../function/helpers'
import iconFeatherMinusCircleDisable from '../../Asset/Icon/feather_minus-circle-disable.svg'
import iconFeatherMinusCircleEnable from '../../Asset/Icon/feather_minus-circle-enable.svg'
import iconFeatherPlusCircleEnable from '../../Asset/Icon/feather_plus-circle-enable.svg'
import iconFeatherPlusCircleDisable from '../../Asset/Icon/feather_plus-circle-disable.svg'
import iconWhatsappGrey from '../../Asset/Icon/whatsapp_grey.svg'
import {WAhandler} from '../../function/helpers'
import {NavbarWithArrowBack} from '../Properties/Navbar/Navbar'
import HtmlParser from 'react-html-parser';
import {Helmet} from 'react-helmet'

function convertToRupiah(money) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
}
function Produk() {

    const [counter, setCounter] = useState(1);
    const [prodDetail, setProdDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [pathnameURL, setPathnameURL] = useState('');
    let { id } = useParams();
    let { mprod_name } = useParams();
    let currentId = id;
    let access_token = getToken();    
    let history = useHistory();
    let location = useLocation();

    useEffect(() => {
        if(access_token !== null){
            prodDetailWithToken();
            setPathnameURL(window.location.pathname);
            
        }else{  
            prodDetailWithoutToken();
        }
    } , []);

    function snackbarAlert(messages){

        message.success({
            content: messages,
            className: 'snackbar-wishlist-success',
            duration: 10
        });
      };

    async function prodDetailWithoutToken() {        
        try{
            const signature = base64Sign("/product/getproductdetail");
            const dataParams = encryptData(`{"product_id":${id}}`);
            const headers = headersWithoutToken(signature)
            const dataProds = await axios.post(BaseURL + '/product/getproductdetail',{data: dataParams},{headers : headers});
            setProdDetail(dataProds.data.response_data.results);
            setLoading(false);
        }catch(error){
            console.log(error);
        }
    }

    async function prodDetailWithToken() {        
        try{
            const signature = base64Sign("/product/getproductdetail");
            const dataParams = encryptData(`{"product_id":${id}}`);
            const headers = headersWithToken(signature, access_token)
            const dataProds = await axios.post(BaseURL + '/product/getproductdetail',{data: dataParams},{headers : headers});
            setProdDetail(dataProds.data.response_data.results);
        
            setLoading(false);
        }catch(error){
            console.log(error);
        }
    }

    async function wishlistProduct() {        
        try{
            const signature = base64Sign("/product/wishlistproduct");
            const dataParams = encryptData(`{"prod_id":${id}}`);
            const headers = headersWithToken(signature, access_token)
            const dataProds = await axios.post(BaseURL + '/product/wishlistproduct',{data: dataParams},{headers : headers});
            if(dataProds.status === 200){
                snackbarAlert("Produk ini dimasukkan kedalam barang favorit")
            }
        }catch(error){
            console.log(error);
        }
    }

    async function unwishlistProduct() {        
        try{
            const signature = base64Sign("/profile/deletewishlist");
            const dataParams = encryptData(`{"product_id":${id}}`);
            const headers = headersWithToken(signature, access_token)
            const dataProds = await axios.post(BaseURL + '/profile/deletewishlist',{data: dataParams},{headers : headers});
        }catch(error){
            console.log(error);
        }
    }

    let plusCart = () => { setCounter(counter + 1) }
    let minusCart = () => { setCounter(counter - 1) }   
    if (counter <= 1) { minusCart = () => { setCounter(1) } }

    if (loading) {
        return (
            <ProductPageDetailLoading/>
        )
    }
    function wishlistProd(){
        if(access_token === null){
            history.push('/login');
        }else{
            prodDetailWithToken();
            if(prodDetail.is_wished){
                unwishlistProduct()
                $('.heart').toggleClass('is-active');
            }else{
                wishlistProduct();                
                $('.heart').toggleClass('is-active');
            }
        }
    }

    function unwishlistProd(){   
    }

    function countProduct(){
        let qty = $('#inpCartField').val();
        qty = parseInt(qty);
        if(qty < 1){qty = 1}
        if(prodDetail.max_buy !== null){
            if(qty > prodDetail.max_buy){
                qty = prodDetail.max_buy;
            }
        }
        
        if(prodDetail.remaining_stock !== null){
            if(qty > prodDetail.remaining_stock){
                qty = prodDetail.remaining_stock;
            }
        }
        setCounter(qty);
    }

    return (
        <div>
            <Helmet>
                <title>{prodDetail.category_name} : {prodDetail.product_name} | ezeePasar | Pasar Modern Digital</title>
            </Helmet>
            <NavbarWithArrowBack link="/back"/>
            <div style={{ maxWidth: 480, marginLeft: 15, marginRight: 15 }}>
            {
                prodDetail.product_stat_stock_id === 2 ? 
                <>
                <div className='sold-out-product'>
                    <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                        <div className="imgProduk">    
                                <><img src={prodDetail.product_image} alt="" className="produk" /><span className='text-sold-out'>Habis</span> </>                                                        
                        </div>
                    </Row>
                </div>
                </> : 
                <>
                    <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                        <div className="imgProduk">    
                            <img src={prodDetail.product_image} alt="" className="produk" />                                                   
                        </div>
                    </Row>
                </>
            }
            <Row style={{ marginBottom: 20 }}>
                <Col span={16}>
                    <Row>
                        <span className="head2">{prodDetail.product_name}</span>
                    </Row>
                    <Row>
                        <span className="text-6">{prodDetail.product_weight} {prodDetail.product_weight_name}</span>
                    </Row>
                    <Row>
                    {
                        prodDetail.product_promo_percent == null
                        ? <></>
                        : <><div className="disc-product">
                            <span className="percent-disc">{ prodDetail.product_promo_percent }%</span>
                            <span className="real-price">{ convertToRupiah(prodDetail.product_price) }</span>
                        </div>                                    
                        </>
                    }
                    </Row>
                    <Row>
                        <span className="price-product">{prodDetail.product_promo_price !== null ? convertToRupiah(prodDetail.product_promo_price) : convertToRupiah(prodDetail.product_price)} / {prodDetail.product_unit_name}</span>
                    </Row>
                </Col>
                <Col span={8}>
                    <Row>
                        <div className="wishlist">
                            {
                                prodDetail.is_wished === true ? 
                                <div className="heart is-active" onClick={() => wishlistProd()}></div>:
                                <div className="heart" onClick={() => wishlistProd()}></div>
                            }
                        </div>
                    </Row>
                    <Row>
                        {
                            prodDetail.product_stat_stock_id !== 2? 
                            <div className="quantity">
                            {counter < 2 ? 
                                <img src={iconFeatherMinusCircleDisable} alt="" style={{ float: 'left' }} id="imgMinus" onClick={minusCart} /> : <img src={iconFeatherMinusCircleEnable} alt="" style={{ float: 'left' }} id="imgMinus" onClick={minusCart} />}
                                    <InputNumber type="number" className="inpQuantity" min={1} id="inpCartField" value={counter} onKeyUp={() => countProduct()}/>
                                {/* <img src="https://wwwd.ezeepasar.com/Images/feather_plus-circle-enable.svg" alt="" style={{ float: 'right', marginLeft: 5 }} id="imgPlus" onClick={plusCart} /> */}
                                {
                                    // prodDetail.max_buy === null ? <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} /> : 
                                    // counter >= prodDetail.max_buy ? <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" /> :
                                    // <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} />
                                    // counter <= prodDetail.remaining_stock ? [
                                    //     (counter <= prodDetail.max_buy ? : )
                                    // ]
                                    // prodDetail.remaining_stock !== null || prodDetail.max_buy !== null ? [
                                    //     (counter <= prodDetail.remaining_stock ? [
                                    //         (counter <= prodDetail.max_buy ? 
                                    //         <>
                                    //             <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} />
                                    //         </> : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" />)
                                    //     ] : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" />)
                                    // ] : <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} />
                                    
                                    prodDetail.remaining_stock !== null ? [
                                        (counter < prodDetail.remaining_stock ? [
                                            (prodDetail.max_buy !== null) ? [
                                                (counter <= prodDetail.max_buy ?  
                                                    <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} /> : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" /> )
                                            ] : <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} />
                                        ] : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" />)
                                    ] : (prodDetail.max_buy !== null) ? [
                                        (counter < prodDetail.max_buy ?  
                                            <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} /> : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" /> )
                                ] : <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} />

                                }
                            </div> 
                            : null
                        }
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: -18}}>
                <Col span={24}>
                {
                    prodDetail.product_terjual !== 0 ?                                         
                        <span className='sp-danger' style={{fontSize: 10}}>Terjual: <span style={{color: 'black'}}>{nFormatter(prodDetail.product_terjual)}</span></span>
                        : null
                }
                {
                    prodDetail.remaining_stock !== null && prodDetail.product_terjual !== 0 ? <span> | </span> : null
                }
                {
                    prodDetail.remaining_stock !== null ?                                         
                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10}}>Stok Tersisa <span style={{color: 'black'}}>{prodDetail.remaining_stock}</span></span>
                        : null
                }
                </Col>
            </Row> 
            {
            prodDetail.remaining_stock !== null ? [
                (counter < prodDetail.remaining_stock ? [
                    (prodDetail.max_buy !== null) ? [
                        (counter <= prodDetail.max_buy ?  
                            null : 
                            <Row>
                                <Col span={24}>
                                    <div>
                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 12, fontWeight:400}}><img src={warningIcon} alt=''/>&nbsp;Jumlah Produk telah mencapai batas pembelian.</span>
                                    </div>
                                </Col>
                            </Row>)
                    ] : null
                ] :
                <Row>
                    <Col span={24}>
                        <div>
                            <span className='sp-danger product-sold-and-stock' style={{fontSize: 12, fontWeight:400}}><img src={warningIcon} alt=''/>&nbsp;Jumlah Produk telah mencapai batas pembelian.</span>
                        </div>
                    </Col>
                </Row>)
            ] : (prodDetail.max_buy !== null) ? [
                    (counter === prodDetail.max_buy ? 
                    <Row>
                        <Col span={24}>
                            <div>
                                <span className='sp-danger product-sold-and-stock' style={{fontSize: 12, fontWeight:400}}><img src={warningIcon} alt=''/>&nbsp;Jumlah Produk telah mencapai batas pembelian.</span>
                            </div>
                        </Col>
                    </Row > : null)
                ] : null
            }
            <br/>
            <Row>
                <Col span={24}>
                    <div className="description-product" style={{fontSize: 12, color: '#848484'}}>
                        {prodDetail.product_description}
                    </div>
                </Col>
            </Row>
            <div className="btn-bantuan-wa">
                <button type="default" className="btn-wa" onClick={WAhandler}>
                    <img src={iconWhatsappGrey} style={{ width: 24, marginRight: 10 }} alt="" />
                    <span style={{ fontSize: 14, color: '#848484', fontWeight: 'bold', fontFamily: 'Montserrat' }}>Butuh Bantuan? Kontak kami</span>
                </button>
            </div>
            <Row style={{ marginTop: 15, textAlign: 'center'}}>
                <Col span={24}>
                    <Link to="/Disclaimer">
                        <span className="textDisclaimer">
                            Gambar yang dipergunakan merupakan ilustrasi.<br />untuk Disclaimer,<a style={{ textDecoration: 'none', fontWeight: 'Bold', color: '#3DB54A' }}> lihat disini</a>
                        </span>
                    </Link>
                </Col>
            </Row>
            <div className="break-page"></div>
            <OtherProduct prod_id={parseInt(id)} cat_id={parseInt(prodDetail.category.mcat_id)}/>
            <br />
            {
                prodDetail.product_stat_stock_id !== 2 ? 
                <Footer prod_id={id} totalItem={counter} detail={prodDetail}/> : null
            }
            </div>
        </div>
    )
}

export default Produk
