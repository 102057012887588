import React from 'react'
import { Link } from 'react-router-dom'
import './TabMenu.css'

function TabMenu2() {
    return (
        <div>
            {/* <div style={{ width: '109%', maxWidth: 480, display: 'table' }}>
                <div>
                    <ul className="nav nav-tabs">
                        <li style={{ cursor: 'pointer' }}>
                            <span>Informasi</span>
                        </li>
                        <li className="active" style={{ cursor: 'pointer' }}>
                            <span>Keamanan</span>
                        </li>
                        <li style={{ cursor: 'pointer' }}>
                            <span>Pengaturan</span>
                        </li>
                    </ul>
                </div>
            </div> */}
            <div style={{ display: 'block', paddingTop: 7, height: '81vh', overflow: 'auto' }}>
                <ul className="nav" style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
                    <li>
                        <Link to="/ChangePassword" id="link_title">Ubah Kata Sandi</Link>
                    </li>
                    {/* <li>
                        <Link to="#">Ubah PIN ezeePasar</Link>
                    </li> */}
                    {/* <li>
                        <Link to="#">Verifikasi Data</Link>
                    </li> */}
                </ul>
            </div>
        </div>
    )
}

export default TabMenu2
