import React from 'react'
import {NavbarCloseOnlyWithLogo} from '../../components/Properties/Navbar/Navbar'
import EzeetunaiPayment from '../../components/Payment/EzeetunaiPayment'

function Ezeetunai() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnlyWithLogo link="/checkout"/>
            <EzeetunaiPayment/>
        </div>
    )
}

export default Ezeetunai
