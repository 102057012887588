import React, {useState} from 'react';
import {Modal} from 'antd'
import { Link } from 'react-router-dom';
import ezeeTunaiIcon from '../../../Asset/Icon/logo/IconezeeTunai.png'

function EzeeTunaiUnactiveModal({isOpen, onClosed}) {

    return (
        <div>
            <div className="center-modal">
                <Modal className="modalProgress" id="modalProgress" visible={isOpen} onClosed={onClosed} footer={null} closable={false} style={{width: '330px !important', height: '356px !important',  zIndex: 99999}} >
                    <div style={{textAlign: 'center'}}>
                        <img src={ezeeTunaiIcon} alt="" style={{width: 56.2, heigth: 64, marginBottom: 10}}/>
                        <div style={{fontSize: 18,  fontWeight: 'bold', marginBottom: 13, color: '#393939', lineHeight: "18px"}}>Aktiivasi ezeeTunai</div>
                        <div style={{fontSize: 12,  paddingLeft: '5%', paddingRight: '5%', fontFamily: 'Open Sans', color: '#848484', lineHeight: "17px"}}>Aktivasi ezeeTunai kamu untuk menikmati berbagai promo menarik serta mempermudah kamu dalam bertransaksi di ezeePasar</div>
                        <Link to="/EzeeTunai/HowToActivation" rel="noopener noreferrer"><button className='btnKeranjang' style={{fontSize: 12, marginTop: 10}} >Aktivasi</button></Link>
                        <button className='btnKeranjang' style={{color: "#848484", backgroundColor: 'unset', marginTop: 10, border: 'unset', fontSize: 12}} onClick={onClosed} >Lain Kali</button>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default EzeeTunaiUnactiveModal;
