import { 
    FETCH_BANKLIST_EZEETUNAI, 
    IS_NEW_LOADING, 
    FETCH_USERS_REQUEST
 } from "../../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../../function/base64Sign";
import { getToken } from "../../../utils/setSessionUser";
import {BaseURL} from '../../../function/helpers'

export const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const backToLoading = () => {
    return {
        type: IS_NEW_LOADING
    }
}

const fetchBankListSuccess = datas => {
    return{
        type: FETCH_BANKLIST_EZEETUNAI,
        payload: datas
    }
}

export const fetchEzeeTunaiBankList = (url) => {
    return (dispatch) => {
        dispatch(fetchUsersRequest())
        const signature = base64Sign(url)
        const access_token = getToken()
        const headers = {
            'Content-Type' : 'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        
        axios.post(BaseURL + url, {data: ''}, {headers: headers})
            .then(bankList => {
                dispatch(fetchBankListSuccess(bankList.data.response_data.results))
               
            }, 
                dispatch(backToLoading())                   
            ).catch(err => {
                console.log(err);
            })
        }
    }