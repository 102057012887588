import { IS_LOADING_WISHLISTS_PROFILE } from "../../ActionType/actionTypes";

export function isLoadingWishlist(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_WISHLISTS_PROFILE, 
            payload: {
                isLoadingWishlist: param
            }
        })
    }
}