import { IS_LOADING_GETCATLIST1_CAT } from "../../ActionType/actionTypes";

export function isLoadingGetCatList1(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_GETCATLIST1_CAT, 
            payload: {
                isLoadingGetCatList1: param
            }
        })
    }
}