import { FETCH_DATA_NOTIFIKASIBROWSER_PROFILE } from '../ActionType/actionTypes'

const initialState = {
    notifikasiBrowser: []
}

const notifikasiBrowserReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_NOTIFIKASIBROWSER_PROFILE) {

        return { ...state, notifikasiBrowser: action.payload.notifikasiBrowser }
    } else {
        return state
    }
}

export default notifikasiBrowserReducer
