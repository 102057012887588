import React from 'react'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import ListCat2 from '../components/ProductListCategory2/ListCat2';
import { useDispatch } from 'react-redux';
import { isLoadingGetCatLayer2List1 } from '../redux/ActionCreators/Loading/isLoadingGetCatLayer2List1Actions';
import { isLoadingGetCatLayer2List2 } from '../redux/ActionCreators/Loading/isLoadingGetCatLayer2List2Actions';
import { isLoadingProductList2 } from '../redux/ActionCreators/Loading/isLoadingProductList2Actions';

function ProductListCategory2() {

    const dispatch = useDispatch()

    function handleClick() {

        dispatch(isLoadingGetCatLayer2List1(true))
        dispatch(isLoadingGetCatLayer2List2(true))
        dispatch(isLoadingProductList2(true))
    }

    return (
        <div className="container body-content mobile-view">
            <NavbarWithMenuIcon link="/" />
            <ListCat2 key={Math.random()} />
        </div>
    )
}

export default ProductListCategory2
