import { IS_LOADING_GETCATLIST2_CAT } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingGetCatList2: true
}

const isLoadingGetCatList2Reducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_GETCATLIST2_CAT) {
        return { ...state, isLoadingGetCatList2: action.payload.isLoadingGetCatList2 }
    } else {
        return state
    }
}

export default isLoadingGetCatList2Reducer