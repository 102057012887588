import React, {useState, useEffect} from 'react'
import {Row, Col, Input} from 'antd'
import {useLocation, useHistory} from "react-router-dom";
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import { setUserSession, getUserFb} from '../../utils/setSessionUser';
import './Register.css'
import {BaseURL, headersWithoutToken, useTitle} from '../../function/helpers'
import $ from 'jquery'
import iconWarning from '../../Asset/Icon/icon-warning-text.svg'
import validator from 'validator'

function FormRegister() {
    useTitle("Register")
    let data = useLocation();
    let history = useHistory();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [pass, setPass] = useState('');
    const [confPass, setConfPass] = useState('');
    const [modalProgress, setModalProgress] = useState(false);
    const [isError, setisError] = useState(false);
    const [alertText, setAlertText] = useState('');
    const [alertTextIsExist, setalertTextIsExist] = useState('');

    var dataUser = data.state.result
    useEffect(() => {
        if(data.state.credential === 'email'){
            setEmail(dataUser)
        }
        else if(data.state.credential === 'phone'){
            setPhone(dataUser)
        }
    }, [])

    async function registerAccount(){
        setModalProgress(true)
        var usrAgent = navigator.userAgent;
        var password = $("#password").val();
        var passwordConfirm = $("#passwordConfirm").val();
        let isExist;
        if(data.state.credential === 'email'){
            setEmail(dataUser)
            setPhone(phone)           
            isExist = await checkUserIsExist(phone);
        }
        else if(data.state.credential === 'phone'){
            setPhone(dataUser)
            setEmail(email)
            isExist = await checkUserIsExist(email);
        }

        if(password !== passwordConfirm){
            $('.reg-pass').addClass('error-input');
            $('.reg-confpass').addClass('error-input');
            setAlertText("Password dan Konfirmasi Password tidak sama");
            return;
        }
        let validasi = 0
        const besarKecil = /[A-Za-z]/g;
        const numbers = /[0-9]/g;
        const spChar = /[/\W|_/g]/g;
        if (password.match(besarKecil) && password.length >= 8) {
            validasi += 1
        }
        if (password.match(numbers)) {
            validasi += 1
        }
        if (password.match(spChar)) {
            validasi += 1
        }
        if (validasi < 2) {
            $('.reg-pass').addClass('error-input');
            $('.reg-confpass').addClass('error-input');
            setAlertText("Kata sandi minimal 8-20 karakter. 2 kombinasi antara huruf, angka, simbol.")
            return;
        }else{
            setAlertText('');
            try{
                const signature = base64Sign("/security/register");
                const dataParams = encryptData(`{"client_id":0,"email":"${email}","login_type":100, "password":"${pass}","mobile":"${phone}","fullname":"${name}","user_agent":"${usrAgent}","token_ezeepasar":true,"token_external_login":""}`)
                const headers = 
                {
                'Content-Type':'application/json',
                'sign' : signature
                }
                if(isExist){
                }else{
                    const dataProds = await axios.post(BaseURL + '/security/register',{data: dataParams},{headers : headers});
                    if(dataProds.status === 200)
                    {
                        setModalProgress(false)
                        setUserSession(dataProds.data.response_data.results.access_token)
                        history.push('/')
                    }else{
                        if(dataProds.response.status === 400){
                            if(dataProds.response.data.response_data.error_id === "0001"){
                                setisError(true);
                                setalertTextIsExist(dataProds.response.data.response_data.error_text)
                                $('#emailphoneVal').addClass('error-input');                       
                            }                            
                        }
                    }
                }
            }catch(ex){
                console.log(ex);
            }            
        }
    }  

    async function checkUserIsExist(username, type) {
        let isExist = false;
        try{
            const signature = base64Sign("/security/validateuserregister");  
            const dataParams = encryptData(`{"username": "${username}"}`)         
            const headers = headersWithoutToken(signature);
            const results = await axios.post(BaseURL + '/security/validateuserregister',{data:dataParams},{headers:headers})
            if(results.status === 200){
                setisError(false);
                setalertTextIsExist('');
                $('#emailphoneVal').removeClass('error-input');
                isExist = false;
            }else{
                if(results.response.status === 400){
                    if(results.response.data.response_data.error_id === '-1'){
                        setisError(true);
                        setalertTextIsExist(results.response.data.response_data.error_text)
                        $('#emailphoneVal').addClass('error-input');
                        isExist = true;
                    }
                }
            }
        }catch(err){
        }
        return isExist;
    }
    
    if(!name || !email || !pass || !confPass || !phone){
        $('#reg-button').attr('disabled', true);
    }else{
        $('#reg-button').attr('disabled', false);
    }

    return(
     <div className="text-1" style={{marginTop: 80}}>
        <h2 className="head-2">Selamat Datang!</h2>
        <div style={{marginTop: 25}}>
            <span>Sekarang, kamu bisa menikmati</span>
            <br/>
            <span >beragam fasilitas yang telah kami siapkan.</span>
            <br/>
            <span>Mohon lengkapi data berikut untuk keamanan akun.</span>
        </div>
        <div style={{marginLeft: 15, marginRight: 15, marginTop: 20}}>
            <Row>
                <Col>
                    <span className="title-label">Nama Lengkap *</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Input className="form-control-register reg-name" onChange={e => setName(e.target.value)}></Input>
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="title-hint">Contoh: JohnDoe</span>
                </Col>
            </Row>
            {data.state.credential === 'email' ? 
                <div>
                    <Row style={{marginTop:10}}>
                        <Col>
                            <span className="title-label">No. Telepon *</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Input type="number" id="emailphoneVal" className="form-control-register reg-phonenum" onChange={e => setPhone(e.target.value)}></Input>
                        </Col>
                    </Row>
                    {
                        alertTextIsExist !== '' ? 
                        <Row>
                            <Col>
                                <img src={iconWarning} alt=""/><span className='title-hint' style={{color: 'red'}}> {alertTextIsExist}</span>
                            </Col>
                        </Row> : 
                        null
                    }                    
                    <Row>
                        <Col>
                            <span className="title-hint">Contoh: 08123456789</span>
                        </Col>
                    </Row>
                </div> : 
                <div>
                    <Row style={{marginTop:10}}>
                        <Col>
                            <span className="title-label">Email *</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Input className="form-control-register reg-email" id="emailphoneVal" onChange={e => setEmail(e.target.value)}></Input>
                        </Col>
                    </Row>
                    {
                        alertTextIsExist !== '' ? 
                        // <Row>
                            <div>
                                <img src={iconWarning} alt=""/><span className='title-hint' style={{color: 'red'}}> {alertTextIsExist}</span>
                            </div>
                        // </Row> : 
                        :
                        null
                    }
                    <Row>
                        <Col>
                            <span className="title-hint">Contoh: ezeepasar@ezeepasar.com</span>
                        </Col>
                    </Row>
                </div>
                }
            <Row style={{marginTop: 10}}>
                <Col>
                    <span className="title-label">Kata Sandi *</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Input.Password className="form-control-pass-reg reg-pass" autoComplete="new-password" id='password' onChange={e => setPass(e.target.value)}/>                                               
                </Col>
            </Row>
            <Row>
                <Col>
                    <span className="title-hint">Contoh: johndoe123</span>
                </Col>
            </Row>
            <Row style={{marginTop: 10}}>
                <Col>
                    <span className="title-label">Konfirmasi Kata Sandi *</span>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Input.Password className="form-control-pass-reg reg-confpass" autoComplete="new-password" id='passwordConfirm' onChange={e => setConfPass(e.target.value)}/>                                               
                </Col>
            </Row>
            {
                alertText !== '' ? 
                <Row>
                    <Col>
                        <img src={iconWarning} alt=""/><span className='title-hint' style={{color: 'red'}}> {alertText}</span>
                    </Col>
                </Row> : 
                null
            }
            <Row>
                <Col>
                    <span className="title-hint">Contoh: johndoe123</span>
                </Col>
            </Row>
            <Row style={{marginTop: 12}}>
                <Col span={24}>
                    <button className="btn-register" id="reg-button" onClick={() => registerAccount()}>Mulai Belanja</button>
                </Col>
            </Row>
        </div>
    </div>
    )
}

export default FormRegister