import {IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_BANKLIST_EZEETUNAI} from "../../ActionType/actionTypes";

const initialState = {
    bankList : [],
    loading : true
}

const ezeeTunaiGetBankListReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST : 
        return{
            ...state,
            loading: true
        }
        case FETCH_BANKLIST_EZEETUNAI : 
        return{
            ...state,
            loading: false,
            bankList : action.payload 
        }
        case IS_NEW_LOADING : 
        return{ 
            ...state,           
            loading : true
        }
        default : return state
    }
}

export default ezeeTunaiGetBankListReducer