import React from 'react'
import { Layout, Menu, Row, Col } from 'antd'
import iconBackHeader from '../../Asset/Icon/back_header.svg'
import { useHistory } from 'react-router-dom'

const { Header } = Layout

function HeaderWithoutClose() {
    const history = useHistory()

    function goToBack() {
        // window.location.href = 'javascript:history.go(-1)';
        history.push("/")
    }
    return (
        <Layout>
            <Header style={{ padding: 0, position: 'fixed', zIndex: 1, height: 49 }}>
                <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']} style={{ boxShadow: '0px 5px 10px rgb(0 0 0 / 5%)'}}>
                    <Row style={{ width: 480 }}>
                        <Col span={12} style={{ paddingLeft: 5, marginTop: -5, cursor: 'pointer' }}>
                            <img src={iconBackHeader} alt="arrowBack" onClick={goToBack} />
                        </Col>
                    </Row>
                </Menu>
            </Header>
        </Layout>
    )
}

export default HeaderWithoutClose
