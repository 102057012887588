import React from 'react'
import './CheckoutModal.css'
import {Row, Col, Modal} from 'antd'
import { useHistory } from 'react-router-dom'
import ezTunaiCashback from '../../../Asset/Icon/Checkout/ezeeTunai_cashback.svg'
import productCashback from '../../../Asset/Icon/Checkout/product_cashback.svg'
import cashbackIsEmpty from '../../../Asset/Icon/Checkout/cashbackisEmpty.svg'
import { checkoutUserSession } from '../../../utils/setSessionUser'

export function CashbackModal({isOpen, onClosed, data}) {

    const closeModal = () => {
        
    }
    return (
        <div>
            <Modal
                visible={isOpen}
                className="modalAddCart modalCashback" 
                style={{borderRadius: 15}}  
                footer={
                <>
                     <Row>
                        <Col span={24}>
                            <div>
                                <button className='modal-button-cashback' onClick={onClosed}>
                                    <span className='spanCart'>
                                        Oke
                                    </span>
                                </button>
                            </div>
                        </Col>
                    </Row>
                </>
                }
                onClosed={onClosed}
                onCancel={onClosed}
            >
                <div style={{textAlign: 'center'}}>
                    <Row>
                        <Col span={24}>
                            <img src={data.mcb_mcbtype_id === 102 ? productCashback : ezTunaiCashback} alt=""/>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24}>
                            <div>
                                <p className='title-cashback-modal'>{data.cashback_value_text}</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div>
                                <p className='sub-title-cashback-modal'>{data.cashback_desc_text}</p>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                </div>
            </Modal>
        </div>
    )
}

export function CashbackIsEmpty({isOpen, onClosed, callbackPaymentStatus}){
    checkoutUserSession.setCheckoutDataIsCashback(false);

    function continuePayment(){
        checkoutUserSession.setCheckoutDataIsCashback(false);
        callbackPaymentStatus(true)
    }

    return(
        <div>
            <Modal
                visible={isOpen}
                className="modalProgress" 
                style={{borderRadius: 15}}  
                footer={null}
                onClosed={onClosed}
                onCancel={onClosed}
            >
                <div style={{textAlign: 'center'}}>                    
                <img src={cashbackIsEmpty} alt=""/>
                    <Row>
                        <Col span={24}>
                            <div>
                                <p className='title-cashback-isempty'>Yah, Kuota Cashback Telah Habis. Tetap Lanjutkan Pesanan?</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div>
                                <p className='sub-title-cashback-modal'>Kamu akan melanjutkan proses pemesanan tanpa mendapat cashback.</p>
                            </div>
                        </Col>
                    </Row>
                    <br/>
                    <Row>
                        <Col span={24}>
                            <div style={{marginRight: 15}} onClick={onClosed}>
                                <button className='modal-button-cashback' onClick={() => continuePayment()}>
                                    <span className='spanCart'>
                                        Ya, Lanjutkan Pesanan
                                    </span>
                                </button>
                            </div>
                            <br/>
                            <span style={{marginTop: 10, fontWeight: 600}} onClick={onClosed}>
                                 Nanti Saja
                            </span>
                        </Col>
                    </Row>
                </div>
            </Modal>            
        </div>
    )
}

export function CashbackModalDetail({isOpen, onClosed, data_snk, data_desc}){
    return(
        <div>
        <Modal
            visible={isOpen}
            className="modalAddCart modalPaymentMethod" 
            style={{borderRadius: 15}}  
            footer={null}
            onClosed={onClosed}
            onCancel={onClosed}
        >
            <div style={{textAlign: 'left'}}>
                <Row>
                    <Col span={24}>
                        <div>
                            <p className='title-cashback-modal'>Program Cashback</p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <div>
                            <p className='sub-title-cashback-modal'>{data_desc}</p>
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col span={24}>
                        <div>
                            <p className='title-cashback-modal'>Syarat & Ketentuan</p>
                        </div>
                    </Col>
                </Row>
                <Row style={{overflowX: 'auto', maxHeight: 175}}>
                    <Col span={24}>
                        <div>
                            <ul className='sub-title-cashback-modal' style={{textAlign: 'left'}}>
                                {data_snk !== null ? 
                                    data_snk.map(datas => {
                                        return(
                                            <li>{datas}</li>
                                        )
                                    }) : null
                                }
                            </ul>
                        </div>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col span={24}>
                        <div style={{marginRight: 15}} onClick={onClosed}>
                            <button className='modal-button-cashback'>
                                <span className='spanCart'>
                                    Oke
                                </span>
                            </button>
                        </div>
                    </Col>
                </Row>
            </div>
        </Modal>
    </div>
    )
}
