import React, {useState, useEffect} from 'react'
import {Row, Col, Modal} from 'antd'
import './FilterModal.css'
import $ from 'jquery'
import DatePicker from 'react-mobile-datepicker';
import moment from 'moment'
import {useSwipeable} from 'react-swipeable'

function FilterModal({show, closedModal, receiveDataFromParent}) {
    const [visibleDate, setVisibleDate] = useState(false);
    const [flag, setFlag] = useState(0);
    const [dateId, setDateId] = useState("1");
    const [datePickerFrom, setDatePickerFrom] = useState(moment(new Date()).format("DD MMMM YYYY"))
    const [datePickerTo, setDatePickerTo] = useState(moment(new Date()).format("DD MMMM YYYY"))
    const [statusID, setStatusID] = useState([]);
    const [isDatePickerOpen, setIsDatePickerOpen] = useState(true);
    // let statusID = [];

    useEffect(() => {
    }, [statusID, isDatePickerOpen])

    function activeBox(id){
        if($('#'+id).hasClass('active-header')){
            $('#'+id).removeClass('active-header');
            setStatusID(statusID.filter(item => item !== id));
        }else{
            $('#'+id).addClass('active-header');
            setStatusID([...statusID, id]);
        }
        
    }

    function openModalDate(fl){
        setVisibleDate(true);
        setFlag(fl);
    }

    function closedModalDate(){
        setVisibleDate(false);
    }

    function acceptDate(val){
        const date = moment(val).format('DD MMMM YYYY');
        if(flag === 1){
            setDatePickerFrom(date);
            setVisibleDate(false);
        }else{
            setDatePickerTo(date);
            setVisibleDate(false);
        }
    }

    function callbackToParent(){
        if(isDatePickerOpen){
            receiveDataFromParent(statusID, "", "", dateId);
        }else{
            receiveDataFromParent(statusID, moment(datePickerFrom, "DD-MMMM-YYYY").format("YYYY-MM-DD"), moment(datePickerTo, "DD-MMMM-YYYY").format("YYYY-MM-DD"), dateId);
        }
        setVisibleDate(false);
    }

    function selectDateId(id){
        setDateId(id);
        setIsDatePickerOpen(true);
        if(id === 6){
            setIsDatePickerOpen(false);
        }
    }

    function dropDownAndUpModal(){        
        var modalFilter = $('.modal-filter .ant-modal-content');
        if(modalFilter.height() === 310){
            modalFilter.animate({
                height: '550px'
            }, {duration: 200, queue: false, complete: function(e){modalFilter.css('overflowY', 'auto'); } });
        }
        else if(modalFilter.height() === 550){
            modalFilter.animate({
                height: '310px'
            }, {duration: 200, queue: false, complete: function(e){modalFilter.css('overflowY', ''); } });
        }else{
        }
    }

    const swipeUpAndDown = 
    useSwipeable({
            onSwipedUp: (e) => $('.modal-filter .ant-modal-content').animate({
                height: '550px'
            }, {duration: 250, queue: false, complete: function(e){$('.modal-filter .ant-modal-content').css('overflowY', 'auto'); } }),
            onSwipedDown: (e) => $('.modal-filter .ant-modal-content').animate({
                height: '310px'
            }, {duration: 250, queue: false, complete: function(e){$('.modal-filter .ant-modal-content').css('overflowY', ''); } })            
        });
    

    var minDatePicker = moment(new Date()).subtract(1, 'years').format(("YYYY, MM, DD"));
    var maxDatePicker = moment(new Date()).format(("YYYY, MM, DD"))

    const monthMap = {
        '1': 'Januari',
        '2': 'Februari',
        '3': 'Maret',
        '4': 'April',
        '5': 'Mei',
        '6': 'Juni',
        '7': 'Juli',
        '8': 'Agustus',
        '9': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'Desember',
        };
         
        const dateConfig = {        
            'date': {
                format: 'DD',
                caption: 'Day',
                step: 1,
            },
            'month': {
                format: value => monthMap[value.getMonth() + 1],
                caption: 'Mon',
                step: 1,
            },
            'year': {
                format: 'YYYY',
                caption: 'Year',
                step: 1,
            }
        };

    return (
        <div className='container'>
            <div className='div-modal-addcard'>
            <Modal 
            title={
                <div {...swipeUpAndDown} onClick={() => dropDownAndUpModal()}>
                    <span>
                        Filter
                    </span>
                    <div style={{marginTop: -27,  paddingBottom: 32}}>
                        <hr className='hr-top-header' />
                    </div>
                </div>
            } id="modalFilter" 
            visible={show} 
            className="modalAddCart modal-filter" 
            closable={false}
            onCancel={closedModal}     
            footer={
                <div>
                    <Row>
                         <Col span={24}>
                             <button className='btnKeranjang' onClick={() => callbackToParent()}>
                                <span className="spanCart">Terapkan</span>
                             </button>
                        </Col>
                    </Row>
                </div>
            }>
                <div style={{marginTop: 55}}>
                <p className='span-header'>Status Transaksi</p>
                    <table className='table-filter' style={{width: '100%', margin: '0px 10px 10px -12px'}}>
                        <tbody>
                            <tr>
                                <td>
                                    <div className='tab-produk no-select-text' onClick={() => activeBox(2)} id='2'>Menunggu Pembayaran</div>
                                </td>
                                <td>
                                    <div className='tab-produk no-select-text' onClick={() => activeBox(7)} id='7'>Pesanan Selesai</div>
                                    </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='tab-produk no-select-text' onClick={() => activeBox(99)} id='99'>Pesanan Dibatalkan</div>
                                </td>
                                <td>
                                    <div className='tab-produk no-select-text' onClick={() => activeBox(4)} id='4'>Pesanan Diproses</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div className='tab-produk no-select-text' onClick={() => activeBox(10)} id='10'>Payment Failed</div>
                                </td>
                                <td>
                                    <div className='tab-produk no-select-text' onClick={() => activeBox(9)} id='9'>Payment Expired</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div id="divtanggalfilter" style={{marginTop: 15}}>
                    <p className='span-header'>Tanggal Transaksi</p>
                    <div className='period'>
                       <table style={{width: '100%'}}>
                           <tbody>
                               <tr>
                                   <td><span className='span-header' style={{fontSize: 12}}>Semua Tanggal Transaksi</span></td>
                                   <td style={{}}>
                                       <input type="radio" name="date-filter" onClick={() => selectDateId(1)} id="1" style={{width: 20, height: 20}}/>
                                   </td>
                               </tr>
                               <hr className='hr-table'/>
                               <tr>
                                   <td><span className='span-header' style={{fontSize: 12}}>Hari Ini</span></td>
                                   <td style={{}}>
                                       <input type="radio" name="date-filter" onClick={() => selectDateId(2)} id="2" style={{width: 20, height: 20}}/>
                                   </td>
                               </tr>
                               <hr className='hr-table'/>
                               <tr>
                                   <td><span className='span-header' style={{fontSize: 12}}>Minggu Ini</span></td>
                                   <td style={{}}>
                                       <input type="radio" name="date-filter" onClick={() => selectDateId(3)} id="3" style={{width: 20, height: 20}}/>
                                   </td>
                               </tr>
                               <hr className='hr-table'/>
                               <tr>
                                   <td><span className='span-header' style={{fontSize: 12}}>Bulan Ini</span></td>
                                   <td style={{}}>
                                       <input type="radio" name="date-filter" onClick={() => selectDateId(4)} id="4" style={{width: 20, height: 20}}/>
                                   </td>
                               </tr>
                               <hr className='hr-table'/>
                               <tr>
                                   <td><span className='span-header' style={{fontSize: 12}}>Bulan Lalu</span></td>
                                   <td style={{}}>
                                       <input type="radio" name="date-filter" onClick={() => selectDateId(5)} id="5" style={{width: 20, height: 20}}/>
                                   </td>
                               </tr>
                               <hr className='hr-table'/>
                               <tr>
                                   <td><span className='span-header' style={{fontSize: 12}}>Pilih Tanggal Sendiri</span></td>
                                   <td style={{}}>
                                       <input type="radio" name="date-filter" onClick={() => selectDateId(6)} id="6" style={{width: 20, height: 20}}/>
                                   </td>
                               </tr>
                           </tbody>
                       </table>   
                       <br/>
                       <Row hidden={isDatePickerOpen}>
                           <Col span={12}>
                               <div className=''>
                                    <label>Mulai</label><br/>
                                    <input type="text" className="inpDateFromTo"  onClick={() => openModalDate(1)} value={datePickerFrom === "" ? moment(new Date()).format("DD MMMM YYYY") : datePickerFrom}/>
                               </div>
                           </Col>
                           <Col span={12}>
                               <div className=''>
                                    <label>Sampai</label><br/>
                                    <input type="text" className="inpDateFromTo" onClick={() => openModalDate(2)} value={datePickerTo === "" ? moment(new Date()).format("DD MMMM YYYY") : datePickerTo}/>
                               </div>
                           </Col>
                       </Row>
                    </div>
                </div>
            </Modal>
            <div className="div-modal-delivery-method">
                <Modal
                    title="Tanggal Transaksi"
                    visible={visibleDate}
                    // onOk={closedModal}
                    onCancel={() => closedModalDate()}
                    className="modalAddCart modal-delivery-method" 
                    style={{borderRadius: 15}}
                    footer={null}  
                    >
                    <table style={{width: '100%', margin: '0 auto'}} className="tbl-delivery-method">
                        <tbody>
                            <tr> 
                                <td>
                                <DatePicker
                                    value={new Date()}
                                    isOpen={false}
                                    isPopup={false}
                                    onSelect={acceptDate}
                                    onCancel={null}
                                    cancelText=""
                                    showHeader={false}
                                    dateFormat={["DD", "MM", "YYYY"]}
                                    confirmText="Pilih Tanggal"
                                    dateConfig={dateConfig}
                                    showFooter={true}
                                    theme="default"
                                    className="datePickerDeliveryMethod"
                                    style={{marginBottom: '112px'}}
                                    min={new Date(minDatePicker)}
                                    max={new Date(maxDatePicker)}
                                />
                                </td>
                            </tr>                     
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                </Modal>
            </div>
            </div>
        </div>
    )
}

export default FilterModal
