import { FETCH_DATA_NEWPROD_HOME } from "../ActionType/actionTypes"

const initialState = {
    newProds: []
}

const newProdsReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_NEWPROD_HOME) {
        return { ...state, newProds: action.payload.newProds }
    } else {
        return state
    }
}

export default newProdsReducer