import React from 'react'
import './TopUpEzeeTunaiHeadOffice.css'
import ezeelinkLogo from '../../Asset/Icon/EzeelinkHeadOffice.svg'

function TopUpEzeeTunaiHeadOffice() {
    return (
        <div style={{paddingTop: 50, marginLeft: 25, marginRight: 25}}>
            <div style={{textAlign: 'center', paddingTop: '10%'}}>
                <span className='head-2'>Ezeelink Head Office</span>
                <br/>
                <img src={ezeelinkLogo} alt="" style={{paddingTop: '8%'}}/>
            </div>
            <hr className='hrTopUp'/>
            <div className='topup-instruction'>
                <span className='head-2' style={{fontSize: 16}}>Instruksi</span>
                <div className='step'>
                    <div>
                        <div className='circle'>1</div>
                        <div className='line'></div>
                    </div>
                    <div>
                        <div className='bodyStep'>Hubungi hotline whatsapp kami untuk registrasi</div>
                    </div>
                </div>
                <div className='step'>
                    <div>
                        <div className='circle'>2</div>
                        <div className='line'></div>
                    </div>
                    <div>
                        <div className='bodyStep'>Datang ke kantor Ezeelink Indonesia sesuai waktu perjanjian, lalu temui tim Customer Management</div>
                    </div>                    
                </div>
                <div className='step'>
                    <div>
                        <div className='circle'>3</div>
                        <div className='line'></div>
                    </div>
                    <div>
                        <div className='bodyStep'>Lakukan top up sesuai dengan proses yang berlaku</div>
                    </div>
                </div>
                <div className='step'>
                    <div>
                        <div className='circle'>4</div>
                    </div>
                    <div>
                        <div className='bodyStep'><b>Top up berhasil</b></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TopUpEzeeTunaiHeadOffice
