import { IS_LOADING_WISHLISTS_PROFILE } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingWishlist: true
}

const isLoadingWishlistReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_WISHLISTS_PROFILE) {
        return { ...state, isLoadingWishlist: action.payload.isLoadingWishlist }
    } else {
        return state
    }
}

export default isLoadingWishlistReducer