import axios from 'axios'
import base64Sign from '../../function/base64Sign'
import { getToken } from '../../utils/setSessionUser'
import { FETCH_DATA_PROMOPAGE_MENU} from '../ActionType/actionTypes'
import {BaseURL} from '../../function/helpers'
import { isLoadingPromoPageMenu } from './Loading/isLoadingPromoPageActions'

export const fetchDataPromoPage = (url) => {
    return ( dispatch ) => {
        const signature = base64Sign(url)
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + url, {data: ""}, {headers: headers})
            .then(promos => {
                dispatch({
                    type: FETCH_DATA_PROMOPAGE_MENU,
                    payload: {
                        promoPage: promos.data.response_data.results
                    }
                })
                dispatch(isLoadingPromoPageMenu(false))
            })
            .catch(error => console.error(error))
    }
}