import { FETCH_DATA_PROFILE_DETAIL } from "../ActionType/actionTypes"

const initialState = {
    profileDetail: [],
}

const profileDetailReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_PROFILE_DETAIL) {
        return { ...state, profileDetail: action.payload.profileDetail }
    } else {
        return state
    }
}

export default profileDetailReducer