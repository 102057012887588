export const getUserFb = () => {
    return localStorage.getItem("fblst_442571430422984") || null
}

export const getToken = () => {
    return localStorage.getItem("token") || null
}

export const setUserSession = (token) => {
    sessionStorage.setItem("token", token);
    localStorage.setItem("token", token)
}

export const removeUserSession = () => {
    localStorage.removeItem("token");
    sessionStorage.removeItem("token");
}

export const setloginType = (id) => {
    localStorage.setItem("loginType", id)
}

export const getloginType = () => {
    return localStorage.getItem("loginType") || null
}

export const removeLoginType = () => {
    localStorage.removeItem("loginType");
}

export var checkoutUserSession = (function(){
    var totalItem = 0;
    var totalPrice = 0;

    var address = '';
    var address_id = 0;
    var address_is_default = false;
    var address_name = '';
    var receiver_mobile = '';
    var receiver_name = '';
    var lat = '';
    var lng = '';

    var ekspedisi_id = 0;
    var ekspedisi_name = "";
    var dateShipping = "";
    var shippingPrice = 0;
    var timeFrameId = 0;
    var timeFrame = '';

    var paymentId = 0;
    var paymentFee = 0;
    var paymentName = "";
    var paymentImg = "";
    var paymentSaldo = 0;

    var promoId = 0;
    var promoTypeId = 0;
    var promoType = "";
    var promoName = "";
    var promoPrice = 0;
    var totalPriceBeforePromo = 0;
    var totalPriceAfterPromo = 0;

    var isCashbackUsed = false;
    var cashbackDetail = ''

    var checkoutDataPaymentId = 0;
    var checkoutDataHarga = 0;
    var checkoutDataAddressId = '';
    var checkoutDataDateKirim = '';
    var checkoutDataPromoCode = '';
    var checkoutDataEkspedisiId = '';
    var checkoutDataIsCashback = false;
    var paymentIsHitAlready = false;
    var order_id = 0;

    var tokenSnapMidtrans = '';
    var qrisShopeePay = '';
    var urlRedirectShopeePay = '';

    var agentcode = '';


    var getTotalItem = function() {
        return totalItem;
    }

    var setTotalItem = function(item) {
        totalItem = item
    };

    var getTotalPrice = function() {
        return totalPrice;
    }

    var setTotalPrice = function(price) {
        totalPrice = price
    };

    var getAddress = function() {
        return address;
    };

    var setAddress = function(addressCust) {
        address = addressCust;
    };

    var getAddressId = function() {
        return address_id;
    };  

    var setAddressId = function(addressId) {
        address_id = addressId;
    };

    var getAddressIsDefault = function() {
        return address_is_default;
    };

    var setAddressIsDefault = function(address_isDefault) {
        address_is_default = address_isDefault;
    };

    var getAddressName = function() {
        return address_name;
    };

    var setAddressName = function(addressName) {
        address_name = addressName;
    };

    var getReceiverMobile = function() {
        return receiver_mobile;
    };

    var setReceiverMobile = function(receiverMobile) {
        receiver_mobile = receiverMobile;
    };

    var getReceiverName = function() {
        return receiver_name;
    };

    var setReceiverName = function(receiverName) {
        receiver_name = receiverName;
    };

    var getLat = function() {
        return lat;
    };

    var setLat = function(latitude) {
        lat = latitude;
    };

    var getLng = function() {
        return lng;
    };

    var setLng = function(longitude) {
        lng = longitude;
    };

    var getEkspedisiId = function() {
        return ekspedisi_id;
    };

    var setEkspedisiId = function(id) {
        ekspedisi_id = id;
    };

    var getNameEkspedisi = function() {
        return ekspedisi_name;
    };

    var setNameEkspedisi = function(name) {
        ekspedisi_name = name;
    };

    var getDateShipping = function() {
        return dateShipping;
    };

    var setDateShipping = function(date) {
        dateShipping = date;
    };

    var getShippingPrice = function() {
        return shippingPrice;
    };

    var setShippingPrice = function(price) {
        shippingPrice = price;
    };

    var getTimeFrame = function() {
        return timeFrame;
    };

    var setTimeFrame = function(timeframe) {
        timeFrame = timeframe;
    };

    var getTimeFrameId = function() {
        return timeFrameId;
    };

    var setTimeFrameId = function(timeframeid) {
        timeFrameId = timeframeid;
    };
    
    var getPaymentId = function() {
        return paymentId;
    };

    var setPaymentId = function(id) {
        paymentId = id;
    };

    var getPaymentFee = function() {
        return paymentFee;
    };

    var setPaymentFee = function(fee) {
        paymentFee = fee;
    };

    var getPaymentName = function() {
        return paymentName;
    };

    var setPaymentName = function(name) {
        paymentName = name;
    };

    var setPaymentImg = function(img) {
        paymentImg = img;
    };

    var getPaymentImg = function() {
        return paymentImg;
    };
    
    var setPaymentSaldo = function(saldo) {
        paymentSaldo = saldo;
    };

    var getPaymentSaldo = function() {
        return paymentSaldo;
    };
    
    var setPromoId = function(id) {
        promoId = id;
    };

    var getPromoId = function() {
        return promoId;
    };

    var setPromoTypeId = function(id) {
        promoTypeId = id;
    };

    var getPromoTypeId = function() {
        return promoTypeId;
    };

    var setPromoType = function(type) {
        promoType = type;
    };

    var getPromoType = function() {
        return promoType;
    };

    var setPromoName = function(name) {
        promoName = name;
    };

    var getPromoName = function() {
        return promoName;
    };

    var setPromoPrice = function(price) {
        promoPrice = price;
    };

    var getPromoPrice = function() {
        return promoPrice;
    };

    var gettotalPriceBeforePromo = function() {
        return totalPriceBeforePromo;
    };

    var settotalPriceBeforePromo = function(total_price_before_promo) {
        totalPriceBeforePromo = total_price_before_promo;
    };;

    var gettotalPriceAfterPromo = function() {
        return totalPriceAfterPromo;
    };

    var settotalPriceAfterPromo = function(total_price_after_promo) {
        totalPriceAfterPromo = total_price_after_promo;
    };
    
    var getIsCashbackUsed = function() {
        return isCashbackUsed;
    };

    var setIsCashbackUsed= function(status) {
        isCashbackUsed = status;
    };

    var setCheckoutDataHarga = function(chkHarga) {
        checkoutDataHarga = chkHarga;
    };

    var getcheckoutDataPaymentId = function() {
        return checkoutDataPaymentId;
    };

    var setcheckoutDataPaymentId= function(paymentId) {
        checkoutDataPaymentId = paymentId;
    };

    var getCheckoutDataHarga = function() {
        return checkoutDataHarga;
    };

    var setCheckoutDataAddressId = function(addressId) {
        checkoutDataAddressId = addressId;
    };

    var getCheckoutDataAddressId= function() {
        return checkoutDataAddressId;
    };

    var setCheckoutDataDateKirim= function(dateKirim) {
        checkoutDataDateKirim = dateKirim;
    };

    var getCheckoutDataDateKirim= function() {
        return checkoutDataDateKirim;
    };

    var setCheckoutDataPromoCode = function(promoCode) {
        checkoutDataPromoCode = promoCode;
    };

    var getCheckoutDataPromoCode = function() {
        return checkoutDataPromoCode;
    };

    var setCheckoutDataEkspedisiId = function(ekspedisiId) {
        checkoutDataEkspedisiId = ekspedisiId;
    };

    var getCheckoutDataEkspedisiId= function() {
        return checkoutDataEkspedisiId;
    };

    var setCheckoutDataIsCashback = function(isCashback) {
        checkoutDataIsCashback = isCashback;
    };

    var getCheckoutDataisCashback = function() {
        return checkoutDataIsCashback;
    };

    var setPaymentIsHitAlready = function(status) {
        paymentIsHitAlready = status;
    };

    var getPaymentIsHitAlready = function() {
        return paymentIsHitAlready;
    };

    var setOrderId = function(id) {
        order_id = id;
    };

    var getOrderId = function() {
        return order_id;
    };

    var setTokenSnapMidtrans = function(token) {
        tokenSnapMidtrans = token;
    };

    var getTokenSnapMidtrans= function() {
        return tokenSnapMidtrans;
    };

    var setQrisShopeePay = function(qris) {
        qrisShopeePay = qris;
    };

    var getQrisShopeePay= function() {
        return qrisShopeePay;
    };

    var setUrlShopeePay = function(url) {
        urlRedirectShopeePay = url;
    };

    var getUrlShopeePay= function() {
        return urlRedirectShopeePay;
    };

    var setAgentCode = function(code) {
        agentcode = code;
    };

    var getAgentCode= function() {
        return agentcode;
    };

    return{
        getTotalItem : getTotalItem,
        setTotalItem : setTotalItem,
        getTotalPrice : getTotalPrice,
        setTotalPrice : setTotalPrice,
        getAddress : getAddress,
        setAddress : setAddress,
        getAddressId : getAddressId,
        setAddressId : setAddressId,
        getAddressIsDefault : getAddressIsDefault,
        setAddressIsDefault : setAddressIsDefault,
        getAddressName : getAddressName,
        setAddressName : setAddressName,
        getReceiverMobile : getReceiverMobile,
        setReceiverMobile : setReceiverMobile,
        getReceiverName : getReceiverName,
        setReceiverName : setReceiverName,
        setLat : setLat,
        getLat : getLat,
        setLng : setLng,
        getLng : getLng,        
        getEkspedisiId : getEkspedisiId,
        setEkspedisiId : setEkspedisiId,
        getNameEkspedisi : getNameEkspedisi,
        setNameEkspedisi : setNameEkspedisi,
        getDateShipping: getDateShipping,
        setDateShipping: setDateShipping,
        getTimeFrame: getTimeFrame,
        setTimeFrame: setTimeFrame,
        getTimeFrameId: getTimeFrameId,
        setTimeFrameId: setTimeFrameId,
        getShippingPrice : getShippingPrice,
        setShippingPrice : setShippingPrice,
        getPaymentId : getPaymentId,
        setPaymentId : setPaymentId,
        getPaymentFee : getPaymentFee,
        setPaymentFee : setPaymentFee,
        getPaymentName : getPaymentName,
        setPaymentName : setPaymentName,
        getPaymentImg : getPaymentImg,
        setPaymentImg : setPaymentImg,
        getPaymentSaldo : getPaymentSaldo,
        setPaymentSaldo : setPaymentSaldo,
        setPromoId : setPromoId,
        getPromoId : getPromoId,
        setPromoTypeId : setPromoTypeId,
        getPromoTypeId : getPromoTypeId,
        setPromoType : setPromoType,
        getPromoType : getPromoType,
        setPromoName : setPromoName,
        getPromoName : getPromoName,
        setPromoPrice : setPromoPrice,
        getPromoPrice : getPromoPrice,
        gettotalPriceBeforePromo : gettotalPriceBeforePromo,
        settotalPriceBeforePromo : settotalPriceBeforePromo,
        settotalPriceAfterPromo : settotalPriceAfterPromo,
        gettotalPriceAfterPromo : gettotalPriceAfterPromo,
        setcheckoutDataPaymentId : setcheckoutDataPaymentId,
        getcheckoutDataPaymentId : getcheckoutDataPaymentId,
        setCheckoutDataHarga : setCheckoutDataHarga,
        getCheckoutDataHarga : getCheckoutDataHarga,
        setCheckoutDataAddressId : setCheckoutDataAddressId,
        getCheckoutDataAddressId : getCheckoutDataAddressId,
        setCheckoutDataDateKirim : setCheckoutDataDateKirim,
        getCheckoutDataDateKirim : getCheckoutDataDateKirim,
        setCheckoutDataEkspedisiId : setCheckoutDataEkspedisiId,
        getCheckoutDataEkspedisiId : getCheckoutDataEkspedisiId,
        setCheckoutDataPromoCode : setCheckoutDataPromoCode,
        getCheckoutDataPromoCode : getCheckoutDataPromoCode,
        setCheckoutDataIsCashback : setCheckoutDataIsCashback,
        getCheckoutDataisCashback : getCheckoutDataisCashback,
        setPaymentIsHitAlready : setPaymentIsHitAlready,
        getPaymentIsHitAlready : getPaymentIsHitAlready,
        setOrderId : setOrderId,
        getOrderId : getOrderId,
        setTokenSnapMidtrans : setTokenSnapMidtrans,
        getTokenSnapMidtrans : getTokenSnapMidtrans,
        setQrisShopeePay : setQrisShopeePay,
        getQrisShopeePay : getQrisShopeePay,
        setUrlShopeePay : setUrlShopeePay,
        getUrlShopeePay : getUrlShopeePay,
        setAgentCode : setAgentCode,
        getAgentCode : getAgentCode
    }
})();