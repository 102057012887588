import { FETCH_DATA_PROMOLIST_HOME } from "../ActionType/actionTypes"

const initialState = {
    promoProds: []
}

const promoProdsReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_PROMOLIST_HOME) {
        return { ...state, promoProds: action.payload.promoProds }
    } else {
        return state
    }
}

export default promoProdsReducer