import firebase from 'firebase/compat/app';
import {getMessaging, getToken, onMessage, deleteToken} from 'firebase/messaging';
import { sentFirebaseToken, sentTokenToServer} from './function/helpers';
import * as CONSTVAR from './utils/ConstVar'

const firebaseConfig = {
  apiKey: "AIzaSyDK8lCYIS7fzmz4Qlt3oLDkG1ZqY-SNXi4",
  authDomain: "fcmtest-e5398.firebaseapp.com",
  databaseURL: "https://fcmtest-e5398.firebaseio.com",
  projectId: "fcmtest-e5398",
  storageBucket: "fcmtest-e5398.appspot.com",
  messagingSenderId: "478494300772",
  appId: "1:478494300772:web:10a3a170c7fdb31653dd71",
  measurementId: "G-R3FC3CERT8"
}

firebase.initializeApp(firebaseConfig);

const messaging = getMessaging();
// const foregroundMsg = firebase.messaging();

const {REACT_APP_VAPID_KEY} = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getTokenFirebase = (setTokenFound) => {
  return getToken(messaging, { vapidKey: CONSTVAR.FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      if (currentToken) {
        // console.log('current token for client: ', currentToken);
        sentFirebaseToken(currentToken);
      } else {
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
};

export const onMessageListener = () => 
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('Message received. ', payload);
      resolve(payload);
    });
  });

export const deleteTokenFCM = () => {
  getToken(messaging, { vapidKey: CONSTVAR.FIREBASE_VAPID_KEY })
    .then((currentToken) => {
      deleteToken(messaging, { vapidKey: CONSTVAR.FIREBASE_VAPID_KEY })
        .then((currentToken) => {
        // console.log("success delete")
        sentTokenToServer(false);
      })
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
}
