import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import { fetchDataPromoPage } from '../redux/ActionCreators/promoPageActions'
import './Promo.css'
import { isLoadingPromoPageMenu } from '../redux/ActionCreators/Loading/isLoadingPromoPageActions'

function Promo() {

    const promoPage = useSelector(state => state.promoPageReducer.promoPage)
    const isLoadingPromoPage = useSelector(state => state.isLoadingPromoPageReducer.isLoadingPromoPageMenu)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        dispatch( fetchDataPromoPage("/promo/listpromosi") )
    }, [dispatch])

    function promoDetailHandle(bannerId) {
        history.push({pathname: `/banner/mbanner_id=${bannerId}`, state:{results: bannerId}})
        dispatch(isLoadingPromoPageMenu(true))
    }

    return (
        <div className="container body-content mobile-view" style={{ padding:'0px 15px'}}>
            <div style={{ marginLeft: -15 }}>
                <NavbarWithMenuIcon link="/" />
            </div>
            <div className="row text-center" style={{ marginTop: 60, paddingTop: 15 }}>
                <span className="head-2" style={{ margin: 0 }}>Promosi</span>
            </div>
            <div className="row text-center" style={{ marginTop: 7}}>
                <div className="col-12">
                    <span className="text-1" style={{ fontFamily: 'Open Sans', fontSize: 12}}>
                        Dapatkan informasi promo menarik dari ezeePasar.
                    </span>
                </div>
            </div>
            {
                (!isLoadingPromoPage) &&
                promoPage.map((promo, i) => {
                    return(
                        <div key={promo.mpromcod_id} className='row' id='divPromoItem' style={{ marginTop: 40 }}>
                            <div onClick={() => (promoDetailHandle(promo.mbanner_id))} className="col-12 text-center" style={{ padding: 0 }}>
                                <img style={{ width: '100%', borderTopLeftRadius: 8, borderTopRightRadius: 8, cursor: 'pointer'}} src={promo.mbanner_image} alt="" width="436px" height="228px" />
                            </div>
                            <div className="col-12 promo-title" style={{ marginTop: 19}}>
                                <span style={{ color: '#393939', fontSize: 14, fontWeight: 600 }}>
                                    {promo.mbanner_name}
                                </span>
                                <span className="promo-period">
                                    {promo.publish_date}
                                </span>
                            </div>
                            <div className="col-12" style={{ padding: '3px 20px 17px', marginTop: 5 }}>
                                <span style={{ color: '#848484', fontFamily: 'Open Sans', fontSize: 12, fontWeight: 'normal'}}>
                                    {promo.mbanner_desc}
                                </span>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )
}

export default Promo
