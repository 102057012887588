import React from 'react'
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'
import ProcessTopUp from '../../components/EzeeTunai/ProcessTopUp'

function ProcessTopUpEzeeTunai() {
        let fromPage = window.location.search;
    fromPage = fromPage.replace('?from=', '');
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link={fromPage === 'checkout' ? "/checkout" : "/EzeeTunai/TopUp"}/>
            <ProcessTopUp/>
        </div>
    )
}

export default ProcessTopUpEzeeTunai
