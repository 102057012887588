import React, {useState}from 'react'
import {Link, useHistory} from 'react-router-dom'
import { Row, Col, Input, Modal} from 'antd'
import validator from 'validator'
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import { setUserSession, getUserFb} from '../../utils/setSessionUser';
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import './Register.css'
import '../LoginPage/Login.css'
import {BaseURL, headersWithoutToken, useTitle} from '../../function/helpers'
import iconFacebookColor from '../../Asset/Icon/Facebook_Color.svg'
import spinnerLoading1s from '../../Asset/Icon/spinner-1s-58px.svg'
import iconGoogleColor from '../../Asset/Icon/Google_Color.svg'
import iconWarning from '../../Asset/Icon/icon-warning-text.svg'
import $ from 'jquery'
import * as CONSTVAR from '../../utils/ConstVar'

const clientId = CONSTVAR.GOOGLE_CLIENT_ID
const appId = CONSTVAR.FACEBOOK_APP_ID

function Register() {

    useTitle("Register")

    const [emailOrPhone, setemailOrPhoneVal] = useState('');
    const [validateInput, setValidateInput] = useState('');
    const [modalProgress, setModalProgress] = useState(false);
    const [alertText, setAlertText] = useState('')
    let history = useHistory();

    
    
    async function checkUserIsExist(username, type) {
        try{
            const signature = base64Sign("/security/validateuserregister");  
            const dataParams = encryptData(`{"username": "${username}"}`)         
            const headers = headersWithoutToken(signature);
            const results = await axios.post(BaseURL + '/security/validateuserregister',{data:dataParams},{headers:headers})
            if(results.status === 200){
                history.push({pathname: '/formregister', state:{result: username, credential: type}})
            }else{
                if(results.response.status === 400){
                    setAlertText(results.response.data.response_data.error_text)
                    $('.reg-input').addClass('error-input');
                }
            }
        }catch(err){
            console.log(err)
        }
    }

    const validateEmailorPhone = (e) => {

        e.preventDefault();
        var valField = e.target.emailOrPhone.value;
        if(validator.isEmail(valField)){
            const validateInput = 'email';
            setValidateInput(validateInput);
            checkUserIsExist(valField, validateInput);
        } 
        else if (validator.isNumeric(valField))
        {
            const validateInput = 'phone'
            setValidateInput(validateInput);
            checkUserIsExist(valField, validateInput);
        }
        else
        {
            setAlertText('Email atau No. Telepon tidak valid')
            $('.reg-input').addClass('error-input');
        }
    }

    async function onSuccess(res){
        var usrAgent = navigator.userAgent;
        try{
            setModalProgress(true);
            if(res.tokenObj.id_token != null) {
                const signature = base64Sign("/security/register");
                const dataParams = encryptData(`{"client_id":0,"email":"","login_type":101, "password":"","mobile":"","fullname":"","user_agent":"${usrAgent}","token_ezeepasar":true,"token_external_login":"${res.tokenObj.id_token}"}`)
                const headers = headersWithoutToken(signature);
                const dataProds = await axios.post(BaseURL + '/security/register',{data: dataParams},{headers : headers});
                if(dataProds.data.response_code === 200)
                {
                    setModalProgress(false);
                    setUserSession(dataProds.data.response_data.results.access_token)
                    // history.push('/')
                }
            }
        }catch(ex){

        }
    };

    async function callbackFacebook(res){
        var getFbToken = getUserFb();

        var usrAgent = navigator.userAgent;
        try{
                setModalProgress(true);
                const signature = base64Sign("/security/register");
                const dataParams = encryptData(`{"client_id":0,"email":"","login_type":102, "password":"","mobile":"","fullname":"","user_agent":"${usrAgent}","token_ezeepasar":true,"token_external_login":"${getFbToken}"}`)
                const headers = headersWithoutToken(signature);
                const dataProds = await axios.post(BaseURL + '/security/register',{data: dataParams},{headers : headers});
                if(dataProds.data.response_code === 200)
                {
                    setModalProgress(false);
                    setUserSession(dataProds.data.response_data.results.access_token)
                    history.push('/')
                }
        }catch(ex){
            console.log(ex);
        }
    }

    return(
        <div className="text-1" style={{marginTop: 80}}>        
        <h2 className="head-2">Daftar Akun ezeePasar</h2>
            <div style={{marginTop: 20, marginLeft: 15, marginRight: 15}}>   
                <form onSubmit={validateEmailorPhone}>             
                        <Row>
                            <Col>
                                <span className="title-label">Email atau No. Telepon</span>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Input className="form-control-register reg-input" id="emailOrPhone" name="emailOrPhone" onChange={e => setemailOrPhoneVal(e.target.value)}></Input>
                            </Col>
                        </Row>
                        {
                            alertText !== '' ? 
                            <Row>
                                <Col>
                                    <img src={iconWarning} alt=""/><span className='title-hint' style={{color: 'red'}}> {alertText}</span>
                                </Col>
                            </Row> : 
                            null
                        }
                        <Row>
                            <Col>
                                <span className="title-hint">Contoh: ezeepasar@ezeepasar.com</span>
                            </Col>
                        </Row>
                        <Row style={{marginTop: 12}}>
                            <Col span={24}>
                            {/* <Link to={{pathname: "/formregister", state:{result: 'validateInput'}}}> */}
                            <button className="btn-register" disabled={!emailOrPhone}>Daftar</button>
                            </Col>
                        </Row>
                    </form>
                    <div style={{marginTop: 35, position: 'relative'}}>
                        <div className="divider">
                            <span>atau masuk dengan</span>
                        </div>
                    </div>
                    <div style={{ marginTop: 50}}>
                    <GoogleLogin
                        clientId={clientId}
                        button="Login"
                        onSuccess={onSuccess}
                        cookiePolicy={'single_host_origin'}
                        isSignedIn={true}
                        render={renderProps => (
                            <div className="div-login-with" onClick={renderProps.onClick}>
                                    <img src={iconGoogleColor} className="img-login-with" alt=""/>
                                    <span className="span-login-with metro">Google</span>
                            </div>
                        )}/>                                                  
                    </div>
                    <br/>
                    <FacebookLogin
                        appId={appId}
                        autoLoad={true}
                        fields="name,email,picture"
                        scope="public_profile,email"                            
                        callback={callbackFacebook}                        
                        cssClass="div-login-with"
                        textButton="Facebook &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                        style={{}}
                        icon={<img src={iconFacebookColor} className="img-login-with" alt=""/>}
                        />
                </div>
                <div className="center-modal">
                <Modal className="modalProgress" id="modalProgress" visible={modalProgress} footer={null} closable={false} style={{marginTop: '10%', width: '330px !important'}} >
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 14, fontWeight: 600, marginBottom: 10}}>Sedang dalam proses</div>
                        <div style={{fontSize: 10}}>Kami sedang memproses.</div>
                        <div style={{fontSize: 10}}>Mohon menunggu</div>
                        <img src={spinnerLoading1s} style={{marginTop: 10}} alt=""/>
                    </div>
                </Modal>
            </div>
                <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 55}}>
                    <span className="spanTitle">Sudah punya akun ezeePasar?<span className="title-register"><Link to="/login" style={{color: '#3DB54A'}}> Login</Link></span></span>
                </div>
            </div>      
    )
}

export default Register