import { IS_LOADING_PROFILE_DETAIL } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingProfileDetail: true
}

const isLoadingProfileDetailReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_PROFILE_DETAIL) {
        return { ...state, isLoadingProfileDetail: action.payload.isLoadingProfileDetail }
    } else {
        return state
    }
}

export default isLoadingProfileDetailReducer