import React, { useEffect, useState } from 'react'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import { Input, Modal, Layout } from 'antd'
import './ChangePassword.css'
import base64Sign from '../function/base64Sign'
import { getToken } from '../utils/setSessionUser'
import axios from 'axios'
import encryptData from '../function/encryptData'
import { useHistory } from 'react-router-dom'
import {BaseURL} from '../function/helpers'
import errorIcon from '../Asset/Icon/error_icon.svg'
import iconSendEmail from '../Asset/Icon/send_email.png'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileDetail } from '../redux/ActionCreators/profileDetailActions'
import clarityCheckCircleLine from '../Asset/Icon/clarity_check-circle-line.png'

const { Footer } = Layout

function ChangePassword() {

    let history = useHistory()
    const dispatch = useDispatch()
    const profileDetail = useSelector(state => state.profileDetailReducer.profileDetail)

    const [oldPass, setOldPass] = useState('')
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [wrongPass, setWrongPass] = useState(null)
    const [wrongNewPass, setWrongNewPass] = useState(null)
    const [wrongPassConfirm, setWrongPassConfirm] = useState(null)
    const [isVisible, setIsVisible] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        dispatch( fetchProfileDetail("/profile/getprofile") )
    }, [dispatch])

    function forgotPasswordHandler(email) {
        // const param = encryptData(`{"email": "${email}"}`)
        const signature = base64Sign("/profile/forgotpassword")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + "/profile/forgotpassword", { data: null }, { headers: headers })
            .then(forgotPassword => {
                if (forgotPassword.data.response_data.error_id === "0000") {
                    setIsVisible(true)
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    function closeModal() {
        setIsVisible(false)
    }

    function goToHome() {
        history.push("/")
    }

    function saveChangePassword(oldPass, newPass, confirmPass) {
        let validasi = 0
        const besarKecil = /[A-Za-z]/g;
        const numbers = /[0-9]/g;
        const spChar = /[/\W|_/g]/g;

        if (newPass.match(besarKecil) && newPass.length >= 8) {
            validasi += 1
        }
        if (newPass.match(numbers)) {
            validasi += 1
        }
        if (newPass.match(spChar)) {
            validasi += 1
        }
        if (validasi < 2) {
            setWrongNewPass("Kata sandi minimal 8-20 karakter. 2 kombinasi antara huruf, angka, simbol.")
        } else {
            const param = encryptData(`{
                "old_passwd":"${oldPass}",
                "new_passwd":"${newPass}",
                "confirm_new_passwd":"${confirmPass}"
            }`)
            const signature = base64Sign("/profile/updatepassword")
            const access_token = getToken()
            const headers = {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token
            }
            
            axios.post(BaseURL + "/profile/updatepassword", { data: param }, { headers: headers })
            .then(updated => {
                if (updated.status === 200) {
                    setIsSuccess(true)
                }else{
                    if (updated.response.data.response_message === "Kata Sandi salah. Coba lagi atau klik Lupa Kata Sandi!") {
                        setWrongPass(updated.response.data.response_message)
                    } else if (updated.response.data.response_message === "Konfirmasi Kata Sandi Baru tidak sama") {
                        setWrongPassConfirm(updated.response.data.response_message)
                    }
                }
            })
            .catch(error => {
                console.log(error);
            })
        }
    }

    return (
        <div className="container body-content mobile-view">
            <NavbarWithMenuIcon link="/back" />
            <div className="row" style={{ margin: '68px 9px 0px', zIndex: 0, paddingTop: 70 }}>
                <p style={{ fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600, paddingLeft: 14, marginBottom: -2 }}>
                    Ubah Kata Sandi
                </p>
                <div className="col-12 form-grup">
                    <label style={{ marginLeft: 3 }}>Masukkan Kata Sandi Lama</label>
                    <span onClick={() => {forgotPasswordHandler(profileDetail.customer_email)}} style={{ cursor: 'pointer', fontWeight: 'bold', float: 'right', color: '#3DB54A' }}>
                        Lupa kata sandi?
                    </span>
                    <Input.Password onChange={e => setOldPass(e.target.value)} className="form-control-change-password" placeholder="Kata Sandi Lama" autoComplete="new-password" />
                    {
                        wrongPass &&
                        <div className="error-txt"><img src={errorIcon} alt="" />
                            {wrongPass}
                        </div>
                    }
                    <div className="col-12 form-grup">
                        <label className="placeholders">Contoh: atik12345</label>
                    </div>
                    <hr style={{ margin: '20px 0px' }} />
                </div>
                <div className="col-12 form-grup">
                    <label style={{ marginLeft: 3 }}>Masukkan Kata Sandi Baru</label>
                    <Input.Password onChange={e => setNewPass(e.target.value)} className="form-control-change-password" placeholder="Kata Sandi Baru" autoComplete="new-password" />
                    {
                        (wrongNewPass) ?
                        <div className="error-txt"><img src={errorIcon} alt="" />
                            {wrongNewPass}
                        </div> :
                        <div className="col-12 form-grup">
                            <label className="placeholders">Contoh: atik12345</label>
                        </div>
                    }
                </div>
                <div className="col-12 form-grup">
                    <label style={{ marginLeft: 3 }}>Konfirmasi Kata Sandi Baru</label>
                    <Input.Password onChange={e => setConfirmPass(e.target.value)} className="form-control-change-password" placeholder="Kata Sandi Baru" autoComplete="new-password" />
                    {
                        (wrongPassConfirm) ?
                        <div className="error-txt"><img src={errorIcon} alt="" />
                            {wrongPassConfirm}
                        </div> :
                        <div className="col-12 form-grup">
                            <label className="placeholders">Contoh: atik12345</label>
                        </div>
                    }
                </div>
                <div style={{ marginLeft: -21 }}>
                    <Layout>
                        <Footer className="footerLayout">
                            <button onClick={() => saveChangePassword(oldPass, newPass, confirmPass)}>
                                Simpan Data
                            </button>
                        </Footer>
                    </Layout>
                </div>
            </div>
            <Modal
                visible={isVisible}
                onCancel={closeModal}
                footer={null}
                className="modalVefiedPassword"
            >
                <div>
                    <img src={iconSendEmail} alt="" width="85px" height="85px" />
                </div>
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#393939" }}>
                    Email Berhasil Dikirim
                </div>
                <div style={{ fontSize: 10 }}>
                    Silahkan periksa email dan ikuti instruksi
                </div>
                <div style={{ fontSize: 10 }}>
                    untuk mereset kata sandi Anda
                </div>
                <button onClick={closeModal} className="btn" style={{ backgroundColor: "#3DB54A", color: "#fff", marginTop: 15, marginLeft: '-11%', padding: "10px 50px", outline: 'unset', width: '125%' }}>Oke</button>
            </Modal>
            <Modal
                visible={isSuccess}
                onCancel={closeModal}
                footer={null}
                className="modalVefiedPassword"
            >
                <div>
                    <img src={clarityCheckCircleLine} alt="" width="95px" height="95px" />
                </div>
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#393939" }}>
                    Kata Sandi Berhasil Diubah
                </div>
                <div style={{ fontSize: 10 }}>
                    Kata sandi kamu berhasil diubah.
                </div>
                <div style={{ fontSize: 10 }}>
                    Akses akunmu dengan kata sandi baru.
                </div>
                <button onClick={goToHome} className="btn" style={{ backgroundColor: "#3DB54A", color: "#fff", marginTop: 15, marginLeft: '-11%', fontSize: 12, padding: "10px 50px", outline: 'unset', width: '125%' }}>Kembali ke Home</button>
            </Modal>
        </div>
    )
}

export default ChangePassword
