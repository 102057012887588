import React from 'react'
import {NavbarCloseOnly} from '../components/Properties/Navbar/Navbar'
import RegisterPage from '../components/RegisterPage/Register'
function Register(){

    return(
        <div className="App container body-content mobile-view">
            <NavbarCloseOnly link="/back"/>
            <RegisterPage/>
        </div>
    )
}

export default Register