import React, { useEffect, useState } from 'react'
import { Row, Col, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CategoryTabLoading, ProdListLoading, TitleProdListLoading } from '../skeletonLoading/loadingTag'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { fetchDataListCatLayer2 } from '../../redux/ActionCreators/getCatLayer2List1Action'
import { fetchDataList2CatLayer2 } from '../../redux/ActionCreators/getCatLayer2List2Actions'
import { fetchProductListsLayer2 } from '../../redux/ActionCreators/productListLayer2Actions'
import iconSort from '../../Asset/Icon/icon_sort.svg'
import sortAToZ from '../../Asset/Icon/icon_a_to_z.svg'
import sortZToA from '../../Asset/Icon/icon_z_to_a.svg'
import sortTerendah from '../../Asset/Icon/icon_harga_terendah.svg'
import sortTertinggi from '../../Asset/Icon/icon_harga_tertinggi.svg'
import AddCartModal from '../PopUpModal/HomePage/AddCartModal'
import { nFormatter } from '../../function/helpers'

function ListCat2() {

    // const catId2 = useLocation().search.split("=")[1].slice(0,(useLocation().search.split("=")[1].length - 6))
    // const layerId2 = useLocation().search.split("=")[2]
    // console.log(useParams(), 'ini useParams');
    const { catId, catId2, layerId, layerId2 } = useParams()
    // const { layerId1, layerKey1 } = useLocation().state
    const history = useHistory()
    const getCatLayer2List1 = useSelector(state => state.getCatLayer2List1Reducer.getCatLayer2List1)
    const getCatLayer2List2 = useSelector(state => state.getCatLayer2List2Reducer.getCatLayer2List2)
    const prodListsLayer2 = useSelector(state => state.prodListsLayer2Reducer.prodListsLayer2)
    const isLoadingGetCatLayer2List1 = useSelector(state => state.isLoadingGetCatLayer2List1Reducer.isLoadingGetCatLayer2List1)
    const isLoadingGetCatLayer2List2 = useSelector(state => state.isLoadingGetCatLayer2List2Reducer.isLoadingGetCatLayer2List2)
    const isLoadingProductList2 = useSelector(state => state.isLoadingProductList2Reducer.isLoadingProductList2)
    const [catIdString, setCatIdString] = useState(catId2.toString())
    const dispatch = useDispatch()
    const [scrollDown, setScrollDown] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [isOrder, setIsOrder] = useState(false)
    const [prodId, setProdId] = useState(0)
    
    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }

    function handleClickLayer1(layerId1, layerKey1, layerId2, layerKey2) {
        // history.push({pathname: `/ProdListCategory/category=${layerKey2}&layer=${layerId2}`, state: { layerId1: layerId1, layerKey1: layerKey1 }})
        history.push(`/ProdListCategory/cat_1=${layerKey1}&layer_1=${layerId1}&cat_2=${layerKey2}&layer_2=${layerId2}`)
    }

    function handleClickLayer2(layerId1, layerKey1, layerId2, layerKey2) {
        // history.push({pathname: `/ProdListCategory/category=${layerKey2}&layer=${layerId2}`, state: { layerId1: layerId1, layerKey1: layerKey1 }})
        history.push(`/ProdListCategory/cat_1=${layerKey1}&layer_1=${layerId1}&cat_2=${layerKey2}&layer_2=${layerId2}`)
        window.location.reload();
    }

    function handleSortingProduct(sortItem, layerId, layerKey) {
        dispatch( fetchProductListsLayer2(`{
            "layer_id" : ${layerId},
            "layer_key":"${layerKey}",
            "sort_item":${sortItem},
            "page":1,
            "row_per_page":100
        }`, "/product/getproductlist") )
        setIsVisible(false)
    }

    const productDetail = (prodId, prodName) => {
        const productName = prodName;
        history.push({pathname: `/produk/${prodId}/${productName.replace(/\//g, " ")}`, state: {result: prodId}})
    }

    function openModalWithData(id) {
        if(id === undefined){
            setIsOrder(false);
        }
        else
        {
            setIsOrder(true)
            const prodID = id;
            setProdId(prodID);
        }
    }

    useEffect(() => {
        // console.log('useEffect1');
        dispatch( fetchDataListCatLayer2(`{"layer_id": ${layerId},"layer_key": "${catId}"}`, "/product/getcatandsubcatproduct") )
        // console.log('useEffect2');
        dispatch( fetchDataList2CatLayer2(`{"layer_id": ${layerId2},"layer_key": "${catId2}"}`, "/product/getcatandsubcatproduct") )
        // console.log('useEffect3');
        dispatch( fetchProductListsLayer2(`{
            "layer_id" : ${layerId2},
            "layer_key":"${catId2}",
            "sort_item":0,
            "page":1,
            "row_per_page":100
        }`, "/product/getproductlist") )
        // console.log('useEffect4');
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > 0) {
                setScrollDown(true)
            } else {
                setScrollDown(false)
            }
        };
        window.addEventListener("scroll", handleScroll);
    }, [dispatch])

    const loops = [1,2,3,4,5,6]
    let activeCat = []
    let upperCat = []
    
    if (!isLoadingGetCatLayer2List1) {
        getCatLayer2List1.forEach(category => {
            if(category.layer_key === catIdString) activeCat.push(category)
        })
        getCatLayer2List1.forEach(category => {
            if(category.layer_key !== catIdString) upperCat.push(category)
        })
    }
    
    return (
        <div style={{ position: 'relative', zIndex: 1 }}>
            <div style={{ marginTop: 58, overflowX: 'auto' }}>
                {
                    (isLoadingGetCatLayer2List1 && activeCat.length === 0) ?
                    <CategoryTabLoading /> :
                    <table style={{ margin: '10px 10px 20px 10px', overflowX: 'scroll' }}>
                        <tbody>
                            <tr>
                                <td>
                                    <div onClick={() => (handleClickLayer1(layerId, catId, activeCat[0].layer_id, activeCat[0].layer_key))} className="tab-produk no-select-text active_header">
                                        <img className="img-tab" src={ activeCat[0].img } alt="" />
                                        <span>{ activeCat[0].name }</span>
                                    </div>
                                </td>
                                {
                                    (upperCat) &&
                                    upperCat.map(kategori => {
                                        return(
                                            <td key={kategori.layer_key}>
                                                <div onClick={() => (handleClickLayer1(layerId, catId, kategori.layer_id, kategori.layer_key))} className="tab-produk no-select-text">
                                                    <img className="img-tab" src={ kategori.img } alt="" />
                                                    <span>{ kategori.name }</span>
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                }
            </div>
            <div style={{ marginTop: 20, marginLeft: 40, marginBottom: 10 }}>
                <div>
                    {
                        (isLoadingGetCatLayer2List1) ?
                        <TitleProdListLoading /> :
                        <span className="head-2">{ activeCat[0].name }</span>
                    }
                    {
                        (!scrollDown) ?
                        <div onClick={() => setIsVisible(!isVisible)} className='div-opt-btn' style={{position: 'relative',backgroundColor: 'unset', padding: 'unset', borderRadius: 'unset', border: 'unset', boxShadow: 'unset', marginRight: '10%', float: 'right'}}>
                            <img src={iconSort} alt='iconSort' style={{ cursor: 'pointer', marginTop: '2%', position: 'fixed'}} />
                        </div> :
                        <div onClick={() => setIsVisible(!isVisible)} className='div-opt-btn' style={{position : 'relative',backgroundColor: '#ffffff', padding: 5, borderRadius: 20, border: '1px solid rgb(238, 238, 238)', boxShadow: 'unset', marginRight: '10%', float: 'right' }}>
                            <img src={iconSort} alt='iconSort' style={{ cursor: 'pointer', display: 'block', position: 'fixed'}} />
                        </div>
                    }
                </div>
            </div>
            {
                (isLoadingGetCatLayer2List2) ?
                <CategoryTabLoading /> :
                (getCatLayer2List2 && layerId2 !==3) &&
                <div style={{ marginTop: 18, overflowX: 'auto' }}>
                    <table style={{ margin: '0px 10px 10px 10px', overflowX: 'scroll' }}>
                        <tbody>
                            <tr>
                                {
                                    getCatLayer2List2.map(kategori => {
                                        return (
                                            <td key={kategori.layer_key}>
                                                <div onClick={() => (handleClickLayer2(activeCat[0].layer_id, activeCat[0].layer_key, kategori.layer_id, kategori.layer_key))} className="tab-produk no-select-text">
                                                    <img className="img-tab" src={ kategori.img } alt="" />
                                                    <span>{ kategori.name }</span>
                                                </div>
                                            </td>
                                        )
                                    })
                                }
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            <div style={{ marginTop: 25 }}>
                {
                    (isLoadingProductList2) ?
                    <Row>
                        {
                            loops.map(loop => {
                                return (
                                    <ProdListLoading key={loop} />
                                )
                            })
                        }
                    </Row> :
                    (prodListsLayer2.length === 0) ?
                    <Row style={{ justifyContent: 'center', marginTop: -10 }}>
                        <div>
                            <img src="https://wwwd.ezeepasar.com/ezPasar1/Images/product_not_found.png" alt="" width="70px" height="66px" />
                        </div>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <div>
                                <label style={{ fontSize: 20, fontWeight: 600 }}>
                                    <p>Mereka akan segera hadir di ezeePasar.</p>
                                </label>
                            </div>
                            <div style={{ marginTop: -16 }}>
                                <span className="text-1">
                                    Coba kata kunci lainatau cek produk rekomendasi di bawah
                                </span>
                            </div>
                        </Col>
                    </Row> :
                    <Row>
                        {
                            prodListsLayer2.map((prodList, i) => {
                                return (
                                    <Col key={i} span={10} className="item-product" style={{ paddingBottom: 20, marginBottom: 15, width: '46%', float: 'left' }}>
                                        <div onClick={() => productDetail(prodList.product_id, prodList.product_name)} className="box-produk" style={{ width: '100%', position: 'relative' }}>
                                            {
                                                (prodList.product_stat_stock_id === 2) ?
                                                <>
                                                    <img src={ prodList.product_image } alt="" className='imgProduct'/>
                                                    <div className="sold-out" style={{ marginLeft: -11, marginTop: -11, height: '78%', width: '113%' }}></div>
                                                    <div className="sold-out-text" style={{ marginTop: -140, marginLeft: 70, marginBottom: 65 }}>Habis</div>
                                                </> :
                                                <img src={ prodList.product_image } alt="" className='imgProduct'/>
                                            }
                                            <div className="text-2" style={{ marginTop: 12 }}>
                                                <div className="text-produk1" style={{ fontSize: 13 }}>
                                                { prodList.product_name }
                                                </div>
                                                <div className="text-produk2">{ prodList.product_weight } { prodList.product_weight_name }</div>
                                                {
                                                    prodList.product_promo_percent == null
                                                    ? <p className="text-produk3">{ convertToRupiah(prodList.product_price) } / { prodList.product_unit_name }</p>
                                                    : <><div className="disc-product">
                                                        <span className="percent-disc">{ prodList.product_promo_percent }%</span>
                                                        <span className="real-price">{ convertToRupiah(prodList.product_price) }</span>
                                                    </div>
                                                    <p className="text-produk3">{ convertToRupiah(prodList.product_promo_price) } / { prodList.product_unit_name }</p>
                                                    </>
                                                }
                                                {
                                                    prodList.product_terjual !== null && prodList.product_terjual !== 0 ? 
                                                    <div>
                                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10, float: 'left'}}>Terjual: <span style={{color: 'black'}}>{nFormatter(prodList.product_terjual)}</span></span>
                                                    </div> : null
                                                }
                                            </div>
                                        </div>
                                        {
                                            (prodList.product_stat_stock_id === 2) ?
                                            <button className="btn-pesan-sold" style={{ cursor: 'default !important' }}>Pesan</button> :
                                            <button className="btn-pesan" onClick={() =>openModalWithData(prodList.product_id)}>Pesan</button>
                                        }
                                    </Col>
                                )
                            })
                        }
                    </Row>
                }
            </div>
            <Modal
                visible={isVisible}
                onCancel={() => setIsVisible(false)}
                footer={null}
                className={(!scrollDown) ? "modalVefiedFilterProd" : "modalVefiedFilterProd"}
            >
                <div className='div-sort-item' style={!scrollDown ? {display: 'unset', marginTop: '25%', marginRight: '-6%', cursor: 'pointer'} : {display: 'unset', marginTop: '20%', marginRight: '-4%', cursor: 'pointer'}}>
                    <img onClick={() => handleSortingProduct(1, layerId2, catId2)} style={{ width: 82, height: 32 }} src={sortAToZ} alt='aToZ' />
                    <img onClick={() => handleSortingProduct(2, layerId2, catId2)} style={{ width: 82, height: 32 }} src={sortZToA} alt='zToA' />
                    <img onClick={() => handleSortingProduct(3, layerId2, catId2)} style={{ width: 82, height: 32 }} src={sortTerendah} alt='terendah' />
                    <img onClick={() => handleSortingProduct(4, layerId2, catId2)} style={{ width: 82, height: 32 }} src={sortTertinggi} alt='tertinggi' />
                </div>
            </Modal>
            {
                isOrder ? <AddCartModal showModal={isOrder} setModal={setIsOrder} closedModal={() => setIsOrder(false)} productId={prodId}/> : null
            }
        </div>
    )
}

export default ListCat2
