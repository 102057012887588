import React, {useEffect, useState}from 'react'
import './ShopeePayPayment.css'
import {useHistory} from 'react-router-dom'
import FlipCountdown from '@rumess/react-flip-countdown' 
import { useLocation, Link} from 'react-router-dom'
import './VirtualAccPayment.css'
import { checkoutUserSession, getToken} from '../../utils/setSessionUser';
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import encryptData from '../../function/encryptData';
import moment from 'moment';
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import {BaseURL, headersWithToken, moneyFormat} from '../../function/helpers'
import {Row, Col, Layout, Collapse} from 'antd';
import HtmlParser from 'react-html-parser';
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'
import QRCode from "react-qr-code";
import $ from 'jquery'

const {Footer} = Layout

const { Panel } = Collapse;

function ShopeePayPayment() {
    moment.lang('en');
    const [shopeePayDetail, setShopeePayDetail] = useState({});
    const [loading, setLoading] = useState(true);
    // var usrAgent = navigator.userAgent;
    let location = useLocation();
    let history = useHistory();

    useEffect(() => {     
        shopeePayDetailAPI(location.state.order_id);
    }, []);

    let access_token = getToken();

    async function shopeePayDetailAPI(id) {
        try{
            const signature = base64Sign("/checkout/getpaymentshopeedetail"); 
            const dataParams = encryptData(`{"order_id":"${id}"}`)           
            const headers = headersWithToken(signature , access_token);
            const data = await axios.post(BaseURL + '/checkout/getpaymentshopeedetail',{data: dataParams},{headers : headers});
            var datas = data.data.response_data.results;
            if(data.status === 200){
                setShopeePayDetail(datas);
                if(datas.order_status_id === 4){
                    history.push(`"/payment/finishpayment?order_id=${datas.order_code}&result=success"`)
                }                
                if(datas.order_status_id === 9){
                    history.push('/RiwayatPesananDetail/'+datas.order_id)
                }
            }            
            setLoading(false);
            
        }catch(err){
            console.log(err);
        }
    }

    function toOrderDetail(){
        history.push('/RiwayatPesananDetail/'+shopeePayDetail.order_id)
    }

    function openShopeeApps(){        
        window.open(shopeePayDetail.redirect_url_app, '_blank');
    }

    if(loading){
        return(
            <LoadingModal/>
        )
    }else{
        var formate = moment.unix(shopeePayDetail.expired_time).format("yyyy-MM-DD HH:mm:ss");
    }
    $(window).on('popstate', function (e) {
        var state = e.originalEvent.state;
        if (state !== null) {
            history.push('/');
        }
    });
    // console.log(isError);
    return (
        <div style={{marginTop: '20%'}}>
            <NavbarCloseOnly link={location.state.isFromHistoryOrder ? "/RiwayatPesanan" : "/back"}/>
                <div style={{textAlign: 'center'}}>
                <img className="head-2" style={{textAlign: 'center'}} alt="" src="https://wwwd.ezeepasar.com/Images/shopeePay_mobile.png"></img>
                {!shopeePayDetail.transaction_apps ? 
                    <div style={{backgroundColor: '#F4F4F4', paddingTop: '1.3em', paddingBottom: '1.3em'}}>
                        <aside>
                            <QRCode value={shopeePayDetail.redirect_url_app} alt=""/>
                            <p style={{font: 'normal normal 12px/18px Open Sans', color: '#393939', width: '66%', margin: '0 auto', marginBottom: '-10%'}}>Scan kode QR di bawah ini menggunakan aplikasi ShopeePay</p>
                            <p style={{fontFamily: 'Montserrat', lineHeight: 12, fontSize: 15, marginBottom: '-30%'}}>Order ID : <b>{shopeePayDetail.order_code}</b></p>
                            <p style={{fontSize: 12, lineHeight: 12, marginTop: '1.5em', marginBottom: '-10%'}}><b>Jumlah Transfer : {moneyFormat(shopeePayDetail.amount)}</b></p>
                            <div className="div-hr" style={{marginBottom: 20}}>
                                <hr style={{backgroundColor: 'black', height: 1, width: '100%', marginBottom: '-5%'}}></hr>
                            </div>
                            <p style={{fontSize: 12}}>Mohon lakukan pembayaran dalam waktu</p>
                            <FlipCountdown
                                endAt={formate}
                                size={'medium'}
                                hideMonth={true}
                                hideDay={true}
                                hideYear={true}
                                hideHour={true}
                                hourTitle=''
                                minuteTitle=''
                                secondTitle=''
                                style={{marginTop: '-10%'}}
                            />
                        </aside>
                    </div>  : 
                    <div style={{paddingTop: '1.3em', paddingBottom: '1.3em'}}>
                        <>
                            <Row>
                                <Col span={12}>
                                    <p style={{font: 'normal normal 12px/18px Open Sans', fontWeight: 600, marginRight: 35}}>Order ID</p>
                                </Col>
                                <Col span={12}>
                                    <p style={{fontFamily: 'Montserrat', letterSpacing: '-2px'}}><b>{shopeePayDetail.order_code}</b></p>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={12}>
                                <p style={{font: 'normal normal 12px/18px Open Sans', fontWeight: 600}}><b>Jumlah Transfer</b></p>
                                </Col>
                                <Col span={12}>
                                <p style={{fontFamily: 'Montserrat', letterSpacing: '-1px'}}><b>{moneyFormat(shopeePayDetail.amount)}</b></p>
                                </Col>
                            </Row>                                                                                     
                            <div className="div-hr" style={{marginBottom: 20, width: '100%'}}>
                                <hr style={{backgroundColor: 'black', height: 1, width: '100%', marginBottom: '-5%'}}></hr>
                            </div>
                            <p style={{fontSize: 12}}>Mohon lakukan pembayaran dalam waktu</p>
                            <FlipCountdown
                                endAt={formate}
                                size={'medium'}
                                hideMonth={true}
                                hideDay={true}
                                hideYear={true}
                                hideHour={true}
                                hourTitle=''
                                minuteTitle=''
                                secondTitle=''
                                style={{marginTop: '-10%'}}
                                endAtZero
                            />
                        </>
                    </div>  
            }
            </div>            
            <div className="panduan" style={{width: '80%', marginLeft: '10%', marginTop: 10}}>
            <Collapse accordion>
                <Panel header="Panduan Pembayaran" key="1">
                    <table style={{maxWidth: '380px !important', width: '100%',}} className='table-content'>
                            <tbody>
                                {
                                    shopeePayDetail.instruction_detail.map((item, index) => {
                                        return (
                                        <tr className="content-item-title" style={{width: '100%'}}>
                                            <td style={{verticalAlign: 'top'}}>{index + 1}.</td>
                                            <td style={{display: 'inline-block', overflowWrap: 'anywhere', paddingLeft: 5, textAlign: 'left', float: 'left'}}>{HtmlParser(item)}</td>
                                        </tr>  
                                        )
                                    })
                                }
                        </tbody>
                    </table>
                </Panel>
            </Collapse>
            </div>
                <Layout style={{marginTop: '28%'}}>
                    <Footer className="footerLayout" style={{fontFamily: 'Montserrat', height: 70}}>
                        <Row>   
                            {!shopeePayDetail.transaction_apps ? 
                                <Col span={24}>
                                    <button className="btnBuy" style={{height: '60px'}} onClick={() => toOrderDetail()}>
                                        <span className="spanCart">
                                            <Link style={{color: "#fff", backgroundColor: 'transparent'}} className="spanCartLink">Saya sudah bayar</Link>
                                        </span>
                                    </button>
                                </Col> : 
                                <>
                                <Col span={12}>
                                    <button className="btnBuy" style={{height: '50px', width: '90%', backgroundColor: '#fff'}} onClick={() => toOrderDetail()}>
                                        <span className="spanCart">
                                            <Link style={{color: "#3DB54A", backgroundColor: 'transparent'}} className="spanCartLink">Status pembayaran</Link>
                                        </span>
                                    </button>
                                </Col>
                                <Col span={12} style={{ float:'right'}}>
                                    <button className="btnBuy" style={{height: '50px', width: '90%'}}>
                                        <span className="spanCart" onClick={() => openShopeeApps()}>
                                            <Link style={{color: "#fff", backgroundColor: 'transparent'}} className="spanCartLink">Lanjutkan</Link>
                                        </span>
                                    </button>
                                </Col>
                                </>
                        }             
                        </Row>
                    </Footer>
                </Layout>
        </div>
    )
}

export default ShopeePayPayment
