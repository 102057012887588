import axios from "axios";
import base64Sign from "../../function/base64Sign";
import encryptData from "../../function/encryptData";
import { getToken } from "../../utils/setSessionUser";
import { isLoading } from "./isLoadingActions";
import { FETCH_DATA_OTHERPRODUCTLIST, IS_LOADING } from "../ActionType/actionTypes";
import {BaseURL} from '../../function/helpers'
import { isLoadingProductOther } from "./Loading/isLoadingProductOther";

export const fetchOtherProductList = (params, url)  => {
    return (dispatch) => {
        const param = encryptData(params);
        const signature = base64Sign(url);
        const access_token = getToken();
        const headers = {
            "Content-Type": "application/json",
            "sign": signature,
            "access_token": access_token
        };
        axios
            .post(BaseURL + url, { data: param }, { headers: headers })
            .then((data) => {
                dispatch({
                    type: FETCH_DATA_OTHERPRODUCTLIST,
                    payload: {
                        getOtherProduct: data.data.response_data.results,
                    },
                });
                dispatch(isLoadingProductOther(false))
            })
            .catch((error) => {
                console.log(error);
            });
    }
}