import { FETCH_DATA_ABOUT_HOME, IS_LOADING } from "../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../function/base64Sign";
import { getToken } from "../../utils/setSessionUser";
import {BaseURL} from '../../function/helpers'
import { isLoadingAboutHome } from "./Loading/isLoadingAboutHomeActions";

export const  fetchDataAbout = (url) => {
    return ( dispatch ) => {
        const signature = base64Sign(url)
        const access_token = getToken()
        const headers = {
            'Content-Type' : 'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
    
        axios.post(BaseURL + url, {data: ""}, {headers: headers})
            .then(about => {
                dispatch({
                    type: FETCH_DATA_ABOUT_HOME,
                    payload: {
                        about: about.data.response_data.results
                    }
                })
                dispatch(isLoadingAboutHome(false))
            })
            .catch(error => {
                console.log(error);
            })
    }
}