import React, {useState, useEffect}from 'react'
import { Row, Col, Button, Card, Modal} from 'antd'
import AddCartModal from'../PopUpModal/HomePage/AddCartModal';
import {nFormatter} from '../../function/helpers'
import {CardProdOtherLoading} from '../skeletonLoading/loadingTag'
import {useHistory} from "react-router-dom";
import {getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import encryptData from '../../function/encryptData'
import axios from 'axios';
import {BaseURL, headersWithToken} from '../../function/helpers'

function ProdukRekomendasi() {
    const [visible, setVisible] = useState(false);
    const [prodId, setProdId ] = useState(0);
    const [loading, setLoading] = useState(true);
    const [productRecommend, setProductRecommend] = useState([]);
    let history = useHistory();
    let access_token = getToken();

    function openModalWithData(id) {
        if(id === undefined){
            setVisible(false);
        }
        else
        {
            setVisible(true)
            const prodID = id;
            setProdId(prodID);
        }
    }

    useEffect(() => {
        getproductRecommend();
        openModalWithData();
    } , [])

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }

    async function getproductRecommend() {
        const signature = base64Sign("/product/getproductdashboardall");        
        const headers = headersWithToken(signature, access_token);   
        const dataParams = encryptData(`
        {"prod_type":5,
        "sort_item":0,
        "page":1,
        "row_per_page":100}`);       
        await axios
        .post(BaseURL + '/product/getproductdashboardall',{data:dataParams},{headers:headers}).then(data=>{
            var datas = data.data.response_data.results;            
            setProductRecommend(datas)
            setLoading(false)
        }).catch(err => console.log(err));

    }

    if(loading){
        let prods = [1,2,3,4,5,6]
        return(
            <div>
                <Row style={{marginLeft: 15, marginRight: 15}}>               
                {prods.map(dt => {
                    return(                
                        <Col span={10} className="item-product" style={{ paddingBottom: 20, marginBottom: 15, width: '46%', float: 'left' }}>
                            <CardProdOtherLoading/>
                        </Col>
                    
                    )
                })}
                </Row>
            </div>
            
        )
    }

    const productDetail = (prodId, prodName) => {
        const productId = prodId;
        const productName = prodName;
        history.push({pathname: `/produk/${productId}/${productName.replace(/\//g, " ")}`, state: {result: productId}})
    }

  return (
    <Row>
    {
            productRecommend.map(prodList=> {
                return (
                    <Col span={10} className="item-product" style={{ paddingBottom: 20 ,marginBottom: 15, width: '46%', float: 'left' }}>
                        <div className="box-produk" style={{ width: '100%', position: 'relative' }} onClick={() => productDetail(prodList.product_id, prodList.product_name)}>                                
                        {
                            prodList.product_stat_stock_id === 2 
                            ? <div className='sold-out-product'><img src={prodList.product_image} alt="" className='imgProduct'></img><span className='text-sold-out'>Habis</span></div> : 
                            <img src={prodList.product_image} alt="" className='imgProduct'></img>
                        }                           
                            <div className="text-2" style={{ marginTop: 12 }}>
                                <div className="text-produk1" style={{ fontSize: 13 }}>
                                { prodList.product_name }
                                </div>
                                <div className="text-produk2">{ prodList.product_weight } { prodList.product_weight_name }</div>
                                {
                                    prodList.product_promo_percent == null
                                    ? <p className="text-produk3">{ convertToRupiah(prodList.product_price) } / { prodList.product_unit_name }</p>
                                    : <><div className="disc-product">
                                        <span className="percent-disc">{ prodList.product_promo_percent }%</span>
                                        <span className="real-price">{ convertToRupiah(prodList.product_price) }</span>
                                    </div>
                                    <p className="text-produk3">{ convertToRupiah(prodList.product_promo_price) } / { prodList.product_unit_name }</p>
                                    </>
                                }
                                {
                                    prodList.product_terjual !== 0 ? 
                                    <div>
                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10, float: 'left'}}>Terjual: <span style={{color: 'black'}}>{nFormatter(prodList.product_terjual)}</span></span>
                                    </div> : null
                                }                                         
                                {
                                    prodList.product_stat_stock_id === 2
                                    ? <div onClick={(event) => event.stopPropagation()}><button className="btn-pesan-sold" shape="round">Pesan</button></div>
                                    : <div onClick={(event) => event.stopPropagation()}><Button className="btn-pesan" shape="round" onClick={() =>openModalWithData(prodList.product_id)}>Pesan</Button></div>
                                }
                            </div>
                        </div>
                    </Col>
                )
            })
    }   
        {
            visible ? <AddCartModal showModal={visible} setModal={setVisible} closedModal={() => setVisible(false)} productId={prodId}/> : null
        }     
    </Row>
  )
}

export default ProdukRekomendasi