import React from 'react'
import { NavbarCloseOnly } from '../components/Properties/Navbar/Navbar.js'
import ServerErrorImage from "../Asset/Icon/500.svg"
import { useHistory } from 'react-router-dom'

function InternalServerError() {

    const history = useHistory()

    return (
        <div className="container body-content mobile-view" style={{ textAlign: 'center', height: 2542 }}>
            <NavbarCloseOnly link="/back" />
            <div style={{ paddingTop: 175 }}>
                <img src={ServerErrorImage} alt="InternalServerError" />
            </div>
            <div style={{ marginTop: 15 }}>
                <button onClick={() => history.goBack()} className='btn ez-btn-green-no-match' style={{ width: '80%', height: 50, marginTop: 15, borderRadius: 8, fontWeight: 'bold', fontSize: 14, alignItems: 'center' }}>
                    Coba Lagi
                </button>
            </div>
        </div>
    )
}

export default InternalServerError