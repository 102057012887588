import React, {useEffect} from 'react'
import { useHistory } from 'react-router-dom'
import {Row, Col, Modal} from 'antd'
import timeFrameNotAvailableIcon from '../../../Asset/Icon/timeframeIcon.svg'

function TimeFrameIsNotExist({isOpen, onClosedTimeFrameNotExist, callbackToParent, date, addrId}) {
    let history = useHistory();

    useEffect(() => {
        
    } ,[])

    function goToDeliveryMethod(){
        history.push("/deliverymethod", {address_id : addrId, datekirim : date});
    }

    function callbackToParentData(){
        callbackToParent(0, 0)
    }

    function closeModalCallback(){
        onClosedTimeFrameNotExist(0)
    }

  return (
    <div>
        <Modal
          visible={isOpen}
          className="modalAddCart modalCashback" 
          style={{borderRadius: 15}}  
          footer={
          <>
                <Row style={{marginBottom: 20}}>
                  <Col span={24}>
                      <div>
                          <button className='modal-button-cashback' onClick={() => callbackToParentData()}>
                              <span className='spanCart'>
                                  Buat Pesanan
                              </span>
                          </button>
                      </div>
                  </Col>
              </Row>
              <Row style={{marginBottom: 20}}>
                  <Col span={24}>
                      <div style={{textAlign: 'center'}}>
                        <span className='sub-title-cashback-modal' style={{fontWeight: 700, fontSize: 14, }} onClick={() => goToDeliveryMethod()}>Ubah Tanggal</span>
                      </div>
                  </Col>
              </Row>
          </>
          }
          onClosed={closeModalCallback}
          onCancel={closeModalCallback}
      >
          <div style={{textAlign: 'center'}}>
              <Row>
                  <Col span={24}>
                      <img src={timeFrameNotAvailableIcon} alt=""/>
                  </Col>
              </Row>
              <br/>
              <Row>
                  <Col span={24}>
                      <div>
                          <p className='title-cashback-modal'>Slot Waktu Pengiriman di Tanggal Yang Kamu Pilih Tidak Tersedia</p>
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col span={24}>
                      <div>
                          <p className='sub-title-cashback-modal'>Tenang pesanan kamu tetap kami kirimkan sesuai tanggal yang kamu pilih secepatnya atau silahkan ubah tanggal lain untuk atur ulang waktu pengiriman.</p>
                      </div>
                  </Col>
              </Row>
              <br/>             
        </div>
      </Modal>
    </div>
  )
}

export default TimeFrameIsNotExist