import { IS_LOADING_BANNER_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingBannerHome: true
}

const isLoadingBannerReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_BANNER_HOME) {
        return { ...state, isLoadingBannerHome: action.payload.isLoadingBannerHome }
    } else {
        return state
    }
}

export default isLoadingBannerReducer