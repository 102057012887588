import React from 'react'
import {Modal} from 'antd'
import checklist_success from '../../../Asset/Icon/clarity_check-circle-line.png'
function AgentCodeSuccessModal({isOpen, onClosed, data}) {
  return (
    <div>
    <div className="center-modal">
        <Modal className="modalProgress" id="modalProgress" visible={isOpen} footer={null} closable={false} style={{marginTop: '10%', width: '330px !important'}} >
            <div style={{textAlign: 'center'}}>
                <img alt="" src={checklist_success}/>
                <div style={{fontSize: 14, fontWeight: 600, marginBottom: 10}}>Berhasil</div>
                <div style={{fontSize: 10}}></div>
                <div style={{fontSize: 10}}>{data}</div>
                <button className='btnKeranjang' style={{fontSize: 12, marginTop: 10}} onClick={onClosed}>Tutup</button>
            </div>
        </Modal>
    </div>
</div>
  )
}

export default AgentCodeSuccessModal