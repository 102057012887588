import React from 'react'
import Header2 from '../components/Header2'
import './Disclaimer.css'

function Disclaimer({isWebview}) {
    return (
        <div className="container body-content mobile-view">
            {isWebview ? null : <Header2 />}
            <div className="maintext">
                <p style={{ marginTop: 50, color: '#393939', fontSize: 15, fontFamily: 'Montserrat', fontWeight: 600, padding: '0px 0px 7px 0px' }}>
                    Disclaimer Gambar
                </p>
                <text>
                    <p>
                        Gambar, ukuran, bentuk yang tertera dalam brosur maupun website adalah merupakan informasi umum terkait produk.
                    </p>
                    <p>
                        Gambar, ukuran, bentuk dapat berubah sewaktu-waktu, perubahan dalam brosur dan website bersifat tidak mengikat dan bukan merupakan tanggung jawab developer untuk menginformasikannnya.
                    </p>
                    <p>
                        Gambar atau foto produk tidak sepenuhnya mewakili bentuk asli. Efek sinar selama proses pemotretan mungkin mendistorsi warna, selain itu gambar dibuat dengan tujuan utama “menjual” atau “promosi” sehingga harus dibuat semenarik mungkin.
                    </p>
                    <p>
                        Gambar-gambar yang ada di dalam situs ini bisa jadi sudah tidak up-to-date dengan gambar produk terbaru, sehingga bisa saja kemasan, bentuk, wujud, ukuran, atau penampilan produk berbeda dengan yang ada di gambar.
                    </p>
                    <p>
                        Kami tidak bertanggungjawab apabila produk yang diterima tidak sesuai (tidak sama persis) dengan gambar yang ada di situs ini.
                    </p>
                </text>
            </div>
        </div>
    )
}

export default Disclaimer
