import React, {useState, useEffect} from  'react';
import { Row, Col, Input, Modal} from 'antd'
import { Link, useHistory } from 'react-router-dom'
import './Login.css'
import {GoogleLogin, GoogleLogout} from 'react-google-login';
import validator from 'validator'
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import { setUserSession, removeUserSession, getUserFb, setloginType } from '../../utils/setSessionUser';
import $ from 'jquery';
import FacebookLogin from 'react-facebook-login';
import AppleLogin from 'react-apple-login'
import axios from 'axios';
import {BaseURL, useTitle, headersWithoutToken} from '../../function/helpers'
import google_logo from '../../Asset/Icon/logo/Google_Color.svg'
import iconErrorRed from '../../Asset/Icon/error_icon.svg'
import iconFacebookColor from '../../Asset/Icon/Facebook_Color.svg'
import apple_logo from '../../Asset/Icon/ic_apple_login.png'
import spinnerLoading1s from '../../Asset/Icon/spinner-1s-58px.svg'
import * as CONSTVAR from '../../utils/ConstVar'

const clientId = CONSTVAR.GOOGLE_CLIENT_ID
const appId = CONSTVAR.FACEBOOK_APP_ID

function Login() {

    useTitle("Login")

    const [emailorPhone, setEmailorPhoneVal] = useState('');
    const [pass, setPassVal] = useState('');
    const [errorEmailPhone, setErrorEmailPass] = useState(null);
    const [errorPass, setErrorPass] = useState(null);
    const [modalProgress, setModalProgress] = useState(false);

    let history = useHistory();

    async function onSuccess(res){
        var usrAgent = navigator.userAgent;

        try{
            if(res.tokenObj.id_token != null) {
                const signature = base64Sign("/security/login");
                const dataParams = encryptData(`{"client_id":0,"email":"","login_type":101, "password":"","mobile":"","user_agent":"${usrAgent}","token_ezeepasar":true, "token_external_login" : "${res.tokenObj.id_token}"}`)
                const headers = headersWithoutToken(signature);
                const dataProds = await axios.post(BaseURL + '/security/login',{data: dataParams},{headers : headers});
                if(dataProds.data.response_code === 200)
                {
                    setModalProgress(false);
                    setUserSession(dataProds.data.response_data.results.access_token)
                    setloginType('101')
                    history.push('/')
                }
            }
        }catch(ex){

        }
    };

    async function callbackFacebook(res){
        setModalProgress(true);
        var usrAgent = navigator.userAgent;
        try{
                const signature = base64Sign("/security/login");
                const dataParams = encryptData(`{"client_id":0,"email":"","login_type":102, "password":"","mobile":"","user_agent":"${usrAgent}","token_ezeepasar":true,"token_external_login":"${res.accessToken}"}`)
                const headers = headersWithoutToken(signature);
                const dataProds = await axios.post(BaseURL + '/security/login',{data: dataParams},{headers : headers});
                if(dataProds.data.response_code === 200)
                {
                    setModalProgress(false);
                    setUserSession(dataProds.data.response_data.results.access_token)
                    setloginType('102')
                    history.push('/')
                }
        }catch(ex){
            console.log(ex);
        }
    }

    async function HandleLogin(){  
        setModalProgress(true);
        var usrAgent = "";  
        var mail = "";
        var phoneNum = "";
        var password = pass;
        
        var x = navigator.userAgent;
        usrAgent = x;

        if(validator.isEmail(emailorPhone)){
            mail = emailorPhone;
        }
        else if(validator.isNumeric(emailorPhone)){
            phoneNum = emailorPhone;
        }

        try{
            const signature = base64Sign("/security/login");
            const dataParams = encryptData(`{"client_id":0,"email":"${mail}", "login_type":100, "password":"${password}","mobile":"${phoneNum}","user_agent":"${usrAgent}","token_ezeepasar":true}`)
            const headers = headersWithoutToken(signature);
            const dataProds = await axios.post(BaseURL + '/security/login',{data: dataParams},{headers : headers});
            if(dataProds.status === 200)
            {
                setUserSession(dataProds.data.response_data.results.access_token)
                setloginType('100')
                history.push('/')
            }else{
                if(dataProds.response.status === 400){
                    if(dataProds.response.data.response_data.error_id === "0001"){
                        $('#logins .ant-input').css("border", "1px solid red")
                        setErrorEmailPass(dataProds.response.data.response_message);                        
                    }else if(dataProds.response.data.response_data.error_id === "0002"){
                        $('.ant-input-password').css("border", "1px solid red")
                        setErrorPass(dataProds.response.data.response_message);
                    }
                    setModalProgress(false);
                }
            }
        } catch(error) {
            setModalProgress(false);
        }
    }

    const onFailure = (res) => {
    };

    const onLogout = () => {
        alert('[Logout Success]')
    };

    const cookiesPrint = () => {
        removeUserSession();
    }

    const handleOk = () => {
        setModalProgress(false);
    };
    
    const handleCancel = () => {
        setModalProgress(false);
    };

    return(
        <div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                <Row style={{ marginTop: 85 }}>
                    <Col>
                        <span className="title-head">Masuk</span>
                    </Col>
                </Row>
            </div>
            <div style={{marginLeft: 15, marginRight: 15}}>
                <Row>
                    <Col>
                        <span className="title-label">Email atau No. Telepon</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} id="logins">
                        <Input className="form-control-log" onChange={e => setEmailorPhoneVal(e.target.value)} id="emailorphoneval"></Input>
                    </Col>
                </Row>
                <Row>
                    <Col id="emailOrPhoneAlert">
                        {errorEmailPhone ? <div className="error-txt"><img src={iconErrorRed} alt="" />{errorEmailPhone}</div> : null}
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="title-hint">Contoh: ezeepasar@ezeepasar.com</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <span className="title-label">Kata Sandi</span>
                    </Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Input.Password className="form-control-log" autoComplete="new-password" onChange={e => setPassVal(e.target.value)} id="passwordVal" style={{fontWeight: 600}}/>                                               
                    </Col>
                </Row>
                <Row>
                    <Col id="passAlert">
                        {errorPass ? <div className="error-txt"><img src={iconErrorRed} alt="" />{errorPass}</div> : null}
                    </Col>
                </Row>
                {/* <div>
                    <img src={isRevealPwd ? hidePass : unhidePass} onClick={ () => setIsRevealPwd(prevState => !prevState) } alt="" className="img-pwd" type={isRevealPwd ? "text" : "password"} value={pwd} onChange={e => setPwd(e.target.value)}/>
                </div> */}
                <Row>
                    <Col>
                        <span className="title-hint">Contoh: ezeepasar12345</span>
                    </Col>
                </Row>
                <Row style={{marginTop: 12}}>
                    <Col span={24}>
                        <button className="btn-login" disabled={!emailorPhone || !pass} onClick={() => HandleLogin()}>Masuk</button>
                    </Col>
                </Row>
            </div>
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 35}}>
                <Row>
                    <Col>
                        <span className="title-forgotpass"><Link to="/forgotpassword" style={{color: '#3DB54A'}}>Lupa Kata Sandi?</Link></span>
                    </Col>
                </Row>
            </div>
            <div style={{marginTop: 35, position: 'relative'}}>
                <div className="divider">
                    <span>atau masuk dengan</span>
                </div>
            </div>
            <div style={{marginLeft: 15, marginRight: 15, marginTop: 50, cursor: 'pointer'}}>
                <GoogleLogin
                    clientId={clientId}
                    button="Login"
                    onSuccess={onSuccess}
                    cookiePolicy={'single_host_origin'}
                    isSignedIn={true}
                    redirectUri='https://localhost:3000/signin-google'
                    render={renderProps => (
                        <div className="div-login-with" onClick={renderProps.onClick} style={{marginBottom: 10}}>
                            <img src={google_logo} className="img-login-with" alt=""/>
                            <span className="span-login-with metro">Google</span>
                        </div>
                    )}/>                                                  
                <FacebookLogin
                    appId={appId}
                    autoLoad={true}
                    fields="name,email,picture"
                    scope="public_profile,email"                            
                    callback={callbackFacebook}                        
                    cssClass="div-login-with"
                    textButton="Facebook &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"
                    style={{marginTop: 10}}
                    icon={<img src={iconFacebookColor} className="img-login-with" alt=""/>}
                    />                 
                <AppleLogin clientId="com.ezeepasar.web" redirectURI="https://www.ezeepasar.com/account/Login" usePopup={true}                     
                    render={renderProps => (
                        <div className="div-login-with apple-button" onClick={renderProps.onClick} style={{marginTop: 10}}>
                            <img src={apple_logo} className="img-login-with" alt="" style={{marginLeft: 7 ,width: 37, height: 37}}/>
                            <span className="span-login-with metro" style={{marginRight: 3}}>Apple ID </span>
                        </div>
                    )}/>
                {/* <GoogleLogout clientId={clientId} onLogoutSuccess={onLogout} buttonText="logout"/> */}
                {/* <div style={{marginTop: 10}} className="div-login-with">
                    <img src="https://wwwd.ezeepasar.com/Images/Facebook_Color.svg" className="img-login-with" alt=""/>
                    <span className="span-login-with">Facebook</span>

                 </div>          */}
                </div> 
            
                <Modal className="modalProgress" id="modalProgress" visible={modalProgress} footer={null} closable={false} style={{marginTop: '10%', width: '330px !important'}} >
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 14, fontWeight: 600, marginBottom: 10}}>Sedang dalam proses</div>
                        <div style={{fontSize: 10}}>Kami sedang memproses.</div>
                        <div style={{fontSize: 10}}>Mohon menunggu</div>
                        <img src={spinnerLoading1s} style={{marginTop: 10}} alt=""/>
                    </div>
                </Modal>
            
            <div style={{display: 'flex',  justifyContent:'center', alignItems:'center', marginTop: 55}}>
                <span id="spanTitle">Belum punya akun ezeePasar?<span className="title-register"><Link to="/register" style={{color: '#3DB54A'}}> Daftar</Link></span></span>
            </div>
        </div>
    )
}

export default Login