import React from 'react'
import {NavbarWithSearchAndMenu} from '../components/Properties/Navbar/Navbar'
import AvailableCart from '../components/CartPage/AvailableProduct';
import {checkoutUserSession} from '../utils/setSessionUser'
import {clearCheckoutSession} from '../function/helpers'

function Cart() {
    clearCheckoutSession();
    return (
        <div className="container body-content mobile-view cart">
            <NavbarWithSearchAndMenu link="/"/>
            <AvailableCart/> 
        </div>
    )
}

export default Cart
