import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { CategoryLoading } from '../skeletonLoading/loadingTag'
import './Category.css'
import { fetchCategory } from '../../redux/ActionCreators/categoryActions'
import { IS_LOADING_CATEGORY_HOME } from '../../redux/ActionType/actionTypes'

function Category() {

    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)
    const dispatch = useDispatch()

    useEffect(() => {
            dispatch( fetchCategory("/dashboard/getcategory") )
    }, [dispatch])
    
    function handleLoading() {
        dispatch({
            type : IS_LOADING_CATEGORY_HOME,
            payload: {
                isLoadingCategoryHome : true
            }
        })
    }

    if(isLoadingCategoryHome) {
        const loops = [1,2,3,4,5,6]
        return (
            <div style={{height: 284}}>
                <div className="box-items">
                    <Row style={{ flexWrap: 'nowrap', marginLeft: 15 }}>
                        {
                            loops.map(loop => {
                                return(
                                    <CategoryLoading key={loop} />
                                )
                            })
                        }
                    </Row>
                    <Row style={{ flexWrap: 'nowrap', marginLeft: 15}}>
                        {
                            loops.map(loop => {
                                return(
                                    <CategoryLoading key={loop} />
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
        )
    } else {
        let atas = 0
        let bawah = 0
        let equal = 0

        if(categories.length % 2 === 1) {
            atas = Math.ceil(categories.length / 2)
            bawah = categories.length - atas
        } else {
            equal = categories.length / 2
        }

        function dataAtas(params1) {
            let data = []
            for (let i = 0; i < params1; i++) {
                data.push(categories[i])
            }
            return data
        }

        function dataBawah(params2) {
            let data = []
            for (let i = params2 + 1; i < categories.length; i++) {
                data.push(categories[i])
            }
            return data
        }

        function dataAtasEqual(params1) {
            let data = []
            for (let i = 0; i < params1; i++) {
                data.push(categories[i])
            }
            return data
        }

        function dataBawahEqual(params2) {
            let data = []
            for (let i = params2; i < categories.length; i++) {
                data.push(categories[i])
            }
            return data
        }

        return (
            <div style={{height: 284}}>
                <div className="box-items">
                    {
                        equal === 0 ?
                        (
                            <>
                                <Row style={{ flexWrap: 'nowrap', marginLeft: 15 }}>
                                    {
                                        dataAtas(atas).map(category => {
                                            return (
                                                <Col onClick={handleLoading} key={category.mcat_id} className="items">
                                                    <Link to={{ pathname: `/ProductListCategory/${category.mcat_id}`, state: { layerKey1: 1 } }}>
                                                        <img style={{ borderRadius: 5 }} src={category.mcat_img} alt="" width="80" height="80"/>
                                                        <span className="item-span">{category.mcat_name}</span>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                <Row style={{ flexWrap: 'nowrap', marginLeft: 15 }}>
                                    {
                                        dataBawah(bawah).map(category => {
                                            return (
                                                <Col onClick={handleLoading} key={category.mcat_id} className="items">
                                                    <Link to={{pathname: `/ProductListCategory/${category.mcat_id}`, state: { layerKey1: 1 }}}>
                                                        <img style={{ borderRadius: 5 }} src={category.mcat_img} alt="" width="80" height="80"/>
                                                        <span className="item-span">{category.mcat_name}</span>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </>
                        ) :
                        (
                            <>
                                <Row style={{ flexWrap: 'nowrap', marginLeft: 15 }}>
                                    {
                                        dataAtasEqual(equal).map(category => {
                                            return (
                                                <Col onClick={handleLoading} key={category.mcat_id} className="items">
                                                    <Link to={{pathname: `/ProductListCategory/${category.mcat_id}`, state: { layerKey1: 1 }}}>
                                                        <img style={{ borderRadius: 5 }} src={category.mcat_img} alt="" width="80" height="80"/>
                                                        <span className="item-span">{category.mcat_name}</span>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                                <Row style={{ flexWrap: 'nowrap', marginLeft: 15}}>
                                    {
                                        dataBawahEqual(equal).map(category => {
                                            return (
                                                <Col onClick={handleLoading} key={category.mcat_id} className="items">
                                                    <Link to={{pathname: `/ProductListCategory/${category.mcat_id}`, state: { layerKey1: 1 }}}>
                                                        <img style={{ borderRadius: 5 }} src={category.mcat_img} alt="" width="80" height="80"/>
                                                        <span className="item-span">{category.mcat_name}</span>
                                                    </Link>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </>
                        )
                    }
                </div>
                <div className="break-page"></div>
            </div>
        )
    }

}

export default Category
