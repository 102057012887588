import React from 'react';
import LoginPage from '../components/LoginPage/Login';
import Header from '../components/MenuPage/HeaderWithoutClose';
import '../App.css';

function Login() {
    return(
        <div className="App container body-content mobile-view">
            <Header/>
            <LoginPage/>
        </div>
    )
}

export default Login