import React from 'react'
import Header2 from '../components/Header2'
import iconHistoryKosong from '../Asset/Icon/history_kosong.png'

function RiwayatPesanan() {
    return (
        <div className="container body-content mobile-view" style={{ height: '81vh' }}>
            <Header2 />
            <div className="row" style={{ marginTop: 83, paddingLeft: 9, paddingRight: 9, zIndex: 0, overflowY: 'hidden' }}>
                <p style={{ color: '#393939', fontSize: 18, fontFamily: 'Montserrat', fontWeight: 600, padding: '0px 0px 8px 0px', textAlign: 'center' }}>
                    Riwayat Pesanan
                </p>
                <div>
                    <div style={{ textAlign: 'center', marginTop: 31 }}>
                        <img src={iconHistoryKosong} alt="" width="102px" height="108px" />
                        <p style={{ color: '#393939', fontSize: 16, fontWeight: 600, marginTop: 13.5 }}>
                            Belum ada Pesanan
                        </p>
                        <p style={{ fontFamily: "Open Sans", color: '#848484', fontSize: 13, fontWeight: 'normal', padding: '0px 63px 0px 63px' }}>
                            Semua pesanan Anda akan ditampilkan disini. Yuk,
                            cobain belanja produk di ezeePasar!
                        </p>
                        <button className="btn ez-btn-green" style={{ width: '75%', borderRadius: 8, fontWeight: 'bold', fontSize: 14, alignItems: 'center', height: 50, marginTop: 20 }}>
                            Belanja Sekarang
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RiwayatPesanan
