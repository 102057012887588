import { FETCH_DATA_BANNER_HOME } from "../ActionType/actionTypes"

const initialState = {
    banners: []
}

const bannersReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_BANNER_HOME) {
        return { ...state, banners: action.payload.banners }
    } else {
        return state
    }
}

export default bannersReducer