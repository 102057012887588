import { IS_LOADING_PROMOPAGE_MENU } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingPromoPageMenu: true
}

const isLoadingPromoPageReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_PROMOPAGE_MENU) {
        return { ...state, isLoadingPromoPageMenu: action.payload.isLoadingPromoPageMenu }
    } else {
        return state
    }
}

export default isLoadingPromoPageReducer