import React from 'react';
import {Row, Col, Modal} from 'antd'
import noInternetImg from '../../../Asset/Icon/nointernet.svg'

function NoInternetModal({isOpen, onClosed}) {
    const reloadPage = () => {
        window.location.reload();
    }
  return(
      <div>
          <Modal 
            visible={isOpen}
            className="modalAddCart modalPaymentMethod" 
            style={{borderRadius: 15, zIndex: 99999}}  
            footer={null}
            onClosed={onClosed}
          >
              <div style={{textAlign: 'center', paddingTop: 20}}>
                  <img src={noInternetImg} alt="" />
              </div>
              <br/>
                <Row>
                    <Col span={24}>
                        <div>
                            <button className='modal-button-cashback' onClick={() => reloadPage()}>
                                <span className='spanCart'>
                                    Coba Lagi
                                </span>
                            </button>
                        </div>
                    </Col>
                </Row>
          </Modal>
      </div>
  )
}

export default NoInternetModal;
