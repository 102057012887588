import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import FooterWA from '../components/HomePage/FooterSide'
import ReCAPTCHA from 'react-google-recaptcha'
import './HubungiKami.css'
import iconWhatsappGrey from '../Asset/Icon/whatsapp_grey.svg'
import iconInstagramGreen from '../Asset/Icon/Instagram_green.svg'
import iconFBGreen from '../Asset/Icon/FB_green.svg'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileDetail } from '../redux/ActionCreators/profileDetailActions'
import encryptData from '../function/encryptData'
import base64Sign from '../function/base64Sign'
import { getToken } from '../utils/setSessionUser'
import axios from 'axios'
import { BaseURL } from '../function/helpers'
import { useHistory } from 'react-router-dom'
import $ from 'jquery'

function HubungiKami() {

    const dispatch = useDispatch()
    const history = useHistory()
    const access_token = getToken()
    const profileDetail = useSelector(state => state.profileDetailReducer.profileDetail)
    const isLoadingProfDetail = useSelector(state => state.isLoadingProfileDetailReducer.isLoadingProfileDetail)

    const [inputHandle, setInputHandle] = useState({
        frm_nama: profileDetail.customer_name,
        frm_email: profileDetail.customer_email,
        frm_hp: profileDetail.customer_mobile,
        frm_keperluan: 'Pelayanan - Pertanyaan Umum',
        frm_pesan: '',
    });

    function handleChange(e) {
        setInputHandle({
            ...inputHandle,
            [e.target.name] : e.target.value
        })
    }

    function handleChangeReCaptcha(value) {
    }

    function instagramHandler() {
        window.open("https://www.instagram.com/ezeepasar/", "_blank")
    }

    function fbHandler() {
        window.open("https://www.facebook.com/ezeePasar/", "_blank")
    }

    function handleClickKirimPesan(nama, email, hp, keperluan, pesan) {
        if (nama === undefined) {
            nama = profileDetail.customer_name
        }
        if (email === undefined) {
            email = profileDetail.customer_email
        }
        if (hp === undefined) {
            hp = profileDetail.customer_mobile
        }

        const param = encryptData(`{
            "hubkami_name":"${nama}",
            "hubkami_email":"${email}",
            "hubkami_mobile":"${hp}",
            "hubkami_keperluan":"${keperluan}",
            "hubkami_pesan":"${pesan}"
        }`)
        const signature = base64Sign("/dashboard/contactus")
        // const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature
        }
        axios.post(BaseURL + "/dashboard/contactus", {data: param}, {headers : headers})
            .then(contactUs => {
                if (contactUs.data.response_data.error_id === "0000") {                    
                    dispatch( fetchProfileDetail("/profile/getprofile") )
                    setInputHandle({
                        frm_nama: profileDetail.customer_name,
                        frm_email: profileDetail.customer_email,
                        frm_hp: profileDetail.customer_mobile,
                        frm_keperluan: 'Pelayanan - Pertanyaan Umum',
                        frm_pesan: '',});
                        window.grecaptcha.reset();
                        window.scrollTo(0, 0);
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    useEffect(() => {
        dispatch( fetchProfileDetail("/profile/getprofile") )
    }, [dispatch]);

    return (
        <div className="container body-content mobile-view" style={{ textAlign: 'center', height: 1470 }}>
            <NavbarWithMenuIcon link="/back"/>
            <FooterWA />
            <div style={{ marginTop: 20 }}>
                <div>
                    <span className="head-2">Hubungi Kami</span>
                </div>
                <div style={{ marginTop: 20 }}>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1098.6465624505504!2d106.81256399794883!3d-6.164029293421183!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e69f6772884e3d7%3A0xfcef9995f20dfd47!2sWisma%20Techking%202!5e0!3m2!1sid!2sid!4v1589501416671!5m2!1sid!2sid"
                                title="gmap_canvas" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"
                                style={{ width: '100%', height: 200 }}
                            ></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <Row style={{ marginTop: 20 }}>
                <Col span={24}>
                    <span className="head-2">Kantor</span>
                </Col>
                <Col span={24}>
                    <span style={{ fontSize: 10, color: '#848484' }}>
                        Jl. AM Sangaji No. 24 - Wisma Techking 2
                        <br />
                        Petojo Utara, Kecamatan Gambir, Kota Jakarta Pusat
                        <br />
                        DKI Jakarta 10130
                        <br />
                        <span style={{ marginTop: 10, color: '#393939' }}>PT. Ezee Tuntas Sejahtera</span>
                        <br />
                        <a href="mailto:info@ezeepasar.com" style={{ color: "#393939" }}>info@ezeepasar.com</a>
                    </span>
                </Col>
            </Row>
            <div className="main-hubungi-kami" style={{ minHeight: 'unset', textAlign: 'left', marginTop: -10, paddingLeft: 25 }}>
                {
                    (!access_token || isLoadingProfDetail) ?
                    <form>
                        <span className="head-3" style={{ color: '#393939' }}>Data Pengiriman</span>
                        <div className="form-grup" style={{ marginTop: 18 }}>
                            <label style={{ marginBottom: 0 }}>Nama *</label>
                            <input style={{ border: '1px solid #C4C4C4', width: '97%' }} type="text" className="form-control-hub" id="frm_nama" name="frm_nama"
                                placeholder="Nama" onChange={handleChange} />
                            <div className="placeholders">Contoh: John Doe</div>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>Alamat email *</label>
                            <input style={{ border: '1px solid #C4C4C4', width: '97%' }} type="email" className="form-control-hub" id="frm_email" name="frm_email"
                                placeholder="Alamat email" onChange={handleChange} />
                            <div className="placeholders">Contoh: johndoe@ezeepasar.co.id</div>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>No.Telepon</label>
                            <input style={{ border: '1px solid #C4C4C4', width: '97%' }} type="number" className="form-control-hub" id="frm_hp" name="frm_hp"
                                placeholder="No. Handphone / Whatsapp" onChange={handleChange} />
                            <div className="placeholders">Contoh: 08123456789</div>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>Keperluan</label>
                            <select className="form-control-hub" id="frm_keperluan" name="frm_keperluan" onChange={handleChange}>
                                <option>Pelayanan - Pertanyaan Umum</option>
                                <option>Pelayanan - Laporan & Komplain</option>
                                <option>Kerjasama Usaha - Supplier</option>
                                <option>Kerjasama Usaha - Agen</option>
                                <option>Media</option>
                                <option>Lainnya</option>
                            </select>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>Pesan *</label>
                            <textarea style={{ color: '#848484' }} className="form-control-hub" id="frm_pesan" name="frm_pesan" placeholder="Pesan" onChange={handleChange} />
                            <div className="placeholders">Contoh: Berapa lama proses pengiriman barang?</div>
                        </div>
                        <div style={{ marginTop: 20, textAlign: 'center', marginLeft: '-4%' }}>
                            <ReCAPTCHA
                                sitekey="6LdFB6QZAAAAAJ2opuq4OHbPVzIp4XGuw2awArIn"
                                onChange={handleChangeReCaptcha}
                                className="recaptcha-hubungi-kami"
                            />
                        </div>
                        <div style={{ marginTop: 40, textAlign: 'center' }}>
                            <button onClick={() => handleClickKirimPesan(inputHandle.frm_nama, inputHandle.frm_email, inputHandle.frm_hp, inputHandle.frm_keperluan, inputHandle.frm_pesan)} type="button" className='btn btn-custom btn-lg btn-success-hubungi-kami' disabled={!inputHandle.frm_pesan || inputHandle.frm_nama.length === 0 || inputHandle.frm_email.length === 0 || inputHandle.frm_hp.length === 0}>
                                Kirim Pesan
                            </button>
                        </div>
                    </form> :
                    <form>
                        <span className="head-3" style={{ color: '#393939' }}>Data Pengiriman</span>
                        <div className="form-grup" style={{ marginTop: 18 }}>
                            <label style={{ marginBottom: 0 }}>Nama *</label>
                            <input style={{ border: '1px solid #C4C4C4', width: '97%' }} type="text" className="form-control-hub" id="frm_nama" name="frm_nama"
                                placeholder="Nama" value={(inputHandle.frm_nama === undefined) ? profileDetail.customer_name : inputHandle.frm_nama} onChange={handleChange} />
                            <div className="placeholders">Contoh: John Doe</div>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>Alamat email *</label>
                            <input style={{ border: '1px solid #C4C4C4', width: '97%' }} type="email" className="form-control-hub" id="frm_email" name="frm_email"
                                placeholder="Alamat email" value={(inputHandle.frm_email === undefined) ? profileDetail.customer_email : inputHandle.frm_email} onChange={handleChange} />
                            <div className="placeholders">Contoh: johndoe@ezeepasar.co.id</div>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>No.Telepon</label>
                            <input style={{ border: '1px solid #C4C4C4', width: '97%' }} type="number" className="form-control-hub" id="frm_hp" name="frm_hp"
                                placeholder="No. Handphone / Whatsapp" value={(inputHandle.frm_hp === undefined) ? profileDetail.customer_mobile : inputHandle.frm_hp} onChange={handleChange} />
                            <div className="placeholders">Contoh: 08123456789</div>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>Keperluan</label>
                            <select className="form-control-hub" id="frm_keperluan" name="frm_keperluan" value={inputHandle.frm_keperluan} onChange={handleChange}>
                                <option value='Pelayanan - Pertanyaan Umum'>Pelayanan - Pertanyaan Umum</option>
                                <option value='Pelayanan - Laporan & Komplain'>Pelayanan - Laporan & Komplain</option>
                                <option value='Kerjasama Usaha - Supplier'>Kerjasama Usaha - Supplier</option>
                                <option value='Kerjasama Usaha - Agen'>Kerjasama Usaha - Agen</option>
                                <option value='Media'>Media</option>
                                <option value='Lainnya'>Lainnya</option>
                            </select>
                        </div>
                        <div className="form-grup">
                            <label style={{ marginBottom: 0 }}>Pesan *</label>
                            <textarea style={{ color: '#848484' }} className="form-control-hub" id="frm_pesan" name="frm_pesan" placeholder="Pesan"value={inputHandle.frm_pesan} onChange={handleChange} />
                            <div className="placeholders">Contoh: Berapa lama proses pengiriman barang?</div>
                        </div>
                        <div style={{ marginTop: 20, textAlign: 'center', marginLeft: '-4%' }}>
                            <ReCAPTCHA
                                sitekey="6LdFB6QZAAAAAJ2opuq4OHbPVzIp4XGuw2awArIn"
                                onChange={handleChangeReCaptcha}
                                className="recaptcha-hubungi-kami"
                            />
                        </div>
                        <div style={{ marginTop: 40, textAlign: 'center' }}>
                            <button onClick={() => handleClickKirimPesan(inputHandle.frm_nama, inputHandle.frm_email, inputHandle.frm_hp, inputHandle.frm_keperluan, inputHandle.frm_pesan)} type="button" className='btn btn-custom btn-lg btn-success-hubungi-kami' disabled={!inputHandle.frm_pesan || inputHandle.frm_nama === undefined || inputHandle.frm_email === undefined || inputHandle.frm_hp === undefined}>
                                Kirim Pesan
                            </button>
                        </div>
                    </form>
                }
            </div>
            <div>
                <Row style={{ justifyContent: 'center' }}>
                    <Col span={24} style={{ marginBottom: 10 }}>
                        <span className="head-about">
                            Bantuan Pelayanan
                        </span>
                    </Col>
                    <Col span={24}>
                        <span>
                            <img src={iconWhatsappGrey} alt="" style={{ marginRight: 8 }} />
                            <span style={{ color: '#848484' }}>
                                +62817844931
                            </span>
                        </span>
                    </Col>
                </Row>
                <Row style={{ justifyContent: 'center', marginTop: 15 }}>
                    <Col span={24}>
                        <span className="head-about">Informasi Terkini</span>
                    </Col>
                    <Col span={12} style={{ cursor: 'pointer' }} onClick={instagramHandler}>
                        <span style={{ color: '#3DB54A', fontSize: 15 }}>
                            <img src={iconInstagramGreen} alt="" width="70px" />
                            ezeePasar
                        </span>
                    </Col>
                    <Col span={12} style={{ cursor: 'pointer' }} onClick={fbHandler}>
                    <span style={{ color: '#3DB54A', fontSize: 15 }}>
                            <img src={iconFBGreen} alt="" width="70px" />
                            ezeePasar
                        </span>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default HubungiKami
