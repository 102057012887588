import React, {useEffect, useState}from 'react';
import { Offline, Online, Detector} from "react-detect-offline";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

import Home from './pages/Home';
import MenuPage from './pages/Menu';
import Product from './pages/Produk';
import HowToShopPage from './pages/HowToShop';
import AgenPage from './pages/Agen';
import TentangKamiPage from './pages/TentangKami';
import KebijakanPrivasiPage from './pages/KebijakanPrivasi';
import HubungiKamiPage from './pages/HubungiKami';
import Login from './pages/Login';
import Register from './pages/Register';
import FormRegister from './pages/FormRegister';
import ForgotPassword from './pages/ForgotPassword';
import Banner from './pages/Banner';
import { createBrowserHistory } from 'history'
import ProductOther from './pages/ProductOther';
import Cart from './pages/Cart';
import ProductListCategory from './pages/ProductListCategory';
import Profile from './pages/Profile';
import DataPribadi from './pages/DataPribadi';
import RiwayatPesanan from './pages/RiwayatPesanan';
import AlamatKirim from './pages/AlamatKirim';
import Wishlist from './pages/Wishlist';
import Disclaimer from './pages/Disclaimer';
import NotifikasiBrowser from './pages/NotifikasiBrowser';
import ChangePassword from './pages/ChangePassword';
import Promo from './pages/Promo';
import ProductListCategory2 from './pages/ProductListCategory2';
import FormAddress from './pages/FormAddress';
import Checkout from './pages/Checkout';
import DeliveryMethod from './pages/DeliveryMethod';
import PromoCode from './pages/PromoCode';
import PromoCodeDetail from './pages/PromoCodeDetail';
import EzeetunaiPayment from './pages/Payment/Ezeetunai';
import PaymentSuccess from './pages/Payment/PaymentSuccess';
import VirtualAccount from './pages/Payment/VirtualAcc';
import Gopay from './pages/Payment/Gopay';
import ShopeePay from './pages/Payment/ShopeePay';
import HistoryOrder from './pages/HistoryOrder';
import HistoryDetailPage from './pages/HistoryDetail';
import Notification from './pages/Notification';
import TopUpEzeeTunai from './pages/EzeeTunai/TopUpEzeeTunai';
import ProcessTopUpEzeeTunai from  './pages/EzeeTunai/ProcessTopUpEzeeTunai'
import TopUpEzeeTunaiHeadOffice from './pages/EzeeTunai/TopUpHeadOffice'
import HowToEzeetunaiAct from './pages/EzeeTunai/HowToEzeetunaiAct';
import NotificationDetail from './pages/NotificationDetail';
import WithPageView from './googleAnalytics/WithPageView';
import NoInternetModal from '../src/components/PopUpModal/HomePage/NoInternetModal'
import Search from './pages/Search'
import SearchAddress from './pages/SearchAddress';
import Webview from './pages/Webview';
import ScrollToTop from '../src/function/ScrollToTop'
import './App.css';
import VerifikasiEmail from './pages/VerifikasiEmail';
import EmailChangePassword from './pages/EmailChangePassword';
import axios from 'axios'
import { checkoutUserSession, setUserSession, removeUserSession} from './utils/setSessionUser';
import { BaseURL, headersWithoutToken} from './function/helpers';
import base64Sign from './function/base64Sign';
import encryptData from './function/encryptData';
import AgentCodeSuccessModal from './components/PopUpModal/HomePage/AgentCodeSuccessModal';
import LaunchMobileApp from './pages/LaunchMobileApp';
import NoMatch from './pages/NoMatch';
import InternalServerError from './pages/InternalServerError';
import RequestTimeOutModal from './components/PopUpModal/HomePage/RequestTimeOutModal';
import MetodePembayaran from './pages/MetodePembayaran';
import EzeeTunaiBinding from './pages/EzeeTunai/EzeeTunaiBinding';
import EzeeTunaiBinded from './pages/EzeeTunai/EzeeTunaiBinded';

const history = createBrowserHistory();

function App() {

  const [isOnline, setIsOnline] = useState(false);
  const [isAgentModal, setIsAgentModal] = useState({status: false, data: ''});
  const [isRTO, setIsRTO] = useState(false)
  let History = useHistory();

  useEffect(() => {
    window.scrollTo(0,0);
    window.addEventListener("offline", (event) => {
      setIsOnline(true);
    
  });
  // window.localStorage.setItem('promopage', '123123123');
  window.addEventListener("online", (event) => {
      setIsOnline(false);
  });

  window.onbeforeunload = function() {
    checkoutUserSession.setAgentCode("")
  }

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
  if (error.response.status === 401) {
      history.push('/login')
      removeUserSession();
      window.location.reload();
  } else if (error.response.status === 408) {
    // console.log('masuk 408 RTO');
    setIsRTO(true)
  }
  return error;
  });

    if(window.location.href.indexOf("belanja") > -1){
      checkUrlAgent();
    }
  } ,[]);

  function checkUrlAgent(){
    var catPromo = window.location.pathname.split("/")[2];
    var agentId = window.location.pathname.split("/")[3];
    var agenPromoCode = window.location.pathname.split("/")[4];
    checkAgentPromo(catPromo,agentId,agenPromoCode);
  }

  async function checkAgentPromo(promocode, promocategory, categorycode) {
    try{
        const signature = base64Sign("/promo/parsingpromo"); 
        const dataParams = encryptData(`{"promo_category":"${promocode}", "category_code":"${promocategory}", "promo_code":"${categorycode}"}`)           
        const headers = headersWithoutToken(signature);
        const data = await axios.post(BaseURL + '/promo/parsingpromo',{data: dataParams},{headers : headers});
        if(data.status === 200){
          var datas = data.data.response_data;
          var promoCode = window.location.pathname.split("/")[4];
          checkoutUserSession.setAgentCode(promoCode)
          sessionStorage.setItem("agentCode", promoCode)
          window.history.pushState(null, null, "/");
          setIsAgentModal({status: true, data: datas.error_text})
        }else{
          window.history.pushState(null, null, "/");
        }                
    }catch(err){
        console.log(err);
    }
}

  axios.interceptors.response.use(response => {
    return response;
  }, error => {
  if (error.response.status === 401) {
      history.push('/login')
      removeUserSession();
      window.location.reload();
  } else if (error.response.status === 408) {
    // console.log('masuk 408 RTO');
      setIsRTO(true)
  }
  return error;
  });

  return (
    <div>
        <Router history={history}>
          <ScrollToTop />
            <Switch>
              <Route exact path="/" component={WithPageView(Home)} />
              <Route path="/belanja/:promo_category/:category_code/:promo_code" component={WithPageView(Home)}/>
              <Route path="/login" component={WithPageView(Login)} />
              <Route path="/forgotpassword" component={WithPageView(ForgotPassword)} />
              <Route path="/register" component={WithPageView(Register)} />
              <Route path="/formregister" component={WithPageView(FormRegister)} />
              <Route path="/menu" component={WithPageView(MenuPage)} />
              <Route path="/notification" component={WithPageView(Notification)} />
              <Route path="/cart" component={WithPageView(Cart)}/>
              <Route path="/checkout" component={WithPageView(Checkout)} />
              <Route path="/deliverymethod" component={WithPageView(DeliveryMethod)} />
              <Route path="/promocode" component={WithPageView(PromoCode)} />
              <Route path="/promo-code-detail" component={WithPageView(PromoCodeDetail)} />
              <Route path="/payment/ezeetunai" component={WithPageView(EzeetunaiPayment)} /> 
              <Route path="/EzeeTunaiSnap/CheckStatusPayment/:orderId" component={WithPageView(PaymentSuccess)} />
              {/* <Route path="/payment/finishpayment" component={WithPageView(PaymentSuccess)} /> */}
              <Route path="/payment/virtualaccount" component={WithPageView(VirtualAccount)} />
              <Route path="/payment/gopay" component={WithPageView(Gopay)} />
              <Route path="/payment/shopeepay" component={WithPageView(ShopeePay)} />
              <Route path="/banner/mbanner_id=:id" component={WithPageView(Banner)} />
              <Route exact path="/produk/:id/:mprod_name" component={WithPageView(Product)} />
              <Route path="/produk/ProductOther/:id/:title" component={WithPageView(ProductOther)} />
              <Route path="/RiwayatPesanan" component={WithPageView(HistoryOrder)} />
              <Route exact path="/RiwayatPesananDetail/:id" component={WithPageView(HistoryDetailPage)} />
              <Route path="/CaraBerbelanja" component={WithPageView(HowToShopPage)} />
              <Route path="/AgenPage" component={WithPageView(AgenPage)} />
              <Route path="/TentangKami" component={WithPageView(TentangKamiPage)} />
              <Route path="/KebijakanPrivasiIndex" component={WithPageView(KebijakanPrivasiPage)} />
              <Route path="/MetodePembayaran" component={WithPageView(MetodePembayaran)} />
              <Route path="/HubungiKami" component={WithPageView(HubungiKamiPage)} />
              <Route exact path="/ProductListCategory/:catId" component={WithPageView(ProductListCategory)} />
              <Route exact path="/ProdListCategory/cat_1=:catId&layer_1=:layerId&cat_2=:catId2&layer_2=:layerId2" component={WithPageView(ProductListCategory2)} />
              <Route exact path="/profile" component={WithPageView(Profile)} />
              <Route path="/DataPribadi" component={WithPageView(DataPribadi)} />
              <Route path="/AlamatKirim" component={WithPageView(AlamatKirim)} />
              <Route path="/WishList" component={WithPageView(Wishlist)} />
              <Route path="/Disclaimer" component={WithPageView(Disclaimer)} />
              <Route path="/NotifikasiDetail" component={WithPageView(NotificationDetail)} />
              <Route path="/Notifikasi" component={WithPageView(NotifikasiBrowser)} />
              <Route path="/ChangePassword" component={WithPageView(ChangePassword)} />
              <Route path="/Promo" component={WithPageView(Promo)} />
              <Route path="/FormAddress" component={WithPageView(FormAddress)} />
              <Route path="/EzeeTunaiBinding" component={WithPageView(EzeeTunaiBinding)} />
              <Route path="/EzeeTunaiSnap/CheckStatusBinding" component={WithPageView(EzeeTunaiBinded)} />
              <Route path="/EzeeTunai/TopUp" component={WithPageView(TopUpEzeeTunai)} />
              <Route path="/EzeeTunai/ProcessTopUp" component={WithPageView(ProcessTopUpEzeeTunai)} />
              <Route path="/EzeeTunai/ProcessTopUpHeadOffice" component={WithPageView(TopUpEzeeTunaiHeadOffice)} />
              <Route path="/EzeeTunai/HowToActivation" component={WithPageView(HowToEzeetunaiAct)} />
              <Route path="/Search" component={WithPageView(Search)} />
              <Route path="/SearchAddress" component={WithPageView(SearchAddress)} />
              <Route exact path="/profile/verifyEmail" component={WithPageView(VerifikasiEmail)} />
              <Route exact path="/profile/changepassword" component={WithPageView(EmailChangePassword)} />
              <Route exact path="/webView/:page" component={WithPageView(Webview)}/>
              <Route exact path="/app" component={WithPageView(LaunchMobileApp)} />
              <Route path="/500" component={WithPageView(InternalServerError)} />
              <Route path="*" component={WithPageView(NoMatch)} />
            </Switch>
          {/* </ScrollToTop> */}
        </Router>
        <NoInternetModal isOpen={isOnline} onClosed={() => setIsOnline(false)}/>
        <AgentCodeSuccessModal isOpen={isAgentModal.status} onClosed={() => setIsAgentModal({status : false, data: ''})} data={isAgentModal.data}/>
        <RequestTimeOutModal isOpen={isRTO} onClosed={() => (setIsRTO(false))} />
    </div>
  );
}

export default App;
