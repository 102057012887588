import { IS_LOADING_MOSTSEARCHPROD_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingMostSearchProdHome: true
}

const isLoadingMostSearchProdReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_MOSTSEARCHPROD_HOME) {
        return { ...state, isLoadingMostSearchProdHome: action.payload.isLoadingMostSearchProdHome }
    } else {
        return state
    }
}

export default isLoadingMostSearchProdReducer