import React,{useState} from 'react'
import { Link, useHistory, useLocation} from 'react-router-dom'
import {Row, Col, Layout, message} from 'antd';
import { checkoutUserSession } from '../../utils/setSessionUser';
import $ from 'jquery'
import './OnePageCheckout.css'
import { headersWithToken, BaseURL} from '../../function/helpers';
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import encryptData from '../../function/encryptData';
import { getToken } from '../../utils/setSessionUser';
import ErrorPaymentModal from '../PopUpModal/CheckoutPage/ErrorPaymentModal'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import {CashbackIsEmpty} from '../PopUpModal/CheckoutPage/CashbackModal'
import TimeFrameNotAvailModal from '../PopUpModal/CheckoutPage/TimeFrameNotAvailModal'
import TimeFrameIsNotExist from '../PopUpModal/CheckoutPage/TimeFrameIsNotExist';

const {Footer} = Layout
function FooterCheckout({ paymentMethodId, harga, addressId, dateKirim ,promoCode, ekspedisiId, is_cashback, timeFrame, agentcode, removeCashbackSnackbar, btnIsDisabled}) {  
    let history = useHistory();
    let access_token = getToken();
    let location = useLocation();
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState({status : false, message : ''});
    const [cashbackIsEmpty, setCashbackIsEmpty] = useState({visible : false, data : ''});       
    const [timeFrameNotAvailModal, setTimeFrameNotAvailModal] = useState(false);
    const [timeFrameNotExistModal, setTimeFrameNotExistModal] = useState(false);
    const [timeFrameValue, setTimeFrameValue] = useState(timeFrame);
    const [isCashback,  setIsCashback] = useState(is_cashback);
    const [continuePayment, setContinuePayment] = useState(false);

    useState(() => {
        if(location.state !== undefined){
            if(location.state.cashbackIsEmpty){
                setCashbackIsEmpty({visible : true, cashbackError : location.state.cashbackError}) 
            }else if(location.state.timeFrameNotAvail){
                setTimeFrameNotAvailModal(true)
            }else if(location.state.timeFrameNotExist){
                setTimeFrameNotExistModal(true)
            }
        }

        if(timeFrame === undefined || timeFrame === "-1"){timeFrame = 0;}
    },[])

    function error(messages){
        message.error({
            content: messages,
            className: 'checkoutSnackbarError',
            duration: 3
        });
      };

      async function paymentShopeeWeb() {
        try{
            const signature = base64Sign("/checkout/paymentshopeeqris"); 
            const dataParams = encryptData(`
            {"address_id":"${addressId}",
            "date_kirim": "${dateKirim}",
            "payment_id": "${paymentMethodId}",
            "promo_code": "${promoCode}",
            "expedisi_id" : "${ekspedisiId}",
            "is_cashback" : "${is_cashback}",
            "timeframe_id" : "${timeFrame}",
            "agen_code" : "${agentcode}"}`)           
            const headers = headersWithToken(signature , access_token);
            const data = await axios.post(BaseURL + '/checkout/paymentshopeeqris',{data: dataParams},{headers : headers});                     
            if(data.status === 200){
                var datas = data.data.response_data.results;
                history.push('/payment/shopeepay', {order_id : datas.order_id}); 
                checkoutUserSession.setPaymentIsHitAlready(true);
                checkoutUserSession.setOrderId(datas.request_id);
                sessionStorage.removeItem("agentCode");
            }else{                                
                if(data.response.status === 400){
                    let error = data.response.data.response_data;
                    if(error !== null){
                        if(error.error_id === '0169'){
                            setCashbackIsEmpty({visible : true, cashbackError : data.response.data.response_message})                          
                        }else if(error.error_id === '0070'){
                            setTimeFrameNotAvailModal(true)                            
                        }
                        else if(error.error_id === '0071'){
                            setTimeFrameNotExistModal(true);
                        }else{
                           setIsError({status : true, message : data.response.data.response_message}); 
                        }
                    }else{
                        setIsError({status : true, message : data.response.data.response_message});
                    }                
                    setLoading(false);
                }
            }
        }catch(err){
            setLoading(false);
            setIsError({status : true, message : err.response.data.response_message});            
        }
    }

    async function paymentShopeeMobile() {
        try{
            const signature = base64Sign("/checkout/paymentshopee"); 
            const dataParams = encryptData(`
            {"address_id":"${addressId}",
            "date_kirim": "${dateKirim}",
            "payment_id": "${paymentMethodId}",
            "promo_code": "${promoCode}",
            "expedisi_id" : "${ekspedisiId}",
            "is_cashback" : "${is_cashback}",
            "timeframe_id" : "${timeFrame}",
            "agen_code" : "${agentcode}"}`)           
            const headers = headersWithToken(signature , access_token);
            const data = await axios.post(BaseURL + '/checkout/paymentshopee',{data: dataParams},{headers : headers});
            if(data.status === 200){
                var datas = data.data.response_data.results;
                history.push('/payment/shopeepay', {order_id : datas.request_id});
                checkoutUserSession.setPaymentIsHitAlready(true);
                checkoutUserSession.setOrderId(datas.request_id);
                sessionStorage.removeItem("agentCode");
            }else{                
                if(data.response.status === 400){
                    let error = data.response.data.response_data;
                    if(error !== null){
                        if(error.error_id === '0169'){
                            setCashbackIsEmpty({visible : true, cashbackError : data.response.data.response_message})                          
                        }else if(error.error_id === '0070')
                        {
                            setTimeFrameNotAvailModal(true)                            
                        }else if(error.error_id === '0071'){
                            setTimeFrameNotExistModal(true);
                        }else{
                           setIsError({status : true, message : data.response.data.response_message}); 
                        }
                    }else{
                        setIsError({status : true, message : data.response.data.response_message});
                    }                
                    setLoading(false);
                }
            }
        }catch(err){
            setLoading(false);
            setIsError({status : true, message : err.response.data.response_message});   
        }
    }

    async function paymentVirtualAccount() {
        try{
            const signature = base64Sign("/checkout/paymentVABCA"); 
            const dataParams = encryptData(`
            {"address_id":"${addressId}",
            "date_kirim": "${dateKirim}",
            "payment_id": "${paymentMethodId}",
            "promo_code": "${promoCode}",
            "expedisi_id" : "${ekspedisiId}",
            "is_cashback" : "${is_cashback}",
            "timeframe_id" : "${timeFrame}",
            "agen_code" : "${agentcode}"}`)
            const headers = headersWithToken(signature, access_token)
            const data = await axios.post(BaseURL + '/checkout/paymentVABCA',{data: dataParams},{headers : headers});            
            if(data.status === 200){
                var datas = data.data.response_data.results;
                setLoading(false);
                history.push('/payment/virtualaccount', {vaDetail : {va_number : datas.va_number, company_code : datas.company_code, total_amount : datas.total_amount, tbcava_id : datas.tbcava_id, countDownSec : datas.countdownSec, paymentImg: datas.payment_img, order_id : datas.order_id}, vaInstruction : datas.payment_instruction});                
                sessionStorage.removeItem("agentCode");
            }else{                
                if(data.response.status === 400){
                    let error = data.response.data.response_data;
                    if(error !== null){
                        if(error.error_id === '0169'){
                            setCashbackIsEmpty({visible : true, cashbackError : data.response.data.response_message})                               
                        }else if(error.error_id === '0070'){
                            setTimeFrameNotAvailModal(true)                            
                        }else if(error.error_id === '0071'){
                            setTimeFrameNotExistModal(true);
                        }else{
                           setIsError({status : true, message : data.response.data.response_message}); 
                        }
                    }else{
                        setIsError({status : true, message : data.response.data.response_message});
                    }                
                    setLoading(false);
                }
            }            
        }catch(err){
            setLoading(false);
            setIsError({status : true, message : err.response.data.response_message});             
        }
    }

    async function paymentGopay() {
        try{
            const signature = base64Sign("/checkout/paymentgopay"); 
            const dataParams = encryptData(`
            {"address_id":"${addressId}",
            "date_kirim": "${dateKirim}",
            "payment_id": "${paymentMethodId}",
            "promo_code": "${promoCode}",
            "expedisi_id" : "${ekspedisiId}",
            "is_cashback" : "${is_cashback}",
            "timeframe_id" : "${timeFrame}",
            "agen_code" : "${agentcode}"}`)                
            const headers = headersWithToken(signature, access_token)
            const data = await axios.post(BaseURL + '/checkout/paymentgopay',{data: dataParams},{headers : headers});
            if(data.status === 200){
                var datas = data.data.response_data.results;
                setLoading(false);
                history.push('/payment/gopay', {tokenSnap : datas.token});       
                checkoutUserSession.setTokenSnapMidtrans(datas.token);
                checkoutUserSession.setPaymentIsHitAlready(true);
                sessionStorage.removeItem("agentCode");
            }else{                                
                if(data.response.status === 400){
                    let error = data.response.data.response_data;
                    if(error !== null){
                        if(error.error_id === '0169'){
                            setCashbackIsEmpty({visible : true, cashbackError : data.response.data.response_message})                               
                        }else if(error.error_id === '0070'){
                            setTimeFrameNotAvailModal(true)                            
                        }else if(error.error_id === '0071'){
                            setTimeFrameNotExistModal(true);
                        }else{
                           setIsError({status : true, message : data.response.data.response_message}); 
                        }
                    }else{
                        setIsError({status : true, message : data.response.data.response_message});
                    }                
                    setLoading(false);
                }              
            }
            
        }catch(err){
            setLoading(false);
            setIsError({status : true, message : err.response.data.response_message});   
        }
    }

    async function paymentEzTunai() {
        try {
            const signature = base64Sign("/checkout/PaymentEzeeTunai"); 
            const dataParams = encryptData(`
            {"address_id":"${addressId}",
            "date_kirim": "${dateKirim}",
            "payment_id": "${paymentMethodId}",
            "promo_code": "${promoCode}",
            "expedisi_id" : "${ekspedisiId}",
            "is_cashback" : "${is_cashback}",
            "timeframe_id" : "${timeFrame}",
            "agen_code" : "${agentcode}"}`)                
            const headers = headersWithToken(signature, access_token)
            const data = await axios.post(BaseURL + "/checkout/PaymentEzeeTunai", {data: dataParams}, {headers: headers})
            if (data.status === 200 && data.data.response_code === 200) {
                setLoading(false)
                window.location.replace(data.data.response_data.results.webRedirectUrl)
            }
        } catch (error) {
            console.log(error);
        }
    }

    function paymentCheck(){
        setLoading(true);
        if(addressId === 0){
            error("Alamat pengiriman harus dipilih");
            setLoading(false);
            return
        }else if(paymentMethodId === 0){
            error("Metode pembayaran harus dipilih");
            setLoading(false);
            return
        }

        checkoutUserSession.setcheckoutDataPaymentId(paymentMethodId);
        checkoutUserSession.setCheckoutDataHarga(harga);
        checkoutUserSession.setCheckoutDataAddressId(addressId);
        checkoutUserSession.setCheckoutDataDateKirim(dateKirim);
        checkoutUserSession.setCheckoutDataPromoCode(promoCode);
        checkoutUserSession.setCheckoutDataEkspedisiId(ekspedisiId);
        checkoutUserSession.setCheckoutDataIsCashback(is_cashback);
        checkoutUserSession.setTimeFrame(timeFrame);
        checkoutUserSession.setPaymentIsHitAlready(false);

        if(paymentMethodId === 100){
            paymentVirtualAccount();
        }else if(paymentMethodId === 101){  
            paymentGopay();         
        }else if(paymentMethodId === 103){            
            // history.push('/payment/ezeetunai', {price : harga, timeframe: timeFrame});
            paymentEzTunai()
        }else if(paymentMethodId === 104){
            if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
                paymentShopeeMobile();
            }else{
                paymentShopeeWeb();
            }       
        }else{
            alert("Metode Pembayaran Belum Tersedia")
        }
    }

    function callbackToParent(timeframe, flag){
       if(flag === 0){
           setTimeFrameNotExistModal(false)
           timeFrame = 0;
       }else{
           setTimeFrameNotAvailModal(false);
           setTimeFrameValue(timeframe.id);
           timeFrame = timeframe.id;
       }
       paymentCheck();
    }

    function onClosedTimeFrameNotAvailable(timeframe){
        setTimeFrameNotAvailModal(false);
        timeFrame = 0;
    }

    function onClosedTimeFrameNotExist(timeframe){
        setTimeFrameNotExistModal(false);
        timeFrame = 0;
    }

    function callbackPaymentStatus(status){
        setIsCashback(false);
        is_cashback = false;
        paymentCheck();
    }

    function closedCashbackModal(){
        setCashbackIsEmpty({visible : false, data : ''})
        checkoutUserSession.setCheckoutDataIsCashback(false);
        removeCashbackSnackbar(false)
    }

    function closedTimeFrameModal(){
        setTimeFrameNotAvailModal(false)
    }

    function closedTimeFrameNotExistModal(){
        setTimeFrameNotExistModal(false)               
    }

    if(loading){
        return(
            <LoadingModal/>
        )
    }


    return (
        <>
        <Layout style={{marginTop: 75}}>
            <Footer className="footerLayout" style={{fontFamily: 'Montserrat', height: 70, marginLeft: 2}}>                
                <Row>                
                    <Col span={14} style={{paddingRight: 5, paddingTop: 10}}>
                        <span style={{color: '#848484', fontSize: 14}}>Total Pembayaran</span><br/>
                        <span style={{color: '#F79226', fontSize: 15, fontWeight: 'bold'}}>Rp{harga}</span>
                    </Col>
                    <Col span={10} style={{paddingLeft: 5, paddingTop: 10}}>
                        <button className="btnBuy" onClick={!btnIsDisabled ? () => paymentCheck() : null} disabled={btnIsDisabled}>
                            <span className="spanCart">
                                <Link style={{color: "#fff", backgroundColor: 'transparent'}} className="spanCartLink">Buat Pesanan</Link>
                            </span>
                        </button>
                    </Col>
                </Row>
            </Footer>
        </Layout>
        {
            <ErrorPaymentModal isOpen={isError.status} onClosed={() => setIsError({status: false, message: ''})} data={isError.message} isListAddr={false}/>
        }
        <CashbackIsEmpty isOpen={cashbackIsEmpty.visible} onClosed={closedCashbackModal} callbackPaymentStatus={callbackPaymentStatus}/>
        <TimeFrameNotAvailModal isOpen={timeFrameNotAvailModal} onClosedTimeFrameNotAvailable={onClosedTimeFrameNotAvailable} addrId={addressId} date={dateKirim} ekspedisiId={ekspedisiId} callbackToParent = {callbackToParent} />
        <TimeFrameIsNotExist isOpen={timeFrameNotExistModal} onClosedTimeFrameNotExist={onClosedTimeFrameNotExist} addrId={addressId} date={dateKirim} callbackToParent={callbackToParent} />
        </>
    )
}

export default FooterCheckout
