import React, {useState, useEffect}from 'react'
import {Row, Col, Modal} from 'antd'
import timeFrameNotAvailableIcon from '../../../Asset/Icon/timeframeIcon.svg'
import { headersWithToken, BaseURL} from '../../../function/helpers';
import base64Sign from '../../../function/base64Sign';
import axios from 'axios';
import { getToken } from '../../../utils/setSessionUser';
import encryptData from '../../../function/encryptData';
import infoIcon from '../../../Asset/Icon/info_icon.svg'
import ActivateIcon from '../../../Asset/Icon/activate-icon.svg'
import './CheckoutModal.css'
import $ from 'jquery'

function TimeFrameNotAvailModal({isOpen, onClosedTimeFrameNotAvailable, addrId, date, ekspedisiId, callbackToParent}) {
  const [listTimeFrame, setListTimeFrame] = useState([]);
  const [LoadingTimeFrame, setLoadingTimeFrame] = useState(true);
  const [timeFrame, setTimeFrame] = useState({id : 0, time : ''});
  let access_token = getToken();

  useEffect(() => {
      getTimeFrame();
  } ,[])

  async function getTimeFrame() {
    let timeFrameData;
    const signature = base64Sign("/checkout/getlistexpedisibydate"); 
    const dataParams = encryptData(`{"address_id":${addrId}, "date_kirim": "${date}"}`);           
    const headers = headersWithToken(signature, access_token);
    axios
    .post(BaseURL + '/checkout/getlistexpedisibydate',{data:dataParams},{headers:headers}).then(data=>{           
        setLoadingTimeFrame(false)
        timeFrameData = data.data.response_data.results.find(q => q.expedisi_id === ekspedisiId);
        setListTimeFrame(timeFrameData.timeframe);       
        let firstIndexTimeFrame = timeFrameData.timeframe.find(q => q.is_available === true);
        setTimeFrame({id: firstIndexTimeFrame.id, time: firstIndexTimeFrame.name});
        $('#timeFrameBox_'+firstIndexTimeFrame.id).addClass('activated_border');
        $('#timeFrameIcon_'+firstIndexTimeFrame.id).attr('src', ActivateIcon);     
    }).catch(err => console.log(err));
}

    function chooseTimeFrame(id, value, isAvailable){
        if(isAvailable){
            let allEl = $('.time-frame-box');
            allEl.removeClass('activated_border');
            $('.timeframe-iconActivated').attr('src', '');
    
            $('#timeFrameBox_'+id).addClass('activated_border');
            $('#timeFrameIcon_'+id).attr('src', ActivateIcon); 
            setTimeFrame({id: id, time: value});
        }
    }

    function callbackToParentData(){
        callbackToParent(timeFrame, 1);
    }

    function callbackClosedModal(){
        onClosedTimeFrameNotAvailable(0);
    }

    var isNotAvailable = listTimeFrame.find(q => q.is_available === false)
  return (
    <div>
        <Modal
          visible={isOpen}
          className="modalAddCart modalCashback" 
          style={{borderRadius: 15}}  
          footer={
          <>
                <Row>
                  <Col span={24}>
                      <div>
                          <button className='modal-button-cashback' onClick={() => callbackToParentData()}>
                              <span className='spanCart'>
                                  Buat Pesanan
                              </span>
                          </button>
                      </div>
                  </Col>
              </Row>
          </>
          }
          onClosed={callbackClosedModal}
          onCancel={callbackClosedModal}
      >
          <div style={{textAlign: 'center'}}>
              <Row>
                  <Col span={24}>
                      <img src={timeFrameNotAvailableIcon} alt=""/>
                  </Col>
              </Row>
              <br/>
              <Row>
                  <Col span={24}>
                      <div>
                          <p className='title-cashback-modal'>Yah, Kuota Telah Habis. Tetap Lanjutkan Pesanan?</p>
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col span={24}>
                      <div>
                          <p className='sub-title-cashback-modal'>Slot waktu kirim yang kamu pilih sudah penuh, silahkan pilih waktu kirim lain yang masih tersedia</p>
                      </div>
                  </Col>
              </Row>
              <br/>
              <Row>
                  <Col span={24}>
                      <div>
                          <p className='sub-title-modal-bold' style={{float: 'left'}}>Pilih Waktu Pengiriman</p>
                      </div>
                  </Col>
              </Row>
              <Row style={{maxWidth: 300, marginLeft: '10%'}}>
                  <Col span={24}>
                   {LoadingTimeFrame ? <></> : 
                                [listTimeFrame.length === 0 ? <></> : 
                                <>
                                <div className='time-frame-section'>                                   
                                    <div style={{flexWrap: 'wrap', placeContent: 'center', width: '115%', marginTop: -25}}>
                                        {
                                            listTimeFrame.map((item, index) => {
                                                return(
                                                <div className="time-frame-box" key={item.id} id={"timeFrameBox_"+item.id} onClick={() => chooseTimeFrame(item.id, item.name, item.is_available)} style={item.is_available ? null : {backgroundColor: '#F4F4F4'}}>                                        
                                                    <p style={item.is_available ? {fontWeight: 600, fontSize: 11, marginTop: '-10px'} : {fontWeight: 600, fontSize: 11, marginTop: '-10px', color: '#848484'}} className='time-frame-time'>
                                                        {item.name}                                        
                                                    </p>
                                                    <div style={{float: 'right', marginRight: 24}}>
                                                        <img src="" className="timeframe-iconActivated" alt="" style={{marginTop: -35}} id={"timeFrameIcon_"+item.id}/>
                                                    </div>
                                                </div>
                                                )
                                            })
                                        }
                                    </div>                                            
                                </div>
                                </>]
                        }
                  </Col>
              </Row>
              <br/>
              {
                isNotAvailable !== undefined ? <span className="title-hint" style={{marginLeft: 15, float: 'left'}}><img src={infoIcon} alt=""/> Slot waktu kirim yang penuh tidak dapat dipilih</span> : null
              }
        </div>
      </Modal>
    </div>
  )
}

export default TimeFrameNotAvailModal