import { IS_LOADING_PROMOPAGE_MENU } from "../../ActionType/actionTypes";

export function isLoadingPromoPageMenu(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_PROMOPAGE_MENU, 
            payload: {
                isLoadingPromoPageMenu: param
            }
        })
    }
}