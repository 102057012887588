import {IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_EZEETUNAI_SUCCESS_ACTIVATION} from "../../ActionType/actionTypes";

const initialState = {
    data : {},
    loading : true
}

const ezeeTunaiGetSuccessActivationReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST : 
        return{
            ...state,
            loading: true
        }
        case FETCH_EZEETUNAI_SUCCESS_ACTIVATION : 
        return{
            ...state,
            loading: false,
            data : action.payload 
        }
        case IS_NEW_LOADING : 
        return{ 
            ...state,           
            loading : true
        }
        default : return state
    }
}

export default ezeeTunaiGetSuccessActivationReducer