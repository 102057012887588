import React, {useEffect, useState} from 'react'
import {NavbarWithArrowBack} from '../components/Properties/Navbar/Navbar'
import Body from '../components/ProfilePage/Body'
import TabMenu1 from '../components/ProfilePage/TabMenu1'
import TabMenu2 from '../components/ProfilePage/TabMenu2'
import TabMenu3 from '../components/ProfilePage/TabMenu3'
import '../components/ProfilePage/TabMenu.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileDetail } from '../redux/ActionCreators/profileDetailActions'


function Profile() {

    const profileDetail = useSelector(state => state.profileDetailReducer.profileDetail)
    const dispatch = useDispatch()

    const [ tab1, setTab1 ] = useState("activated")
    const [ tab2, setTab2 ] = useState("")
    const [ tab3, setTab3 ] = useState("")

    function activeTab(params) {
        if(params === "tab1") {
            setTab1("activated")
            setTab2("")
            setTab3("")
        } else if (params === "tab2") {
            setTab1("")
            setTab2("activated")
            setTab3("")
        } else {
            setTab1("")
            setTab2("")
            setTab3("activated")
        }
    }

    useEffect(() => {
        dispatch( fetchProfileDetail("/profile/getprofile") )
    }, [dispatch])

    return (
        <div className="container body-content mobile-view">
            <NavbarWithArrowBack link="/back" />
            <Body profileDetail={profileDetail} />
            <div style={{ width: '109%', maxWidth: 480, display: 'table', marginTop: 10, position: 'fixed' }}>
                <div>
                    <ul className="nav nav-tabs">
                        <li className={tab1} style={{ cursor: 'pointer' }}>
                            <div onClick={() => activeTab("tab1")}>Informasi</div>
                        </li>
                        <li className={tab2} style={{ cursor: 'pointer' }}>
                            <div onClick={() => activeTab("tab2")}>Keamanan</div>
                        </li>
                        <li className={tab3} style={{ cursor: 'pointer' }}>
                            <div onClick={() => activeTab("tab3")}>Pengaturan</div>
                        </li>
                    </ul>
                    {
                        (tab1 === "activated") ?
                        <TabMenu1 /> :
                        (tab2 === "activated") ?
                        <TabMenu2 /> :
                        <TabMenu3 />
                    }
                </div>
            </div>
        </div>
    )
}

export default Profile
