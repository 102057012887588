import { Base64 } from 'js-base64'

function base64Sign(endpoint) {
    let id = "21;";
    let url = "https://api.ezeepasar.com/mobile-amaranthus";
    // https://api.ezeepasar.com/mobile-citrus
    // https://api.ezeepasar.com/mobile-amaranthus
    // https://apid.ezeelink.co.id/ezpasar/api2
    // https://api.ezeepasar.com/ezpj
    // https://apid.ezeelink.co.id/ezpasar/api3
    let module = ";Basic 054f367bf88511c10ccb3d3c73599201f8a44800";

    let sign = id+url+endpoint+module;
    let enc = Base64.encode(sign);
    // let dec = Base64.decode(enc);

    return enc;
}

export default base64Sign
