import React, { useEffect } from 'react'
import { Carousel } from 'antd'
import { useHistory } from 'react-router-dom'
import { BannerLoading } from '../skeletonLoading/loadingTag'
import { useSelector, useDispatch } from 'react-redux'
import { fetchBanner } from '../../redux/ActionCreators/bannerActions'
import './Banner.css'

function Banner() {

    const banners = useSelector(state => state.bannersReducer.banners)
    const isLoadingBannerHome = useSelector(state => state.isLoadingBannerReducer.isLoadingBannerHome)
    const dispatch = useDispatch()

    useEffect(() => {
            dispatch( fetchBanner("/dashboard/getbanner") )
    }, [dispatch])

    let history = useHistory();
    
    const bannerDetail = (bannerid) => {
        const bannerId = bannerid;
        history.push({pathname: `/banner/mbanner_id=${bannerId}`, state:{results: bannerId}})
    }

    
    const contentStyle = {
        width: '100%',
        height: 'auto'
    }
    
    return (
        <div className="banner-dot" style={{ marginTop: 60 }}>
            <Carousel autoplay>
                {
                    (isLoadingBannerHome) ? (<BannerLoading />) :
                    banners.map(banner => {
                        return(
                            <div key={banner.mbanner_id} className="banner-div" onClick={() => bannerDetail(banner.mbanner_id)}>
                                <img src={banner.mbanner_image} alt="..." style={contentStyle} className="bannerImg"/>
                            </div>                            
                        )
                    })
                }
            </Carousel>
        </div>
    )
}

export default Banner
