import {FETCH_DATA_GETCATEGORYLIST1_CAT} from '../ActionType/actionTypes'

const initialState = {
    getCategoryList1: []
}

const getCategoryList1Reducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_GETCATEGORYLIST1_CAT) {
        return { ...state, getCategoryList1: action.payload.getCategoryList1}
    } else {
        return state
    }
}

export default getCategoryList1Reducer