import React, {useEffect, useState} from 'react'
import thankyouIcon from '../../Asset/Icon/thankyou.png';
import './EzeetunaiPayment.css';
import { Link, useHistory, useParams} from 'react-router-dom'
import {useLocation} from 'react-router-dom'
import base64Sign from '../../function/base64Sign';
import { BaseURL, headersWithToken } from '../../function/helpers';
import axios from 'axios';
import { getToken } from '../../utils/setSessionUser';
import encryptData from '../../function/encryptData';

function PaymentSuccess() {

    let location = useLocation();
    const { orderId } = useParams()
    // var text = '';
    const [text, setText] = useState('')
    const [paymentID, setPaymentID] = useState(0)
    // let paymentID = 0;
    let history = useHistory();
    const access_token = getToken()

    useEffect(() => {    
        let params = (new URL(document.location)).searchParams;
        let status = params.get("status_code");

        // if(location.state !== undefined){
        //     if(location.state.paymentId === 103){

        //     }
        // }else{
        //     if(status !== 200){
        //         navigateOrderDetail();
        //     }
        // }
        CheckStatusPayment(orderId)
    } ,[])
    
    if(location.state !== undefined){
        paymentID = location.state.paymentId
    }

    // if(paymentID === 103){
    //     setText('Saldo ezeeTunai')
    // }

    async function CheckStatusPayment(orderId) {
        try {
            const signature = base64Sign("/EzeeTunaiSnap/CheckStatusPayment"); 
            const dataParams = encryptData(`{"tord_id": ${orderId}}`);           
            const headers = headersWithToken(signature, access_token)
            const dataStatus = await axios.post(BaseURL + '/EzeeTunaiSnap/CheckStatusPayment',{data: dataParams},{headers : headers});
            if (dataStatus.status === 200 && dataStatus.data.response_code === 200) {
                if (dataStatus.data.response_data.is_paid) {
                    setPaymentID(103)
                    setText('Saldo ezeeTunai')
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function navigateOrderDetail(){
        let params = (new URL(document.location)).searchParams;
        let id = params.get("order_id");
        history.push({pathname: `/RiwayatPesananDetail/${orderId}?fromcheckout=true`});
    }

    return (
        <div style={{marginTop: 59, textAlign: 'center'}}>
            <div className="main">
                <img alt="" src={thankyouIcon} style={{marginTop: 70, marginBottom: 20}}/>
                <div>
                    <p className="head-2">Pembayaran Berhasil!</p>    
                </div>
                <div className="divSpanSuccess">
                    {
                        paymentID !== 0 ? 
                        <span className="ezeetunaiSuccessSpan">Pembayaran berhasil dilakukan menggunakan <br/><b>{text}</b> Klik tombol dibawah untuk mengecek status pesananmu</span>
                        : <span className="ezeetunaiSuccessSpan">Pembayaran segera kami proses untuk verifikasi dan pesananmu akan segera kami antarkan <br/>Terimakasih sudah berbelanja di ezeePasar</span>
                    }
                </div>
                <br/>
                <br/>
                <div onClick={() => navigateOrderDetail()}>
                    <div>
                        <button className="ez-btn-green btn-lihat-pesanan">
                            Lihat Pesanan
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentSuccess
