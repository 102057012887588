import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Spin } from 'antd'
import { Link } from 'react-router-dom';
import { NavbarLogoOnly } from '../components/Properties/Navbar/Navbar';
import base64Sign from '../function/base64Sign';
import encryptData from '../function/encryptData';
import { BaseURL } from '../function/helpers';
import { getToken } from '../utils/setSessionUser';
import './VerifikasiEmail.css'

function VerifikasiEmail({ location }) {

  const signVerifyEmail = location.search.split("=")[1]
  const [isLoading, setIsLoading] = useState(true)
  const [isFailed, setIsFailed] = useState(false)

  useEffect(() => {
    function validateVerifyEmail() {
      const param = encryptData(`{"signature": "${signVerifyEmail}"}`)
      const signature = base64Sign("/profile/validateverifyemail")
      const access_token = getToken()
      const headers = {
        'Content-Type':'application/json',
        'sign' : signature,
        'access_token' : access_token
      }
      axios.post(BaseURL + "/profile/validateverifyemail", { data: param }, { headers: headers })
        .then(results => {
          if (results.status === 200) {
            setIsLoading(false)
            setIsFailed(false)
          } else {
            if (results.response.data.response_message === "Failed") {
              setIsLoading(false)
              setIsFailed(true)
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    }
    validateVerifyEmail()
  }, [])
  

  return (
    <div className="container body-content mobile-view" style={{ height: '100%' }}>
      <NavbarLogoOnly />
      <div className='main-verify-email' style={{ zIndex: 9999, marginTop: '10%', textAlign: 'center' }}>
        <div className='col-md-12 col-sm-12 col-xs-12' style={{ marginTop: 5, textAlign: 'center' }}>
          {
            (isLoading) ?
            <div className="spin-load" style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin size="large" />
            </div> :
            (!isFailed) ?
            <div style={{ maxWidth: 457, width: '100%' }}>
              <span style={{ fontSize: 15, color: '#848484' }}>Selamat!</span>
              <br />
              <span style={{ fontSize: 15, color: '#848484' }}>Email Anda sudah berhasil di verifikasi</span>
            </div> :
            <div style={{ maxWidth: 457, width: '100%' }}>
              <span style={{ fontSize: 15, color: '#848484' }}>Maaf! Verifikasi email Anda gagal</span>
              <br />
              <span style={{ fontSize: 15, color: '#848484' }}>Silahkan ulangi verifikasi email di halaman profile ezeepasar</span>
            </div>
          }
        </div>
        <div style={{ border: 'none', marginTop: 15 }}>
          <Link to="/DataPribadi" className='ez-btn-green-verify-email'>
            Lihat Profil
          </Link>
        </div>
      </div>
    </div>
  )
}

export default VerifikasiEmail