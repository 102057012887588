import React from 'react'
import {NavbarLogoOnly} from '../../components/Properties/Navbar/Navbar'
import VirtualAccPayment from '../../components/Payment/VirtualAccPayment'

function VirtualAcc() {
    return (
        <div className="container body-content mobile-view">
            <NavbarLogoOnly link="/"/>
            <VirtualAccPayment/>
        </div>
    )
}

export default VirtualAcc
