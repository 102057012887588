import {FETCH_DATA_ADDRESSLISTS_PROFILE} from '../ActionType/actionTypes'

const initialState = {
    getAddressLists: []
}

const getAddressListsReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_ADDRESSLISTS_PROFILE) {

        return { ...state, getAddressLists: action.payload.getAddressLists}
    } else {
        return state
    }
}

export default getAddressListsReducer