import React, {useState, useEffect} from 'react'
import { Row, Col, Card, Button } from 'antd'
import {Link, useHistory } from 'react-router-dom'
import AddCartModal from '../PopUpModal/HomePage/AddCartModal';
import { nFormatter } from '../../function/helpers';
import { useDispatch } from 'react-redux';
import { isLoadingMostSearchProdHome } from '../../redux/ActionCreators/Loading/isLoadingMostSearchProdActions';
import $ from 'jquery'

function MostSearch(props) {

    const [ visible, setVisible ] = useState(false);
    const [prodId, setProdId ] = useState(0);

    const { mostSearchProds } = props
    let history = useHistory();
    let isContainPromo;
    const dispatch = useDispatch()

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }

    function openModalWithData(id) {
        if(id === undefined){
            setVisible(false);
        }
        else
        {
            setVisible(true)
            const prodID = id;
            setProdId(prodID);
        }
    }

    useEffect(() => {
        openModalWithData();
    }, [])

    const productDetail = (prodId, prodName) => {
        const productId = prodId;
        const productName = prodName;
        history.push({pathname: `/produk/${productId}/${productName.replace(/\//g, " ")}`, state: {result: productId}})
        dispatch(isLoadingMostSearchProdHome(true))
    }

    const lihatSemuaProduct = (type_id, title_prod) => {
        const id = type_id
        const title = title_prod
        history.push({pathname: `/produk/ProductOther/${id}/${title}`, state: {result: id}})
        dispatch(isLoadingMostSearchProdHome(true))
    }

    let isPromo = mostSearchProds.find(q => q.product_promo_percent !== null);
    if(isPromo !== undefined){
        isContainPromo = true;
    }else{
        isContainPromo = false;
    }

    return (
        <div>
            <Row>
                <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                    <span>Pencarian Populer</span>
                    <Link to="#" className="link_product" style={{ float: "right" }} onClick={() => lihatSemuaProduct(7, "Pencarian Populer")}>Lihat Semua</Link>
                </Col>
                <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10, fontFamily: 'Montserrat', color: '#848484'  }}>
                    Populer berdasarkan pencarian di ezeePasar
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: -8, justifyContent: 'center' }}>
                    <Col span={24} bordered="true" style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6, flexWrap: 'nowrap', overflowX: 'auto' }}>
                        <div className="card-body" style={{ display: 'flex', marginBottom: 8}}>
                            {
                                mostSearchProds.map((product, i) => {
                                    return (
                                        <div key={i}>
                                            <Card
                                                hoverable
                                                style={isContainPromo ? {height : 280} : null}
                                                onClick={() => productDetail(product.product_id, product.product_name)}
                                                className="card-products"
                                            >
                                                {/* <img style={{ borderRadius: 4 }} alt="img" width="80" height="80" src={ product.product_image } /> */}
                                                {
                                                    product.product_stat_stock_id === 2
                                                    ? <><img style={{ borderRadius: 4 }} alt="img" width="80" height="80" src={ product.product_image } /><div className="sold-out"></div><div className="sold-out-text">Habis</div></>
                                                    : <img style={{ borderRadius: 4 }} alt="img" width="80" height="80" src={ product.product_image } />
                                                }
                                                <p className="text-product1">{ product.product_name }</p>
                                                <p className="text-product2">{ product.product_weight } { product.product_weight_name }</p>
                                                {
                                                    product.product_promo_percent == null
                                                    ? <p className="text-product3">{ convertToRupiah(product.product_price) } / { product.product_unit_name }</p>
                                                    : <><div className="disc-product">
                                                        <span className="percent-disc">{ product.product_promo_percent }%</span>
                                                        <span className="real-price">{ convertToRupiah(product.product_price) }</span>
                                                    </div>
                                                    <p className="text-product3">{ convertToRupiah(product.product_promo_price) } / { product.product_unit_name }</p>
                                                    </>
                                                }
                                                {
                                                    product.product_terjual !== null && product.product_terjual !== 0 ? 
                                                    <div>
                                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10, float: 'left'}}>Terjual: <span style={{color: 'black'}}>{nFormatter(product.product_terjual)}</span></span>
                                                    </div> :
                                                    <div>
                                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10,visibility: 'hidden', float: 'left'}}>hidden</span>
                                                    </div>
                                                } 
                                                {
                                                    product.product_stat_stock_id === 2
                                                    ? <div onClick={(event) => event.stopPropagation()}><button className="btn-pesan-sold" style={isContainPromo ? product.product_promo_percent === null ? {marginTop: 46} : null : null} shape="round">Pesan</button></div>
                                                    : <div onClick={(event) => event.stopPropagation()}><Button className="btn-pesan" style={isContainPromo ? product.product_promo_percent === null ? {marginTop: 46} : null : null} shape="round" onClick={() =>openModalWithData(product.product_id)}>Pesan</Button></div>
                                                }
                                            </Card>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Col>
            </Row>
            {
                visible ? <AddCartModal showModal={visible} setModal={setVisible} closedModal={() => setVisible(false)} productId={prodId}/> : null
            }            
            <div className="break-page"></div>
        </div>
    )
}

export default MostSearch
