import React, { useEffect } from 'react'
import { Layout, Col, Row } from 'antd'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategory } from '../../redux/ActionCreators/categoryActions';
const { Footer} = Layout

function FooterBanner() {

    const history = useHistory()
    const dispatch = useDispatch()
    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)

    useEffect(() => {
        dispatch( fetchCategory("/dashboard/getcategory") )
    }, [dispatch]);
    

    function clickHandle(catId) {
        history.push(`/ProductListCategory/${catId}`)
    }

    return (
        <Layout style={{marginTop: 70}}>
            {
                (!isLoadingCategoryHome) &&
                <Footer className="footerLayout">
                    <Row>                  
                        <Col span={24} style={{paddingLeft: 5}}>
                            <button onClick={() => clickHandle(categories[0].mcat_id)} className="btnKeranjang" style={{height: 42}}>
                                <span className="spanCart">Lihat Sekarang</span>
                            </button>
                        </Col>
                    </Row>
                </Footer>
            }
        </Layout>
    )
}

export default FooterBanner
