import React from 'react'
import { Layout } from 'antd'
import './FooterSide.css'
import iconWhatsappWhite from '../../Asset/Icon/whatsapp_white.svg'

const { Footer } = Layout

function FooterSide() {

    function WAHandler() {
        const message = "Hai ezeePasar! Ada yang ingin saya tanyakan.."
        if (window.matchMedia('(min-width: 768px)').matches) {
            window.open('https://web.whatsapp.com/send?phone=62817844931&text=' + message, '_self');
        } else {
            if (navigator.userAgent.indexOf('wv') !== -1 || navigator.userAgent.indexOf('Version/') !== -1) {
                window.open('https://api.whatsapp.com/send?phone=62817844931&text=' + message, '_blank');
            }
            else {
                window.open('https://api.whatsapp.com/send?phone=62817844931&text=' + message, '_self');
            }        }    
    }

    return (
        <Layout style={{marginTop: 70}}>
            <Footer className="footerLayout">
                <button onClick={WAHandler}>
                    <img src={iconWhatsappWhite} alt="" width="24" style={{ marginRight: 10 }} />
                    Info & Bantuan Contact Whatsapp
                </button>
            </Footer>
        </Layout>
    )
}

export default FooterSide
