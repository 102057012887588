import React, {useState, useEffect}from 'react'
import { useFetchWithoutData} from '../../src/customHooks/useFetch';
import {getToken} from '../utils/setSessionUser'
import base64Sign from '../function/base64Sign';
import encryptData from '../function/encryptData'
import axios from 'axios';
import {checkoutUserSession} from '../utils/setSessionUser'
import currencyFormatter from 'currency-formatter';

export const BaseURL = 'https://api.ezeepasar.com/mobile-amaranthus';
// https://api.ezeepasar.com/mobile-citrus
// https://api.ezeepasar.com/mobile-amaranthus
// https://apid.ezeelink.co.id/ezpasar/api2 
// https://apid.ezeelink.co.id/ezpasar/api3 
const getTokens = getToken();   

export function GetCartCount(){
    const [cartCount, isloading] = useFetchWithoutData('/cart/cartcount');

    return cartCount;
}

export function GetNotifCount(){
    const [notifCount, loadingNotif] = useFetchWithoutData('/notification/countnotif');

    return notifCount;
}

export function GetPlaceHolder(){
    const [placeHolderData, loadingNotif] = useFetchWithoutData('/dashboard/placeholdersearch');
    return placeHolderData;
}

export function nFormatter(num, digits){
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: " rb" },
            { value: 1e6, symbol: " jt" },
            { value: 1e9, symbol: " milyar" },
            { value: 1e12, symbol: " t" },
        ];
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
            return num >= item.value;
        });
        return item ? (num / item.value).toFixed(2).replace(rx, "$1") + item.symbol : "0";
    }

export function headersWithToken(sign, accessToken){
    const header = {
        'Content-Type':'application/json',
        'sign' : sign,
        'access_token' : accessToken
        }
    return header;
}

export function headersWithoutToken(sign){
    const header = {
        'Content-Type':'application/json',
        'sign' : sign
        }
    return header;
}

export function sentTokenToServer(sent){
    window.localStorage.setItem('sentToServer', sent ? '1' : '0');
}

export function isTokenSentToServer(){
    return window.localStorage.getItem('sentToServer') === '1';
}

export function isdeniednotification() {
    return window.localStorage.getItem('deniedNotif') === '1';
}

export function setdeniednotification(sent) {
    window.localStorage.setItem('deniedNotif', sent ? '1' : '0');
}

export async function sentFirebaseToken(token){
    try{
        if(!isTokenSentToServer()){
            const signature = base64Sign("/security/setcustomerfirebasetoken");
            const dataParams = encryptData(`{"access_token": "${token}"}`);
            const access_token = getToken();
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/security/setcustomerfirebasetoken',{data: dataParams},{headers : headers});
            if(dataProds.status === 200){
                sentTokenToServer(true);
            }else{
                sentTokenToServer(false);
            }
        }else{

        }
    }catch(error){
        console.log(error);
    }
}  

export function WAhandler(){
    const message = "Hai ezeePasar! Ada yang ingin saya tanyakan.."
    if (window.matchMedia('(min-width: 768px)').matches) {
        window.open('https://web.whatsapp.com/send?phone=62817844931&text=' + message, '_blank');
    } else {
        if (navigator.userAgent.indexOf('wv') !== -1 || navigator.userAgent.indexOf('Version/') !== -1) {
            window.open('https://api.whatsapp.com/send?phone=62817844931&text=' + message, '_blank');
        }
        else {
            window.open('https://api.whatsapp.com/send?phone=62817844931&text=' + message, '_blank');
        }      
    }      
}

export function clearCheckoutSession(){
    checkoutUserSession.setAddress("")
    checkoutUserSession.setAddressId(0)
    checkoutUserSession.setAddressName("")
    checkoutUserSession.setReceiverMobile("")
    checkoutUserSession.setReceiverName("")

    checkoutUserSession.setEkspedisiId(0);
    checkoutUserSession.setNameEkspedisi("");
    checkoutUserSession.setDateShipping("");
    checkoutUserSession.setShippingPrice(0);
    checkoutUserSession.setTimeFrame('')

    checkoutUserSession.setPaymentId(0);
    checkoutUserSession.setPaymentName("");
    checkoutUserSession.setPaymentFee(0);
    checkoutUserSession.setPaymentImg("");
    checkoutUserSession.setPaymentSaldo(0);

    checkoutUserSession.setPromoId(0);
    checkoutUserSession.setPromoTypeId(0);
    checkoutUserSession.setPromoType("");
    checkoutUserSession.setPromoName("");
    checkoutUserSession.setPromoPrice(0);
    checkoutUserSession.settotalPriceAfterPromo(0);
    checkoutUserSession.settotalPriceBeforePromo(0);
}
  
export function moneyFormat(idr){
    let money = currencyFormatter.format(idr, { code: 'IDR' });
    return money;
}

export function useTitle(title) {
    useEffect(() => {
      const prevTitle = document.title + " | ezeePasar | Pasar Modern Digital"
      document.title = title + " | ezeePasar | Pasar Modern Digital"
      return () => {
        document.title = prevTitle
      }
    })
  }
