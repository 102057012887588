import React from 'react'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import BannerDetail from '../components/BannerPage/PromoBanner';
import Footer from '../components/BannerPage/Footer';

function Banner() {
    return (
        <div className="container body-content mobile-view">
            <NavbarWithMenuIcon link="/back" />
            <BannerDetail/>
            <Footer/>
        </div>
    )
}

export default Banner
