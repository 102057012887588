import React, {useState} from 'react'
import { Layout } from 'antd'
import './HistoryDetail.css'
import {useHistory} from "react-router-dom";
import { getToken } from '../../utils/setSessionUser';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios'
import {BaseURL, WAhandler} from '../../function/helpers'
import RePurchaseModal from './RePurchaseModal';

const { Footer } = Layout

export function FooterRePayment({paymentId, orderId, linkGopay}){
    let history = useHistory();

    function repayProduct(){
        if(paymentId === 104){
            history.push('/payment/shopeepay',{isFromHistoryOrder: true, order_id : orderId, payment_id: paymentId})
        }else if(paymentId === 101){
            history.push('/payment/gopay',{isFromHistoryOrder: true, order_id : orderId, payment_id: paymentId, tokenSnap: linkGopay})
        }else if(paymentId === 103){

        }else{

        }
    }
    return(
        <Layout style={{marginTop: 70}}>
            <Footer className="footerLayout" style={{marginLeft: -15}}>
                <button style={{width: '84%'}} onClick={() => repayProduct()}>
                    Ulangi Pembayaran
                </button>
                <div style={{fontFamily: 'Montserrat', marginTop: 10}}>
                    <span className='product-qty' style={{color: '#393939'}}>Butuh bantuan?<span className='greenText' onClick={() => WAhandler()}> Tanya Admin</span></span>
                </div>
            </Footer>
        </Layout>
    )
}

export function FooterOrderDetailExpired({orderId, statStok, orderItems}) {
    const [visibleRepurchase, setVisibleRepurchase] = useState(false);
    let history = useHistory();
    let access_token = getToken();
    
    function addCart(){
        const signature = base64Sign("/customer/reorder");
        const dataParams = encryptData(`{"order_id": ${orderId}}`);
        const headers =
        {
        'Content-Type':'application/json',
        'sign' : signature,
        'access_token' : access_token
        }
        axios
        .post(BaseURL + '/customer/reorder',{data:dataParams},{headers:headers}).then(data=>{
            if(data.status === 200){
                history.push('/cart')
            }
        }).catch(err => console.log(err));
    }

    function repurchaseItems(){
        setVisibleRepurchase(true);
    }

    let isEmpty = orderItems.find(q => q.mprod_mstatstok_id === 2);
    // console.log(isEmpty, statStok, orderItems.length);
    
    return (
        <Layout style={{marginTop: 70}}>
            <Footer className="footerLayout" style={{marginLeft: -15}}>
                {
                    // orderItems.length === 1 ? (orderItems.length === 1 ? 
                    // <>
                    // <button style={{width: '84%'}} disabled>
                    //     Beli Lagi
                    // </button>
                    // </> : 
                    // <button style={{width: '84%'}} onClick={() => addCart()}>
                    //     Beli Lagi
                    // </button>) : 
                    //     <button style={{width: '84%'}} onClick={() => repurchaseItems()}>
                    //         Beli Lagi
                    //     </button>
                    statStok === 2 ? 
                    <>
                    <button style={{width: '84%'}} onClick={() => addCart()} disabled>
                        Beli Lagi
                    </button></>:
                    (orderItems.length === 1 ? 
                        <button style={{width: '84%'}} onClick={() => addCart()}>
                            Beli Lagi
                        </button> : 
                        <button style={{width: '84%'}} onClick={() => repurchaseItems()}>
                            Beli Lagi
                        </button>)
                }
                <div style={{fontFamily: 'Montserrat', marginTop: 10}}>
                    <span className='product-qty' style={{color: '#393939'}}>Butuh bantuan?<span className='greenText' onClick={() => WAhandler()}> Tanya Admin</span></span>
                </div>
            </Footer>
            {
                visibleRepurchase ? <RePurchaseModal showModal={visibleRepurchase} closeModal={() => setVisibleRepurchase(false)} data={orderItems}/> : null
            }
        </Layout>
    )
}

export function FooterOrderDetailNonExpired() {
    return (
        <Layout style={{marginTop: 70}}>
            <Footer className="footerLayout" style={{marginLeft: -15}}>
                <div style={{fontFamily: 'Montserrat', marginTop: 10, marginBottom: 10}}>
                    <span className='product-qty' style={{color: '#393939'}}>Butuh bantuan?<span className='greenText' onClick={() => WAhandler()}> Tanya Admin</span></span>
                </div>
            </Footer>
        </Layout>
    )
}
