export const GOOGLE_CLIENT_ID = '150505561294-cmf2kemk92n6pq72i09i3equmeimhmr0.apps.googleusercontent.com'
export const FACEBOOK_APP_ID = '863459367511093'
export const APPLE_CLIENT_ID = 'com.ezeepasar.web'
export const FIREBASE_VAPID_KEY = 'BLyubxKs12_xWznN7WNlTAWNk8YnwizQ0aq_33YaWgq5hrSCKO0yQNDcPUnYD-mgPVCmTec-oOeDOiH5RF3eFps'
export const FIREBASE_CONFIG = {  
    apiKey: "AIzaSyDb06zNOMdRgkeN44yXxzI3BAHEoYPjCE8",
    authDomain: "ezeepasarweb.firebaseapp.com",
    databaseURL: "https://ezeepasarweb.firebaseio.com",
    projectId: "ezeepasarweb",
    storageBucket: "ezeepasarweb.appspot.com",
    messagingSenderId: "1095839671048",
    appId: "1:1095839671048:web:ed340b9599afca62be02bd",
    measurementId: "G-VKS36G264X"
}

export const GOOGLE_ANALYTICS_ID = 'UA-165046252-1'
//midtrans demo : SB-Mid-client-C-OIXObzAyQKvm67
//midtrans live : Mid-client-GbmkuVCbiTsvAiKc

// Firebase Vapid Key
//BIHYly0rmXR-BXFncWaKO4DtixgYTyrkRSF4e2g9nAWkWErUKw65CmCEhF6znYoXcXn3YWMZG5L7dX0v2kDidTg

// Facebook Login Test App Id
//442571430422984

//FCM Test Credential
// apiKey: "AIzaSyDK8lCYIS7fzmz4Qlt3oLDkG1ZqY-SNXi4",
// authDomain: "fcmtest-e5398.firebaseapp.com",
// databaseURL: "https://fcmtest-e5398.firebaseio.com",
// projectId: "fcmtest-e5398",
// storageBucket: "fcmtest-e5398.appspot.com",
// messagingSenderId: "478494300772",
// appId: "1:478494300772:web:10a3a170c7fdb31653dd71",
// measurementId: "G-R3FC3CERT8"