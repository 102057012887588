import React, { useEffect, useState } from 'react'

function LaunchMobileApp() {

    const [isAndroid, setIsAndroid] = useState(false)
    const [isiOS, setIsiOS] = useState(false)

    useEffect(() => {
        function redirectPlayStore() {
            return (
                <a href="https://play.google.com/store/apps/details?id=com.ezee.main&hl=in&gl=US" target={"_blank"} rel="noopener noreferrer" />
            )
        }
        function checkBrowser() {
            let isAndroid = false
            let isiOS = false
            // if (/android/i.test(navigator.userAgent)) {
            //     isAndroid = true
            //     setIsAndroid(true)
            //     window.location.replace("https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.ezee.main&ddl=1&pcampaignid=web_ddl_1")
            // } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
            //     isiOS = true
            //     setIsiOS(true)
            //     window.location.replace("https://apps.apple.com/id/app/ezeelink/id1009099732?l=id")
            //     // window.open("market://details?=com.ezee.main&hl=en&gl=US")
            // }
            if (navigator.userAgent.toLowerCase().indexOf("ios") > -1 || navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
                return window.location.replace("https://itunes.apple.com/id/app/ezeelink/id1009099732?mt=8");
            }
            else if (navigator.userAgent.toLowerCase().indexOf("android") > -1){
                // redirectPlayStore()
                setIsAndroid(true)
                window.open("https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.ezee.main&ddl=1&pcampaignid=web_ddl_1", "", "noopener,noreferrer");
                return null
            }
            else if (navigator.userAgent.toLowerCase().indexOf("mac") > -1){
                // return window.location.replace("https://apps.apple.com/id/app/ezeelink/id1009099732?l=id");
            }
            // else{
            //     return window.location.replace("https://play.google.com/store/apps/details?id=com.ezee.main&hl=en");
            // }
        }
        checkBrowser()
    }, [])
    
    return (
        <div>
            LaunchMobileApp
            <br />
            {
                // (isMobile) ? "true" : "false"
                (isAndroid) ? "Android" : "Not Android"
                // (isiOS) ? "iOS" : "Not iOS"
            }
        </div>
    )
}

export default LaunchMobileApp