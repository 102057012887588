import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import './TabMenu.css'

function TabMenu1() {
    let history = useHistory();

    function navigateAddress(){
        history.push("/alamatkirim", {isFromCheckout : false});
    }

    return (
        <>
            <div style={{ display: 'block', paddingTop: 7, height: '81vh', overflow: 'auto' }}>
                <ul className="nav" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <li>
                        <Link to="/DataPribadi" id="link_title">
                            Data Pribadi
                        </Link>
                    </li>
                    <li onClick={() => navigateAddress()}>
                        <Link id="link_title">Alamat Kirim</Link>
                    </li>
                    {/* <li>
                        <Link to="#">Akun Bank</Link>
                    </li> */}
                    <li>
                        <Link to="/WishList" id="link_title">Wishlist</Link>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default TabMenu1
