import { IS_LOADING_PROMOLIST_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingPromoProdsHome: true
}

const isLoadingPromoProdsReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_PROMOLIST_HOME) {
        return { ...state, isLoadingPromoProdsHome: action.payload.isLoadingPromoProdsHome }
    } else {
        return state
    }
}

export default isLoadingPromoProdsReducer