import React, { useState } from 'react'
import moment from 'moment'
import './Body.css'
import base64Sign from '../../function/base64Sign';
import { getToken } from '../../utils/setSessionUser';
import axios from 'axios';
import { Modal, Row, Col} from 'antd';
import {BaseURL} from '../../function/helpers'
import profilePictDefault from '../../Asset/Icon/profile_default.png'
import iconAccountVerified from '../../Asset/Icon/dashboard_account_verified.png'
import iconEmailNotVerified from '../../Asset/Icon/dashboard_email_not_verified.png'
import iconEmailVerifiedSuccess from '../../Asset/Icon/dashboard_email_verified_success.png'

function Body(props) {
    
    const { profileDetail } = props
    const [visible, setVisible] = useState(false)

    function formattedDate(dt){
        var date = new Date(dt * 1000).toDateString();
        return date;
    }

    function handleCancel() {
        setVisible(false)
    }

    function verifEmailHandler() {
        const signature = base64Sign("/profile/verifyemail")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + 
            "/profile/verifyemail",
            { data: "" }, 
            { headers: headers }
        )
            .then(verified => {
                if(verified.data.response_data.results.message === "SUCCESS") {
                    setVisible(true)
                }
            })
            .catch(error => console.log(error))
    }
    
    return (
        <>
            <div className="profileLayer1"></div>
            <div className="profilLayer2">
                <div className="profilePict">
                    {
                        (profileDetail.customer_photo === null) ?
                        <img className="profileDefault" style={{ borderRadius: '50%', border: '2px solid #fff' }} src={profilePictDefault} alt="" width="80px" height="80px" /> :
                        <img className="profileDefault" style={{ borderRadius: '50%', border: '2px solid #fff' }} src={ profileDetail.customer_photo } alt="" width="80px" height="80px" />
                    }
                </div>
                <span className="username">
                    { profileDetail.customer_name }
                    {
                        (profileDetail.is_verify_email) &&
                        <span style={{ marginLeft: 3 }}>
                            <img src={iconAccountVerified} alt="" width="20" height="20" />
                        </span> 
                    }
                </span>
                <span className="membersince" id="memberSince">member sejak { moment(formattedDate(profileDetail.created_date)).format('MMMM yy') }</span>
                {
                    (!profileDetail.is_verify_email) &&
                    <div className="row email-ver-div">
                        <div style={{ textAlign: 'left'}}>
                            <div className="membersince" style={{justifyContent: 'start', fontSize: 13, fontWeight: 400 }}>
                                <Row>
                                    <Col span={3}>
                                        <img src={iconEmailNotVerified} alt="" width="49" height="48" />
                                        
                                    </Col>
                                    <Col span={12} style={{alignSelf: 'center'}}>
                                        <span>Email belum di verifikasi</span>
                                    </Col>
                                    <Col span={9} style={{alignSelf: 'center'}}>
                                        <div style={{ float: 'right' }}>
                                            <input onClick={verifEmailHandler} className="btn btn-md btn-success" type="button" style={{ fontSize: 12, backgroundColor: "#5cb85c", borderColor: "#4cae4c", width: '100%' }} value="Verifikasi" />
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal
                visible={visible}
                onCancel={handleCancel}
                footer={null}
                className="modalVefiedEmailVerif"
            >
                <div>
                    <img src={iconEmailVerifiedSuccess} alt="" width="85px" height="85px" />
                </div>
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#3DB54A" }}>
                    Email Verifikasi Berhasil Dikirim
                </div>
                <div style={{ fontSize: 10 }}>
                    Silahkan periksa email Anda untuk melakukan verifikasi email
                </div>
                <button onClick={handleCancel} className="btn" style={{ backgroundColor: "#3DB54A", color: "#fff", marginTop: 40, padding: "10px 50px", outline: 'unset' }}>Oke</button>
            </Modal>
        </>
    )
}

export default Body
