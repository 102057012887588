import React, {useState, useEffect} from 'react'
import {Row, Col, Modal, Alert, message} from 'antd'
import './OnePageCheckout.css'
import { Link, useLocation, useHistory } from 'react-router-dom'
import mapping_icon from '../../Asset/Icon/mapping_icon.svg'
import shipping_icon from '../../Asset/Icon/shipping_icon.svg'
import payment_icon from '../../Asset/Icon/payment_icon.svg'
import promo_icon from '../../Asset/Icon/promo_icon.svg'
import arrow_icon from '../../Asset/Icon/arrow.svg'
import close_icon from '../../Asset/Icon/close.svg'
import {getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import 'moment/locale/id';
import moment from 'moment';
import $ from 'jquery'
import { checkoutUserSession } from '../../utils/setSessionUser'
import Footer from '../../components/Checkout/Footer'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import {ModalPaymentMethodLoading} from '../skeletonLoading/loadingTag'
import encryptData from '../../function/encryptData'
import {CashbackModal, CashbackModalDetail, CashbackIsEmpty} from '../PopUpModal/CheckoutPage/CashbackModal'
import snackbarIconCashback from '../../Asset/Icon/Checkout/iconSnackbar_cashback.svg'
import {BaseURL, headersWithToken, moneyFormat, useTitle} from '../../function/helpers'
import HtmlParser from 'react-html-parser'
import {NavbarCloseOnlyWithLogo} from '../Properties/Navbar/Navbar'

function OnePageCheckout() {
    useTitle("EzeePasar - Check Out Pemesanan")
    const [checkoutDetail, setCheckoutDetail] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingPayType, setLoadingPayType] = useState(true);
    const [visible, setVisible] = useState(false);
    const [totalPrice, setTotalPrice] = useState(0);
    const [totalItem, setTotalItem] = useState(0);
    const [paymentMethodList, setPaymentMethodList] = useState([]);
    const [isCashback, setIsCashback] = useState({status : false, visible: false, cashbackDetail: null});
    const [isCashbackDetail, setIsCashbackDetail] = useState(false);
    const [showAllProds, setShowAllProds] = useState(false);
    const [promoAgent, setPromoAgent] = useState({promoCodeId : 0, promoCodeType : 0, promoCodeTypeId : 0, promoCodeName : '', promoCodePrice : 0, totalPriceAfterPromo : 0, totalPriceBeforePromo : 0});
    const [bindingList, setBindingList] = useState([])

    var access_token = getToken();
    let history = useHistory();
    let location = useLocation();
    let agentCode = sessionStorage.getItem('agentCode');

    moment.locale('id');

    useEffect(() => {        
        if(location.state !== undefined){
            if(location.state.isFromBuyNow){
                getCheckoutDetailFromBuyNow()
            }else{
                getCheckoutDetail();
            }            
            if(location.state.cashbackIsEmpty){
               
            }
        }else{
            getCheckoutDetail();
        }

        if(promoCodeId !== 0){
            if(location.state !== undefined){
                if(location.state.isPromoCodeUsed){
                    checkCashback(paymentId, location.state.addrId, location.state.ekspedisi);
                }
            }
        }
        getPaymentList();
        getBindingList()
    },[])
    
    var sum = 0;
    let priceProds = 0;
    let totalPriceVar = 0;
    let shippingPriceFee = 0;
    let shippingDate = '';
    let totalHargaFix = 0;

    let address = checkoutUserSession.getAddress();
    let address_id = checkoutUserSession.getAddressId();
    let address_name = checkoutUserSession.getAddressName();
    let receiver_mobile = checkoutUserSession.getReceiverMobile();

    let depoLat = checkoutUserSession.getLat();
    let depoLng = checkoutUserSession.getLng();

    let ekspedisi_id = checkoutUserSession.getEkspedisiId();
    let ekspedisi_name = checkoutUserSession.getNameEkspedisi();
    let dateShippingCustom = checkoutUserSession.getDateShipping();
    let shippingPrice = checkoutUserSession.getShippingPrice();
    let timeFrame = 0 || checkoutUserSession.getTimeFrame();

    let paymentId = checkoutUserSession.getPaymentId();
    let paymentName = checkoutUserSession.getPaymentName();
    let paymentFee = checkoutUserSession.getPaymentFee();
    let paymentImg = checkoutUserSession.getPaymentImg();
    let paymentSaldo = checkoutUserSession.getPaymentSaldo();

    let promoCodeId = checkoutUserSession.getPromoId();
    let promoCodeTypeId = checkoutUserSession.getPromoTypeId();
    let promoCodeType = checkoutUserSession.getPromoType();
    let promoCodeName = checkoutUserSession.getPromoName();
    let promoCodePrice = checkoutUserSession.getPromoPrice();
    let totalPriceAfterPromo = checkoutUserSession.gettotalPriceAfterPromo();
    let totalPriceBeforePromo = checkoutUserSession.gettotalPriceBeforePromo();

    if(promoCodePrice > 0){
        totalPriceVar = totalPrice - promoCodePrice;  
        if(totalPriceVar < 0){
            totalPriceVar = 0;
        }
    }

    function errorCheckout(messages){
        message.error({
            content: messages,
            className: 'checkoutSnackbarError',
            duration: 3
        });
      };

    async function getBindingList() {
        try {
            const signature = base64Sign("/EzeeTunaiSnap/GetAccountBindingList"); 
            const headers = headersWithToken(signature, access_token);
            const accountBindingList = await axios.post(BaseURL + "/EzeeTunaiSnap/GetAccountBindingList", {data: ""}, {headers: headers})
            if (accountBindingList.status === 200 && accountBindingList.data.response_code === 200) {
                setBindingList(accountBindingList.data.response_data.results)
            } else if (accountBindingList.response.status === 400 && accountBindingList.response.data.response_code === 400) {
                setBindingList([])
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    async function getCheckoutDetailFromBuyNow() {
        const signature = base64Sign("/cart/buynow");
        const dataParams = encryptData(`{"product_id": ${location.state.prodId}, "qty": ${parseInt(location.state.items)}}`)
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/cart/buynow',{data:dataParams},{headers:headers}).then(data=>{
            const datas = data.data.response_data.results;
            setCheckoutDetail(datas);
            datas.product.forEach(function(value, index, arry){
                if(value.product_promo_price !== null){
                    sum += value.product_promo_price * value.quantity; 
                }else{
                    sum += value.product_price * value.quantity; 
                }      
            });
            if(datas.address.address === null){
                setTotalPrice(0);
            }else{
                setTotalPrice(sum); 
            }          
            totalHargaFix = totalPrice;
            let qtyPrd =  data.data.response_data.results.product.map(item => item.quantity).reduce((prev, next) => prev + next);
            let eksp = checkoutUserSession.getEkspedisiId();
            if(paymentId !== 0){
                var id = paymentId;
                var addrId = address_id || datas.address.address_id;
                var ekspedisi;
                if(datas.shipping === null){ekspedisi = 0}else{ ekspedisi = eksp || datas.shipping.expedisi_id;}  
                checkCashback(id, addrId, ekspedisi);
            }
            setTotalItem(qtyPrd);
            setLoading(false)
        }).catch(err => console.log(err));

    }

    async function getCheckoutDetail() {
        const signature = base64Sign("/checkout/checkoutdata");            
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/checkout/checkoutdata',{data:""},{headers:headers}).then(data=>{
            const datas = data.data.response_data.results;
            setCheckoutDetail(datas);
            datas.product.forEach(function(value, index, arry){
                if(value.product_promo_price !== null){
                    sum += value.product_promo_price * value.quantity; 
                }else{
                    sum += value.product_price * value.quantity; 
                }        
            });
            if(datas.address.address === null){
                setTotalPrice(0);
            }else{
                setTotalPrice(sum); 
            }            
            totalHargaFix = totalPrice;
            let qtyPrd =  datas.product.map(item => item.quantity).reduce((prev, next) => prev + next);
            let eksp = checkoutUserSession.getEkspedisiId();
            if(paymentId !== 0){
                var id = paymentId;
                var addrId = address_id || datas.address.address_id;
                var ekspedisi;
                if(datas.shipping === null){ekspedisi = 0}else{ ekspedisi = eksp || datas.shipping.expedisi_id;}           
                checkCashback(id, addrId, ekspedisi);
            }
            setTotalItem(qtyPrd);
            setLoading(false)
        }).catch(err => console.log(err));

    }

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'decimal', currency: 'IDR'}).format(money)
    }

    function onClosed(){
        setVisible(false);
    }

    function openCashbackModalDetail(){
        setIsCashbackDetail(true);
    }

    async function getPaymentList() {
        const signature = base64Sign("/checkout/getlistpaymenttype");            
        const headers = headersWithToken(signature, access_token)
        axios
        .post(BaseURL + '/checkout/getlistpaymenttype',{data:""},{headers:headers}).then(data=>{
            setPaymentMethodList(data.data.response_data.results);
            setLoadingPayType(false)
        }).catch(err => console.log(err));
    }
    
    async function checkCashback(id, addr, ekspedisi_id) {
            const signature = base64Sign("/checkout/checkcashback");  
            const dataParams = encryptData(`{"address_id": ${addr}, "payment_id": ${id}, "expedisi_id" : ${ekspedisi_id}}`)         
            const headers = headersWithToken(signature, access_token);
            await axios.post(BaseURL + '/checkout/checkcashback',{data:dataParams},{headers:headers})
            .then(data=>{
                if(data.status === 200){                
                    setIsCashback({status : true, visible: true, cashbackDetail: data.data.response_data.results});
                    // checkoutUserSession.setIsCashback
                }else{
                    setIsCashback({status : false, visible: false,cashbackDetail: null})
                }
            }).catch((error) => {
                if(error.response.response_code === 500){
                    
                }else{
                    const err = error.response.data;
                    if(err.response_data !== null){
                        if(err.response_data.error_id === '0069'){
                        setIsCashback({status : false, visible: false,cashbackDetail: null})
                        }
                    }else{
                        if(ekspedisi_id !== 102){
                            errorCheckout(err.response_message);
                        }
                    }
                }
            })
        }
    
        async function UsePromoAgen(promo_code, addr, ekspedisi, payment){
            try{
                const signature = base64Sign("/promo/usepromo"); 
                const dataParams = encryptData(`{"promo_code":"${promo_code}", "address_id": ${addr}, "expedisi_id": ${ekspedisi}, "payment_id": ${payment}}`);           
                const headers = headersWithToken(signature, access_token);
                const data = await axios.post(BaseURL + '/promo/usepromo',{data: dataParams},{headers : headers});
                if(data.status === 200){
                    var datas = data.data.response_data.results;
                    checkoutUserSession.setPromoId(datas.mpromcod_id);
                    checkoutUserSession.setPromoType(datas.mpromotype_name);
                    checkoutUserSession.setPromoTypeId(datas.mpromcod_promo_type_id);
                    checkoutUserSession.setPromoName(datas.mpromcod_code);
                    checkoutUserSession.setPromoPrice(datas.total_promo_amount);
                    checkoutUserSession.settotalPriceAfterPromo(datas.total_bayar);
                    checkoutUserSession.settotalPriceBeforePromo(datas.total_transaksi);                                                     
                    setPromoAgent({
                        promoCodeId: datas.mpromcod_id,
                        promoCodeType: datas.mpromotype_name,
                        promoCodeTypeId: datas.mpromcod_promo_type_id,
                        promoCodeName: datas.mpromcod_code,
                        promoCodePrice: datas.total_promo_amount,
                        totalPriceAfterPromo: datas.total_bayar,
                        totalPriceBeforePromo: datas.total_transaksi
                    })
                    // setLoading(false);
                }else{
                    if(data.response.status === 400){
                        errorCheckout(data.response.data.response_message) 
                    }
                }
                setLoading(false)
            }catch(err){
                console.log(err)
            }
        }
        
    function selectPaymentMethod(id, name, fee, img, saldo) {
        if(checkoutDetail.address.address_id === 0){
            errorCheckout("Silahkan pilih alamat pengiriman terlebih dahulu");
            setVisible(false);
            return;
        }else{
        if(agentCode !== null){
            UsePromoAgen(agentCode, address_id, ekspedisi_id, id);
        }
        checkCashback(id, address_id, ekspedisi_id);
        if(totalPriceVar === 0){
             if(id === paymentId){                 
                 checkoutUserSession.setPaymentId(id);
                 checkoutUserSession.setPaymentName(name);
                 checkoutUserSession.setPaymentFee(fee);
                 checkoutUserSession.setPaymentImg(img);  
                 checkoutUserSession.setPaymentSaldo(saldo);     
                 setTotalPrice(totalPrice + 0);
                 totalHargaFix = totalPrice;
             }else{    
                 if(fee < paymentFee){
                     setTotalPrice(totalPrice);
                     totalHargaFix = totalPrice;
                     
                     checkoutUserSession.setPaymentId(id);
                     checkoutUserSession.setPaymentName(name);
                     checkoutUserSession.setPaymentFee(fee);
                     checkoutUserSession.setPaymentImg(img);  
                     checkoutUserSession.setPaymentSaldo(saldo);                  
                 }else{
                     
                     checkoutUserSession.setPaymentId(id);
                     checkoutUserSession.setPaymentName(name);
                     checkoutUserSession.setPaymentFee(fee);
                     checkoutUserSession.setPaymentImg(img);
                     checkoutUserSession.setPaymentSaldo(saldo);  
                     setTotalPrice(totalPrice); 
                     totalHargaFix = totalPrice;                  
                 }
             }   
        }else{
            let hargaReal = totalPrice - promoCodePrice;
            if(hargaReal === totalPriceAfterPromo){
             
             checkoutUserSession.setPaymentId(id);
             checkoutUserSession.setPaymentName(name);
             checkoutUserSession.setPaymentFee(fee);
             checkoutUserSession.setPaymentImg(img); 
             checkoutUserSession.setPaymentSaldo(saldo); 
             totalHargaFix = totalPriceAfterPromo + fee;
            }else{
             if(id === paymentId){
                 checkoutUserSession.setPaymentId(id);
                 checkoutUserSession.setPaymentName(name);
                 checkoutUserSession.setPaymentFee(fee);
                 checkoutUserSession.setPaymentImg(img); 
                 checkoutUserSession.setPaymentSaldo(saldo);         
                 setTotalPrice(totalPrice + 0 - promoCodePrice);
                 totalHargaFix = totalPrice;
             }else{    
                 if(fee < paymentFee){
                     setTotalPrice(totalPrice);
                     totalHargaFix = totalPrice;
                     checkoutUserSession.setPaymentId(id);
                     checkoutUserSession.setPaymentName(name);
                     checkoutUserSession.setPaymentFee(fee);
                     checkoutUserSession.setPaymentImg(img); 
                     checkoutUserSession.setPaymentSaldo(saldo);                   
                 }else{ 
                     checkoutUserSession.setPaymentId(id);
                     checkoutUserSession.setPaymentName(name);
                     checkoutUserSession.setPaymentFee(fee);
                     checkoutUserSession.setPaymentImg(img); 
                     checkoutUserSession.setPaymentSaldo(saldo); 
                     setTotalPrice(totalPrice); 
                     totalHargaFix = totalPrice;                  
                 }
            }
        }
     }
        setVisible(false);
        $("html, body").animate({ scrollTop: $("#priceInformationSec").offset().top }, 150);
    }
}
      
     function unusePromoCode(){         
         checkoutUserSession.setPromoId(0);
         checkoutUserSession.setPromoTypeId(0);
         checkoutUserSession.setPromoType('');
         checkoutUserSession.setPromoName('');
         checkoutUserSession.setPromoPrice(0);
         checkoutUserSession.settotalPriceAfterPromo(0);
         checkoutUserSession.settotalPriceBeforePromo(0);
         promoCodeName = '';
     }

     function removeCashbackSnackbar(status){
        setIsCashback({status : status, visible: false, cashbackDetail: null});
     }

     function linkToDeliveryMethod(){
        let addressId;
        let isSelfPickUp = false;
        let dateKirim
        if(ekspedisi_id === 102){
            addressId = checkoutDetail.address.address_id;
            isSelfPickUp = true;
        }else{
            if(address_id === 0){
                addressId = checkoutDetail.address.address_id;
                isSelfPickUp = false;
            }else{
                addressId = address_id;
                isSelfPickUp = false;
            }
        }
        
        if(dateShippingCustom === ''){
            dateKirim = checkoutDetail.shipping.date_kirim
        }else{
            dateKirim = moment(dateShippingCustom, "YYYY-MM-DD").format("YYYY-MM-DD")
        }
        
        history.push("/deliverymethod", {address_id : addressId, fromSelfPickup : isSelfPickUp, datekirim : dateKirim});

     }

    function toEzTunaiBinding() {
        history.push("/EzeeTunaiBinding")
    }
 
     function linkToPromoPage(){
         history.push("/promocode", {addressId : address_id || checkoutDetail.address.address_id, expedisiId : ekspedisi_id, paymentId: paymentId, promoCodeId: promoCodeId, ekspedisi: ekspedisi_id});
     }

     function toListAddress(){
         let addressischanged;
         if(address_id === 0 ){addressischanged = false}else{addressischanged = true}
         history.push("/alamatkirim",{isFromCheckout : true, date : shippingDate, addressisChanged : addressischanged, addressId : address_id, ekspedisi: ekspedisi_id});
     }

     function goToDepoLocation(){
         let lat = depoLat;
         let lng = depoLng;
        if (window.matchMedia('(min-width: 768px)').matches) {
            window.open('https://maps.google.com/?q='+lat+','+lng+'' ,'_blank');
        } else {
            if (navigator.userAgent.indexOf('wv') !== -1 || navigator.userAgent.indexOf('Version/') !== -1) {
                window.open('https://maps.google.com/?q='+lat+','+lng+'' , '_blank');
            }
            else {
                window.open('https://maps.google.com/?q='+lat+','+lng+'' , '_blank');
            }      
        }
     }

     if(loading){
        return (
            <LoadingModal/>
        )
    }else{
       if( checkoutDetail.length === 0){
            return(
                <><span style={{marginTop: 70}}>Error</span></>
            )           
       }else{
            if(address_id === 0){
                address_id = checkoutDetail.address.address_id;
                address_name = checkoutDetail.address.address_name;
                receiver_mobile = checkoutDetail.address.receiver_mobile;
                address = checkoutDetail.address.address;
            }

            checkoutDetail.product.forEach(function(value, index, arry){
                if(value.product_promo_price !== null){
                    sum += value.product_promo_price * value.quantity; 
                }else{
                    sum += value.product_price * value.quantity; 
                }       
            });            
            
            priceProds = sum;
            
            if(totalPrice !== 0){
                    // if(checkoutDetail.shipping === null){
                    // }else{
                        if(ekspedisi_id === 0){
                            if(checkoutDetail.shipping === null){
                                ekspedisi_id = checkoutUserSession.getEkspedisiId();
                            }else{
                                ekspedisi_id = checkoutDetail.shipping.expedisi_id
                            }
                        }else{
                            ekspedisi_id = checkoutUserSession.getEkspedisiId();
                        }
                    
                    if(dateShippingCustom === ''){
                        if(checkoutDetail.shipping === null){
                            shippingDate = '';
                        }else{
                            shippingDate = moment(checkoutDetail.shipping.date_kirim, 'YYYY MM DD').format('dddd,DD MMMM YYYY'); 
                        }                    
                    }else{
                        shippingDate = moment(dateShippingCustom).format('dddd,DD MMMM YYYY');
                    }
                
                    if(timeFrame === ''){
                        timeFrame = checkoutDetail.timeframe;
                    }else{
                        if(ekspedisi_id === 102){
                            timeFrame = 0;
                        }else{
                            timeFrame = checkoutUserSession.getTimeFrame();
                        }
                    }

                    if(shippingPrice === -1){
                        shippingPriceFee = 0;
                    }else if(shippingPrice > 0){
                        shippingPriceFee = shippingPrice;
                    }else{
                        if(ekspedisi_name === ''){
                            if(checkoutDetail.shipping === null){
                                shippingPriceFee = 0;
                            }else{
                                shippingPriceFee = checkoutDetail.shipping.price;
                            }
                        }else{
                            shippingPriceFee = shippingPrice;
                        }
                    }
                                                                
                    if(shippingPrice !== 0){
                        if(shippingPrice === -1){
                            totalHargaFix = priceProds + paymentFee;
                        }else{
                            totalHargaFix = priceProds + shippingPriceFee + paymentFee;
                        }
                    }else{
                        totalHargaFix = totalPrice + shippingPriceFee + paymentFee;
                    }

                    if(ekspedisi_id === 102){
                        totalHargaFix = totalPrice + paymentFee
                    }
            // }
        
            if(promoCodeTypeId !== null){
                if(promoCodeTypeId === 102){
                    totalHargaFix = totalPrice - promoCodePrice + paymentFee + shippingPriceFee;
                }else if(promoCodeTypeId === 101){
                    shippingPriceFee = shippingPrice - promoCodePrice;
                    if(shippingPriceFee < 0){
                        shippingPriceFee = shippingPrice;
                        promoCodePrice = shippingPrice;
                    }
                    totalHargaFix = totalHargaFix - shippingPriceFee + paymentFee;
                }else if(promoCodeTypeId === 103){
                    if(shippingPriceFee === 0){
                        shippingPrice = 0;
                        checkoutUserSession.setPromoId(0);
                        checkoutUserSession.setPromoTypeId(0);
                        checkoutUserSession.setPromoType('');
                        checkoutUserSession.setPromoName('');
                        checkoutUserSession.setPromoPrice(0);
                        checkoutUserSession.settotalPriceAfterPromo(0);
                        checkoutUserSession.settotalPriceBeforePromo(0);
                        promoCodeName = '';
                    }else if(promoCodePrice > shippingPriceFee){
                        promoCodePrice = 0;
                    }else{
                        shippingPrice = promoCodePrice;
                    }
                    totalHargaFix = totalHargaFix - shippingPriceFee + promoCodePrice;
                }
            }
        }

            if(totalHargaFix < 0){
                totalHargaFix = 0;
            }

            $('#div-paymentMethod')[paymentName === '' ? 'hide':'show']();

            function goToTopUpPage() {
                history.push("/EzeeTunai/TopUp?from=checkout");
            }

            function showAllProducts(show){
                setShowAllProds(show);
            }

            return (
                <div className='container-checkout-page'>
                    <NavbarCloseOnlyWithLogo link={location.state !== undefined ? (location.state.isFromBuyNow ? location.state.url : "/cart") : "/cart"}/>
                    <section className="section-page">
                        <Row>
                            <Col span={24}>
                                <div className="box-alert-info" style={{marginTop: 95, maxWidth: 430}}>
                                    <img src="https://wwwd.ezeepasar.com/ezPasar1/Images/alert_info_back.png" alt="" className="box-alert-info-icon"/>
                                    <p>Ini adalah halaman terakhir dari proses belanja kamu, Pastikan semua data sudah terisi dengan benar.</p>
                                </div>
                            </Col>
                            {totalPrice !== 0 ? 
                            <>
                            <Col span={24} style={{marginTop: 10}}>
                                <div className="alamat-pengiriman-sec" style={{}}>
                                    {
                                        shippingDate !== '' ? 
                                        <>
                                            <div>
                                                <div style={{marginTop: 8}}>
                                                    <img src={mapping_icon} alt=""/>
                                                    {ekspedisi_id !== 102 ? <h5 className="title" style={{marginLeft: 37}}>Alamat Pengiriman</h5> : <h5 className="title" style={{marginLeft: 37}}>Lokasi Pengambilan</h5>}
                                                </div>
                                                <div className="found_address" style={{margin: '20px 0 0 37px'}} id={ checkoutDetail.address.address_id||address_id}>  
                                                    {ekspedisi_id !== 102 ? 
                                                    <>
                                                        <div onClick={() => toListAddress()}><span className='editAddress'>Ganti</span></div>
                                                        <p className="content-2">{ address_name}</p>                                  
                                                        <p className="content-2">{ receiver_mobile}</p>                                  
                                                        <p className="content"> { address} </p>
                                                    </>
                                                    : 
                                                    <>
                                                        <p onClick={() => goToDepoLocation()} className='editAddress'>Lihat Lokasi</p>
                                                        <p className="content-2">{ address_name }</p>                                 
                                                        <p className="content"> { address }</p>
                                                    </>
                                                    }                                
                                                </div>
                                            </div>
                                        </> : 
                                            <div>
                                                <div style={{marginTop: 8}}>
                                                    <img src={mapping_icon} alt=""/>
                                                    <h5 className="title" style={{marginLeft: 37}}>Alamat Pengiriman</h5>
                                                </div>
                                                <div style={{textAlign: 'center'}} onClick={() => toListAddress()}>
                                                    <Link><button className='btn-add-address-checkout'>Tambah Alamat Kirim</button></Link>
                                                </div>
                                                <br/>
                                            </div>
                                    }
                                </div>
                            </Col>
                            {
                                shippingDate !== '' ? 
                                <>
                                <Col span={24} style={{marginTop: 10}}>
                                    <div className="metode-pengiriman-sec" style={{height: 135}}>
                                        <div>
                                            <div style={{marginTop: 8}}>
                                                <img src={shipping_icon} alt=""/>
                                                <h5 className="title" style={{marginLeft: 37}}>Metode Pengiriman</h5>
                                            </div>
                                            <div className="found_address" style={{margin: '20px 0 0 37px'}}>  
                                                <span onClick={() => linkToDeliveryMethod()} className="editAddress">Ganti</span>
                                                <Row>
                                                    <Col span={15}>
                                                        <span className="content-2">
                                                            {
                                                                ekspedisi_name === "" ? checkoutDetail.shipping.nama : ekspedisi_name
                                                            } 
                                                            ({shippingPriceFee === 0 ? <span>Gratis</span> : moneyFormat(shippingPriceFee)})</span> 
                                                        <br/>                                                               
                                                        <span className="content">
                                                            {
                                                                ekspedisi_id === 102 ? <span>Batas Pengambilan</span> : <span>Akan tiba,</span>
                                                            }&nbsp; 
                                                            {shippingDate}
                                                        </span>  
                                                        {
                                                            timeFrame === null || timeFrame.id === 0 || timeFrame.id === undefined ? <></> : ekspedisi_id === 102 ? <></> : <div className='vertical-line-checkout'></div> 
                                                        }
                                                    </Col>
                                                    <Col span={9}>
                                                        <span className='content' style={{float: 'right'}}>
                                                        {
                                                            ekspedisi_id === 102 ? <></> : 
                                                            (timeFrame === null || timeFrame.id === 0 || timeFrame.id === undefined ? <></> : 
                                                            <>
                                                            Waktu Kirim <br/> {timeFrame.name}
                                                            </>)
                                                        }
                                                        </span>  
                                                    </Col>
                                                </Row>                                
                                            </div>
                                        </div>
                                    </div>
                                </Col>                                
                                </> : 
                                <Col span={24} style={{marginTop: 10}}>
                                    <div className="metode-pengiriman-sec" style={{height: 117}}>
                                        <div>
                                            <div style={{marginTop: 8}}>
                                                <img src={shipping_icon} alt=""/>
                                                <h5 className="title" style={{marginLeft: 37}}>Metode Pengiriman</h5>
                                            </div>
                                            <div className="found_address text-address-not-found" style={{margin: '20px 0 0 37px'}}>  
                                                <p>Pilihan metode pengiriman akan tersedia</p>
                                                <p>setelah anda mengisi alamat pengiriman</p>
                                            </div>
                                        </div>
                                    </div>
                                </Col>                                
                            }
                            </> :
                            <>
                              <Col span={24} style={{marginTop: 10}}>
                                <div className="alamat-pengiriman-sec" style={{}}>
                                    <div>
                                        <div style={{marginTop: 8}}>
                                            <img src={mapping_icon} alt=""/>
                                            <h5 className="title" style={{marginLeft: 37}}>Alamat Pengiriman</h5>
                                        </div>
                                        <div style={{textAlign: 'center'}} onClick={() => toListAddress()}>
                                           <Link><button className='btn-add-address-checkout'>Tambah Alamat Kirim</button></Link>
                                        </div>
                                        <br/>
                                    </div>
                                </div>
                            </Col>
                            <Col span={24} style={{marginTop: 10}}>
                                <div className="metode-pengiriman-sec" style={{height: 117}}>
                                    <div>
                                        <div style={{marginTop: 8}}>
                                            <img src={shipping_icon} alt=""/>
                                            <h5 className="title" style={{marginLeft: 37}}>Metode Pengiriman</h5>
                                        </div>
                                        <div className="found_address text-address-not-found" style={{margin: '20px 0 0 37px'}}>  
                                            <p>Pilihan metode pengiriman akan tersedia</p>
                                            <p>setelah anda mengisi alamat pengiriman</p>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            </>   
                        }
                        </Row>
                    </section>
                    <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
                    <div className="daftar-pesanan-sec">
                        <section className="main">                    
                                <Row style={{borderBottom: 'unset', marginBottom: 15, marginTop: 15}}>
                                    <Col span={24}>
                                        <h5 className="title">Daftar Pesanan</h5>
                                    </Col>
                                </Row>
                            {
                                showAllProds ? 
                                    checkoutDetail.product.map(products => {
                                        return(
                                            <div id="product">
                                                        <Row>
                                                            <Col span={7} style={{textAlign: "center", paddingLeft: 0, paddingRight: 0}}>
                                                                <img alt="" src={products.product_image} style={{width: 95}}/>
                                                            </Col>
                                                            <Col span={7} className="product-sec-title">
                                                                <span className="name">{products.product_name}</span>
                                                                <br/>
                                                                <span className="weight">{products.product_weight}{products.product_weight_name}</span>
                                                            </Col>
                                                            <Col span={10} className="priceqty">
                                                                <span className="items">{products.quantity} {products.product_unit_name}</span>
                                                                <br/>
                                                                {
                                                                    products.product_promo_price !== null ? <div className='disc-product' style={{float: 'right'}}>
                                                                        <span className="real-price" style={{marginRight: 10}}>Rp{ convertToRupiah(products.product_price) }</span><span className="price-enable">
                                                                    Rp{convertToRupiah(products.product_promo_price)} / {products.product_unit_name}</span>
                                                                    </div> : <span className="price-enable">Rp{convertToRupiah(products.product_price)}</span>
                                                                }
                                                            </Col>
                                                        </Row>                                            
                                            </div>
                                        )
                                    }) : 
                                    <div id="product">
                                        {
                                            checkoutDetail.product.length > 1 && !showAllProds ?
                                            <>
                                                <Row>
                                                    <Col span={7} style={{textAlign: "center", paddingLeft: 0, paddingRight: 0}}>
                                                        <img alt="" src={checkoutDetail.product[0].product_image} style={{width: 95}}/>
                                                    </Col>
                                                    <Col span={7} className="product-sec-title">
                                                        <span className="name">{checkoutDetail.product[0].product_name}</span>
                                                        <br/>
                                                        <span className="weight">{checkoutDetail.product[0].product_weight}{checkoutDetail.product[0].product_weight_name}</span>
                                                    </Col>
                                                    <Col span={10} className="priceqty">
                                                        <span className="items">{checkoutDetail.product[0].quantity} {checkoutDetail.product[0].product_unit_name}</span>
                                                        <br/>
                                                        {
                                                            checkoutDetail.product[0].product_promo_price !== null ? <div className='disc-product' style={{float: 'right'}}>
                                                                <span className="real-price" style={{marginRight: 10}}>Rp{ convertToRupiah(checkoutDetail.product[0].product_price) }</span><span className="price-enable">
                                                            Rp{convertToRupiah(checkoutDetail.product[0].product_promo_price)} / {checkoutDetail.product[0].product_unit_name}</span>
                                                            </div> : <span className="price-enable">Rp{convertToRupiah(checkoutDetail.product[0].product_price)}</span>
                                                        }
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col span={7} style={{textAlign: "center", paddingLeft: 0, paddingRight: 0}}>
                                                        <img alt="" src={checkoutDetail.product[1].product_image} style={{width: 95}}/>
                                                    </Col>
                                                    <Col span={7} className="product-sec-title">
                                                        <span className="name">{checkoutDetail.product[1].product_name}</span>
                                                        <br/>
                                                        <span className="weight">{checkoutDetail.product[1].product_weight}{checkoutDetail.product[1].product_weight_name}</span>
                                                    </Col>
                                                    <Col span={10} className="priceqty">
                                                        <span className="items">{checkoutDetail.product[1].quantity} {checkoutDetail.product[1].product_unit_name}</span>
                                                        <br/>
                                                        {
                                                            checkoutDetail.product[1].product_promo_price !== null ? <div className='disc-product' style={{float: 'right'}}>
                                                                <span className="real-price" style={{marginRight: 10}}>Rp{ convertToRupiah(checkoutDetail.product[1].product_price) }</span><span className="price-enable">
                                                            Rp{convertToRupiah(checkoutDetail.product[1].product_promo_price)} / {checkoutDetail.product[1].product_unit_name}</span>
                                                            </div> : <span className="price-enable">Rp{convertToRupiah(checkoutDetail.product[1].product_price)}</span>
                                                        }
                                                    </Col>
                                                </Row>
                                            </> : 
                                            checkoutDetail.product.map(products => {
                                                return(
                                                    <Row>
                                                        <Col span={7} style={{textAlign: "center", paddingLeft: 0, paddingRight: 0}}>
                                                            <img alt="" src={products.product_image} style={{width: 95}}/>
                                                        </Col>
                                                        <Col span={7} className="product-sec-title">
                                                            <span className="name">{products.product_name}</span>
                                                            <br/>
                                                            <span className="weight">{products.product_weight}{products.product_weight_name}</span>
                                                        </Col>
                                                        <Col span={10} className="priceqty">
                                                            <span className="items">{products.quantity} {products.product_unit_name}</span>
                                                            <br/>
                                                            {
                                                                products.product_promo_price !== null ? <div className='disc-product' style={{float: 'right'}}>
                                                                    <span className="real-price" style={{marginRight: 10}}>Rp{ convertToRupiah(products.product_price) }</span><span className="price-enable">
                                                                Rp{convertToRupiah(products.product_promo_price)} / {products.product_unit_name}</span>
                                                                </div> : <span className="price-enable">Rp{convertToRupiah(products.product_price)}</span>
                                                            }
                                                        </Col>
                                                    </Row> 
                                                )
                                            })
                                        }
                                    </div>
                            }
                            {
                                checkoutDetail.product.length <= 2 ? 
                                null :
                                !showAllProds ?                             
                                    <Row style={{borderBottom: 'unset', textAlign: 'center', marginTop: '10%'}}>
                                        <Col span={24}>
                                            <h5 className="title" style={{color: '#3DB54A', cursor: 'pointer'}} onClick={() => showAllProducts(true)}>Lihat Semua</h5>
                                        </Col>
                                    </Row> : 
                                    <Row style={{borderBottom: 'unset', textAlign: 'center', marginTop: '10%'}}>
                                        <Col span={24}>
                                            <h5 className="title" style={{color: '#3DB54A', cursor: 'pointer'}} onClick={() => showAllProducts(false)}>Sembunyikan</h5>
                                        </Col>
                                    </Row>
                            }
                        </section>
                    </div>
                    <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
                    <section>
                        <div className="metode-pembayaran-sec" style={paymentId !== 0 ? {marginLeft: 25, marginRight: 25 ,height: 100} : {marginLeft: 25, marginRight: 25 ,height: 65}}>
                            <Row>
                                <Col span={24}>
                                    <div style={{marginTop: 8, cursor: 'pointer'}} onClick={() => setVisible(true)}>
                                        <img src={payment_icon} alt=""/>
                                        <div>
                                            <h5 className="title" style={{marginLeft: 37}}>Metode Pembayaran &nbsp; <img src={arrow_icon} style={{float: 'right'}}  alt=""/></h5>
                                        </div>
                                        {paymentId !== 0 ? 
                                        <div style={{marginLeft: "10%"}} id="div-paymentMethod">
                                            <img src={paymentImg} alt="" style={{width: 32}}/> &nbsp;
                                            {paymentId === 103 ? <span>{paymentName} <b>(Rp{convertToRupiah(paymentSaldo)})</b></span> : <span>{paymentName}</span>}
                                        </div> : <></>}
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </section>
                    <CashbackModal isOpen={isCashback.visible} onClosed={() => setIsCashback({status: true, visible: false, cashbackDetail: isCashback.cashbackDetail})} data = {isCashback.status ? isCashback.cashbackDetail : ""}/>
                    <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
                    <section>
                        {promoCodeName !== '' ? 
                                    <div className="promo-sec activated_promo" style={{height: 65, marginLeft: 25, marginRight: 25}} onClick={() => linkToPromoPage()}>
                                        <Row>
                                            <Col span={24}>
                                                <Link><div style={{marginTop: 8}}>
                                                    <img src={promo_icon} alt=""/>
                                                    <div style={{lineHeight: '10px'}}>
                                                        <h5 className="title-promo-checkout" style={{marginLeft: 37}}>{promoCodeName}</h5>
                                                        <span style={{marginLeft: 37, font: 'normal normal 11px/0px Open Sans', color: '#848484'}} className="title-promoused">
                                                        1 promo digunakan
                                                        </span>
                                                    </div>
                                                    
                                                        <img src={close_icon} style={{float: 'right', marginTop: '-11%'}} onClick={() => unusePromoCode()} alt=""/>
                                                    
                                                </div>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </div>    
                                    : 
                                    <div className="promo-sec" style={{height: 65, marginLeft: 25, marginRight: 25, cursor: 'pointer'}}>
                                        <Row>
                                            <Col span={24}>
                                                <span onClick={() => linkToPromoPage()}><div style={{marginTop: 8}}>
                                                    <img src={promo_icon} alt=""/>
                                                    <div>
                                                        <h5 className="title" style={{marginLeft: 37}}>Lebih Hemat Pakai Promo <img src={arrow_icon} style={{float: 'right'}}  alt=""/></h5>
                                                    </div>                                                   
                                                </div>
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>}                
                    </section>
                    <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>                    
`                    <section id="priceInformationSec">
                        <Row style={{borderBottom: 'unset'}}>
                            <Col span={24}>
                                <h5 className="title" style={{minHeight: 0, marginTop: 25, marginLeft: 25}}>Rincian Biaya</h5>
                            </Col>
                        </Row>
                        <Row style={{marginLeft: 25, marginRight: 25}} className="detailTrx">
                            <Col span={12} style={{textAlign: 'left'}}>
                                <span>Total Belanja ( {totalItem} item )</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right', fontWeight: 600}}>
                                <span>Rp{convertToRupiah(priceProds)}</span>
                            </Col>
                            <br/>
                            {
                                promoCodeTypeId === 102 ? 
                                <>
                                <Col span={12} style={{textAlign: 'left', color: 'red'}}>
                                    <span style={{ marginBottom: -7}}>Diskon</span>
                                </Col>                    
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600,color: 'red'}}>
                                    
                                </Col>
                                <br/>
                                <Col span={12} style={{textAlign: 'left',color: 'red'}}>
                                    <span>Kode Promosi {promoCodeName}</span>
                                </Col>
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600, color: 'red'}}>
                                    <span>-Rp{convertToRupiah(promoCodePrice)}</span>
                                </Col>
                                <br/>
                                </>
                            : null}
                            <Col span={12} style={{textAlign: 'left'}}>
                                <span>Biaya Kirim</span>
                            </Col>
                            {
                                promoCodeTypeId === 103 && shippingPrice !== 0 ? 
                                <>
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600}}>
                                    <span style={{textDecorationLine : 'line-through'}}>Rp{convertToRupiah(shippingPriceFee)}</span>
                                </Col>
                                </>
                                : 
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600}}>
                                    <span>Rp{convertToRupiah(shippingPriceFee)}</span>
                                </Col>
                            }
                            <br/>
                            {
                                promoCodeTypeId === 101 ? 
                                <>
                                <Col span={12} style={{textAlign: 'left', color: 'red'}}>
                                    <span style={{ marginBottom: -7}}>Potongan Ongkir</span>
                                </Col>                    
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600,color: 'red'}}>
                                    
                                </Col>
                                <br/>
                                <Col span={12} style={{textAlign: 'left',color: 'red'}}>
                                    <span>Kode Promosi {promoCodeName}</span>
                                </Col>
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600, color: 'red'}}>
                                    <span>-Rp{convertToRupiah(promoCodePrice)}</span>
                                </Col>
                                <br/>
                                </>
                            : promoCodeTypeId === 103 && shippingPrice !== 0? 
                                <>
                                <Col span={12} style={{textAlign: 'left', color: 'red'}}>
                                    <span style={{ marginBottom: -7}}>FLAT ONGKIR</span>
                                </Col>                    
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600,color: 'red'}}>
                                    
                                </Col>
                                <br/>
                                <Col span={12} style={{textAlign: 'left',color: 'red'}}>
                                    <span>Kode Promosi {promoCodeName}</span>
                                </Col>
                                <Col span={12} style={{textAlign: 'right', fontWeight: 600, color: 'red'}}>
                                    <span>Rp{convertToRupiah(promoCodePrice)}</span>
                                </Col>
                                <br/>
                                </>
                            : null
                            }
                            <Col span={12} style={{textAlign: 'left'}}>
                                <span>Biaya Administrasi</span>
                            </Col>
                            <Col span={12} style={{textAlign: 'right', fontWeight: 600}}>
                                <span>Rp{convertToRupiah(paymentFee)}</span>
                            </Col>
                        </Row>
                    </section>                    
                    <div className="break-page" style={{marginLeft: -25, marginRight: -25, height: 2}}></div>
                        <Row style={{borderBottom: 'unset', marginLeft: 25, marginRight: 25,fontFamily: 'open sans', fontSize: 12}}>
                            <Col span={12} style={{textAlign: 'left'}}>
                                <b>Total Pembayaran</b>
                            </Col>
                            <Col span={12} style={{textAlign: 'right', fontWeight: 600}}>
                                <b>Rp{checkoutDetail.address.address !== null ? convertToRupiah(totalHargaFix) : convertToRupiah(priceProds)}</b>
                            </Col>
                        </Row>
                    <br/>   
                    <br/>
                    <br/>
                    <div className="container">
                    <div className="div-modal-addCard">
                        <Modal
                        title="Pilih Metode Pembayaran"
                        visible={visible}
                        // onOk={closedModal}
                        onCancel={onClosed}
                        className="modalAddCart modalPaymentMethod" 
                        footer = {null}
                        style={{borderRadius: 15}}             
                        >
                        <table style={{width: '100%', margin: '0 auto'}} className="tbl-payment-method">
                            <tbody>
                                {/* <tr className="row-payment-method">
                                    <td style={{width: 35, lineHeight: 5}}><img src='https://api.ezeelink.co.id/resources/ezeepasar/payment-type/ezeetunai-gold.png' alt="" style={{width: 35}}/></td>
                                    <td style={{width: 240, paddingLeft: 15}}>
                                        <span className="typename">ezeeTunai</span>
                                    </td>
                                    <td onClick={toEzTunaiBinding} style={{textAlign: 'right'}}>
                                        <div>
                                            <button className="btn-aktivasi-et">Hubungkan</button>
                                        </div>
                                    </td>
                                </tr> */}
                                {(loadingPayType) ? 
                                <>
                                    <ModalPaymentMethodLoading/>
                                </> :                                 
                                    paymentMethodList.map(paymentLists => {
                                        return(
                                            <tr className="row-payment-method">
                                                <td style={{width: 35, lineHeight: 5}}><img src={paymentLists.img} alt="" style={{width: 35}}/></td>
                                                {
                                                        paymentLists.paytype_id === 103 ? [
                                                            (paymentLists.ezeetunai.is_active === true ? 
                                                                [
                                                                    (paymentLists.ezeetunai.saldo >= totalHargaFix ?
                                                                        <>
                                                                            <td style={{width: 240, paddingLeft: 15}}>
                                                                                <span className="typename">{paymentLists.paytype_name} <b>(Rp{convertToRupiah(paymentLists.ezeetunai.saldo)})</b></span>
                                                                                <div>
                                                                                    <span style={{ fontSize: 12, fontWeight: 600, color: '#3DB54A' }}>{`+${bindingList[0].mcust_mobile} - ${bindingList[0].mcust_name} ${bindingList[0].status_prime ? '(Prime)' : ''}`}</span>
                                                                                </div>
                                                                            </td>
                                                                            <td style={{textAlign: 'right'}}>
                                                                                <input type="radio" name="radio" value={paymentLists.paytype_id} style={{marginRight: '15%'}} onClick={() => selectPaymentMethod(paymentLists.paytype_id, paymentLists.paytype_name, paymentLists.paytype_fee, paymentLists.img, paymentLists.ezeetunai.saldo)}/>
                                                                            </td>
                                                                        </>
                                                                    : 
                                                                        <>
                                                                        <td style={{width: 240, paddingLeft: 15}}>
                                                                            <span>{paymentLists.paytype_name} (Rp{convertToRupiah(paymentLists.ezeetunai.saldo)})</span>
                                                                            <br/>
                                                                            <span className="error-text">Saldo Anda Tidak Cukup</span>
                                                                        </td>
                                                                        <td style={{textAlign: 'right'}}>
                                                                            <button className="btn-aktivasi-et topup" onClick={() => goToTopUpPage()} style={{backgroundColor : '#3DB54A', border: '1px solid #3DB54A', color: 'white'}}>TopUp</button>
                                                                        </td>
                                                                        </>
                                                                    )
                                                                ]
                                                            : <>
                                                                <td style={{width: 240, paddingLeft: 15}}>
                                                                    <span className="typename">{paymentLists.paytype_name}</span>
                                                                    <br/>
                                                                    <span className="sub-class-typename">Bayar Cepat dan Mudah</span>
                                                                </td>
                                                                <td onClick={toEzTunaiBinding} style={{textAlign: 'right'}}>
                                                                    <button className="btn-aktivasi-et">Hubungkan</button>
                                                                    {/* <a href="https://play.google.com/store/apps/details?id=com.ezee.main&hl=in&gl=US" target={"_blank"} rel="noopener noreferrer">
                                                                        <button className="btn-aktivasi-et">Aktivasi</button>
                                                                    </a> */}
                                                                </td>
                                                            </>
                                                            )
                                                        ]
                                                    : <>
                                                        <td style={{width: 240, paddingLeft: 15}}>
                                                            <span className="typename">{paymentLists.paytype_name}</span>                                                   
                                                        </td>
                                                        <td style={{textAlign: 'right'}}>
                                                            <input type="radio" name="radio" value={paymentLists.paytype_id} style={{marginRight: '15%'}} onClick={() => selectPaymentMethod(paymentLists.paytype_id, paymentLists.paytype_name, paymentLists.paytype_fee, paymentLists.img)}/>
                                                        </td>
                                                    </>
                                                    }
                                            </tr>
                                        )
                                    })
                                }                                                        
                            </tbody>
                        </table>
                        <br/>
                        <br/>
                        </Modal>
                    </div>
                </div>
                <div>                   
                    {
                        isCashback.status ? 
                            <Alert showIcon icon={<><img alt="" className='blink-image' style={{position: 'absolute', top: 0, left: 4, }} src={snackbarIconCashback}/></>} type="success" style={{fontSize: 12}} message={HtmlParser(isCashback.cashbackDetail.cashback_value)} action={<u style={{color: '#3DB54A', fontWeight: 600, fontSize: 12}} onClick={() => openCashbackModalDetail()}>Lihat Detail</u>}/>                    
                        : <div></div>
                    }                   
                    <CashbackModalDetail isOpen={isCashbackDetail} onClosed={() => setIsCashbackDetail(false)} data_snk={isCashbackDetail ? isCashback.cashbackDetail.mcb_snk : null } data_desc={isCashbackDetail ? isCashback.cashbackDetail.mcb_desc : null}/>
                    <Footer 
                        paymentMethodId={paymentId}
                        harga={checkoutDetail.address.address !== null ? convertToRupiah(totalHargaFix) : convertToRupiah(priceProds)}
                        addressId={address_id || checkoutDetail.address.address_id}
                        dateKirim={moment(shippingDate, "dddd,DD MMMM YYYY").format("yyyy-MM-DD")}
                        promoCode={promoCodeName} 
                        ekspedisiId={ekspedisi_id}
                        is_cashback = {isCashback.status}
                        timeFrame = {timeFrame === null || timeFrame === undefined ? 0 : (timeFrame.id === undefined || timeFrame.id === '' ? 0 : timeFrame.id)}
                        agentcode = {agentCode === null ? "" : agentCode}
                        removeCashbackSnackbar = {removeCashbackSnackbar}
                        btnIsDisabled = {shippingDate === '' ? true : false}
                    />
                </div>
            </div>
            )
       }
    }
}

export default OnePageCheckout
