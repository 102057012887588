import React, {useState, useEffect, useCallback} from 'react'
import {Row, Col, Checkbox, Modal, InputNumber} from 'antd'
import { useFetchWithoutData, useFetch } from '../../customHooks/useFetch';
import OtherProduct from '../../components/HomePage/OtherProduct';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import Footer from './FooterCart';
import { getToken } from '../../utils/setSessionUser';
import $ from 'jquery'
import './AvailableProduct.css'
import { fetchCartList } from '../../redux/ActionCreators/getCartListActions'
import UnavailableProducts from './UnavailableProducts';
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import warningIcon from '../../Asset/Icon/warning-cart.png';
import plusIconEnable from '../../Asset/Icon/plus_icon_enable.svg';
import plusIconDisable from '../../Asset/Icon/plus_icon_disabled.svg';
import minusIconEnable from '../../Asset/Icon/minus_icon_enable.svg';
import minusIconDisable from '../../Asset/Icon/minus_icon_disabled.svg';
import iconFeatherTrash2 from '../../Asset/Icon/feather_trash-2.svg'
import iconFeatherMinusCircleDisable from '../../Asset/Icon/feather_minus-circle-disable.svg'
import iconFeatherMinusCircleEnable from '../../Asset/Icon/feather_minus-circle-enable.svg'
import iconFeatherPlusCircleDisable from '../../Asset/Icon/feather_plus-circle-disable.svg'
import _ from 'lodash';
import {BaseURL, useTitle, headersWithToken} from '../../function/helpers';

function AvailableProduct() {
    useTitle("Keranjang Pemesanan")

    const [ counter, setCounter ] = useState(1);
    const [ cartLists, setCartLists] = useState([])
    const [ loading, setLoading ] = useState(true);
    const [ isCheckAll, setIsCheckAll] = useState();
    const [ isChecked, setIsChecked] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [deleteProdId, setDeleteProdId] = useState(0);
    const [ totalItem, setTotalItem ] = useState({ price: 0, total: 0});
    let priceProds = 0;
    let itemProds = 0;
    let cartArr = [];
    let cartOutOfStockArr = [];
    var access_token = getToken(); 
    
    useEffect(() => {
        getCartList();      
    }, [])

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'decimal', currency: 'IDR'}).format(money)
    }

    async function getCartList(){
        var access_token = getToken();
        const signature = base64Sign("/cart/getcartlist");   
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/cart/getcartlist',{data:""},{headers:headers}).then(data=>{
            setCartLists(data.data.response_data.results);
            setLoading(false)
        }).catch(err => console.log(err));
    }

    if(loading){        
        return(
           <LoadingModal/>
        )        
    }

    function preventChar(evt){
        if (evt.which !== 8 && evt.which !== 0 && evt.which < 48 || evt.which > 57)
        {
            evt.preventDefault();
        }
    }

    function disableForMaxQty(prod_id, flag){
        var IconDis = $("#imgPlus_"+prod_id);
        if(flag === 1){         
           $("#alertMaxQty_"+prod_id).css('visibility','visible');
           IconDis.attr('src', plusIconDisable);
           IconDis.prop("disabled",true);
        }else{
           $("#alertMaxQty_"+prod_id).css('visibility','hidden');
           IconDis.attr('src', plusIconEnable);
           IconDis.attr('disabled', false);
        }
    }

    const plusCart = (prod_id, maxQty, remainingStock) => {
        setCartLists(
            state => ({
                ready_stock : state.ready_stock.map( q => {
                    if(q.product_id === prod_id){
                        return {
                            ...q,
                            quantity : q.quantity + 1,
                        }
                    }else{
                        return {
                            ...q
                        }
                    }
                }),
                out_of_stock : state.out_of_stock
            }),            
        );

        let qtyUpdated = cartLists.ready_stock.find(q => q.product_id === prod_id).quantity + 1;

        if(maxQty !== null){
            if(qtyUpdated >= maxQty){
                disableForMaxQty(prod_id, 1);
            }
        }
        
        if(remainingStock !== null){
            if(qtyUpdated >= remainingStock){
                disableForMaxQty(prod_id, 1);
            }
        }
        hitAPIAfterDebounce(prod_id, qtyUpdated);
    }

    function minusCart(prod_id, maxQty, remainingStock){
        setCartLists(
            state => ({
                ready_stock : state.ready_stock.map( q => {
                    if(q.product_id === prod_id){
                        return {
                            ...q,
                            quantity : q.quantity - 1,
                        }
                    }else{
                        return {
                            ...q
                        }
                    }
                }),
                out_of_stock : state.out_of_stock

            })
        );
        let qtyUpdated = cartLists.ready_stock.find(q => q.product_id === prod_id).quantity - 1;
        
        if(remainingStock !== null){
            if(qtyUpdated < remainingStock){
                disableForMaxQty(prod_id, 2);
            }
        }

        if(maxQty !== null){
            if(qtyUpdated < maxQty){
                disableForMaxQty(prod_id, 2);
            }
        }
        
        hitAPIAfterDebounce(prod_id, qtyUpdated);

    }

    
    function hitAPIAfterDebounce(prod_id,qtyUpdated){
        const debounced = _.debounce(function(){testfunc(prod_id, qtyUpdated)}, 500);
        debounced(prod_id, qtyUpdated);
    }

    function testfunc(prod_id){
        var wt = $("#inpQty_"+prod_id).val();
        updateQuantity(prod_id, wt);
    }

    
    function debounceAPI(prod_id, maxQty, remainingStock){
        updateQuantityOnKeyUp(prod_id, maxQty, remainingStock);
        let wt = $("#inpQty_"+prod_id).val();
        const debounced = _.debounce(function(){
            if(maxQty !== null){
                if(wt >= maxQty){
                    updateQuantity(prod_id, maxQty)
                }
            }else if(remainingStock !== null){
                if(wt >= remainingStock){
                    updateQuantity(prod_id, remainingStock)
                }
            }
            else{
                updateQuantity(prod_id, wt)
            }
        }, 500);
        debounced(prod_id, wt);
        // console.log(wt, maxQty);
    }

    var arrFiltered = cartLists.ready_stock.filter(function (el){
        return el.is_checked === true
    }); 
   
    if(arrFiltered.length < 1){
        itemProds = 0;
    }else{
        let qtyPrd =  arrFiltered.map(item => item.quantity).reduce((prev, next) => prev + next);

        itemProds = qtyPrd;
    }

    var sum = 0;

    arrFiltered.forEach(function(value, index, arry){
        if(value.product_promo_price !== null){
            sum += value.product_promo_price * value.quantity; 
        }else{
            sum += value.product_price * value.quantity; 
        }    
    });

    priceProds = sum;

    for (var i = 0; i < cartLists.ready_stock.length; i++){
        if(cartLists.ready_stock[i].is_checked === true){
            cartArr.push(cartLists.ready_stock[i].tcartlog_id);            
        }
    }

    if(cartLists.out_of_stock === []){
        cartOutOfStockArr = [];
    }else{
        for (var x = 0; x < cartLists.out_of_stock.length; x++){
            cartOutOfStockArr.push(cartLists.out_of_stock[x].tcartlog_id);
        }
    }

    function handleSelectAll(){
        setIsCheckAll(!isCheckAll);
        cartArr.push(cartLists.ready_stock.map(data => data.tcartlog_id));
        if(isCheckAll){
            cartArr=[];
            checkedAll(false);
            setIsCheckAll(false);
            getCartList();
            setTotalItem({...totalItem, price:0, total: 0});
        }else{
            checkedAll(true);    
            getCartList();                
            setIsCheckAll(true);
        }
    }
     
    function deleteMultipleProducts(tcartlog_id){
        deleteCartProd(tcartlog_id);
        setIsModalVisible(false);

    }

    function deleteOutofStock(){
        deleteOutOfStockCart();
    }

    async function updateQuantity(prod_id, qty) {
        var access_token = getToken();     
        
        try{
            const signature = base64Sign("/cart/addminusitemqty");
            const dataParams = encryptData(`{"product_id":${prod_id}, "qty": ${qty}}`);
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/cart/addminusitemqty',{data: dataParams},{headers : headers});
            setTotalItem({...totalItem, price: dataProds.data.response_data.results.total_harga,total: dataProds.data.response_data.results.total_item});
        }catch(error){

        }
    }

    async function deleteCartProd(tcartlog_id) {
        try{    
            let requests = JSON.stringify(cartArr);
            const signature = base64Sign("/cart/deletecart");
            var dataParams = null;
            if(tcartlog_id === undefined){
                dataParams = encryptData(`{"ListID":${requests}}`);
            }else{
                dataParams = encryptData(`{"ListID":[${tcartlog_id}]}`);
            }
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/cart/deletecart',{data: dataParams},{headers : headers});
            getCartList();
        }catch(error){
            console.log(error);
        }
    }

    async function deleteOutOfStockCart() {
        try{    
            let requests = JSON.stringify(cartOutOfStockArr);
            const signature = base64Sign("/cart/deletcart");
            const dataParams = encryptData(`{"ListID":${requests}}`);
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/cart/deletecart',{data: dataParams},{headers : headers});
            // window.location.reload();
            getCartList();
        }catch(error){
            console.log(error);
        }
    }

    function handleCheckboxChange(cartlogId){
        let selected = cartArr;
        let find = selected.indexOf(cartlogId);
        if(find > -1){
            selected.splice(find, 1);
            updateCartunChecklist(cartlogId); 
            
        } else {
            selected.push(cartlogId);
            updateCartChecklist(cartlogId);                
            $("checkboxProd_"+cartlogId).prop("checked", false);     
        }
    }    

    function updateQuantityOnKeyUp(prod_id, maxQty, remainingStock){
        let qty = parseInt($("#inpQty_"+prod_id).val());

        setCartLists(
            state => ({
                ready_stock : state.ready_stock.map( q => {
                    if(q.product_id === prod_id){  
                        if(qty < 1){
                            return {
                                ...q,
                                quantity : isNaN(qty) === true ? 1 : 1,
                            } 
                        }                             
                        if(maxQty !== null){
                            if(qty > maxQty){
                                return {
                                    ...q,
                                    quantity : isNaN(qty) === true ? 1 : maxQty,
                                }
                            }else{
                                return {
                                    ...q,
                                    quantity : isNaN(qty) === true ? 1 : qty,
                                }
                            }
                        }
                        if(remainingStock !== null){
                            if(qty > remainingStock){
                                return {
                                    ...q,
                                    quantity : isNaN(qty) === true ? 1 : remainingStock,
                                }
                            }else{
                                return {
                                    ...q,
                                    quantity : isNaN(qty) === true ? 1 : qty,
                                }
                            }
                        }else{
                            return {
                                ...q,
                                quantity : isNaN(qty) === true ? 1 : qty,
                            }
                        }

                    }else{
                        return {
                            ...q
                        }
                    }
                }),
                out_of_stock : state.out_of_stock

            }),
        );
        if(maxQty !== null){
            if(qty >= maxQty){
                disableForMaxQty(prod_id, 1);
            }
        }
        if(remainingStock !== null){
            if(qty >= remainingStock){
                disableForMaxQty(prod_id, 1);
            }
        }
    }

    async function updateCartChecklist(tcartlog_id) {
        try{
            const signature = base64Sign("/cart/updatecartchecklist");
            const dataParams = encryptData(`{"ListID":[{"tcartlog_id":${tcartlog_id},"status":true}]}`);
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/cart/updatecartchecklist',{data: dataParams},{headers : headers});
            getCartList();
        }catch(error){
            console.log(error);
        }
    }

    async function updateCartunChecklist(tcartlog_id) {
        try{
            const signature = base64Sign("/cart/updatecartchecklist");
            const dataParams = encryptData(`{"ListID":[{"tcartlog_id":${tcartlog_id},"status":false}]}`);
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/cart/updatecartchecklist',{data: dataParams},{headers : headers});
            getCartList();
        }catch(error){
            console.log(error);
        }
    }

    async function checkedAll(statusCart){
        try{    
            let req = [];
            for (var i = 0; i < cartLists.ready_stock.length; i++) {
                const element = cartLists.ready_stock[i].tcartlog_id;
                req.push(({"tcartlog_id":element,"status":statusCart}));
            }
            let requests = JSON.stringify(req);
            const signature = base64Sign("/cart/updatecartchecklist");
            const dataParams = encryptData(`{"ListID":${requests}}`);
            const headers = headersWithToken(signature, access_token);
            const dataProds = await axios.post(BaseURL + '/cart/updatecartchecklist',{data: dataParams},{headers : headers});        
        }catch(error){
            console.log(error);
        }
    }

     const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };



    return (        
        <div style={{marginLeft: 25, marginRight: 25}}>
            <br/>
            <Row style={{marginTop: 50}}>
                <Col span={24}>
                    <span className="head-1-cart">Keranjang Belanja</span>
                </Col>
            </Row>
            <br/>
            {cartLists.ready_stock.length === 0 && cartLists.out_of_stock.length === 0 ? 
            <div>
                <UnavailableProducts/>
            </div> 
            : 
            <div>
                {cartLists.ready_stock.length !== 0 ?
                <div>
                    <div className="cartProduct">
                        <Row>
                            <Col span={20}>
                            <Checkbox onChange={() => handleSelectAll()} checked={cartArr.length === cartLists.ready_stock.length ? true : false} id="checkboxAll"></Checkbox>
                                <span className="head-2-cart">Pilih Semua Produk</span>
                            </Col>
                            <Col style={{float: 'right'}}>
                                <div onClick={() => showModal(setDeleteProdId(cartArr))}>
                                    <span className="head-2-cart" style={{color: '#35DB5A', fontWeight: 'bold', fontFamily: 'Open Sans', marginTop: 2, cursor: 'pointer'}}>Hapus</span>
                                </div>
                            </Col>
                        </Row>
                    </div>
                <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
                <br/>
                <div className="cartProduct">
                    <Row>
                        <Col span={24}>
                            <table style={{width: '100%'}} className="div-tbl-cartlist">
                                <tbody>
                                {
                                    cartLists.ready_stock.map((listProdsReadyStock, i) => {
                                        return(
                                            <>
                                                <tr key={i}>
                                                    <td className="checkbox-cartlist">
                                                        <Checkbox type="checkbox" onChange={() => handleCheckboxChange(listProdsReadyStock.tcartlog_id, listProdsReadyStock.product_price, listProdsReadyStock.quantity)} id={"checkboxProd_"+listProdsReadyStock.tcartlog_id} checked={listProdsReadyStock.is_checked}></Checkbox>
                                                    </td>
                                                    <td className="prodImage">
                                                        <img src={listProdsReadyStock.product_image} alt="" style={{width: 70, height: 70, marginLeft: 25}}/>
                                                    </td>
                                                    <td style={{verticalAlign: 'top', paddingLeft: 20, width: '100%'}}>
                                                        <div className="prod-name"><span>{listProdsReadyStock.product_name}</span></div>
                                                        <div className="satberat-name"><span>{listProdsReadyStock.product_weight} {listProdsReadyStock.product_weight_name}</span></div>
                                                        {
                                                            listProdsReadyStock.product_promo_percent == null
                                                            ? <></>
                                                            : <><div className="disc-product">
                                                                <span className="percent-disc">{ listProdsReadyStock.product_promo_percent }%</span>
                                                                <span className="real-price">Rp{ convertToRupiah(listProdsReadyStock.product_price) }</span>
                                                            </div>                                    
                                                            </>
                                                        }    
                                                         <div className="prod-harga-jual-enable"><span>Rp{listProdsReadyStock.product_promo_price !== null ? convertToRupiah(listProdsReadyStock.product_promo_price) : convertToRupiah(listProdsReadyStock.product_price)} / {listProdsReadyStock.product_unit_name}</span></div>                                                                                                           
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={2}></td>
                                                    <td style={{whiteSpace: 'nowrap', paddingLeft: 20}}>
                                                        <img src={iconFeatherTrash2} alt="" style={{marginRight: 15, marginBottom: 5, cursor: 'pointer'}} onClick={() => showModal(setDeleteProdId(listProdsReadyStock.tcartlog_id))}/>

                                                        {listProdsReadyStock.quantity > 1 ? <img src={minusIconEnable} alt="" className="" id={"imgMinus_"+listProdsReadyStock.product_id} onClick={() => minusCart(listProdsReadyStock.product_id, listProdsReadyStock.max_buy, listProdsReadyStock.remaining_stock)} style={{cursor: 'pointer'}}/> : <img src={minusIconDisable} alt="" style={{ marginBottom: 3, marginRight: 1 }} id={"imgMinus_"+listProdsReadyStock.product_id}/>}
                                                        <InputNumber type="number" className="inpQuantity" id={"inpQty_"+listProdsReadyStock.product_id} style={{cursor: 'pointer'}} 
                                                        value=
                                                        {
                                                            listProdsReadyStock.remaining_stock !== null ? [
                                                                listProdsReadyStock.quantity > listProdsReadyStock.remaining_stock ? listProdsReadyStock.remaining_stock : 
                                                                    (listProdsReadyStock.max_buy !== null ? (listProdsReadyStock.quantity > listProdsReadyStock.max_buy ? listProdsReadyStock.max_buy : listProdsReadyStock.quantity) : listProdsReadyStock.quantity)
                                                            ] : (listProdsReadyStock.max_buy !== null ? (listProdsReadyStock.quantity > listProdsReadyStock.max_buy ? listProdsReadyStock.max_buy : listProdsReadyStock.quantity) : listProdsReadyStock.quantity)
                                                        } 
                                                        onKeyPress={(e) => preventChar(e)} onKeyUp={() => debounceAPI(listProdsReadyStock.product_id, listProdsReadyStock.max_buy, listProdsReadyStock.remaining_stock)}/>                                                        
                                                        {
                                                            listProdsReadyStock.remaining_stock !== null ? [
                                                                (listProdsReadyStock.quantity < listProdsReadyStock.remaining_stock ? [
                                                                    (listProdsReadyStock.max_buy !== null) ? [
                                                                        (listProdsReadyStock.quantity <= listProdsReadyStock.max_buy ?  
                                                                            <img src={plusIconEnable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id} onClick={() => plusCart(listProdsReadyStock.product_id, listProdsReadyStock.max_buy, listProdsReadyStock.remaining_stock)} style={{cursor: 'pointer'}}/> : <img src={plusIconDisable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id}/> )
                                                                    ] : <img src={plusIconEnable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id} onClick={() => plusCart(listProdsReadyStock.product_id, listProdsReadyStock.max_buy, listProdsReadyStock.remaining_stock)} style={{cursor: 'pointer'}}/>
                                                                ] : <img src={plusIconDisable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id}/>)
                                                            ] : (listProdsReadyStock.max_buy !== null) ? [
                                                                    (listProdsReadyStock.quantity < listProdsReadyStock.max_buy ?  
                                                                        <img src={plusIconEnable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id} onClick={() => plusCart(listProdsReadyStock.product_id, listProdsReadyStock.max_buy, listProdsReadyStock.remaining_stock)} style={{cursor: 'pointer'}}/> : <img src={plusIconDisable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id}/> )
                                                            ] : <img src={plusIconEnable} alt="" className="iconQtyAddMinus" id={"imgPlus_"+listProdsReadyStock.product_id} onClick={() => plusCart(listProdsReadyStock.product_id, listProdsReadyStock.max_buy, listProdsReadyStock.remaining_stock)} style={{cursor: 'pointer'}}/>                                                         
                                                        }
                                                    </td>
                                                </tr>
                                                {
                                                    listProdsReadyStock.quantity === listProdsReadyStock.max_buy ? 
                                                    <tr id={"alertMaxQty_"+listProdsReadyStock.product_id}>
                                                        <td colSpan={24} style={{padding: '10px 0'}} className="sp-danger product-sold-and-stock"><img src={warningIcon} alt=""/>  Produk telah mencapai batas pembelian.</td>
                                                    </tr> :
                                                    <tr id={"alertMaxQty_"+listProdsReadyStock.product_id} style={{visibility: 'hidden'}}>
                                                        <td colSpan={24} style={{padding: '10px 0'}} className="sp-danger product-sold-and-stock"><img src={warningIcon} alt=""/>  Produk telah mencapai batas pembelian.</td>
                                                    </tr>
                                                }
                                                <tr style={{borderBottom: '1px solid #f4f4f4', display: 'table-row'}}>
                                                    <td></td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div> : <></>}            
            {cartOutOfStockArr.length > 0 ? 
            <div>
                <div className="break-page" style={{marginLeft: -25, marginRight: -25, marginBottom: 15}}></div>
                    <div className="sold-cart-div">
                        <Row>
                            <Col span={20}>
                                <span className="head-2-cart">Tidak bisa diproses<b> ( {cartLists.out_of_stock.length} )</b></span>
                            </Col>
                            <Col style={{float: 'right'}}>
                                <div onClick={() => deleteOutofStock()}>
                                    <span className="head-2-cart" style={{color: '#35DB5A', fontWeight: 'bold', fontFamily: 'Open Sans', marginTop: 2, cursor: 'pointer'}}>Hapus</span>
                                </div>
                            </Col>
                        </Row>
                    </div>    
            <div className="break-page-down" style={{marginLeft: -25, marginRight: -25}}></div>
            <table style={{width: '100%'}}>
                            <tbody>
                            {
                                cartLists.out_of_stock.map((listProdsOutOfStock, i) => {
                                    return(
                                        <>
                                            <tr key={i}>
                                                <td style={{width: '18%'}}></td>
                                            </tr>
                                            <tr>
                                                <td className="checkbox-cartlist">                                                
                                                </td>
                                                <td className="sold-out-div">  
                                                    <span className="span-sold-out">Habis</span>                                           
                                                    <div><img src={listProdsOutOfStock.product_image} alt="" style={{width: 70, height: 70}} className="imgProd"/></div>                                                    
                                                </td>
                                                <td style={{verticalAlign: 'top', paddingLeft: 20, width: '100%'}}>
                                                    <div className="prod-name"><span>{listProdsOutOfStock.product_name}</span></div>
                                                    <div className="satberat-name"><span>{listProdsOutOfStock.product_weight} {listProdsOutOfStock.product_weight_name}</span></div>
                                                    <div className="prod-harga-jual-disable" ><span>Rp{convertToRupiah(listProdsOutOfStock.product_price)} / {listProdsOutOfStock.product_unit_name}</span></div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2}></td>
                                                {/* <td colSpan={2}></td> */}
                                                <td style={{whiteSpace: 'nowrap', paddingLeft: 20}}>
                                                    <img src={iconFeatherTrash2} alt="" style={{marginRight: 15, marginBottom: 5, cursor: 'pointer'}} onClick={() => showModal(setDeleteProdId(listProdsOutOfStock.tcartlog_id))}/>
                                                    {counter < 2 ? <img src={iconFeatherMinusCircleDisable} alt="" style={{ marginBottom: 3, marginRight: 1 }} id="imgMinus" disabled/> : <img src={iconFeatherMinusCircleEnable} alt="" style={{ marginBottom: 3, marginRight: 1 }} id="imgMinus" disabled/>}
                                                    <InputNumber type="number" className="inpQuantity" min={1} id="inpCartField" value={listProdsOutOfStock.quantity} disabled/>
                                                    <img src={iconFeatherPlusCircleDisable} alt="" style={{marginBottom: 2, marginLeft: 4}} id="imgPlus" disabled/>
                                                </td>
                                            </tr>
                                            <tr style={{borderBottom: '1px solid #f4f4f4'}} span={20}>
                                                <td colSpan={3} style={{padding: '10px 0'}}></td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3} style={{padding: '10px 0'}}></td>
                                            </tr>
                                        </>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                        <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>
                    </div>
                        : <div className="break-page" style={{marginLeft: -25, marginRight: -25}}></div>}
                <div>
                    {/* {cartLists.ready_stock !== [] ? <OtherProduct prod_id={parseInt(cartLists.ready_stock[0].product_id)} /> : cartLists.out_of_stock !== [] ? <OtherProduct prod_id={parseInt(cartLists.out_of_stock[0].product_id)} /> } */}
                    {
                        cartLists.ready_stock.length === 0  ?
                         <OtherProduct prod_id={parseInt(cartLists.out_of_stock[0].product_id)} />  : 
                         <OtherProduct prod_id={parseInt(cartLists.ready_stock[0].product_id)} /> 
                    }
                </div>
                <Footer totalItem={totalItem} harga={priceProds} item={itemProds}/>
            </div>
            }
        <Row>
            <Col span={24}>
                <div style={{textAlign: 'center', color: '#848484'}}>
                    <Modal className="modalDeleteCart" title="Hapus Pesanan Dari Keranjang" visible={isModalVisible} closable={false} onOk={handleOk} onCancel={handleCancel} footer={null} style={{color: '#848484'}}>
                        <div style={{textAlign: 'center'}}>
                            <div style={{marginBottom: 10}}>
                                <div style={{fontSize: 10}}>Kamu akan menghapus produk ini</div>
                                <div style={{fontSize: 10, marginBottom: 20}}>dari keranjang belanja</div>
                            </div>
                            <div>
                                <button className="btn-ez-cart-delete" onClick={() => deleteMultipleProducts(deleteProdId)}>Hapus Pesanan</button>
                            </div>
                            <div>
                                <button className="btn-ez-cart-cancel-delete" onClick={() => handleCancel()}>batalkan</button>
                            </div>
                        </div>
                    </Modal>
                </div>
            </Col>
        </Row>
      </div>
    )
}

export default AvailableProduct;