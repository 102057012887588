import { FETCH_DATA_GETCATLAYER2LIST1_CAT } from '../ActionType/actionTypes'

const initialState = {
    getCatLayer2List1: []
}

const getCatLayer2List1Reducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_GETCATLAYER2LIST1_CAT) {
        return { ...state, getCatLayer2List1: action.payload.getCatLayer2List1}
    } else {
        return state
    }
}

export default getCatLayer2List1Reducer
