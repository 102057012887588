import React from 'react'
import './AlamatKirim.css'
import ListAlamatKirim from '../components/AlamatKirimPage/ListAlamatKirim'

function AlamatKirim() {
    return (
        <div className="container body-content mobile-view ">            
            <ListAlamatKirim />
        </div>
    )
}

export default AlamatKirim
