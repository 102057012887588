import { IS_LOADING_GETCATLAYER2LIST2_CAT } from "../../ActionType/actionTypes";

export function isLoadingGetCatLayer2List2(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_GETCATLAYER2LIST2_CAT, 
            payload: {
                isLoadingGetCatLayer2List2: param
            }
        })
    }
}