import React, {useState, useEffect}from 'react'
import {Row, Col, Modal} from 'antd'
import { useHistory } from 'react-router-dom';
import { getToken } from '../../utils/setSessionUser';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios'
import './RePurchaseModal.css'
import {useLocation, useParams} from "react-router-dom";
import {BaseURL, moneyFormat, headersWithToken} from '../../function/helpers'

function RePurchaseModal({showModal, closeModal, data}) {
    let history = useHistory();
    let access_token = getToken();
    let {id} = useParams();
    // const [orderDetail, loading] = useFetch(`{"product_id": ${productId}, "quantity": ${counter}}`, "/customer/gethistorydetail")
    function repurchaseButton(){
        addCart(data.tord_id);
    }

    function addCart(){
        const signature = base64Sign("/customer/reorder");
        const dataParams = encryptData(`{"order_id": ${id}}`);
        const headers = headersWithToken(signature, access_token)
        axios
        .post(BaseURL + '/customer/reorder',{data:dataParams},{headers:headers}).then(data=>{
            if(data.status === 200){
                history.push('/cart')
            }
        }).catch(err => console.log(err));
    }

    return (
        <div className='container'>
            <div className='div-modal-addcard'>
            <Modal
                title="Atur Produk"
                visible={showModal}
                // onOk={closedModal}
                onCancel={closeModal}
                className="modalAddCart repurchase-modal" 
                style={{borderRadius: 15, width: "490px !important"}} 
                footer={
                <div>
                    <Row>
                        <Col span={24} style={{paddingTop: 15}}>
                            <button className="btnKeranjang">
                                <span className="spanCart" onClick={() => repurchaseButton()}>Tambah Ke Keranjang</span>
                            </button>
                        </Col>
                    </Row>
                </div>
                }            
                >
                <table style={{width: '90%', margin: '0 auto', }} className='table-popup-order-detail'>
                    <tbody>
                        {
                            data.map(prodDetail => {
                                return(
                                    <tr>
                                        {
                                            prodDetail.mprod_mstatstok_id === 2 ? 
                                            <>
                                                <td className="sold-rebuy" style={{width: 80, zIndex: 999}}>
                                                    <img src={prodDetail.torditem_prod_image} alt="" style={{width: 80, height: 80}} />
                                                </td>
                                            </> : 
                                            <>
                                                <td className="prodImg" style={{width: 100}}>
                                                    <img src={prodDetail.torditem_prod_image} alt="" style={{width: 80, height: 80}}/>
                                                </td>
                                            </>
                                        }
                                        <td style={{verticalAlign: 'inherit', float: 'left', marginTop: 12}}>
                                            <div className="prodName">{prodDetail.torditem_prod_name}</div>
                                            <div className="satberatName">{prodDetail.torditem_berat} {prodDetail.msatberat_name}</div>
                                            <div className="prodhargaJualenable">{moneyFormat(prodDetail.torditem_harga_satuan)} / {prodDetail.mstatjual_name}</div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                </table>
                </Modal>
            </div>
        </div>
    )
}

export default RePurchaseModal
