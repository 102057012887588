import { IS_LOADING_MOSTBUYPROD_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingMostBuyProdHome: true
}

const isLoadingMostBuyProdReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_MOSTBUYPROD_HOME) {
        return { ...state, isLoadingMostBuyProdHome: action.payload.isLoadingMostBuyProdHome }
    } else {
        return state
    }
}

export default isLoadingMostBuyProdReducer