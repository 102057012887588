import React from 'react'
import Agen from './Agen'
import Disclaimer from './Disclaimer'
import HowToShop from './HowToShop'
import KebijakanPrivasi from './KebijakanPrivasi'
import TentangKami from './TentangKami'
import $ from 'jquery'

function Webview() {
    var url = window.location.href.substring(window.location.href.lastIndexOf('/') + 1)
  return (
    <div className='container body-content mobile-view'>
        {
            url === 'Disclaimer' ? 
            <Disclaimer isWebview={true} />
            :
            url === 'KebijakanPrivasi' ? 
            <KebijakanPrivasi isWebview={true} />
            :
            url === 'Agen' ? 
            <Agen isWebview={true} />
            :
            url === 'CaraBelanja' ? 
            <HowToShop isWebview={true} />
            :
            url === 'tentangkami' ? 
            <TentangKami isWebview={true} />
            :
            null
        }
    </div>
  )
}

export default Webview