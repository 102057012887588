import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import EzeeTunai from '../HomePage/EzeeTunai'
import moment from 'moment'
import './Body.css'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProfileDetail } from '../../redux/ActionCreators/profileDetailActions'
import { getToken } from '../../utils/setSessionUser'
import loginIcon from '../../Asset/Icon/log-in.png'
import { IS_LOADING } from '../../redux/ActionType/actionTypes'
import IconRiwayatPesanan from '../../Asset/Icon/icon-history-pesanan.png'
import photoProfileDefaultSVG from '../../Asset/Icon/photo_profile_default.svg'
import iconArrowDetailAkun from '../../Asset/Icon/arrow-detail-akun.png'
import iconHomeMenuBar from '../../Asset/Icon/icon_home_menuBar.png'
import iconPromoMenuBar from '../../Asset/Icon/icon_promo_menuBar.png'
import iconProductMenuBar from '../../Asset/Icon/icon_product_menuBar.png'
import iconHowtoshopMenuBar from '../../Asset/Icon/icon_howtoshop_menuBar.png'
import iconAgenMenuBar from '../../Asset/Icon/icon_agen_menuBar.png'
import iconAboutUsMenuBar from '../../Asset/Icon/icon_aboutus_menuBar.png'
import iconKebijakanPrivMenuBar from '../../Asset/Icon/icon_kebijakanPriv_menuBar.png'
import iconContactUsMenuBar from '../../Asset/Icon/icon_contactus_menuBar.png'
import WhatsappGreen from '../../Asset/Icon/Whatsapp_green.svg'
import iconFBGreen from '../../Asset/Icon/FB_green.svg'
import iconInstagramGreen from '../../Asset/Icon/Instagram_green.svg'
import { fetchCategory } from '../../redux/ActionCreators/categoryActions'

function Body() {

    const profileDetail = useSelector(state => state.profileDetailReducer.profileDetail)
    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingProfDetail = useSelector(state => state.isLoadingProfileDetailReducer.isLoadingProfileDetail)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)
    const dispatch = useDispatch()
    const history = useHistory()
    const access_token = getToken()
    
    function formattedDate(dt){
        var date = new Date(dt * 1000).toDateString();
        return date;
    }

    function waHandler() {
        const message = "Hai ezeePasar! Ada yang ingin saya tanyakan.."
        if (window.matchMedia('(min-width: 768px)').matches) {
            window.open('https://web.whatsapp.com/send?phone=62817844931&text=' + message, '_self');
        } else {
            if (navigator.userAgent.indexOf('wv') !== -1 || navigator.userAgent.indexOf('Version/') !== -1) {
                window.open('https://api.whatsapp.com/send?phone=62817844931&text=' + message, '_blank');
            }
            else {
                window.open('https://api.whatsapp.com/send?phone=62817844931&text=' + message, '_self');
            }
        }
    }

    function fbHandler() {
        window.open("https://www.facebook.com/ezeePasar/", "_blank")
    }

    function igHandler() {
        window.open("https://www.instagram.com/ezeepasar/", "_blank")
    }

    function loginHandle() {
        history.push("/login")
    }

    function loadingHandle() {
        dispatch({
            type: IS_LOADING,
            payload: {
                isLoading: true
            }
        })
    }

    function nickName(fullName) {
        return fullName.split(" ")[0]
    }

    useEffect(() => {
        if(access_token !== null){
            dispatch( fetchProfileDetail("/profile/getprofile") )
            dispatch( fetchCategory("/dashboard/getcategory") )
        }
    }, [dispatch])

    if(access_token !== null){
        if(isLoadingProfDetail || isLoadingCategoryHome) {
            return(
                <h1>Loading...</h1>
            )
        }
    }

    return (
        <div className="navbar-collapse in" style={{ position: 'relative', height: 'auto', marginTop: 50, marginLeft: 0, marginRight: 0 }}>
            <div className="menu-head-green-bg"></div>
            <div style={{ marginTop: -180 }}>
                {
                    access_token === null ?
                        <>
                        <div className="row" style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
                            <p style={{ marginTop: 15, color: '#FFF', fontSize: 18, fontWeight: 600 }}>Selamat Datang!</p>
                            <button onClick={loginHandle} style={{ border: '1px solid #FFFFFF', boxSizing: 'border-box', borderRadius: 8, width: 210, height: 40, background: 'none', color: '#FFF', fontWeight: 'bold', fontSize: 12, marginTop: -5 }}>
                                <p style={{ lineHeight: 3 }}>
                                    <span style={{ verticalAlign: 'top', marginRight: 15 }}>
                                        Masuk
                                    </span>
                                    <img src={loginIcon} alt="" width="17px" height="18px" />
                                </p>
                            </button>
                            <p style={{ fontSize: 12, fontWeight: 500, margin: '15px 0px 10px 0px', color: '#FFF', fontFamily: 'Open Sans'}}>
                                belum punya akun?
                            </p>
                            <Link to={"/register"} style={{ fontSize: 12, fontWeight: 600, color: '#FFF', textDecoration: 'underline', backgroundColor: 'unset'}}>
                                Daftar disini.
                            </Link>
                        </div>
                        </> :
                        <>
                            <div>
                                <div style={{ display: 'flex', padding: 10 }}>
                                    <div style={{ width: '80%', float: 'left', display: 'flex', zIndex: 1 }}>
                                        <div>
                                            {
                                                (profileDetail.customer_photo === null || !profileDetail.customer_photo) ?
                                                <img className="photo-profile-frame" src={photoProfileDefaultSVG} alt="pp" width="43" height="43" /> :
                                                <img className="photo-profile-frame" src={ profileDetail.customer_photo } alt="pp" width="43" height="43" />
                                            }
                                        </div>
                                        <div style={{ display: 'inline', textAlign: 'left', marginTop: 10, marginRight: 5, marginBottom: 10 }}>
                                            <p style={{ fontWeight: 650, fontSize: 18, marginTop: 15, marginBottom: 3, color: '#FFFFFF', fontFamily: 'Montserrat' }}>Hi, {nickName(profileDetail.customer_name)}!</p>
                                            <p style={{ fontSize: 10, fontFamily: 'Open Sans', color: '#FFFFFF' }}>member sejak {moment(formattedDate(profileDetail.created_date)).format('MMMM yy')}</p>
                                        </div>
                                    </div>
                                    <div style={{ width: 115, float: 'right', marginRight: 20 }}>
                                        <Link to="/profile" className="spanAccount">
                                            Detail Akun &nbsp;
                                            <img style={{ marginTop: 1 }} src={iconArrowDetailAkun} alt="arrowdetail" width="8px" height="16.8px" />
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            {/* <div style={{ marginTop: -25, padding: -2 }}>
                                <EzeeTunai flag="1"/>
                            </div> */}
                        </>
                }

                <div className="break-page" style={{ marginTop: 60 }}></div>
                <ul id="listMenu" style={{ marginTop: 25 }}>
                    <Link to="/">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconHomeMenuBar} alt="BackHome" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Kembali Ke Home</span>
                        </li>
                    </Link>
                    <Link to="/Promo">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconPromoMenuBar} alt="promo" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Promo</span>
                        </li>
                    </Link>
                    <Link onClick={loadingHandle} to={`/ProductListCategory/${categories[0]?.mcat_id}`}>
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconProductMenuBar} alt="produk" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Produk</span>
                        </li>
                    </Link>
                    {
                        access_token === null ? <></> : 
                        <Link to={"/RiwayatPesanan"}>
                            <li className="menu">
                                <img style={{ marginRight: 15 }} src={IconRiwayatPesanan} alt="produk" />
                                <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Riwayat Pesanan</span>
                            </li>
                        </Link>
                    }
                </ul>
                <div className="break-page" style={{ marginTop: -70 }}></div>
                <ul id="listMenu" style={{ marginTop: 10 }}>
                    <p className="head-2-menu">Seputar ezeePasar</p>
                    <Link to="/CaraBerbelanja">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconHowtoshopMenuBar} alt="howToShop" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Cara Berbelanja</span>
                        </li>
                    </Link>
                    <Link to="/AgenPage">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconAgenMenuBar} alt="agent" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Menjadi Agen</span>
                        </li>
                    </Link>
                    <Link to="/TentangKami">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconAboutUsMenuBar} alt="aboutMe" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Tentang Kami</span>
                        </li>
                    </Link>
                    <Link to="/KebijakanPrivasiIndex">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconKebijakanPrivMenuBar} alt="aboutMe" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Kebijakan Privasi</span>
                        </li>
                    </Link>
                    <Link to="/HubungiKami">
                        <li className="menu">
                            <img style={{ marginRight: 15 }} src={iconContactUsMenuBar} alt="aboutMe" />
                            <span style={{ paddingTop: 10,  fontSize: 14, fontWeight: 600, color: '#393939', fontFamily: 'Open Sans' }}>Hubungi Kami</span>
                        </li>
                    </Link>
                </ul>
                <div className="menu-foot">
                    <img onClick={waHandler} src={WhatsappGreen} alt="wa" className="icon" />
                    <img onClick={fbHandler} src={iconFBGreen} alt="fb" className="icon" style={{ left: 55 }} />
                    <img onClick={igHandler} src={iconInstagramGreen} alt="ig" className="icon" style={{ left: 100 }} />
                    <p>© ezeePasar 2020 - 2022</p>
                </div>
            </div>
        </div>
    )
}

export default Body
