import { IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_BANKLIST_DETAIL_EZEETUNAI} from "../../ActionType/actionTypes";

const initialState = {
    loading : true,
    data : []
}

const ezeeTunaiGetBankDetailReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST : 
        return{
            ...state,
            loading: true
        }
        case FETCH_BANKLIST_DETAIL_EZEETUNAI : 
        return{
            ...state,
            loading: false,
            data : action.payload
        }
        case IS_NEW_LOADING : 
        return{ 
            ...state,           
            loading : true
        }
        default : return state
    }
}

export default ezeeTunaiGetBankDetailReducer