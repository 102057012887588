import React from 'react'
import { Layout, Menu, Row, Col } from 'antd'
import './Navbar.css'
import arrowBackHeader from '../../Asset/Icon/back_header.svg'

const { Header } = Layout

function Navbar() {
    function goToBack() {
        window.location.href = 'javascript:history.go(-1)';
    }
    return (
        <Layout>
            <Header style={{ padding: 0, position: 'fixed', zIndex: 1000, height: 49 }}>
                <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']} style={{ boxShadow: '0px 5px 10px rgb(0 0 0 / 5%)'}}>
                    <div style={{width: 480, textAlign: 'center'}}>
                        <img src={arrowBackHeader} alt="arrowBack" className="arrowBack" onClick={goToBack} />
                        <div>
                            <img src="" alt=""/>
                        </div>
                    </div>
                </Menu>
            </Header>
        </Layout>
    )
}

export default Navbar
