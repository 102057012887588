import React from 'react'
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker,
} from 'react-google-maps'  
import { WrappedMap, SnackBar } from '../components/AlamatKirimPage/Map'
import Header2 from '../components/Header2'
import { Layout, Modal, message } from 'antd'
import PinPointEzeePasar from '../Asset/Icon/Pin-Point-Alamat-icon.svg'
import Geocode from 'react-geocode'
import { FiSearch } from 'react-icons/fi'
import { MdError } from 'react-icons/md'
import _ from 'lodash'
import { createBrowserHistory } from 'history'
import './FormAddress.css'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import axios from 'axios'
import { getToken } from '../utils/setSessionUser'
import base64Sign from '../function/base64Sign'
import encryptData from '../function/encryptData'
import {useSwipeable} from 'react-swipeable'
import $ from 'jquery'
import pinMap from '../Asset/Icon/pin-map.png'
import unKnownLocation from '../Asset/Icon/unknown-location.svg'
import outOfReachLocation from '../Asset/Icon/out-of-reach-location.svg'
import {BaseURL} from '../function/helpers'
import iconAlertInfoBack from '../Asset/Icon/alert_info_back.png'
import spinnerLoading1s from '../Asset/Icon/spinner-1s-58px.svg'

const { Footer } = Layout
const history = createBrowserHistory()

export const GOOGLE_MAP_KEY = "AIzaSyAC8UjJhnj4Tm9LiK5NJOe-bPf7GXy62zU"
Geocode.setApiKey(GOOGLE_MAP_KEY)

class FormAddress extends React.Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
    };
    state = {
        addresId: 0,
        mainText: '',
        address: '',
        detailAddress: '',
        zoom: 15,
        height: 400,
        mapPosition: {
            lat: -6.1640446151855555,
            lng: 106.81258637120476
        },
        markerPosition: {
            lat: -6.1640446151855555,
            lng: 106.81258637120476
        },
        isChecked: false,
        inputSearch: '',
        detailAlamat: '',
        labelAlamat: '',
        namaPenerima: '',
        telepon: '',
        isVisible: false,
        errorMessage: {
            errorDetail: false,
            errorLabel: false,
            errorPenerima: false,
            errorTelepon: false,
        },
        isCurrentLoc: false,
        notAvailableLocation: false,
        errorTextOutOfRange: '',
    }

    getAddress = (fullAddress) => {
        return fullAddress.split(",")[0]
    }

    componentDidMount() {
        const { location } = this.props
        // console.log(location, 'ini location');
        if (location.state.addressId && !location.state.lat || !location.state.lng) {
            const param = encryptData(`{"address_id": ${location.state.addressId}}`)
            const signature = base64Sign("/customer/addressdetail")
            const access_token = getToken()
            const headers = {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token
            }
            axios.post(BaseURL + "/customer/addressdetail", { data: param }, { headers: headers })
                .then(addressDetail => {
                    // console.log(addressDetail, 'ini address detail');
                    this.setState({
                        addresId: addressDetail.data.response_data.results.address_id,
                        address: (addressDetail.data.response_data.results.address).split(",")[0]    ,
                        detailAddress: addressDetail.data.response_data.results.address,
                        mapPosition: {
                            lat: addressDetail.data.response_data.results.address_latitude,
                            lng: addressDetail.data.response_data.results.address_longitude
                        },
                        markerPosition: {
                            lat: addressDetail.data.response_data.results.address_latitude,
                            lng: addressDetail.data.response_data.results.address_longitude
                        },
                        isChecked: addressDetail.data.response_data.results.address_is_default,
                        detailAlamat: addressDetail.data.response_data.results.address_notes,
                        labelAlamat: addressDetail.data.response_data.results.address_name,
                        namaPenerima: addressDetail.data.response_data.results.receiver_name,
                        telepon: addressDetail.data.response_data.results.receiver_mobile,
                    })
                })
                .catch(error => {
                    console.log(error);
                })
        } else if (location.state.addressId && location.state.lat || location.state.lng) {
            this.setState({
                mapPosition: location.state,
                markerPosition: location.state,
            })

            Geocode.fromLatLng(location.state.lat, location.state.lng)
                .then(response => {
                    const detailAddress = response.results[0].formatted_address
                    const address = this.getAddress(detailAddress)
                    this.setState({
                        address: (address) ? address : '',
                        detailAddress: (detailAddress) ? detailAddress : '',
                    })
                })
                .catch(error => {
                    console.log(error);
                })
            
            const param = encryptData(`{"address_id": ${location.state.addressId}}`)
            const signature = base64Sign("/customer/addressdetail")
            const access_token = getToken()
            const headers = {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token
            }
            axios.post(BaseURL + "/customer/addressdetail", { data: param }, { headers: headers })
                .then(addressDetail => {
                    this.setState({
                        addresId: addressDetail.data.response_data.results.address_id,
                        isChecked: addressDetail.data.response_data.results.address_is_default,
                        detailAlamat: addressDetail.data.response_data.results.address_notes,
                        labelAlamat: addressDetail.data.response_data.results.address_name,
                        namaPenerima: addressDetail.data.response_data.results.receiver_name,
                        telepon: addressDetail.data.response_data.results.receiver_mobile,
                    })
                })
                .catch(error => {
                    console.log(error);
                })
        }
        if (location.state.mainText) {
            this.setState({
                mainText: location.state.mainText,
            })
        }
    }

    handleMarker = (e) => {
        let newLat = e.latLng.lat()
        let newLng = e.latLng.lng()
        Geocode.fromLatLng(newLat, newLng)
            .then(response => {
                const detailAddress = response.results[0].formatted_address
                const address = this.getAddress(detailAddress)
                this.setState({
                    address: (address) ? address : '',
                    detailAddress: (detailAddress) ? detailAddress : '',
                    mapPosition: {
                        lat: newLat,
                        lng: newLng
                    },
                    markerPosition: {
                        lat: newLat,
                        lng: newLng
                    }
                })
            })
    }

    sliderHandle = (checked) => {
        this.setState({
            isChecked: !checked
        })
    }

    handleInput = (e) => {
        const { location } = this.props
        if (e.target.value) {
            setTimeout(() => {
                history.push({ pathname: "/SearchAddress", state: { inputSearch: e.target.value, isFromCheckout : location.state.isFromCheckout } })
                window.location.reload()
            }, 2000);
        }
    }

    inputClick = () => {
        const { location } = this.props
        // console.log(location, 'ini location');
        history.push("/SearchAddress",{addressId: location.state.addressId,isFromCheckout : location.state.isFromCheckout})
        window.location.reload()
    }

    handleChange = (e) => {
        switch (e.target.name) {
            case 'detailAlamat':
                this.setState({
                    detailAlamat: e.target.value
                })
                break;
            case 'labelAlamat':
                this.setState({
                    labelAlamat: e.target.value
                })
                break;
            case 'namaPenerima':
                this.setState({
                    namaPenerima: e.target.value
                })
                break;
            case 'telepon':
                this.setState({
                    telepon: e.target.value
                })
                break;
            default:
                break;
        }
        // this.setState({
        //     ...this.state.inputChange,
        //     [ e.target.name ]: e.target.value
        // })
    }

    modalCancelHandler = () => {
        this.setState({
            isVisible: false,
            isCurrentLoc: false,
            notAvailableLocation: false,
        })
    }

    successMessage = () => {
        message.success({
            content: "Alamat berhasil disimpan.",
            className: 'snackbar-success-form-address',
            duration: 4
        })
    }

    saveAddress = (addressId, detail, label, nama, telepon, alamat, mapPosition, isChecked) => {
        if (!detail) {
            const heightSelector = $('.form-layer-address')
            const detailSelector = document.getElementById('snackBar')
            const snackBar = $('#snackBar')
            detailSelector.scrollTop = 0
            heightSelector.animate({
                height: '540px'
            }, { duration: 200, queue: false, complete: function(e){
                heightSelector.css('overflowY', 'auto'); 
                snackBar.css('height', '404px'); 
            } })
            this.setState({
                errorMessage: {
                    errorDetail: true
                }
            })
        } else if (!label) {
            const selector = document.getElementById('snackBar')
            selector.scrollTop = 100
            this.setState({
                errorMessage: {
                    errorLabel: true
                }
            })
        } else if (!nama) {
            const selector = document.getElementById('snackBar')
            selector.scrollTop = 420
            this.setState({
                errorMessage: {
                    errorPenerima: true
                }
            })
        } else if (!telepon) {
            this.setState({
                errorMessage: {
                    errorTelepon: true
                }
            })
        } else {
            this.setState({
                isVisible: true
            })
            const param = encryptData(`{
                "address_id": "${addressId}",
                "address_title": "${label}",
                "receiver_name": "${nama}",
                "receiver_mobile": "${telepon}",
                "address": "${alamat}",
                "address_notes": "${detail}",
                "address_latitude": ${mapPosition.lat},
                "address_longitude": ${mapPosition.lng},
                "address_is_default": ${isChecked}
            }`)
            const signature = base64Sign("/customer/addeditaddress")
            const access_token = getToken()
            const headers = {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token,
            }
            axios.post(BaseURL +"/customer/addeditaddress", { data: param }, { headers: headers })
                .then(savedAddress => {
                    if (savedAddress.status === 200) {
                        this.setState({
                            isVisible: false
                        })
                        // console.log(this.props.location.state, 'ini location state');
                        this.successMessage()
                        setTimeout(() => {
                            if(this.props.location.state.isFromCheckout){
                                this.props.history.push("/AlamatKirim", {isFromCheckout : true, isAddOrEditData: true, isFromSearchAddress: (this.props.location.state.isFromSearchAddress) ? true : false});
                            }else{
                                this.props.history.push("/AlamatKirim", {isFromCheckout : false, isAddOrEditData: true, isFromSearchAddress: (this.props.location.state.isFromSearchAddress) ? true : false})
                            }
                        }, 2000);
                    } else {
                        if (savedAddress.response.data.response_data.error_id === "0022") {
                            this.setState({
                                isVisible: false,
                                notAvailableLocation: true,
                                errorTextOutOfRange: savedAddress.response.data.response_data.error_text
                            })
                        } else if (savedAddress.response.data.response_code === 500) {
                            message.error({
                                content: savedAddress.response.data.response_message,
                                className: 'snackbar-error-form-address',
                                duration: 4
                            })
                        }
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }

    getGeoLocation = () => {
        navigator.permissions.query({ name: 'geolocation' })
            .then(results => {
            })
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(position => {
                Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
                    .then(response => {
                        const detailAddress = response.results[0].formatted_address
                        const address = this.getAddress(detailAddress)
                        this.setState({
                            address: (address) ? address : '',
                            mainText: (address) ? address : '',
                            detailAddress: (detailAddress) ? detailAddress : '',
                            mapPosition: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            },
                            markerPosition: {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            },
                            isCurrentLoc: false,
                    })
                })
            })
        }
    }

    currentLocation = () => {
        navigator.permissions.query({ name: 'geolocation' })
        .then(results => {
            if(results.state === 'granted'){
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(position => {
                        Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
                            .then(response => {
                                const detailAddress = response.results[0].formatted_address
                                const address = this.getAddress(detailAddress)
                                this.setState({
                                    address: (address) ? address : '',
                                    mainText: (address) ? address : '',
                                    detailAddress: (detailAddress) ? detailAddress : '',
                                    mapPosition: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude
                                    },
                                    markerPosition: {
                                        lat: position.coords.latitude,
                                        lng: position.coords.longitude
                                    },
                                    isCurrentLoc: false,
                            })
                        })
                    })
                }
            }else{
                this.setState({
                    isCurrentLoc: true
                }) 
            }
        })
    }

    activatedLocation = () => {
        this.getGeoLocation()
    }

    handleUpAndDown = () => {
        const heightSelector = $('.form-layer-address')
        const snackbarTop = $('#snackbarTop')
        const snackBar = $('#snackBar')
        const mapsSize = $('#maps')
        const currentLocation = $('.custom-map-control-button')
        if (heightSelector.height() === 330) {
            heightSelector.animate({
                height: '450px'
            }, { duration: 200, queue: false, complete: function(e){
                heightSelector.css('overflowY', 'auto'); 
                snackBar.css('height', '404px'); 
                snackbarTop.css('height', '382px');
                mapsSize.css('height', '41vh')
                currentLocation.css('top', '-125px')
            } })
        } else {
            heightSelector.animate({
                height: '370px'
            }, { duration: 200, queue: false, complete: function(e){
                heightSelector.css('overflowY', '');
                snackBar.css('height', 'unset');
                snackbarTop.css('height', '353px'); 
                mapsSize.css('height', '55vh')
                currentLocation.css('top', '-80px')
            } })
        }
    }

    swipeUpAndDown = () => {
        // useSwipeable({

        // })
    }

    handleScroll = () => {
        const selector = document.getElementById('snackBar')
        let scrolling = selector.scrollTop
    }

    render() {
        const MapWithAMarker = withScriptjs(withGoogleMap(props =>
            <GoogleMap
                defaultZoom={15}
                defaultCenter={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                onClick={this.handleMarker}
                options={{
                    streetViewControl: false,
                    zoomControl: false
                }}
            >
                <Marker
                    clickable={true}
                    position={{ lat: this.state.mapPosition.lat, lng: this.state.mapPosition.lng }}
                    icon={{
                        url: PinPointEzeePasar,
                        scaledSize: new window.google.maps.Size(96, 97)
                    }}
                    onClick={this.handleMarker}
                />
                <button onClick={this.currentLocation} className='custom-map-control-button' style={{ zIndex: 0, position: 'relative', }}>
                    <img src={pinMap} alt='' width='20px' height='21px' style={{ marginTop: -3 }} />
                </button>
            </GoogleMap>
        ));
        
        return (
            <div className="container body-content mobile-view" style={{ height: '100%', }}>
                <Header2 />
                <div id='maps' style={{ maxWidth: 480, width: '100%', height: '55vh'  }}>
                    <MapWithAMarker
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${GOOGLE_MAP_KEY}`}
                        loadingElement={<div style={{ height: `100%` }} />}
                        containerElement={<div style={{ height: `100%` }} />}
                        mapElement={<div style={{ height: `100%` }} />}
                    />
                </div>
                {/* <SnackBar /> */}
                <div id='snackbarTop' style={{ width: '100%', height: 353, backgroundColor: '#f3f6f6', position: 'fixed' }}>
                    <div className='form-layer-address'>
                        <div className='col-md-12 col-sm-12 col-xs-12' id='shortway' style={{ display: 'flex', justifyContent: 'center' }}>
                            <hr onClick={this.handleUpAndDown} />
                        </div>
                        <div onScroll={this.handleScroll} id='snackBar' style={{ marginTop: 'unset', overflow: 'hidden scroll', height: 'unset' }}>
                            <section className='form_address'>
                                <div id='header'>
                                    <div className='row' style={{ marginTop: 'unset', marginLeft: 'unset', marginRight: 'unset', marginBottom: 5 }}>
                                        <p style={{ font: 'normal normal 14px/12px Open Sans', fontSize: 10, textAlign: 'center', color: '#848484' }}>
                                            Klik atau swipe untuk menggeser kebawah
                                        </p>
                                    </div>
                                    <div className='row' style={{ marginTop: 'unset', marginLeft: 'unset', marginRight: 'unset', marginBottom: 10 }}>
                                        <div className='col-md-12 col-sm-12 col-xs-12'>
                                            <div className='box-alert-info-address'>
                                                <img src={iconAlertInfoBack} alt='' />
                                                <p>
                                                    Pastikan lokasi yang Anda tandai di peta sesuai dengan alamat Anda serta berada di kawasan jabodetabek.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='address-lbl' style={{ marginTop: '1em' }}>
                                    <div className='form-grup search-address-field' style={{ marginBottom: 20 }}>
                                        <span className='input-group-addon searchSpan' style={{ float: 'left', width: '11%', height: 40, background: '#F4F4F4', border: '1px solid #C4C4C4', borderRight: 0, borderRadius: '10px 0px 0px 10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <FiSearch style={{ fontSize: 20, color: '#c4c4c4' }} />
                                        </span>
                                        <input onClick={this.inputClick} onChange={this.handleInput} type='text' className='form-control' id='search-field-form' placeholder='Cari Alamat' />
                                    </div>
                                    <h4 className='title-str-address'>{(this.state.mainText) ? this.state.mainText : this.state.address}</h4>
                                    <p className='title-str-address-detail'>{this.state.detailAddress}</p>
                                </div>
                                <div className='dtl-address-section' style={{ marginTop: 16 }}>
                                    <div className='form-grup'>
                                        {
                                            (!this.state.errorMessage.errorDetail) ?
                                            <>
                                                <label>Detail Alamat*</label>
                                                <input type='text' id='detail_alamat' name='detailAlamat' value={this.state.detailAlamat} onChange={this.handleChange} className='form-control' maxLength='60' />
                                                <div className='example'>
                                                    <span className='err-lbl'>Contoh: Dekat Masjid Al-falah</span>
                                                    <span className='maxLength' style={{ float: 'right' }}>{this.state.detailAlamat.length}/60</span>
                                                </div>
                                            </> :
                                            <>
                                                <label>Detail Alamat*</label>
                                                <input type='text' id='detail_alamat' name='detailAlamat' onChange={this.handleChange} className='form-control error-border' maxLength='60' />
                                                <div className='error-text-address' style={{ margin: '5px 0px 30px' }}>
                                                    <MdError style={{ fontSize: 20, float: 'left' }} />
                                                    <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Detail alamat tidak boleh kosong</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <div className='form-grup'>
                                        {
                                            (!this.state.errorMessage.errorLabel) ?
                                            <>
                                                <label>Label Alamat*</label>
                                                <input type='text' id='label_alamat' name='labelAlamat' value={this.state.labelAlamat} onChange={this.handleChange} className='form-control' />
                                                <div className='example'>
                                                    <span className='err-lbl'>Contoh: Rumah, Kantor, Apartemen</span>
                                                </div>
                                            </> :
                                            <>
                                                <label>Label Alamat*</label>
                                                <input type='text' id='label_alamat' name='labelAlamat' onChange={this.handleChange} className='form-control error-border' maxLength='60' />
                                                <div className='error-text-address' style={{ margin: '5px 0px 30px' }}>
                                                    <MdError style={{ fontSize: 20, float: 'left' }} />
                                                    <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Label alamat tidak boleh kosong</span>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </section>
                            <hr style={{ height: 10, backgroundColor: '#ccc' }} />
                            <section id='receiver-sec'>
                                <h4 style={{ marginBottom: 30, marginTop: 20 }}>Penerima</h4>
                                {/* <br /> */}
                                <div className='form-grup'>
                                    {
                                        (!this.state.errorMessage.errorPenerima) ?
                                        <>
                                            <label>Nama Penerima*</label>
                                            <input type='text' className='form-control' name='namaPenerima' value={this.state.namaPenerima} onChange={this.handleChange} id='penerima_nama' placeholder='Nama' />
                                            <div className='example'>
                                                <span className='err-lbl'>Contoh: Atik Susanto</span>
                                            </div>
                                        </> :
                                        <>
                                            <label>Nama Penerima*</label>
                                            <input type='text' id='penerima_nama' name='namaPenerima' onChange={this.handleChange} className='form-control error-border' maxLength='60' placeholder='Nama' />
                                            <div className='error-text-address' style={{ margin: '5px 0px 30px' }}>
                                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Nama penerima tidak boleh kosong</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='form-grup'>
                                    {
                                        (!this.state.errorMessage.errorTelepon) ?
                                        <>
                                            <label>Nomor Telepon*</label>
                                            <input className='form-control' type="number" name='telepon' value={this.state.telepon} onChange={this.handleChange} id='penerima_hp' placeholder='No. Handphone / Whatsapp' style={{ border: '1px solid #C4C4C4' }} />
                                            <div className='example'>
                                                <span className='err-lbl'>Contoh: 081234567890</span>
                                            </div>
                                        </> :
                                        <>
                                            <label>Nomor Telepon*</label>
                                            <input id='penerima_hp' name='telepon' type="number" onChange={this.handleChange} className='form-control error-border' maxLength='60' placeholder='No. Handphone / Whatsapp' style={{ border: '1px solid #C4C4C4' }} />
                                            <div className='error-text-address' style={{ margin: '5px 0px 30px' }}>
                                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Nomor telepon tidak boleh kosong</span>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='form-grup' style={{ marginBottom: 20 }}>
                                    <label style={{ float: 'left' }}>Set sebagai alamat utama</label>
                                    <div onClick={() => (this.sliderHandle(this.state.isChecked))} className='switch-address' style={{ float: 'right' }}>
                                        <input type='checkbox' id='is_default' name='alamatUtama' checked={this.state.isChecked} />
                                        <span className="slider-address round-address"></span>
                                    </div>
                                </div> 
                                <br/>
                                <br/>
                                <br/>
                                <br/>
                                {/* <br /> */}
                            </section>
                        </div>
                    </div>
                    
                </div>
                <Layout>
                    <Footer className='footerLayout' style={{marginTop: 10}}>
                        <button onClick={() => (this.saveAddress(this.state.addresId, this.state.detailAlamat, this.state.labelAlamat, this.state.namaPenerima, this.state.telepon, this.state.detailAddress, this.state.mapPosition, this.state.isChecked))}>Simpan Alamat</button>
                    </Footer>
                </Layout>
                <Modal
                    visible={this.state.isVisible}
                    onCancel={this.modalCancelHandler}
                    footer={null}
                    className="modalVefiedWishlist"
                >
                    <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#848484" }}>
                        Sedang dalam proses
                    </div>
                    <div style={{ fontSize: 10 }}>
                        Kami sedang memproses.
                    </div>
                    <div style={{ fontSize: 10 }}>
                        Mohon menunggu.
                    </div>
                    <div style={{ marginTop: 20 }}>
                        <img src={spinnerLoading1s} alt="" width="58px" height="58px" />
                    </div>
                </Modal>
                <Modal
                    visible={this.state.isCurrentLoc}
                    onCancel={this.modalCancelHandler}
                    footer={null}
                    className="modalVefiedFormAddress"
                >
                    <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                        <img src={unKnownLocation} alt="" width="110px" height="100px" />
                    </div>
                    <div style={{ marginTop: 5, fontSize: 18, fontWeight: 600, marginBottom: 5, color: "#393939", display: 'flex', justifyContent: 'center' }}>
                        Lokasi Anda tidak terdeteksi
                    </div>
                    <p style={{ font: 'normal normal 13px/18px Open Sans', width: '67%', margin: '0px auto', display: 'flex', justifyContent: 'center' }}>
                        Kami tidak dapat mengakses lokasi Anda.
                    </p>
                    <p style={{ font: 'normal normal 13px/18px Open Sans', width: '67%', margin: '0px auto', display: 'flex', justifyContent: 'center' }}>
                        Aktifkan GPS untuk menggunakan fitur ini
                    </p>
                    <div>
                        <div className='row' style={{ position: 'relative', bottom: -15, width: '110%', maxWidth: 480, display: 'table', background: 'white', boxShadow: '0px -5px 10px rgb(0 0 0 / 5%)', marginLeft: -21, marginTop: '8%' }}>
                            <div className='col-12' style={{ padding: 0 }}>
                                <button onClick={() => this.activatedLocation()} className='btn btn-lg btn-success-current-loc' style={{ backgroundColor: '#3DB54A', margin: '17px 5%', display: 'block', width: '90%', font: 'normal bold 14px/12px Montserrat', padding: '15px 0', }}>
                                    Aktifkan Lokasi GPS
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    visible={this.state.notAvailableLocation}
                    onCancel={this.modalCancelHandler}
                    footer={null}
                    className="modalVefiedFormAddress"
                >
                    <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                        <img src={outOfReachLocation} alt="" width="110px" height="100px" />
                    </div>
                    <div style={{ marginTop: 5, fontSize: 14, fontWeight: 600, marginBottom: 5, color: "black", display: 'flex', justifyContent: 'center' }}>
                        Maaf, alamat kamu diluar jangkauan tim ekspedisi
                    </div>
                    <p style={{ font: 'normal normal 13px/18px Open Sans', width: '70%', margin: '0px auto', display: 'flex', justifyContent: 'center', textAlign: 'center', lineHeight: 1.5 }}>
                        {this.state.errorTextOutOfRange}
                    </p>
                    <div>
                        <div className='row' style={{ position: 'relative', bottom: -15, width: '110%', maxWidth: 480, display: 'table', background: 'white', boxShadow: '0px -5px 10px rgb(0 0 0 / 5%)', marginLeft: -21, marginTop: '8%' }}>
                            <div className='col-12' style={{ padding: 0 }}>
                                <button onClick={this.modalCancelHandler} className='btn btn-lg btn-success-current-loc' style={{ backgroundColor: '#3DB54A', margin: '17px 5%', display: 'block', width: '90%', font: 'normal bold 14px/12px Montserrat', padding: '15px 0', }}>
                                    OK
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default withRouter(FormAddress)
