import React from 'react'
import {NavbarCloseOnly} from '../components/Properties/Navbar/Navbar'
import NotificationPage from '../components/Notification/Notification'
function Notification() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link="/"/>
            <NotificationPage/>
        </div>
    )
}

export default Notification
