import { FETCH_DATA_MOSTSEARCHPROD_HOME } from "../ActionType/actionTypes"

const initialState = {
    mostSearchProds: []
}

const mostSearchProdsReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_MOSTSEARCHPROD_HOME) {
        return { ...state, mostSearchProds: action.payload.mostSearchProds }
    } else {
        return state
    }
}

export default mostSearchProdsReducer