import React, { useEffect, useState } from 'react'
import { NavbarWithMenuIcon, NavbarWithoutLogo } from '../components/Properties/Navbar/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { fetchNotifikasiBrowser } from '../redux/ActionCreators/notifikasiBrowserActions'
import encryptData from '../function/encryptData'
import base64Sign from '../function/base64Sign'
import { getToken } from '../utils/setSessionUser'
import { BaseURL, headersWithToken } from '../function/helpers'
import { IS_LOADING } from '../redux/ActionType/actionTypes'
import axios from 'axios'
import { Button, Modal, Spin } from 'antd';
import ezeetunaiIconHome from '../Asset/Icon/logo/ezeetunai-home.svg'
import spinner from '../Asset/Icon/spinner/spinner.svg'
import './MetodePembayaran.css'
import { useHistory } from 'react-router-dom'

function MetodePembayaran() {

    const access_token = getToken()
    const notifikasiBrowser = useSelector(state => state.notifikasiBrowserReducer.notifikasiBrowser)
    const isLoading = useSelector(state => state.isLoadingReducer.isLoading)
    const dispatch = useDispatch()
    const history = useHistory()
    const [bindingList, setBindingList] = useState([])
    const [isBinding, setIsBinding] = useState(false)
    const [visible, setVisible] = useState(false)

    async function getBindingList() {
        try {
            const signature = base64Sign("/EzeeTunaiSnap/GetAccountBindingList"); 
            const headers = headersWithToken(signature, access_token);
            const accountBindingList = await axios.post(BaseURL + "/EzeeTunaiSnap/GetAccountBindingList", {data: ""}, {headers: headers})
            if (accountBindingList.status === 200 && accountBindingList.data.response_code === 200) {
                accountBindingList.data.response_data.results.forEach(element => {
                    if (element.is_active === 1) {
                        setIsBinding(true)
                    } else {
                        setIsBinding(false)
                    }
                });
                setBindingList(accountBindingList.data.response_data.results)
            } else if (accountBindingList.response.status === 400 && accountBindingList.response.data.response_code === 400) {
                setIsBinding(false)
                setBindingList([])
            }
        } catch (error) {
            console.log(error.response);
        }
    }

    async function unBindingHandler() {
        try {
            const signature = base64Sign("/EzeeTunaiSnap/SnapUnBindingAccount"); 
            const headers = headersWithToken(signature, access_token);
            const unBinding = await axios.post(BaseURL + "/EzeeTunaiSnap/SnapUnBindingAccount", {data: ""}, {headers: headers})
            if (unBinding.status === 200 && unBinding.data.response_code === 200) {
                setVisible(false)
                window.location.reload()
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        dispatch( fetchNotifikasiBrowser("/profile/listnotification") )
        getBindingList()
        // bindingHandler()
    }, [dispatch])

    function handleSwitch(firebaseTypeGroupId, isChecked) {
        const param = encryptData(`{"firebasetypegroup_id" : ${firebaseTypeGroupId}, "status": ${!isChecked}}`)
        const signature = base64Sign("/profile/updatenotification")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + "/profile/updatenotification", {data: param}, {headers})
            .then(newNotif => {
                if (newNotif.data.response_data.results.message === "Update customer notification success") {
                    dispatch({
                        type: IS_LOADING,
                        payload: {
                            isLoading: true
                        }
                    })
                    dispatch( fetchNotifikasiBrowser("/profile/listnotification") )
                }
            })
            .catch(error => {
                console.log(error);
            })
    }

    return (
        <div className="container body-content mobile-view">
            <NavbarWithoutLogo link="/back" />
            <div className="row" style={{ zIndex: 0, paddingTop: 70, margin: '20px 0px 0px 0px'}}>
                <p style={{ color: '#393939', fontSize: 15, fontFamily: 'Montserrat', fontWeight: 600, padding: '0px 0px 0px 14px', margin: '0px 0px 10px 12px' }}>
                    Metode Pembayaran
                </p>
                <div style={{ fontFamily: 'Montserrat', padding: 0 }}>
                    <div style={{ height: 'auto' }}>
                        <div className="row" style={{ margin: '-6px 0px', fontSize: 12, color: '#848484' }}>
                            {
                                (isLoading) ?
                                <div className="spin-load" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Spin size="large" />
                                </div> :
                                <>
                                    {
                                        isBinding ?
                                        bindingList.map(item => {
                                            return(
                                                <>
                                                    <hr key={item.mcustomer_id} style={{ width: '95%', borderColor: 'black', marginTop: 15, marginBottom: 15 }} />
                                                    <div className="d-flex col-9" style={{ textAlign: 'left' }}>
                                                        <div className='ps-2'>
                                                            <img src='https://www.ezeepasar.com/Images/Icon_ezeeTunai.svg' alt='ezTunaiIcon' width={30} height={30} />
                                                        </div>
                                                        <div className='ms-3'>
                                                            <span style={{ fontFamily: 'Open Sans', fontSize: 14, fontWeight: 600, color: '#393939' }}>
                                                                ezeeTunai
                                                            </span>
                                                            <br />
                                                            <span style={{ fontSize: 12, fontWeight: 600, color: '#3DB54A' }}>
                                                                {`+${item.mcust_mobile} - ${item.mcust_name} ${item.status_prime ? '(Prime)' : ''}`}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-3 ps-2" style={{ textAlign: 'right', paddingRight: 25 }}>
                                                        <div onClick={() => setVisible(true)} style={{ marginTop: 15, cursor: 'pointer' }}>
                                                            <div style={{ fontFamily: 'Montserrat', color: '#EE2E2C', fontWeight: 700 }}>Unbind</div>
                                                        </div>
                                                    </div>
                                                    <hr style={{ width: '95%', borderColor: 'black', marginTop: 15, marginBottom: 15 }} />
                                                </>
                                            )
                                        }) :
                                        <>
                                            <hr style={{ width: '95%', borderColor: 'black', marginTop: 15, marginBottom: 15 }} />
                                            <div className="d-flex col-9" style={{ textAlign: 'left' }}>
                                                <div className='ps-2'>
                                                    <img src='https://www.ezeepasar.com/Images/Icon_ezeeTunai.svg' alt='ezTunaiIcon' width={30} height={30} />
                                                </div>
                                                <div className='ms-3 mt-1'>
                                                    <span style={{ fontFamily: 'Open Sans', fontSize: 14, fontWeight: 600, color: '#393939' }}>
                                                        ezeeTunai
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-3 ps-2" style={{ textAlign: 'right', paddingRight: 25 }}>
                                                <div onClick={() => history.push("/EzeeTunaiBinding")} style={{ marginTop: 10, cursor: 'pointer' }}>
                                                    <div style={{ fontFamily: 'Montserrat', color: '#3DB54A', fontWeight: 700 }}>Hubungkan</div>
                                                </div>
                                            </div>
                                            <hr style={{ width: '95%', borderColor: 'black', marginTop: 15, marginBottom: 15 }} />
                                        </>
                                    }
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="center-modal">
                <Modal className="modalProgress modalMetodePembayaran" id="modalProgress" visible={visible} footer={null} closable={false} style={{ marginTop: '10%', width: '330px !important' }} >
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 16, fontWeight: 600, marginBottom: 10}}>Apa Anda Yakin untuk melakukan Unbind ezeeTunai?</div>
                        <div className='mt-3'>
                            <Button onClick={unBindingHandler} className='me-1 btn-metode-pembayaran btn-submit'>
                                Yakin
                            </Button>
                            <Button onClick={() => setVisible(false)} className='ms-1 btn-metode-pembayaran' style={{ background: '#3DB54A', border: '1px solid #3DB54A', color: '#FFFFFF', fontStyle: 'normal' }}>
                                Batalkan
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default MetodePembayaran