import React, {useState, useEffect} from 'react'
import {Row, Col, Collapse} from 'antd'
import $, { get } from 'jquery'
import { useHistory, useLocation} from 'react-router-dom'
import emptyTab_order from '../../Asset/Icon/empty_tabPesanan.svg'
import emptyTab_promo from '../../Asset/Icon/empty_tabPromo.svg'
import emptyTab_info from '../../Asset/Icon/empty_tabInfo.svg'
import emptyTab_aktifitas from '../../Asset/Icon/empty_tabAktifitas.svg'
import notif_alert from  '../../Asset/Icon/notification_alert.svg'
import accordion_off from '../../Asset/Icon/accordion_off.svg'
import accordion_on from '../../Asset/Icon/accordion_on.svg'
import blush_icon from '../../Asset/Icon/dropdown_blashpush.png'
import { useFetchWithoutData} from '../../customHooks/useFetch';
import './Notification.css'
import {getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import moment from 'moment'
import encryptData from '../../function/encryptData'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loader-spinner' 
import {BaseURL, headersWithToken} from '../../function/helpers'

function Notification() {
    const [listDataNotification, setListDataNotification] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isOrder, setIsOrder] = useState(true);
    const [isPromo, setIsPromo] = useState(false);
    const [isInfo, setIsInfo] = useState(false);
    const [isActivity, setIsActivity] = useState(false);
    const [isLoadMore, setIsLoadMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [nextPageNumber, setNextPageNumber] = useState(1);

    const [openPanel, setOpenPanel] = useState("1");

    const { Panel } = Collapse;

    let access_token = getToken();
    let history = useHistory();
    let location = useLocation();
    
    let listOrder = [];
    let listPromo = [];
    let listInfo = [];
    let listActivity = [];

    let listOrderExt = -1, listPromoExt = -1, listInfoExt = -1, listActivityExt = -1;

    var idActive = $('.active').prop('id');  
    
    useEffect(() => {
        if(listDataNotification.length === 0){
            notificationList();
        }
        $('.ant-collapse-header').remove();
    },[pageNumber])
    
    function notificationList(){
        var url = window.location.search.replace("?tab=", "");
        var type;
        if(url === "Promo"){
            type=101
            setIsOrder(false);
            setIsPromo(true);
            setIsInfo(false);
            setIsActivity(false); 
        // $('.tab-notification').not('#'+type).removeClass('active');             
        }else if(url === "Info"){
            type=103
            setIsOrder(false);
            setIsPromo(false);
            setIsInfo(true);
            setIsActivity(false);
        }else if(url === "Aktifitas"){
            type=100
            setIsOrder(false);
            setIsPromo(false);
            setIsInfo(false);
            setIsActivity(true);
        }else{
            type=102
            setIsOrder(true);
            setIsPromo(false);
            setIsInfo(false);
            setIsActivity(false);
        }
        getNotificationList(type);         
    }

    async function getNotificationList(id, pagenum) {
        let page = pagenum;
        if(page === 1){page = 1}else{page = pageNumber}
        const signature = base64Sign("/notification/listnotif");  
        const dataParams = encryptData(`{"typeId" : ${id},"page" : ${page},"row_per_page" : 10}`)          
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/notification/listnotif',{data:dataParams},{headers:headers}).then(data=>{
            let datas = data.data.response_data.results;
            let nextpage = data.data.response_data.next_page;

            if(pageNumber === 1){
                setListDataNotification(datas);
            }else{
                if(id === idActive || idActive === undefined){                    
                    if(page === 1){                        
                        setListDataNotification(datas);
                    }else{
                        let newData = datas.map(dt => {return dt});
                        setListDataNotification(listNotif => [...listNotif,  ...newData]);
                    }
                }else{
                    setListDataNotification(datas);
                }
            }
            if(nextpage === 0){
                setNextPageNumber(nextpage);
                setIsLoadMore(false);
                setPageNumber(1);
            }else{
                setPageNumber(nextpage);
                setNextPageNumber(nextpage);
            }
            setLoading(false);
        }).catch(err => console.log(err));

    }

    if(loading){
        return(
            <>
                <LoadingModal/>
            </>
        )
    }else{    


        if(isActivity === true){
            listActivity.push(listDataNotification);
        }else if(isPromo === true){
            listPromo.push(listDataNotification);
        }else if(isOrder === true){
            listOrder.push(listDataNotification);
        }else{
            listInfo.push(listDataNotification);
        }

        listOrderExt = listOrder[0];
        listPromoExt = listPromo[0];
        listInfoExt = listInfo[0];
        listActivityExt = listActivity[0]
    }   
    function navigateTab(type){
        setIsLoadMore(false);
        if(pageNumber === 0 || pageNumber > 1){
            setPageNumber(1);
        }
        setIsLoadMore(true);
        if(type === 102){            
            setIsOrder(true);
            setIsPromo(false);
            setIsInfo(false);
            setIsActivity(false);
        }else if(type === 101){            
            setIsOrder(false);
            setIsPromo(true);
            setIsInfo(false);
            setIsActivity(false);
        }else if(type === 103){            
            setIsOrder(false);
            setIsPromo(false);
            setIsInfo(true);
            setIsActivity(false);
        }else if(type === 100){            
            setIsOrder(false);
            setIsPromo(false);
            setIsInfo(false);
            setIsActivity(true);
        }

        history.push("/notification")
        getNotificationList(type, 1);       
    }

    function loadMoreData(id){
        if(nextPageNumber === 0){
            setIsLoadMore(false);
            setPageNumber(1);
        }else{
            setIsLoadMore(true);
            if(idActive === undefined){idActive = 102}
            getNotificationList(idActive, 0);
        }
    }
    function convertUnixDate(dt){
        let date = moment.unix(dt).utc();
        return date;
    }

    function readNotif(fireId, refId, typeId, title){
        readNotifAPI(fireId, refId, typeId, title);
        if(title === 'Menunggu Pembayaran'){history.push('/RiwayatPesananDetail/'+refId, {fromNotif : true})}
        if(typeId === 103){
            let promoDetail;
            promoDetail = listDataNotification.filter(dt => dt.firebaseID === fireId);
            history.push('/NotifikasiDetail', {promoDetail: promoDetail});
        }
        if(typeId === 0){
            // window.location.reload();
            $("tbody[id*='bgColor']").css('background-color','unset');
        }
    }

    function readNotifAPI(firebaseId, refId, typeId){
        if(idActive === undefined){typeId = 102}
        let activetab = $('.active').attr('id');
        if(firebaseId === 0){
            typeId = activetab;
        }
        const signature = base64Sign("/notification/readnotif");  
        const dataParams = encryptData(`{"firebaseID" : ${firebaseId}, "referenceID" : "${refId}","typeId" : ${typeId}}`)          
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/notification/readnotif',{data:dataParams},{headers:headers}).then(data=>{
            $('#bgColor'+firebaseId).css('background-color','unset');
        }).catch(err => console.log(err));
    }

    function dropdownData(key){
        setOpenPanel(key);
        $('.activeAccr').attr("src", accordion_off);
        $('#iconAcc'+key).addClass('activeAccr');
        $('#iconAcc'+key).attr("src", accordion_on);
    }

    function convertDateNotif(dt){
        var today = moment();
        var yesterday = moment().subtract(1, 'day');

        var engagementDate = moment.unix(dt).utc();

        if(moment(engagementDate._d).isSame(today, 'day')){
            return 'Today'
        }else if(moment(engagementDate).isSame(yesterday, 'day')){
            return 'Yesterday'
        }else{
            return engagementDate.format("DD/MM/YY")
        }
    }

    return (
        <>
        <div style={{marginTop: 40, paddingBottom: 40}}>
            <div className='fixed'>
                <div>
                    <br/>
                    <br/>
                    <span className='span-header' style={{marginTop: 10, fontSize: 18, marginLeft: 15}}>Notifikasi</span>
                </div>
                <div>
                    <Row className='row-notif-table'>
                        <Col span={24} style={{overflowX: 'scroll', position: 'absolute', zIndex: 9999, backgroundColor: 'white', width: '100%'}}>
                            <table className='table-notif-header'>
                                <tbody style={{backgroundColor: '#E6F5E8#'}}>
                                    <tr>
                                        <td>
                                            <div className={isOrder ? 'tab-notification no-select-text active' : 'tab-notification no-select-text'} id='102' onClick={() => navigateTab(102)}>
                                                Pesanan
                                            </div>
                                        </td>
                                        <td>
                                            <div className={isPromo ? 'tab-notification no-select-text active' : 'tab-notification no-select-text'} id='101' onClick={() => navigateTab(101)}>
                                                Promo
                                            </div>
                                        </td>
                                        <td>
                                            <div className={isInfo ? 'tab-notification no-select-text active' : 'tab-notification no-select-text'} id="103" onClick={() => navigateTab(103)}>
                                                Info
                                            </div>
                                        </td>
                                        <td>
                                            <div className={isActivity ? 'tab-notification no-select-text active' : 'tab-notification no-select-text'} id="100" onClick={() => navigateTab(100)}>
                                                Aktifitas
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </div>
            </div>
        </div> 
        <div className='list-notification'>
            <Row>
                <Col span={24}>
                    <div className='btn-fixed-bottom'>
                        <button className='floating-button' onClick={() => readNotif(0, 0, 0)}>
                            Tandai semua dibaca
                        </button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <table className='table-list-notification' id={idActive}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={loadMoreData}
                            hasMore={nextPageNumber !== 0 ? true : false}                
                            loader={<>
                            <Loader
                                type="ThreeDots"
                                color="#3DB54A"
                                height={50}
                                width={50}
                                style={{textAlign: 'center'}}
                            />
                        </>}>
                            {
                            isOrder ?                                     
                                (listOrderExt === undefined || listOrderExt.length === 0 ? 
                                <>
                                <Row>
                                    <Col span={24}>
                                        <div style={{textAlign: 'center', marginTop: 40}}>
                                            <img src={emptyTab_order} alt="" style={{width: 123, height: 128}}/><br/>
                                            <span style={{fontWeight: 600}}>Belum Ada Pesanan</span>
                                            <p className='pContent'>Semua pesanan kamu akan ditampilkan disini.</p>
                                        </div>
                                    </Col>
                                </Row>
                                </>
                                    : 
                                <>
                                {
                                    listOrderExt.map((data, i) => {
                                        var color = data.Is_read === false ? '#E6F5E8' : 'unset';
                                        return(
                                            <tbody key={i} style={{backgroundColor: color, cursor: 'pointer'}} id={"bgColor"+data.firebaseID} onClick={() => readNotif(data.firebaseID, data.referenceID, 102, data.title)}>
                                                <tr key={i}>
                                                    <td className='notif-title' key={i}>
                                                        {data.title} &nbsp; {data.inherit !== null ? <img src={accordion_off} id={"iconAcc"+data.referenceID} className='iconAccordion' style={{cursor: 'pointer'}} alt="" onClick={() => dropdownData(data.referenceID)}/>  : null} 
                                                    </td>
                                                    <td></td>
                                                    <td className='notif-date'>{convertDateNotif(data.createDate)}</td>
                                                    {data.Is_read === false ? <td className='notif-read'><img src={notif_alert} alt=''/></td> : null}
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className='notif-message'>
                                                        {data.message}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr className='collapse-data' hidden={false}>
                                                        {
                                                        data.inherit !== null ? 
                                                        <Collapse ghost activeKey={openPanel} destroyInactivePanel={true}>
                                                                <Panel header={null} key={data.referenceID} showArrow={false}>
                                                                <td colSpan={2}>
                                                                {data.inherit.length < 2 ? <img alt="" src={blush_icon} className='notifDetailLine dot'/>: <img alt="" src={blush_icon} className='notifDetailLine'/>}
                                                                    { data.inherit.map((datas, i) => {
                                                                            if(data.inherit.length < 2){
                                                                                return(
                                                                                    <div key={i}>                                                                                  
                                                                                        <div className='notifDetailTitle' key={i}>
                                                                                            {datas.title}
                                                                                        </div>
                                                                                        <div className='notifDetailMessage'>
                                                                                            {convertDateNotif(datas.createDate)}
                                                                                        </div>  
                                                                                    </div>
                                                                                )
                                                                            }else{
                                                                                return(
                                                                                    <>
                                                                                    <div className='notifDetailTitle' key={i}>
                                                                                        {datas.title}
                                                                                    </div>
                                                                                    <div className='notifDetailMessage' style={{marginBottom: 20, paddingLeft: 50}}>
                                                                                        {convertDateNotif(datas.createDate)}
                                                                                    </div>  
                                                                                    </>
                                                                                )
                                                                            }
                                                                        })}                                                         
                                                                </td>                                                                
                                                                </Panel>
                                                        </Collapse> : <></>                                                            
                                                        }
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                        <td></td>
                                                </tr>
                                                <tr style={{borderBottom: '1px solid #F4F4F4'}}>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                    <td></td>                                                    
                                                </tr> 
                                            </tbody>
                                        )
                                    })
                                }                                    
                                    </>)
                            : <></> }
                            {isPromo ? 
                                (listPromoExt === undefined  || listPromoExt.length === 0 ? 
                                    <>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{textAlign: 'center', marginTop: 40}}>
                                                <img src={emptyTab_promo} alt="" style={{width: 123, height: 128}}/><br/>
                                                <span style={{fontWeight: 600}}>Belum Ada Promo</span>
                                                <p className='pContent'>Semua promo kamu akan ditampilkan disini.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    : 
                                    <>
                                {
                                    listPromoExt.map((data, i) => {
                                        var color = data.Is_read === false ? '#E6F5E8' : 'unset';
                                        return(
                                            <tbody key={i} style={{backgroundColor: color}} onClick={() => readNotif(data.firebaseID, data.referenceID, 101)} id={"bgColor"+data.firebaseID}>
                                                <tr key={i}>
                                                    <td className='notif-title'>
                                                        {data.title}
                                                    </td>
                                                    <td></td>
                                                    <td className='notif-date'>{convertDateNotif(data.createDate)}</td>
                                                    {data.Is_read === false ? <td className='notif-read'><img src={notif_alert} alt=''/></td> : null}
                                                </tr>
                                                <tr>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className='notif-message'>
                                                        {data.message}
                                                    </td>
                                                    <td></td>
                                                    <td><img src={data.imgLink} alt="" style={{width: 39, height: 39, borderRadius: 4}}/></td>
                                                    <td></td>
                                                </tr>
                                                <tr></tr>
                                                <tr style={{borderBottom: '1px solid #F4F4F4'}}>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                </tr> 
                                            </tbody>
                                        )
                                    })
                                }
                                </>): <></>}
                            {isInfo ? 
                                (listInfoExt === undefined || listInfoExt.length === 0? 
                                    <>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{textAlign: 'center', marginTop: 40}}>
                                                <img src={emptyTab_info} alt="" style={{width: 123, height: 128}}/><br/>
                                                <span style={{fontWeight: 600}}>Belum Ada Info</span>
                                                <p className='pContent'>Semua Info kamu akan ditampilkan disini.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    : 
                                    <>
                                {
                                    listInfoExt.map((data, i) => {
                                        var color = data.Is_read === false ? '#E6F5E8' : 'unset';
                                        return(                                            
                                            <tbody key={i} style={{backgroundColor: color}} onClick={() => readNotif(data.firebaseID, data.referenceID, 103)} id={"bgColor"+data.firebaseID}>
                                                <tr key={i}>
                                                    <td className='notif-title'>
                                                        {data.title}
                                                    </td>
                                                    <td></td>
                                                    <td className='notif-date'>{convertUnixDate(data.createDate).format("DD/MM/YY")}</td>
                                                    {data.Is_read === false ? <td className='notif-read'><img src={notif_alert} alt=''/></td> : null}
                                                </tr>
                                                <tr>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className='notif-message'>
                                                        {data.message}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr></tr>
                                                <tr style={{borderBottom: '1px solid #F4F4F4'}}>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                </tr> 
                                            </tbody>
                                        )
                                    })
                                }
                                </>)                                
                                : <></>}
                            {isActivity ? 
                                (listActivityExt === undefined || listActivityExt.length === 0? 
                                    <>
                                    <Row>
                                        <Col span={24}>
                                            <div style={{textAlign: 'center', marginTop: 40}}>
                                                <img src={emptyTab_aktifitas} alt="" style={{width: 123, height: 128}}/><br/>
                                                <span style={{fontWeight: 600}}>Belum Ada Aktifitas</span>
                                                <p className='pContent'>Semua aktifitas kamu akan ditampilkan disini.</p>
                                            </div>
                                        </Col>
                                    </Row>
                                    </>
                                    : 
                                    <> 
                                {
                                    listActivityExt.map((data, i) => {
                                        var color = data.Is_read === false ? '#E6F5E8' : 'unset';
                                        return(                                           
                                            <tbody key={i} style={{backgroundColor: color}} onClick={() => readNotif(data.firebaseID, 0, 100)} id={"bgColor"+data.firebaseID}>
                                                <tr key={i}>
                                                    <td className='notif-title'>
                                                        {data.title}
                                                    </td>
                                                    <td></td>
                                                    <td className='notif-date'>{convertUnixDate(data.createDate).format("DD/MM/YY")}</td>
                                                    {data.Is_read === false ? <td className='notif-read'><img src={notif_alert} alt=''/></td> : null}
                                                </tr>
                                                <tr>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td className='notif-message'>
                                                        {data.message}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr></tr>
                                                <tr style={{borderBottom: '1px solid #F4F4F4'}}>
                                                    <td colSpan={3} style={{padding: '5px 0'}}></td>
                                                    <td></td>
                                                </tr> 
                                            </tbody>
                                        )
                                    })
                                }
                            </>) 
                            : <></>}
                        </InfiniteScroll>
                    </table>
                </Col>
            </Row>
        </div>
    </>
    )
}

export default Notification
