import React, {useState,useEffect} from 'react'
import { Row, Col, Button, Card, Modal} from 'antd'
import { useFetch} from '../../customHooks/useFetch';
import { useLocation, useParams, useHistory} from "react-router-dom";
import AddCartModal, { PopUpAddCart } from '../PopUpModal/HomePage/AddCartModal';
import {fetchProductsOther} from '../../redux/ActionCreators/productOtherActions'
import {fetchProductsSearch} from '../../redux/ActionCreators/productSearchActions'
import { connect } from 'react-redux';
import './ProdukOther.css'
import {CardProdOtherLoading} from '../skeletonLoading/loadingTag'
import {nFormatter} from '../../function/helpers'
import iconSort from '../../Asset/Icon/icon_sort.svg'
import sortAToZ from '../../Asset/Icon/icon_a_to_z.svg'
import sortZToA from '../../Asset/Icon/icon_z_to_a.svg'
import sortTerendah from '../../Asset/Icon/icon_harga_terendah.svg'
import sortTertinggi from '../../Asset/Icon/icon_harga_tertinggi.svg'
import productNotFound from '../../Asset/Icon/product_not_found.png'
import { useDispatch, useSelector } from 'react-redux'
import ProdukRekomendasi from './ProdukRekomendasi'
import {getToken} from '../../utils/setSessionUser'
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loader-spinner' 
import $ from 'jquery'

function ProdukOther({product, productSearch, fetchProductsOther, fetchProductsSearch}) {
    
    let { id, title } = useParams();
    const [ visible, setVisible ] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    const [prodId, setProdId ] = useState(0);
    const [scrollDown, setScrollDown] = useState(false);
    const [nextPageNumber, setNextPageNumber] = useState(1);
    const [isLoadMore, setIsLoadMore] = useState(false);
    const dispatch = useDispatch();
    const [datas, setDatas] = useState(product.products);
    // const [dataSearch, setDataSearch] = useState(productSearch.products.product_list);
    // const [datax, setDatax] = useState([])

    let history = useHistory();
    let location = useLocation();
    let data = [];
    let isLoading;
    let access_token = getToken();
    let isContainPromo;

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }
    function openModalWithData(id) {
        if(id === undefined){
            setVisible(false);
        }
        else
        {
            setVisible(true)
            const prodID = id;
            setProdId(prodID);
        }
    }

    useEffect(() => {
        if(title === "Hasil&Pencarian"){
            fetchProductsSearch("/dashboard/search", `{"key": "${location.state.prodTitle}", "is_submit_search": 1}`)
        }else{
           fetchProductsOther("/product/getproductdashboardall", `
            {"prod_type":${id},
            "sort_item":0,
            "page":1,
            "row_per_page":100}`);
        }
        openModalWithData();
        const handleScroll = () => {
            const currentScrollY = window.scrollY;
            if (currentScrollY > 0) {
                setScrollDown(true)
            } else {
                setScrollDown(false)
            }
        };
        window.addEventListener("scroll", handleScroll);
    }, [dispatch])
    
    if(title === "Hasil&Pencarian"){
        data.push(productSearch.products.product_list); 
        isLoading = productSearch.loading;
    }else{
        data.push(product.products);
        isLoading = product.loading;
    }

    if(isLoading){
        let prods = [1,2,3,4,5,6]
        return(
            <div style={{paddingTop: 90}}>
                <Row style={{marginLeft: 15, marginRight: 15}}>               
                {prods.map(dt => {
                    return(                
                        <Col span={10} className="item-product" style={{ paddingBottom: 20, marginBottom: 15, width: '46%', float: 'left' }}>
                            <CardProdOtherLoading/>
                        </Col>
                    
                    )
                })}
                </Row>
            </div>
            
        )
    }
    let isPromo = data[0].find(q => q.product_promo_percent !== null);
    if(isPromo !== undefined){
        isContainPromo = true;
    }else{
        isContainPromo = false;
    }

    function handleSortingProduct(sortItem) {
        fetchProductsOther("/product/getproductdashboardall", `
        {"prod_type":${id},
        "sort_item":${sortItem},
        "page":${nextPageNumber},
        "row_per_page":10}`);
        setIsVisible(false)
    }

    const productDetail = (prodId, prodName) => {
        const productId = prodId;
        const productName = prodName;
        history.push({pathname: `/produk/${productId}/${productName.replace(/\//g, " ")}`, state: {result: productId}})
    }

    function reSearchProduct(){
        history.push(`/produk/ProductOther/${id}/${title}`, {productListSearch: null, prodTitle : productSearch.products.suggest});
    }

    function loadMoreData(){
        if(product.next_page === null){
            setIsLoadMore(false);
        }else{
            // console.log("load more active");
            // setIsLoadMore(true);
            // fetchProductsOther("/product/getproductdashboardall", `
            // {"prod_type":${id},
            // "sort_item":0,
            // "page":${product.next_page},
            // "row_per_page":10}`);
        }
        // console.log(datas);
        // const newArr = [...data[0], ...datas];
        // data[0] = newArr;
        // console.log(data[0]);
    }

    function loadDatas(){
        loadMoreData();
        // const newArr = [...data[0], ...datas];
        // data[0] = newArr;
    }

    if(isLoadMore){
        const newArr = [data[0], ...product.products];
        data[0] = newArr;
    }
    
    // let isPromo = data[0].find(q => q.product_promo_percent !== null);
    // console.log(datas, dataSearch, datax)

    // console.log(data[0]);

    return (
        <div>
            {
                data[0].length === 0 ? 
                <div style={{paddingTop: '20%', paddingLeft: 15, paddingRight: 15}}>
                    <div style={{textAlign: 'center'}}>
                        <img alt="" src={productNotFound}/>
                        <br/>
                        <label style={{fontSize: 20}}>
                            <b>Maaf, Produk tidak ditemukan</b>
                        </label>
                        <br/>
                        <span style={{fontSize: 10, color: '#848484'}}>Coba kata kunci lain atau cek produk rekomendasi di bawah</span>
                    </div>
                    <div>
                        <Row>                            
                            {
                                access_token === null ? <></> : 
                                <>
                                    <span className="head-2-product-other" style={{marginTop: 40}}>Rekomendasi Produk</span>
                                    <ProdukRekomendasi />
                                </>
                            }
                        </Row>
                    </div>
                </div>
                
                :
                <>
                    {
                        title === "Hasil&Pencarian" ? [
                            (productSearch.products.suggest !== null) ? 
                            <div style={{paddingTop: '15%', paddingRight: 15}}>
                            <Row>
                                <p className='suggestion-title'>Menampilkan hasil untuk "{location.state.prodTitle}". Apakah yang kamu maksud <span style={{color: '#3DB54A'}} onClick={() => reSearchProduct()}>"{productSearch.products.suggest}"</span>?</p>
                            </Row>
                            <Row>
                                <span className="head-2-product-other" style={{marginTop: 'unset !important'}}>{title.replace("&", " ")}</span>
                            </Row>
                            </div>
                             :
                         <Row>
                             <span className="head-2-product-other" style={{marginTop: '20%'}}>{title.replace("&", " ")}</span>
                         </Row> 
                        ] :                              
                        <Row>
                            <span className="head-2-product-other" style={{marginTop: '20%'}}>{title.replace("&", " ")}</span>
                        </Row> 
                    }
                    {
                        title !== 'Hasil&Pencarian' ? [
                        (!scrollDown) ?
                        <div onClick={() => setIsVisible(!isVisible)} className='div-opt-btn' style={{position: 'relative',backgroundColor: 'unset', padding: 'unset', borderRadius: 'unset', border: 'unset', boxShadow: 'unset', marginRight: '10%', float: 'right'}}>
                            <img src={iconSort} alt='iconSort' style={{ cursor: 'pointer', marginTop: -14, position: 'fixed'}} />
                        </div> :
                        <div onClick={() => setIsVisible(!isVisible)} className='div-opt-btn' style={{position : 'relative',backgroundColor: '#ffffff', padding: 5, borderRadius: 20, border: '1px solid rgb(238, 238, 238)', boxShadow: 'unset', marginRight: '10%', float: 'right' }}>
                            <img src={iconSort} alt='iconSort' style={{ cursor: 'pointer', display: 'block', position: 'fixed'}} />
                        </div>
                        ] : null
                    }
                    <Row style={{marginLeft: 15, marginRight: 15}}>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={loadMoreData}
                        hasMore={product.next_page !== null ? true : false}
                        max={product.max_page}                      
                        loader={<>
                            <Loader
                                type="ThreeDots"
                                color="#3DB54A"
                                height={50}
                                width={50}
                                style={{textAlign: 'center'}}
                            />
                        </>}
                    >
                    {
                        data[0].map(prodList=> {
                            return (
                                <Col span={10} className="item-product" style={isContainPromo ? { paddingBottom: 20 ,marginBottom: 15, width: '46%', float: 'left', height: '360px' } : { paddingBottom: 20 ,marginBottom: 15, width: '46%', float: 'left' }}>
                                    <div className="box-produk" style={{ width: '100%', position: 'relative' }} onClick={() => productDetail(prodList.product_id, prodList.product_name)}>                                
                                    {
                                        prodList.product_stat_stock_id === 2 
                                        ? <div className='sold-out-product'><img src={prodList.product_image} alt="" className='imgProduct'></img><span className='text-sold-out'>Habis</span></div> : 
                                        <img src={prodList.product_image} alt="" className='imgProduct'></img>
                                    }                           
                                        <div className="text-2" style={{ marginTop: 12 }}>
                                            <div className="text-produk1" style={{ fontSize: 13 }}>
                                            { prodList.product_name }
                                            </div>
                                            <div className="text-produk2">{ prodList.product_weight } { prodList.product_weight_name }</div>
                                            {
                                                prodList.product_promo_percent == null
                                                ? <p className="text-produk3">{ convertToRupiah(prodList.product_price) } / { prodList.product_unit_name }</p>
                                                : <><div className="disc-product">
                                                    <span className="percent-disc">{ prodList.product_promo_percent }%</span>
                                                    <span className="real-price">{ convertToRupiah(prodList.product_price) }</span>
                                                </div>
                                                <p className="text-produk3">{ convertToRupiah(prodList.product_promo_price) } / { prodList.product_unit_name }</p>
                                                </>
                                            }
                                            {
                                                prodList.product_terjual !== 0 ? 
                                                <div>
                                                    <span className='sp-danger product-sold-and-stock' style={{fontSize: 10, float: 'left'}}>Terjual: <span style={{color: 'black'}}>{nFormatter(prodList.product_terjual)}</span></span>
                                                </div> : 
                                                <div>
                                                    <span className='sp-danger product-sold-and-stock' style={{fontSize: 10, float: 'left', visibility: 'hidden'}}>Terjual: <span style={{color: 'black'}}></span></span>
                                                </div>
                                            }                                         
                                            {
                                                prodList.product_stat_stock_id === 2
                                                ? <div onClick={(event) => event.stopPropagation()}><button className="btn-pesan-sold" style={isContainPromo ? prodList.product_promo_percent === null ? {marginTop: 43} : null : null} shape="round">Pesan</button></div>
                                                : <div onClick={(event) => event.stopPropagation()}><Button className="btn-pesan" style={isContainPromo ? prodList.product_promo_percent === null ? {marginTop: 43} : null : null} shape="round" onClick={() =>openModalWithData(prodList.product_id)}>Pesan</Button></div>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            )
                        })
                    }        
                    </InfiniteScroll>
                    </Row>
                    {
                        visible ? <AddCartModal showModal={visible} setModal={setVisible} closedModal={() => setVisible(false)} productId={prodId}/> : null
                    }
                    <Modal
                        visible={isVisible}
                        onCancel={() => setIsVisible(false)}
                        footer={null}
                        className={(!scrollDown) ? "modalVefiedFilterProd" : "modalVefiedFilterProd"}
                    >
                        <div className='div-sort-item' style={!scrollDown ? {display: 'unset', marginTop: 5, cursor: 'pointer'} : {display: 'unset', marginTop: 25, cursor: 'pointer'}}>
                            <img onClick={() => handleSortingProduct(1, id)} style={{ width: 82, height: 32, }} src={sortAToZ} alt='aToZ' />
                            <img onClick={() => handleSortingProduct(2, id)} style={{ width: 82, height: 32 }} src={sortZToA} alt='zToA' />
                            <img onClick={() => handleSortingProduct(3, id)} style={{ width: 82, height: 32 }} src={sortTerendah} alt='terendah' />
                            <img onClick={() => handleSortingProduct(4, id)} style={{ width: 82, height: 32 }} src={sortTertinggi} alt='tertinggi' />
                        </div>
                    </Modal>
                </>
            }
        </div>
    )
}

const mapStateToProps = (state) => {
    return{
        product : state.productOtherReducer,
        productSearch : state.productSearchReducer        

    }
}

const mapDispatchToProps = dispatch => {
    return{
        fetchProductsOther : (url, data) => dispatch(fetchProductsOther(url, data)),
        fetchProductsSearch : (url, data) => dispatch(fetchProductsSearch(url, data))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProdukOther)
