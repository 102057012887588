import React, {useState, useEffect}from 'react';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import { getToken } from '../../utils/setSessionUser';
import {useFetchWithoutData} from '../../customHooks/useFetch';
import {Link, useHistory } from 'react-router-dom'
import $ from 'jquery';
import './SearchPage.css';
import {BaseURL, moneyFormat} from '../../function/helpers'
import iconUilHistory from '../../Asset/Icon/uil_history.svg'
import iconFeatherX from '../../Asset/Icon/feather_x.svg'

function SearchPage({receiveData}) {
    const [listRecentSearch, loadingSearchData] = useFetchWithoutData('/dashboard/listrecentsearch');

    useEffect(() => {

    },[listRecentSearch])

    let history = useHistory();

    async function deleteRecentSearch(id){
        var access_token = getToken();
        const signature = base64Sign("/dashboard/deleterecentsearch");
        const dataParams = encryptData(`{"recent_id": ${id}}`);   
        const headers =
        {
        'Content-Type':'application/json',
        'sign' : signature,
        'access_token' : access_token
        }
        axios
        .post(BaseURL + '/dashboard/deleterecentsearch',{data:dataParams},{headers:headers}).then(data=>{
            if(id === 0){
                window.location.reload();
            }
            $("#recentSearch_"+id).remove();
        }).catch(err => console.log(err));
    }

    function deleteSearch(id){
        deleteRecentSearch(id);
    }

    function navigateProductDetail(productId, productName){
        history.push({pathname: `/produk/${productId}/${productName.replace(/\//g, " ")}`, state: {result: productId}})
    }

    function navigateToOtherProd(item){
        history.push(`/produk/ProductOther/99/Hasil&Pencarian`, {prodTitle : item});
    }

  return (  
    <div style={{paddingTop: 70}} className='resSearch'>
        <div className='recent-search'>
            <br/>
            {receiveData.product_list === undefined ? 
            <>
                {listRecentSearch.length !== 0 ? <>
                    <div className='head-search'>
                    Terakhir dicari
                    <p className='delete-search-span' onClick={() => deleteSearch(0)}>Hapus Semua</p>
                </div>
                </> : null}
                <table>
                    <tbody>
                        {listRecentSearch.map((item, index) => {
                            return(
                                <>
                                    <tr key={item.tsearch_recent_id} id={"recentSearch_"+item.tsearch_recent_id} onClick={() => navigateToOtherProd(item.tsearch_recent_name)}>
                                        <td><img src={iconUilHistory} alt=""/></td>
                                        <td style={{width: '100%'}}>{item.tsearch_recent_name}</td>
                                        <td style={{float: 'right'}} onClick={(e) => e.stopPropagation()}><img src={iconFeatherX} onClick={() => deleteSearch(item.tsearch_recent_id)} alt="" style={{width: 18}}/></td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
            </>
             :         
             <>
             <h6 className='produk-span-searchPage'>Produk</h6>
             <table className='table-res-search'>     
                {receiveData.product_list.map((item, index) => {
                    return(
                            <tr onClick={() => navigateProductDetail(item.product_id, item.product_name)}>
                                <td><img src={item.product_image} alt="" style={{width: 80}}/></td>
                                <td>                        
                                        <div className='prodname'>{item.product_name} </div>
                                        <div className='prodsatuan'>{item.product_weight} {item.product_weight_name}</div>
                                        {
                                            item.product_promo_price !== null ? 
                                            <div className='disc-product'>
                                                <span className="real-price" style={{marginRight: 2}}>{ moneyFormat(item.product_price) }</span>
                                                <span className='prodprice'>
                                                    {moneyFormat(item.product_promo_price)} / {item.product_unit_name}
                                                </span>
                                            </div>
                                            : <p className='prodprice'>{moneyFormat(item.product_price)} / {item.product_unit_name}</p>
                                        }                      
                                </td>
                            </tr>
                    )
                })}
                </table>
            </>
            }

        </div>
    </div>
  )
}

export default SearchPage;
