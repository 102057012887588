import React, { useState, useEffect } from 'react'
import { Row } from 'antd'
import {useLocation, useParams} from "react-router-dom";
import axios from 'axios';
import { Base64 } from 'js-base64';
import CryptoJS from 'crypto-js';
import ReactHtmlParser from 'react-html-parser';
import './PromoBanner.css'
import {getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import encryptData from '../../function/encryptData';
import { BannerPromoLoading } from '../skeletonLoading/loadingTag'
import {BaseURL} from '../../function/helpers';

function PromoBanner(bannerId) {

    // let data = useLocation();
    let {id} = useParams();
    let access_token = getToken();

    const [ loading, setLoading ] = useState(true)
    const [ bannerDetail, setBannerDetail ] = useState('');

    async function fetchBannerDetail() {
        try {
            const param = encryptData(`{"bannerId":${id}}`)
            const signature = base64Sign("/promo/detailpromosi");
            const headers = 
            {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
            }
            const dataBannerDetail = await axios.post(BaseURL + "/promo/detailpromosi",{data: param},{headers : headers})
            setBannerDetail(dataBannerDetail.data.response_data.results)
            setLoading(false)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBannerDetail()
    }, [])
    
    if(loading) {
        return (
            <diV style={{ marginTop: 60 }}>
                <BannerPromoLoading/>
            </diV>
        )
    }

    const contentStyle = {
        width: '100%',
        height: 'auto',
        marginTop: '60px'
    }
    const html = bannerDetail.mbanner_detail;
    return (
        <div>
            <div>
                <img src={bannerDetail.mbanner_image} alt="" style={contentStyle} />
            </div>
            <Row style={{padding: '0px 25px'}}>
                <div className="headTitle" style={{ marginRight: 15, marginTop: 15}}>
                    {bannerDetail.mbanner_name}
                </div>
            </Row>
            <Row style={{padding: '0px 25px', textAlign: 'left'}}>
                <div className="spanHtml">
                    {ReactHtmlParser(html)}
                </div>
            </Row>
        </div>
    )
}

export default PromoBanner
