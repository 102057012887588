import { IS_LOADING_GETCATLAYER2LIST1_CAT } from "../../ActionType/actionTypes";

export function isLoadingGetCatLayer2List1(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_GETCATLAYER2LIST1_CAT, 
            payload: {
                isLoadingGetCatLayer2List1: param
            }
        })
    }
}