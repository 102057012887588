import React, {useState}from 'react'
import {Link, useHistory} from 'react-router-dom'
import { Row, Col, Input, Modal} from 'antd'
import './ForgotPassword.css'
import validator from 'validator'
import axios from 'axios';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import {BaseURL, headersWithoutToken} from '../../function/helpers'
import iconErrorRed from '../../Asset/Icon/error_icon.svg'
import $ from 'jquery'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'

function ForgotPassword(){
    const [email, setEmail] = useState('');
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorInfo, setErrorInfo] = useState('')

    let history = useHistory();

    function sendEmailFunc(){
        setLoading(true);
        sendEmail();
    }

    async function sendEmail(){
        var email = $('#emailValue').val();
        if(validator.isEmail(email)){
            try{
                const signature = base64Sign("/profile/forgotpassword");            
                const dataParams = encryptData(`{"email":"${email}"}`)
                const headers = headersWithoutToken(signature);            
                const datas = await axios.post(BaseURL + '/profile/forgotpassword',{data: dataParams},{headers : headers});
                if(datas.status === 200)
                {
                    setLoading(false);
                    setVisible(true);
                }else{
                    if(datas.response.status === 400){
                        setLoading(false);
                        setVisible(false);
                        setErrorInfo(datas.response.data.response_message)
                        $('#emailValue').css("border", "1px solid red")
                    }
                }
            }catch(err){
                console.log(err);
                setLoading(false);
            }
        }else{
            setLoading(false);
            setVisible(false);
            $('#emailValue').css("border", "1px solid red")
            setErrorInfo("Email tidak valid")
        }
    }

    function closeModal(){
        setVisible(false);
        history.push('/login')
    }

    if(loading){        
        return(
           <LoadingModal/>
        )        
    }

    return(
        <div style={{paddingLeft: 15, paddingRight: 15}}>
            <h2 style={{marginTop: 80}} className="head-2">Lupa Kata Sandi</h2>
            <div style={{marginTop: 25}} className="text-1">
                <span>Masukkan email login Anda dibawah ini dan kami akan mengirimkan pesan email beserta link untuk reset kata sandi Anda</span>
            </div>
            <div style={{marginLeft: 15, marginRight: 15, marginTop: 20}}>
                <Row>
                        <Col>
                            <span className="title-label">Email atau No. Telepon</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Input className="form-control-log form-control-forgot-pass" id='emailValue' onChange={e => setEmail(e.target.value)}></Input>
                        </Col>
                        {errorInfo !== '' ? <div className="error-txt"><img src={iconErrorRed} alt="" />{errorInfo}</div> : null}
                    </Row>
                    <Row style={{marginTop: 12}}>
                        <Col span={24}>
                            <Link><button className="btn-forgot-pass" disabled={!email} onClick={() => sendEmailFunc()}>Submit</button></Link>
                        </Col>
                    </Row>
                </div>
                <Modal className="modalProgress" id="modalProgress" visible={visible} footer={null} closable={false} style={{marginTop: '10%', width: '330px !important'}} >
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 14, fontWeight: 600, marginBottom: 10}}>Email Berhasil Dikirim</div>
                        <div style={{fontSize: 10}}>Silahkan periksa email dan ikuti instruksi untuk mereset kata sandi Anda</div>
                        <br/>
                        <div><button className='ez-btn-green' style={{width: '50%', height: '42px',border: 'none', borderRadius: '3px'}} onClick={() => closeModal()}>Oke</button></div>
                        
                    </div>
                </Modal>
        </div>
    )
}

export default ForgotPassword