import React, { useEffect, useState } from 'react'
import { Row, Col, notification} from 'antd'
import { Link } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Banner from '../components/HomePage/Banner';
import EzeeTunai from '../components/HomePage/EzeeTunai';
import Category from '../components/HomePage/Category';
import PromoSide from '../components/HomePage/PromoSide';
import NewProduct from '../components/HomePage/NewProduct';
import MostBuyProduct from '../components/HomePage/MostBuyProduct';
import RecommendProduct from '../components/HomePage/RecommendProduct';
import MostSearch from '../components/HomePage/MostSearch';
import About from '../components/HomePage/About';
import FooterSide from '../components/HomePage/FooterSide';
import { useSelector, useDispatch } from 'react-redux'
import './Home.css'
import { fetchNewProd } from '../redux/ActionCreators/newProdActions';
import { fetchMostBuyProd } from '../redux/ActionCreators/mostBuyProdActions';
import { fetchRecomProd } from '../redux/ActionCreators/recomprodActions';
import { fetchMostSearchProd } from '../redux/ActionCreators/mostSearchProdAction';
import { CardProdLoading } from '../components/skeletonLoading/loadingTag'
import { fetchPromoProd } from '../redux/ActionCreators/promoProdActions';
import {Navbar} from '../components/Properties/Navbar/Navbar'
import {onMessageListener, getTokenFirebase} from '../firebase'
import NotificationPermission from '../components/Notification/NotificationPermission';
import { getToken } from '../utils/setSessionUser'
import {isTokenSentToServer, isdeniednotification, setdeniednotification, useTitle} from '../function/helpers'
import EzeeTunaiUnactiveModal from '../components/PopUpModal/HomePage/EzeeTunaiUnactiveModal' 
import backgroundPromo from '../Asset/Icon/bg-promo.svg'

function Home() {
    useTitle("ezeePasar")

    const loops = [1,2,3,4,5,6]
    const promoProds = useSelector(state => state.promoProdsReducer.promoProds)
    const isLoadingPromoProdsHome = useSelector(state => state.isLoadingPromoProdsReducer.isLoadingPromoProdsHome)

    const newProds = useSelector(state => state.newProdsReducer.newProds)
    const isLoadingNewProdHome = useSelector(state => state.isLoadingNewProdReducer.isLoadingNewProdHome)
    
    const mostBuyProds = useSelector(state => state.mostBuyProdsReducer.mostBuyProds)
    const isLoadingMostBuyProdHome = useSelector(state => state.isLoadingMostBuyProdReducer.isLoadingMostBuyProdHome)
    
    const recomProds = useSelector(state => state.recomProdsReducer.recomProds)
    const isLoadingRecomendProdHome = useSelector(state => state.isLoadingRecomendProdReducer.isLoadingRecomendProdHome)
    
    const mostSearchProds = useSelector(state => state.mostSearchProdsReducer.mostSearchProds)
    const isLoadingMostSearchProdHome = useSelector(state => state.isLoadingMostSearchProdReducer.isLoadingMostSearchProdHome)

    // const isLoading = useSelector(state => state.isLoadingReducer.isLoading)
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [ezeeTunaiActivation, setezeeTunaiActivation] = useState(true);
    let history = useHistory();
    let token = getToken();
    let userAgent = navigator.userAgent;
    
    onMessageListener()
    .then((payload) => {
        notification.open({
            message: payload.data.title,
            description: payload.data.message,
            onClick: () => {
                if (payload.data.type_notif_code === "TRANSACTION") {
                    history.push('/RiwayatPesananDetail/'+payload.data.session_id)
                }
                else if (payload.data.type_notif_code === "AKTIVITAS_AKUN") {
                    history.push('/notification?tab=Aktifitas')
                }
                else if (payload.data.type_notif_code === "PROMO") {
                    history.push('/notification?tab=Promo')
                }
                else if (payload.data.type_notif_code === "INFO") {
                    history.push('/notification?tab=Info')
                }
                else {
                    history.push('/notification?tab=Promo')
                }
            },
        });
    })
    .catch((err) => console.log('failed: ', err));
    
    function notificationPermission(){
        if(Notification.permission !== 'granted'){
            if(!isdeniednotification()){
                setShow(true);
            }        
        }else{
            setShow(false);
        }
    }

    function NotificationSnackbar(param){
        if(param === 0){
            setShow(false);
            isTokenSentToServer(false);
            setdeniednotification(true);
        }else{
            setdeniednotification(false);
            isTokenSentToServer(true);
            getTokenFirebase();
            setShow(false);
        }
    }

    function checkBrowser(){
        var browserName;
        if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) !== -1 ) {
            browserName = "Opera"
        } else if(navigator.userAgent.indexOf("Chrome") !== -1 ) {
            browserName = "Chrome"
        } else if(navigator.userAgent.indexOf("Safari") !== -1) {
            browserName = "Safari"
        } else if(navigator.userAgent.indexOf("Firefox") !== -1 ){
            browserName = "Firefox"
        } else if((navigator.userAgent.indexOf("MSIE") !== -1 ) || (!!document.documentMode === true )) {
            browserName = "IE"
        } else {
            browserName = "Unknown"
        }

        return browserName
    }

    useEffect(() => {
            dispatch( fetchPromoProd('{"prod_type":"6"}', "/product/getproductdashboard") )
            dispatch( fetchNewProd('{"prod_type":"1"}', "/product/getproductdashboard") )
            dispatch( fetchMostBuyProd('{"prod_type":"2"}', "/product/getproductdashboard") )
            if(token !== null){
                var browserName = checkBrowser();
                if(browserName === "Safari"){
                    
                }else if(browserName === "Unknown"){
                    
                }else{
                    notificationPermission();
                }
                dispatch( fetchRecomProd('{"prod_type":"5"}', "/product/getproductdashboard") )
            }
            dispatch( fetchMostSearchProd('{"prod_type":"7"}', "/product/getproductdashboard") )
    }, [dispatch])

    return (
        <div className="App container body-content mobile-view"> 
            {show ? <NotificationPermission NotificationSnackbar={NotificationSnackbar}/> : null}
            <Navbar />
            <Banner />
            {/* <EzeeTunai /> */}
            <div style={{ marginTop: 30 }}>
                <Category />
            </div>
            <div style={{ marginTop: 60 }}>
                {
                    (isLoadingPromoProdsHome) ? (
                        <div>
                            <Row style={{ marginTop: 0 }}>
                                <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                                    <span>Promo</span>
                                    <Link to="#" className="link_product" style={{ float: "right" }}>Lihat Semua</Link>
                                </Col>
                                <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10, fontFamily: 'Montserrat', color: '#848484' }}>
                                    Promo spesial hanya untuk kamu
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: 15, justifyContent: 'center', backgroundImage: `url(${backgroundPromo})`, backgroundRepeat: 'repeat-x' }}>
                                    <Col span={24} bordered="true" style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6 ,flexWrap: 'nowrap', overflowX: 'auto' }}>
                                        <div className="card-body" style={{ display: 'flex', marginBottom: 8 }}>
                                            {
                                                loops.map((loop, i) => {
                                                    return(
                                                        <CardProdLoading key={i} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                            <div className="break-page"></div>
                        </div>
                    ) : (promoProds.length !== 0) &&
                    <PromoSide promoProds={promoProds} />
                }
                {
                    (isLoadingNewProdHome) ? (
                        <div>
                            <Row style={{ marginTop: 0 }}>
                                <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                                    <span>Produk Terbaru</span>
                                    <Link to="#" className="link_product" style={{ float: "right" }}>Lihat Semua</Link>
                                </Col>
                                <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10}}>
                                    Produk berikut sudah tersedia di ezeePasar
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: 15, justifyContent: 'center', backgroundImage: `url(${backgroundPromo})`, backgroundRepeat: 'repeat-x' }}>
                                    <Col span={24} bordered="true" style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6 ,flexWrap: 'nowrap', overflowX: 'auto' }}>
                                        <div className="card-body" style={{ display: 'flex', marginBottom: 8 }}>
                                            {
                                                loops.map((loop, i) => {
                                                    return(
                                                        <CardProdLoading key={i} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                            <div className="break-page"></div>
                        </div>
                    ) : (newProds.length !== 0) &&
                    <NewProduct newProds={newProds}/>
                }
                {
                    (isLoadingMostBuyProdHome) ? (
                        <div>
                            <Row>
                                <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                                    <span>Produk Terlaris</span>
                                    <Link to="#" className="link_product" style={{ float: "right" }}>Lihat Semua</Link>
                                </Col>
                                <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10, fontFamily: 'Montserrat', color: '#848484'  }}>
                                    Produk-produk terlaris di ezeePasar
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: -8, justifyContent: 'center' }}>
                                    <Col span={24} bordered="true" style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6, flexWrap: 'nowrap', overflowX: 'auto' }}>
                                        <div className="card-body" style={{ display: 'flex', marginBottom: 8}}>
                                            {
                                                loops.map((loop, i) => {
                                                    return(
                                                        <CardProdLoading key={i} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                            <div className="break-page"></div>
                        </div>
                    ) : (mostBuyProds.length !== 0) &&
                    <MostBuyProduct mostBuyProds={mostBuyProds}/>
                }
                    {
                        token !== null ? [
                            (isLoadingRecomendProdHome) ? (
                                <div>
                                    <Row style={{ marginTop: -15 }}>
                                        <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                                            <span>Produk Rekomendasi</span>
                                            <Link to="#" className="link_product" style={{ float: "right" }}>Lihat Semua</Link>
                                        </Col>
                                        <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10, fontFamily: 'Montserrat', color: '#848484' }}>
                                            Semua produk pilihan spesial untuk kamu
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={24} style={{ marginTop: -8, justifyContent: 'center' }}>
                                            <Col span={24} bordered="true" style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6 ,flexWrap: 'nowrap', overflowX: 'auto' }}>
                                                <div className="card-body" style={{ display: 'flex', marginBottom: 8}}>
                                                    {
                                                        loops.map((loop, i) => {
                                                            return(
                                                                <CardProdLoading key={i} />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </Col>
                                        </Col>
                                    </Row>
                                    <div className="break-page"></div>
                                </div>
                            ) : (recomProds.length !== 0) &&
                            <RecommendProduct recomProds={recomProds}/>
                        ] : null
                    }
                {
                    (isLoadingMostSearchProdHome) ? (
                        <div>
                            <Row style={{ marginTop: -15 }}>
                                <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                                    <span>Pencarian Populer</span>
                                    <Link to="#" className="link_product" style={{ float: "right" }}>Lihat Semua</Link>
                                </Col>
                                <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10, fontFamily: 'Montserrat', color: '#848484'  }}>
                                    Populer berdasarkan pencarian di ezeePasar
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ marginTop: -8, justifyContent: 'center' }}>
                                    <Col span={24} bordered="true" style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6, flexWrap: 'nowrap', overflowX: 'auto' }}>
                                        <div className="card-body" style={{ display: 'flex', marginBottom: 8}}>
                                            {
                                                loops.map((loop, i) => {
                                                    return(
                                                        <CardProdLoading key={i} />
                                                    )
                                                })
                                            }
                                        </div>
                                    </Col>
                                </Col>
                            </Row>
                            <div className="break-page"></div>
                        </div>
                    ) : (mostSearchProds.length !== 0) &&
                    <MostSearch mostSearchProds={mostSearchProds}/>
                }
                <About />
            </div>
            <FooterSide />           
        </div>
    )
}

export default Home
