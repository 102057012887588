import {FETCH_DATA_GETCARTLIST_CART} from '../ActionType/actionTypes'

const initialState = {
    getCartLists: {}
}

const getCartListsReducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_GETCARTLIST_CART) {
        let newCartLists = action.payload.getCartLists
        return { ...state, getCartLists: newCartLists}
    } else {
        return state
    }
}

export default getCartListsReducer