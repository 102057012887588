import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import FooterWA from '../components/HomePage/FooterSide'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataAbout } from '../redux/ActionCreators/aboutActions'
import ReactHtmlParser from 'react-html-parser'

function TentangKami({isWebview}) {

    const about = useSelector(state => state.aboutReducer.about)
    const isLoading = useSelector(state => state.isLoadingReducer.isLoading)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch( fetchDataAbout("/dashboard/aboutezeepasar") )
    }, [dispatch])

    return (
        <div className="App container body-content mobile-view" style={{ textAlign: 'center', height: 704 }}>
            {isWebview ? null : <NavbarWithMenuIcon link="/back"/>}
            <FooterWA />
            <div style={{ marginTop: 20 }}>
                <span className="head-2" style={{ fontSize: 18.5, fontWeight: 700 }}>Tentang ezeePasar</span>
            </div>
            <div style={{ marginTop: 20, marginBottom: 30 }}>
                <img style={{ width: '100%' }} src={about.image_home} alt="banner" />
            </div>
            <Row style={{ marginRight: 20, marginLeft: 20 }}>
                <Col span={24} style={{ fontSize: 12, fontFamily: 'Open Sans', color: '#848484' }}>
                    {ReactHtmlParser(about.desc_detail)}
                </Col>
            </Row>
        </div>
    )
}

export default TentangKami
