import React, { useEffect, useState } from 'react'
import { Col, Layout, Row } from 'antd'
import { NavBar, Space } from 'antd-mobile'
import BackIcon from '../../Asset/Icon/back_header.svg'
import infoCircleYellow from '../../Asset/Icon/Info_circle_yellow.svg'
import warningRed from '../../Asset/Icon/carbon_warning-filled_red.png'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { BaseURL, headersWithToken } from '../../function/helpers'
import base64Sign from '../../function/base64Sign'
import { getToken } from '../../utils/setSessionUser'
import encryptData from '../../function/encryptData'
import LoadingModal from '../../components/PopUpModal/Loading/LoadingModal'

const {Footer} = Layout

function EzeeTunaiBinding() {

    const access_token = getToken()
    const [dataBinding, setDataBinding] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    function Backmin1(){
        // const history = useHistory();
        function backPage(){
            // history.goBack()
            window.history.go(-1)
            // console.log(window.location.href);
            // window.location.reload()
        }
        return(
            <div style={{}}>
                <Space>
                    <><img onClick={backPage} src={BackIcon} style={{marginBottom: 3}} alt=""/></>
                </Space>
            </div>
        )
    }

    async function bindingHandler(mobile) {
        try {
            setIsLoading(true)
            const signature = base64Sign("/EzeeTunaiSnap/SnapBindingAccount"); 
            const params = encryptData(`{"mcust_mobile": "${mobile}"}`)
            const headers = headersWithToken(signature, access_token);
            const binding = await axios.post(BaseURL + "/EzeeTunaiSnap/SnapBindingAccount", {data: params}, {headers: headers})
            if (binding.status === 200 && binding.data.response_code === 200) {
                setIsLoading(false)
                window.location.replace(binding.data.response_data.results.pinWebViewUrl)
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function getPhoneList() {
        try {
            setIsLoading(true)
            const signature = base64Sign("/EzeeTunaiSnap/GetAccountPhoneList"); 
            const headers = headersWithToken(signature, access_token);
            const phoneList = await axios.post(BaseURL + "/EzeeTunaiSnap/GetAccountPhoneList", {data: ""}, {headers: headers})
            if (phoneList.status === 200 && phoneList.data.response_code === 200) {
                setIsLoading(false)
                setDataBinding(phoneList.data.response_data.results)
            }
        } catch (error) {
            console.log(error);
        }
    }
    
    useEffect(() => {
        getPhoneList()
    }, [])

    return (
        <div className="container body-content mobile-view">
            <div style={{maxWidth:480}}>
                <NavBar left={Backmin1()}>
                    <div style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700, marginRight: 5 }}>
                        Masukan Nomor Handphone
                    </div>
                </NavBar>
            </div>
            <div style={{ maxWidth: 480, padding: '70px 30px 16px' }}>
                <div className='d-flex justify-content-center' style={{ fontFamily: 'Open Sans', fontSize: 12, padding: '15px 25px', borderRadius: 8, background: 'linear-gradient(0deg, rgba(255, 255, 255, 0.90) 0%, rgba(255, 255, 255, 0.90) 100%), #FFD600' }}>
                    <img src={infoCircleYellow} style={{ marginBottom: 3, marginRight: 8 }} alt=""/>
                    Pastikan Nomor Handphone yang digunakan terdaftar pada ezeeTunai
                </div>
                <div style={{ marginTop: 25 }}>
                    <div style={{ fontFamily: 'Nunito', fontSize: 12 }}>Nomor Handphone Penerima</div>
                    <div className='d-flex align-item-center' style={{
                            fontFamily: 'Montserrat',
                            fontSize:14,
                            fontWeight: 700,
                            marginTop: 15,
                        }}
                    >
                        <div className='d-flex justify-content-center align-items-center' style={{
                                borderRadius: 8,
                                borderTopRightRadius: 0,
                                borderBottomRightRadius: 0,
                                border: dataBinding.length !== 0 ? '1px solid #C4C4C4' : '1px solid #B9121B',
                                background: '#FFF',
                                height: 55,
                                padding: '14px 12px 14px 0px'
                            }}
                        >
                            <img className='ms-3 me-2' src='https://api.ezeelink.co.id/resources/ezeepasar/payment-type/ezeetunai-gold.png' alt="" width={24} height={24}/>
                            <div>
                                ezeeTunai
                            </div>
                        </div>
                        <div style={{
                                borderRadius: 8,
                                borderTopLeftRadius: 0,
                                borderBottomLeftRadius: 0,
                                border: dataBinding.length !== 0 ? '1px solid #C4C4C4' : '1px solid #B9121B',
                                background: '#FFF',
                                height: 55,
                                padding: '14px 12px 14px 0px'
                            }}
                        >
                            <input name='noHp' type='text' placeholder='Masukkan Nomor Handphone' disabled value={dataBinding.length !== 0 ? `+${dataBinding[0]?.mcust_mobile}` : ''} style={{ fontFamily: 'Exo', fontSize: 16, fontWeight: 700, width: 260, border: 0, paddingLeft: 10, color: '#C4C4C4' }} />
                        </div>
                    </div>
                    {
                        dataBinding.length === 0 &&
                        <div style={{ color: '#B9121B', fontFamily: 'Nunito', fontSize: 12, marginTop: 5 }}><img src={warningRed} width={13} height={13} /> Tidak ada nomor yang terdaftar pada ezeeTunai</div>
                    }
                </div>
            </div>
            <Layout style={{marginTop: 75}}>
                <Footer className="footerLayout" style={{fontFamily: 'Exo', height: 70}}>
                    <Row>
                        <Col style={{paddingLeft: 8, paddingTop: 10}}>
                            <button onClick={() => bindingHandler(dataBinding[0]?.mcust_mobile)} style={{
                                    display: 'flex',
                                    width: 435,
                                    height: 48,
                                    padding: '12px 24px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: 8,
                                    alignSelf: 'stretch',
                                    borderRadius: 6,
                                    border: dataBinding.length !== 0 ? '0.6px solid #383838)' : '1px solid #EBEBEB',
                                    background: dataBinding.length !== 0 ? 'var(--palet-gradient-gold, linear-gradient(180deg, #F1D3AC 0%, #E5AE66 100%))' : '#EBEBEB'
                                }}
                                disabled={dataBinding.length === 0}
                            >
                                <span className="spanCart">
                                    <div style={{color: dataBinding.length !== 0 ? "#000000" : "#EBEBEB", backgroundColor: 'transparent'}} className="spanCartLink">KONFIRMASI</div>
                                </span>
                            </button>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
            {
                isLoading &&
                <LoadingModal/>
            }
        </div>
    )
}

export default EzeeTunaiBinding