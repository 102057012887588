import { IS_LOADING_GETCATLAYER2LIST2_CAT } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingGetCatLayer2List2: true
}

const isLoadingGetCatLayer2List2Reducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_GETCATLAYER2LIST2_CAT) {
        return { ...state, isLoadingGetCatLayer2List2: action.payload.isLoadingGetCatLayer2List2 }
    } else {
        return state
    }
}

export default isLoadingGetCatLayer2List2Reducer