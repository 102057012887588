import React, {useEffect, useState}from 'react'
import { Link, useLocation, useHistory} from 'react-router-dom'
import {Row, Col, Layout, message} from 'antd';
import {checkoutUserSession, getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import discountIcon from '../../Asset/Icon/discount-icon.svg'
import FlatOngkirIcon from '../../Asset/Icon/flat_ongkir_icon.svg'
import warningIcon from '../../Asset/Icon/icon-warning-text.svg'
import $, { error } from 'jquery'
import encryptData from '../../function/encryptData';
import {BaseURL} from '../../function/helpers'
import ErrorPromoCode from '../PopUpModal/CheckoutPage/ErrorPromoCode';

const {Footer} = Layout
function FooterPromoCodeDetail({address_id, promo_code, expedisiId, promoCodeId ,paymentId, isUsed}) {

    let access_token = getToken();
    const [loading, setLoading] = useState(true);
    const [errorInfo, setErrorinfo] = useState('');
    const [visible, setVisible] = useState(false);
    const [isUsedPromoCode, setPromoCodeIsUsed] = useState(false);

    let history = useHistory();
    let promoCodeIdUsed = checkoutUserSession.getPromoId();

    useEffect(() => {
        if(isUsed){
            setPromoCodeIsUsed(isUsed);
        }
    },[])

    async function UsePromoAPI(promo_code) {
        try{
            const signature = base64Sign("/promo/usepromo"); 
            const dataParams = encryptData(`{"promo_code":"${promo_code}", "address_id": ${address_id}, "expedisi_id": ${expedisiId}, "payment_id": ${paymentId}}`)           
            const headers =
            {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
            }
            const data = await axios.post(BaseURL + '/promo/usepromo',{data: dataParams},{headers : headers});
            if(data.status === 200){
                var datas = data.data.response_data.results;
                checkoutUserSession.setPromoId(datas.mpromcod_id);
                checkoutUserSession.setPromoType(datas.mpromotype_name);
                checkoutUserSession.setPromoTypeId(datas.mpromcod_promo_type_id);
                checkoutUserSession.setPromoName(datas.mpromcod_code);
                checkoutUserSession.setPromoPrice(datas.total_promo_amount);
                checkoutUserSession.settotalPriceAfterPromo(datas.total_bayar);
                checkoutUserSession.settotalPriceBeforePromo(datas.total_transaksi);         
                history.push('/checkout', {isPromoCodeUsed : true, addrId : address_id});  
                setLoading(false)
            }else{
                if(data.response.status === 400){
                    setErrorinfo(data.response.data.response_message);
                    setVisible(true);   
                }
            }
        }catch(err){
            console.log(err)
        }
    }

    function PromoCodeUse(){
        UsePromoAPI(promo_code);
    }

    function unusePromoCode(id){
        checkoutUserSession.setPromoId(0);
        checkoutUserSession.setPromoTypeId(0);
        checkoutUserSession.setPromoType('');
        checkoutUserSession.setPromoName('');
        checkoutUserSession.setPromoPrice(0);
        checkoutUserSession.settotalPriceAfterPromo(0);
        checkoutUserSession.settotalPriceBeforePromo(0);
        history.push('/checkout')
    }

    return (
        <Layout style={{marginTop: 35}}>
            <Footer className="footerLayout" style={{fontFamily: 'Montserrat', height: 70, marginLeft: 2}}>
                <Row>                
                    <Col span={24} style={{padding: '15px 4px'}}>
                        {
                            isUsedPromoCode &&  promoCodeId === promoCodeIdUsed? 
                            <button className="used-promo" style={{height: '60px'}}>
                                <span className="spanCart" onClick={() => unusePromoCode()}>
                                    <button style={{color: "#fff", backgroundColor: 'transparent', border : 'unset'}} className="spanCartLink">Batal Gunakan</button>
                                </span>
                            </button> : 
                            <button className="btnBuy" style={{height: '60px'}}>
                                <span className="spanCart" onClick={() => PromoCodeUse()}>
                                    <button style={{color: "#fff", backgroundColor: 'transparent'}} className="spanCartLink">Gunakan</button>
                                </span>
                            </button>
                        }
                    </Col>
                </Row>
            </Footer>
        <ErrorPromoCode errorInfo={errorInfo} isOpen={visible} onClosed={() => setVisible(false)} addrId={address_id}/>
        </Layout>
    )
}

export default FooterPromoCodeDetail
