import React from 'react'
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'
import TopUpEzTunai from '../../components/EzeeTunai/TopUpEzeeTunai'

function TopUpEzeeTunai() {
    let fromPage = window.location.search;
    fromPage = fromPage.replace('?from=', '');

    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link={fromPage === 'checkout' ? '/checkout' : '/'}/>
            <TopUpEzTunai/>
        </div>
    )
}

export default TopUpEzeeTunai