import React from 'react'
import Header from '../components/MenuPage/HeaderWithoutClose'
import FormRegisterPage from '../components/RegisterPage/FormRegister'
function FormRegister() {

    return(
        <div className="App container body-content mobile-view">
            <Header/>
            <FormRegisterPage/>
        </div>
    )
}

export default FormRegister