import {FETCH_DATA_GETCATEGORYLIST2_CAT} from '../ActionType/actionTypes'

const initialState = {
    getCategoryList2: []
}

const getCategoryList2Reducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_GETCATEGORYLIST2_CAT) {
        return { ...state, getCategoryList2: action.payload.getCategoryList2}
    } else {
        return state
    }
}

export default getCategoryList2Reducer