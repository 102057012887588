import React, { useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { removeUserSession, getloginType, removeLoginType} from '../../utils/setSessionUser'
import { Modal } from 'antd'
import {GoogleLogout} from 'react-google-login';
import './TabMenu.css'
import logoutIcon from '../../Asset/Icon/logout_icon.svg'
import spinnerLoading1s from '../../Asset/Icon/spinner-1s-58px.svg'
import * as CONSTVAR from '../../utils/ConstVar'
import {deleteTokenFCM} from '../../firebase'
import {sentTokenToServer} from '../../function/helpers'

function TabMenu3() {
    let loginType = getloginType();
    const history = useHistory()
    const [isVisible, setIsVisible] = useState(false)

    function logoutHandling() {
        setIsVisible(true)
        setTimeout(() => {
            removeUserSession()
            removeLoginType()
            sentTokenToServer(false);
            setIsVisible(false)
            deleteTokenFCM()
            history.push("/")
            window.location.reload();
        }, 300);
    }

    const onLogout = (res) => {
        logoutHandling();
        // alert('[Logout Success]')
        removeLoginType();
    };

    return (
        <div>
            <div style={{ display: 'block', paddingTop: 7, height: '81vh', overflow: 'auto' }}>
                <ul className="nav" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                    <li>
                        <Link to="/Notifikasi" id="link_title">Notifikasi Browser</Link>
                    </li>
                    <li>
                        <Link to="/MetodePembayaran" id="link_title">Metode Pembayaran</Link>
                    </li>
                    <li>
                        <Link to="/KebijakanPrivasiIndex" id="link_title">Kebijakan Privasi</Link>
                    </li>
                    <li>
                        <Link style={{ borderBottom: "9px solid #F4F4F4" }} to="/Disclaimer" id="link_title">Disclaimer</Link>
                    </li>
                    <li>
                        {
                            loginType === '101' ? 
                            <GoogleLogout clientId={CONSTVAR.GOOGLE_CLIENT_ID} onLogoutSuccess={onLogout} buttonText="logout" render={renderProps => (                             
                                <div onClick={renderProps.onClick} style={{ padding: '8px 21px', borderBottom: "9px solid #F4F4F4" }} to="#" id="link_title">
                                    <img src={logoutIcon} alt="" width="32" height="32" />
                                    Keluar
                                </div>
                            )}/> : 
                            <Link onClick={logoutHandling} style={{ padding: '8px 21px', borderBottom: "9px solid #F4F4F4" }} to="#" id="link_title">
                                <img src={logoutIcon} alt="" width="32" height="32" />
                                Keluar
                            </Link>
                        }
                    </li>
                </ul>
            </div>
            <Modal
                visible={isVisible}
                footer={null}
                className="modalVefiedEmail"
                closable={false}
            >
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#393939" }}>
                    Sedang dalam proses
                </div>
                <div style={{ fontSize: 10 }}>
                    Kami sedang memproses.
                </div>
                <div style={{ fontSize: 10 }}>
                    Mohon ditunggu
                </div>
                <div>
                    <img src={spinnerLoading1s} alt="" width="58px" height="58px" />
                </div>
            </Modal>
        </div>
    )
}

export default TabMenu3
