import React from 'react'
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'
import ShopeePayPayment from '../../components/Payment/ShopeePayPayment'

function ShopeePay() {
    return (
        <div className="container body-content mobile-view center-page">            
            <ShopeePayPayment/>
        </div>
    )   
}

export default ShopeePay
