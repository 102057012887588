import { FETCH_DATA_BANNER_HOME } from '../ActionType/actionTypes'
import axios from 'axios';
import base64Sign from '../../function/base64Sign';
import { getToken } from '../../utils/setSessionUser';
import {BaseURL} from '../../function/helpers'
import { isLoadingBannerHome } from './Loading/isLoadingBannerActions';

export const fetchBanner = (url) => {
    return ( dispatch ) => {
        const signature = base64Sign(url);
        const access_token = getToken()
        const headers = 
        {
        'Content-Type':'application/json',
        'sign' : signature,
        'access_token' : access_token,
        }
        axios.post(BaseURL + url,
            {data: ''},
            {headers : headers},
        )
            .then( data => {
                dispatch({
                    type: FETCH_DATA_BANNER_HOME,
                    payload: {
                        banners: data.data.response_data.results
                    }
                })
                dispatch(isLoadingBannerHome(false))
            } )
            .catch(error => {
                console.log(error);
            })
    }
}