import React from 'react'
import {NavbarCloseOnlyWithLogo} from '../components/Properties/Navbar/Navbar'
import OnePageCheckout from '../components/Checkout/OnePageCheckout'
function Checkout() {
    return (
        <div className="container body-content mobile-view checkout">
            <NavbarCloseOnlyWithLogo link="/back"/>
            <OnePageCheckout />
        </div>
    )
}

export default Checkout
