import React, {useState, useEffect} from 'react';
import {Form, FormControl} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import { useFetchWithoutData} from '../../../customHooks/useFetch';

function SearchBar() {

    const [placeholderArr, loadingNotif] = useFetchWithoutData('/dashboard/placeholdersearch');
    const [placeholderTxt, setPlaceHolderTxt] = useState("");

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const timer = () => {
        setIndex(prevIndex => {
            if(prevIndex >= 4){
                return 0;
            }
            if(prevIndex === placeholderArr.length - 1){
            return 0;
            } 
            return prevIndex + 1;
        })
        };
        setInterval(timer, 5000);

        return () => { clearInterval(timer); }
    }, []);

    function callbackToPage(e){
    }

  return(
      <>
        <Link to="/Search">
            <Form>
                    <FormControl
                    id="placeholderID"
                    type="search"
                    placeholder={placeholderArr[index]}
                    className="search-bar"
                    aria-label="Search"
                    onKeyUp={(e) => callbackToPage(e)}                    
                />
            </Form>
        </Link>
      </>
  )
}

export default SearchBar;
