import React from 'react';
import {Modal} from 'antd'
import ezeeTunaiSuccessIcon from '../../../Asset/Icon/logo/infoaktivasieztunai.svg'

function EzeeTunaiSuccessActivation({isOpen, onClosed}) {
  return(
    <div>
        <div className="center-modal">
            <Modal className="modalAddCart ezeeTunaiActivation" id="modalProgress" visible={isOpen} onClosed={onClosed} footer={null} closable={true} style={{width: '330px !important', height: '356px !important'}} >
                <div style={{textAlign: 'center'}}>
                    <img src={ezeeTunaiSuccessIcon} alt="" style={{marginTop: 17}}/>
                    <button className='btnKeranjang' style={{fontSize: 12, marginTop: 10}} onClick={onClosed}>Oke</button>
                </div>
            </Modal>
        </div>
    </div>
  );
}

export default EzeeTunaiSuccessActivation;
