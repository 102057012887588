import { FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, IS_NEW_LOADING, FETCH_PRODUCTS_OTHER_SUCCESS } from "../ActionType/actionTypes";

const initialState = {
    products: [],
    loading: true,
    page : 1,
    next_page : null,
    max_page: 1
}

const productOtherReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST : 
        return{
            ...state,
            loading : true
        }
        case FETCH_PRODUCTS_OTHER_SUCCESS : 
        return{ 
            ...state,           
            loading : false,
            products: action.payload.results,
            page : action.payload.page,
            next_page : action.payload.next_page,
            max_page : action.payload.max_page
        }
        case IS_NEW_LOADING : 
        return{ 
            ...state,           
            loading : true
        }
        default : return state
    }
}

export default productOtherReducer