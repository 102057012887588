import { FETCH_DATA_GETCATLAYER2LIST2_CAT } from '../ActionType/actionTypes'

const initialState = {
    getCatLayer2List2: []
}

const getCatLayer2List2Reducer = (state=initialState, action) => {
    if (action.type === FETCH_DATA_GETCATLAYER2LIST2_CAT) {
        return { ...state, getCatLayer2List2: action.payload.getCatLayer2List2}
    } else {
        return state
    }
}

export default getCatLayer2List2Reducer
