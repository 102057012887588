import axios from "axios"
import base64Sign from "../../function/base64Sign"
import encryptData from "../../function/encryptData"
import { getToken } from "../../utils/setSessionUser"
import { FETCH_DATA_GETCATLAYER2LIST1_CAT } from "../ActionType/actionTypes"
import {BaseURL} from '../../function/helpers'
import { isLoadingGetCatLayer2List1 } from "./Loading/isLoadingGetCatLayer2List1Actions"

export const fetchDataListCatLayer2 = (params, url) => {
    return ( dispatch ) => {
        const param = encryptData(params)
        const signature = base64Sign(url)
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(BaseURL + url, {data: param}, {headers: headers})
            .then(layer2List1 => {
                dispatch({
                    type: FETCH_DATA_GETCATLAYER2LIST1_CAT,
                    payload: {
                        getCatLayer2List1: layer2List1.data.response_data.results
                    }
                })
                dispatch(isLoadingGetCatLayer2List1(false))
            })
            .catch(error => console.log(error))
    }
}