import React from 'react'
import { useLocation } from 'react-router-dom'
import Cat from '../components/ProductListCategory/ListCat';
import {NavbarWithArrowBack} from '../components/Properties/Navbar/Navbar'

function ProductListCategory() {

    return (
        <div className="container body-content mobile-view">
            <NavbarWithArrowBack link="/back"/>
            <Cat key={Math.random()} />
        </div>
    )
}

export default ProductListCategory
