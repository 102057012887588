import React, {useState, useEffect}from 'react'
import { useHistory, useLocation} from 'react-router-dom';
import './GopayPayment.css'
import $ from 'jquery'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'

function GopayPayment() {
    const [loading, setLoading] = useState(true);


    let history = useHistory();
    let location = useLocation();
    let tokenSnap = '';

    useEffect(() => {
        if(location.state !== undefined){
            setLoading(false);
            var arr = location.state.tokenSnap.split('/');                
            window.snap.pay(arr[arr.length - 1]);        
        }
    },[]);

    function paymentSnapAction(){
        var arr;
        if(location.state !== undefined){
            arr = location.state.tokenSnap.split('/');
            tokenSnap = arr[arr.length - 1];
        }

        window.snap.pay(tokenSnap, {
            onSuccess: function(){                
                setLoading(false);
            },
            onPending: function(){                
                setLoading(false);
            },
            onError: function(){                
                setLoading(false);
            },
            onClose: function(){
                setLoading(false);
            }
        })
    }

    if(loading){
        return(
            <LoadingModal/>
        )
    }
    
    $(window).on('popstate', function (e) {
        var state = e.originalEvent.state;
        if (state !== null) {
            history.push('/');
        }
    });

    return (
        <div className="main">
            <div style={{zIndex: '999', textAlign: 'center', marginTop: '40%'}}>
                <span className="text-1-snap">Pembayaran snap tertutup, silahkan klik tombol bayar untuk kembali membuka pembayaran snap.</span>
                <br/>
               <div>
                    <button id="paymentSnapBtn" className="ez-btn-green" style={{marginTop: 15, border: 'none'}} onClick={() => paymentSnapAction()}>Bayar!</button>
               </div>
            </div>
        </div>
    )
}

export default GopayPayment
