import React from 'react'
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'
import EzeetunaiSuccess from '../../components/Payment/PaymentSuccess'

function PaymentSuccess() {
    return (
        <div className="container body-content mobile-view">
            <NavbarCloseOnly link="/"/>
            <EzeetunaiSuccess/>
        </div>
    )
}

export default PaymentSuccess
