import React, {useState, useEffect}from 'react'
import { Link, useHistory } from 'react-router-dom'
import {Form, FormControl} from 'react-bootstrap'
import { NavBar, Space } from 'antd-mobile'
import './Navbar.css';
import { useFetchWithoutData } from '../../../customHooks/useFetch';
import BackIcon from '../../../Asset/Icon/back_header.svg'
import LogoEzPasar from '../../../Asset/Icon/logo/logo_ezeepasar.svg'
import SearchIcon from '../../../Asset/Icon/logo/feather_search.svg'
import NotifIcon from  '../../../Asset/Icon/logo/feather_bell.svg'
import CartIcon from  '../../../Asset/Icon/logo/feather_shopping-bag.svg'
import MenuIcons from  '../../../Asset/Icon/logo/feather_menu.svg'
import closeIcon from '../../../Asset/Icon/close.svg'
import SearchBar from './SearchBar'
import SearchPage from '../../SearchPage/SearchPage'
import { GetCartCount, GetNotifCount, headersWithToken, headersWithoutToken, BaseURL} from '../../../function/helpers';
import encryptData from '../../../function/encryptData'
import base64Sign from '../../../function/base64Sign';
import axios from 'axios';
import { getToken } from '../../../utils/setSessionUser';
import { debounce } from 'lodash';
import $ from 'jquery';

export const right = (carts) => {
    let token = getToken();
    let cart;
    let notif
    if(token !== null){
        const countCart = GetCartCount();
        const countNotif = GetNotifCount();
        cart = countCart.cart;
        notif = countNotif.count;
    }else{
        cart = 0;
        notif = 0;
    }

    return(
        <div style={{ fontSize: 18 }}>
        <Space>
            <Link to={token !== null ? "/notification" : "/login"}>
                <div style={{marginTop: 11.5, marginRight: -11}} className="notif-icon" >
                    <img src={NotifIcon} alt="" style={{width: 30}}/>            
                </div>
            </Link>
          {notif === undefined || notif === 0 ? null : <span className="badge-notif">{notif}</span>}
          <div style={{marginRight: -22}} className="cart-icon">
              <Link to={token !== null ? "/cart" : "/login"} className="cart-link"><img src={CartIcon} alt="" width="50" height="50" /></Link>
          </div>
          {cart === undefined || cart === 0 ? null : <span className="badge-notif">{cart}</span>}
          <div style={{marginRight: -15}} className="menu-icon">
              <Link to="/menu" className="menu-link">
                  <img src={MenuIcons} alt=""/>
              </Link>
          </div>
        </Space>
      </div>
    )
}

  const MenuIcon = (
    <div style={{ fontSize: 18 }}>
      <Space>
        <div style={{marginRight: -15}} className="menu-icon">
            <Link to="/menu" className="menu-link">
                <img src={MenuIcons} alt=""/>
            </Link>
        </div>
      </Space>
    </div>
  )

  function logo(){
      return(
        <div style={{}}>
            <Space>
                <img src={LogoEzPasar} alt=""/>
            </Space>
        </div>
      )
  }

  function back(link){
      return(
        <div>
            <Space>
                <Link to={link}><img src={BackIcon} style={{marginBottom: 3}} alt=""/></Link>
            </Space>
        </div>
      )
  }

  function Backmin1(){
    const history = useHistory();
    function backPage(){
        // history.goBack()
        window.history.go(-1)
        // console.log(window.location.href);
        // window.location.reload()
      }
      return(
        <div style={{}}>
            <Space>
                <><img onClick={backPage} src={BackIcon} style={{marginBottom: 3}} alt=""/></>
            </Space>
        </div>
      )
  }

  function BackMin3(){
    const history = useHistory();
    function backPage(){
        // console.log("clicked back min 3");
        window.history.go(-3)
        // history.goBack(-3)
      }
      return(
        <div style={{}}>
            <Space>
                <><img onClick={backPage} src={BackIcon} style={{marginBottom: 3}} alt=""/></>
            </Space>
        </div>
      )
  }

  function BackMin5(){
    const history = useHistory();
    function backPage(){
        // console.log("clicked back min 5");
        window.history.go(-5)
        // history.goBack(-3)
      }
      return(
        <div style={{}}>
            <Space>
                <><img onClick={backPage} src={BackIcon} style={{marginBottom: 3}} alt=""/></>
            </Space>
        </div>
      )
  }

  function closedNav(link){
      return(
        <div>
            <Space>
                <Link to={link}><img src={closeIcon} style={{marginBottom: 3}} alt=""/></Link>
            </Space>
        </div>
      )
  }
  
    function ClosedMin1(link){
        function backPage(){
            window.history.go(-1)
        }
        return(
            <div>
                <Space>
                    <><img onClick={backPage} src={closeIcon} style={{marginBottom: 3}} alt=""/></>
                </Space>
            </div>
        )
    }

  export function Navbar() {

    return (
            <div style={{maxWidth:480}}>
                    <NavBar right={right()} left={logo()}>
                        <div style={{marginRight: 5}}>
                            <SearchBar/>
                        </div>
                    </NavBar>
            </div>
        )
    }

    export function NavbarRefresh() {

        return (
                <div style={{maxWidth:480}}>
                        <NavBar right={right()} left={logo()}>
                            <div style={{marginRight: 5}}>
                                <SearchBar/>
                            </div>
                        </NavBar>
                </div>
            )
        }

    export function NavbarWithArrowBack({link}) {
        let history = useHistory();
        function goBack(){
            history.goBack();
        }
        return (
                <div style={{maxWidth:480}}>
                        <NavBar right={right()} left={link === '/back' ? Backmin1() : back(link)}>
                            <div style={{marginRight: 5}}>
                                <SearchBar/>
                            </div>
                        </NavBar>
                </div>
            )
        }

    export function NavbarWithoutLogo({link}) {
            return (
                    <div style={{maxWidth:480}}>
                            <NavBar right={right()} left={back(link)}>
                                <div style={{marginRight: 5}}>
                                    {/* <SearchBar/> */}
                                </div>
                            </NavBar>
                    </div>
                )
            }

    export function NavbarCloseOnly({link}){
        return (
            <div style={{maxWidth:480}}>
                    <NavBar left={link === '/back' ? Backmin1() : back(link)}>
                    </NavBar>
            </div>
        )
    }

    export function NavbarCloseOnlyForMenu({link}){
        return (
            <div style={{maxWidth:480, cursor: 'pointer'}}>
                    <NavBar right={link === '/back' ? ClosedMin1() : closedNav(link)}>
                    </NavBar>
            </div>
        )
    }

    export function NavbarCloseOnlyWithLogo({link}){
        return (
            <div style={{maxWidth:480}}>
                    <NavBar left={(link === "/back") ? Backmin1() : back(link)}>
                        <div>
                            <Link to="/"><img alt="" src={LogoEzPasar}/></Link>
                        </div>
                    </NavBar>
            </div>
        )
    } 

    export function NavbarLogoOnly({link}){
        return (
            <div style={{maxWidth:480}}>
                    <NavBar>
                        <div>
                            <Link to="/"><img alt="" src={LogoEzPasar}/></Link>
                        </div>
                    </NavBar>
            </div>
        )
    } 

    export function NavbarWithSearchAndMenu({link}){
        return (
            <div style={{maxWidth:480}}>
                    <NavBar left={back(link)} right={MenuIcon}>
                        <div style={{marginRight: 5}}>
                            <SearchBar/>
                        </div>
                    </NavBar>
            </div>
        )
    }

    export function NavbarWithMenuIcon({link}){
        return(
            <div style={{maxWidth:480, marginTop: -10}}>
                <NavBar right={MenuIcon} left={(link === "/back") ? Backmin1() : (link === "/backMin3") ? BackMin3() : (link === "/backMin5") ? BackMin5() : back(link)}>
                    
                </NavBar>
            </div>
        )
    }

    export function NavbarSearchPage({link}){
        const [placeholderArr, loadingNotif] = useFetchWithoutData('/dashboard/placeholdersearch');
        const [placeholderTxt, setPlaceHolderTxt] = useState("");
        const [productList, setProductList] = useState([]);    
        const [index, setIndex] = useState(0);

        let history = useHistory();
        const title = "Hasil&Pencarian"
        const id = 99;
        
        useEffect(() => {
            $('#placeholderID').focus();
            const timer = () => {
                setIndex(prevIndex => {
                    if(prevIndex >= 4){
                        return 0;
                    }
                    if(prevIndex === placeholderArr.length - 1){
                    return 0;
                    } 
                    return prevIndex + 1;
                })
                };
                setInterval(timer, 5000);
            if(placeholderTxt !== ''){
                searchProduct();
            }

            return () => { clearInterval(timer); }
        }, [placeholderTxt]);

        const onSearchItemWithDebounce = debounce((query) => {
            setPlaceHolderTxt(query.target.value);
          }, 50);

        function navigateSearchPage(e){
            e.preventDefault();
            var key = e.which || e.charCode || e.keyCode || 0;
            if(key === 13)
            {
                history.push(`/produk/ProductOther/${id}/${title}`, {productListSearch: productList, prodTitle : placeholderTxt});
            }
        }        

        async function searchProduct(){
            var access_token = getToken();
            if(access_token === null){
                access_token = "";
            }
            const signature = base64Sign("/dashboard/search");
            const dataParams = encryptData(`{"key": "${placeholderTxt}", "is_submit_search": 1}`);   
            const headers = headersWithToken(signature, access_token)
            axios.post(BaseURL + '/dashboard/search',{data:dataParams},{headers:headers}).then(data=>{
                setProductList(data.data.response_data.results);
            }).catch(err => console.log(err));
        }
          
        return (
            <div style={{maxWidth:480}}>
                    <NavBar left={back(link)} className='full-input-form'>
                    <>
                        <Link>
                            <Form onSubmit={(e) => e.preventDefault()} onKeyUp={(evt) => navigateSearchPage(evt)}>
                                <FormControl
                                    id="placeholderID"
                                    type="search"
                                    className="search-bar"
                                    placeholder={placeholderArr[index]}
                                    aria-label="Search"
                                    onChange={(e) => onSearchItemWithDebounce(e)}                                            
                                />
                                {/* <img src={SearchIcon} alt=""/> */}
                            </Form>
                        </Link>
                    </>
                    </NavBar> 
                    <SearchPage receiveData={productList}/>                   
            </div>
        )
    }
