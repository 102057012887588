import { FETCH_EZEETUNAI_BALANCE, IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_BANKLIST_EZEETUNAI, FETCH_EZEETUNAI_SUCCESS_ACTIVATION, FETCH_BANKLIST_DETAIL_EZEETUNAI} from "../ActionType/actionTypes";

const initialState = {
    getBalance: {},
    loading : false,
    bankList : [],
    data : []
}

const ezeeTunaiReducer = (state=initialState, action) => {
    switch(action.type) {
        case FETCH_USERS_REQUEST : 
        return{
            ...state,
            loading: true
        }
        case FETCH_EZEETUNAI_BALANCE : 
        return{
            ...state,
            loading: false,
            getBalance : action.payload 
        }
        case FETCH_BANKLIST_EZEETUNAI : 
        return{
            ...state,
            loading: false,
            bankList : action.payload 
        }
        case FETCH_BANKLIST_DETAIL_EZEETUNAI : 
        return{
            ...state,
            loading: true,
            data : action.payload
        }
        case FETCH_EZEETUNAI_SUCCESS_ACTIVATION : 
        return{
            ...state,
            loading: true,
            data : action.payload
        }
        case IS_NEW_LOADING : 
        return{ 
            ...state,           
            loading : true
        }
        default : return state
    }
}

export default ezeeTunaiReducer