import React, {useState, useEffect}from 'react'
import { checkoutUserSession, getToken} from '../../utils/setSessionUser';
import PinInput from 'react-pin-input';
import './EzeetunaiPayment.css'
import $ from 'jquery'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import encryptData from '../../function/encryptData';
import warningIcon from '../../Asset/Icon/icon-warning-text.svg'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import { useHistory } from 'react-router-dom'
import {BaseURL, headersWithToken} from '../../function/helpers';
import ReactPinField from "react-pin-field"
import {useLocation} from 'react-router-dom'

function EzeetunaiPayment() {

    const [pin, setPin] = useState('');
    const [loading, setLoading] = useState(false);
    const [errorInfo, setErrorinfo] = useState('');

    let history = useHistory();
    let location = useLocation();
    let paymentId = checkoutUserSession.getcheckoutDataPaymentId();
    let harga = checkoutUserSession.getCheckoutDataHarga();
    let address_id = checkoutUserSession.getCheckoutDataAddressId();
    let date_kirim = checkoutUserSession.getCheckoutDataDateKirim();
    let ekspedisi_id = checkoutUserSession.getCheckoutDataEkspedisiId();
    let promo_code = checkoutUserSession.getCheckoutDataPromoCode();
    let isCashback = checkoutUserSession.getCheckoutDataisCashback(); 
    let statusPayAPI = checkoutUserSession.getPaymentIsHitAlready();
    let timeFrameId = checkoutUserSession.getTimeFrame();
    var ref;
    let access_token = getToken();
    useEffect(() => {
        $('.pincode-input-text').attr('type', 'number');
    },[])

    async function paymentCheckAPI() {
        try{
            const signature = base64Sign("/checkout/paymentezeetunai"); 
            const dataParams = encryptData(`
            {"address_id":"${address_id}",
            "date_kirim": "${date_kirim}",
            "payment_id": "${paymentId}",
            "promo_code": "${promo_code}",
            "expedisi_id" : "${ekspedisi_id}",
            "pin" : "${pin}",
            "is_cashback" : "${isCashback}",
            "timeframe_id" : "${location.state.timeframe}"}`)          
            const headers = headersWithToken(signature, access_token)
            const data = await axios.post(BaseURL + '/checkout/paymentezeetunai',{data: dataParams},{headers : headers});
            setLoading(false);
            if(data.status === 200){
                var datas = data.data.response_data.results;
                history.push("/payment/finishpayment?order_id="+datas.tm_id+"&result=success",{paymentId: paymentId});
                checkoutUserSession.setPaymentIsHitAlready(true);
                checkoutUserSession.setOrderId(0);
            }else{
                if(data.response.status === 400){
                    let error = data.response.data.response_data;                       
                    if(error !== null){
                        if(error.error_id === '0169'){
                            history.push('/checkout', {cashbackIsEmpty : true, cashbackError: data.response.data.response_message})     
                        }else if(error.error_id === '0070'){
                            history.push('/checkout', {timeFrameNotAvail : true, timeframeError: data.response.data.response_message}) 
                        }else if(error.error_id === '0071'){
                            history.push('/checkout', {timeFrameNotExist : true, timeframeError: data.response.data.response_message}) 
                        }else{
                            setErrorinfo(data.response.data.response_message);
                        }
                    }else{
                        setErrorinfo(data.response.data.response_message);
                    } 
                    setLoading(false);
                }
            }
        }catch(err){
            setLoading(false);          
            setErrorinfo(err.response.data.response_message);
        }
    }
    
    function pinVal(value){
        if(value.length < 6){
            $('#btnpaymentET').attr('disabled', true);
        }else{
            $('#btnpaymentET').attr('disabled', false);
        }
        setPin(value);
    }

    function paymentCheck(){
        setLoading(true);
        if(!statusPayAPI){
            paymentCheckAPI();
        }
    }

    if(loading){
        return(
            <LoadingModal/>
        )
    }else{

    }
    // document.getElementById()
    // document.getElementsByClassName('pincode-input-text')
    // $('.pincode-input-text')[0].type = 'number';
    // $('.pincode-input-container').find('input:password').prop({type:"number"});
    return (
        <div style={{marginTop: 59}} className="main">
            <p className="head-2" style={{textAlign: 'center', margin: '10px 0 0 0'}}>Verifikasi Pembayaran</p>
            <div style={{textAlign: 'center', fontSize: 12, color: '#848484'}}>
                <br/>
                <div className="amountLayout">
                    <div style={{textAlign: 'center'}}>
                        <div>
                            <br/>
                            <p className="head-3-payment">Jumlah Pembayaran</p>
                            <p className="amount-title">Rp{location.state.price}</p>
                        </div>
                    </div>
                </div>
                <br/>
                <p className="text-3-payment">Masukkan Pin ezeeTunai kamu</p>
                <br/>
                {/* <PinInput
                    id="pinInput"
                    length={6} 
                    initialValue=""
                    secret 
                    onChange={(value, index) => {pinVal(value)}} 
                    type={"numeric"}
                    inputMode="password"
                    style={{padding: '10px'}}  
                    onComplete={(value, index) => {}}
                    autoSelect={true}
                    regexCriteria={/^[0-9]*$/}
                /> */}
                <div>
                    <ReactPinField
                    ref={ref}
                    type={'number'}
                    inputMode={'text'}
                    length={6}
                    initialValue=""
                    class='pincode-input-text'
                    onChange={(value, index) => {pinVal(value)}}
                    />
                </div>
                <br/>
                {errorInfo !== '' ? 
                    <><img src={warningIcon} alt="" style={{marginBottom: 3}}/><span className="error-info"> {errorInfo}</span></> 
                    : ''}
                <br/>
                {/* <br/>
                <p style={{fontSize: 10}} className="head-1-payment">Lupa Pin?</p> */}
                <br/>
                <div style={{marginLeft: 25, marginRight: 25, paddingTop: '45%'}}>
                    <button className="ez-btn-green-bottom" id="btnpaymentET" onClick={() => paymentCheck()} style={{backgroundColor: '#3DB54A', borderRadius: '10px'}}>
                        <span className="span-payment">
                            Verifikasi
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default EzeetunaiPayment
