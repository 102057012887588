import { IS_LOADING_ABOUT_HOME } from "../../ActionType/actionTypes";

export function isLoadingAboutHome(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_ABOUT_HOME, 
            payload: {
                isLoadingAboutHome: param
            }
        })
    }
}