import React, {useEffect}from 'react'
import './VirtualAccPayment.css'
import {Collapse, message} from 'antd';
import copy_icon from '../../Asset/Icon/feather_copy_icon.svg'
import FlipCountdown from '@rumess/react-flip-countdown' 
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import FooterVA from './FooterVA'
import {moneyFormat} from '../../function/helpers'
import { useHistory, useLocation} from 'react-router-dom';
import $ from 'jquery';
const { Panel } = Collapse;

function VirtualAccPayment() {

    let history  = useHistory();
    let location = useLocation();

    useEffect(() => {
    },[]);

    let vaDetail = location.state.vaDetail;
    let vaInstruction = location.state.vaInstruction;

    function AlertSnackbar(messages){
        message.error({
            content: messages,
            className: 'snackbar-success',
            duration: 3
        });
    };

    function copyVAnumber() {
        var copyText = vaDetail.company_code + vaDetail.va_number;
        navigator.clipboard.writeText(copyText);

        AlertSnackbar("Nomor Virtual Account berhasil tersalin");
      }

    var formate = moment.unix(vaDetail.countDownSec).format("yyyy-MM-DD HH:mm:ss")

    $(window).on('popstate', function (e) {
        var state = e.originalEvent.state;
        if (state !== null) {
            history.push('/');
        }
    });

    return (
        <div style={{top: 60}} className="main">
            <div>
                <p className="head-2" style={{textAlign: 'center', margin: '40px 0 0 0'}}>Akun Virtual BCA</p>
                <br/>
                <aside className="checkout-info">
                    <span>Total Belanja</span>
                    <span style={{float: 'right'}}>{moneyFormat(vaDetail.total_amount)}</span>
                </aside>
            </div>
            <br/>
            <div className="va-detail-info">
                <aside style={{textAlign: 'center', marginTop: 50}}>
                    <img src={vaDetail.paymentImg} alt="" style={{marginBottom: 10, width: 127, height: 40}}/>
                    <p style={{fontSize: 10, marginTop: 30}} className="subtitle-1-va">Kode Pembayaran ( Nomor Akun Virtual )</p>
                    <p className="va-number" id="vaNumber" onClick={() => copyVAnumber()}><b>{vaDetail.company_code} - {vaDetail.va_number}</b></p>
                    <img src={copy_icon} className="copy_icon" alt="" />
                    <p style={{fontSize: 10}}><b>Jumlah transfer : {moneyFormat(vaDetail.total_amount)}</b></p>
                    <div className="div-hr" style={{width: '100%', marginBottom: 10}}>
                        <hr style={{backgroundColor: 'black', height: 1, width: '17%'}}></hr>
                    </div>
                    <p style={{fontSize: 10}} className="subtitle-1-va">Mohon lakukan pembayaran dalam waktu</p>
                    <FlipCountdown
                        endAt={formate}
                        size={'medium'}
                        hideMonth={true}
                        hideDay={true}
                        hideYear={true}
                        hourTitle=''
                        minuteTitle=''
                        secondTitle=''
                    />
                </aside>
            </div>
            <br/>
            <div className="panduan">
            <Collapse accordion>
                <Panel header="Panduan Pembayaran" key="1">
                    <table style={{maxWidth: '380px !important', width: '100%'}}>
                        <tbody>
                            {
                                vaInstruction.map((item, index) => {
                                    return (
                                    <tr className="content-item-title">
                                        <td style={{verticalAlign: 'top'}}>{index + 1}.</td>
                                        <td style={{display: 'inline-block', overflowWrap: 'anywhere', paddingLeft: 5}}>{ReactHtmlParser(item)}</td>
                                    </tr>  
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </Panel>
            </Collapse>
            </div>
            <FooterVA order_id={vaDetail.order_id}/>
        </div>
    )
}

export default VirtualAccPayment
