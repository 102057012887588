import React, {useEffect, useState} from 'react'
import { Layout, Col, Row, Modal } from 'antd'
import { useHistory } from 'react-router-dom';
import './FooterProduk.css';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import { getToken } from '../../utils/setSessionUser';
import OtherProduct from '../HomePage/OtherProduct';
import {BaseURL, headersWithToken, clearCheckoutSession} from '../../function/helpers'
import iconFeatherPlusWhite from '../../Asset/Icon/feather_plus_white.svg'

const { Footer} = Layout

function FooterProduk({prod_id, totalItem, detail}) {

    const [visible, setVisible] = useState(false);
    let access_token = getToken();
    let prodDetail = detail;
    let history = useHistory();

    useEffect(() => {
    }, [])
    
    function addCart(){
        if(access_token === null){
            history.push("/login")
        }else{
            addCartAPI();
        }
    }


    function buyNow(){
        if(access_token === null){
            history.push("/login")
        }else{
            clearCheckoutSession();
            history.push('/checkout', {prodId : prod_id, items : totalItem, isFromBuyNow: true, url : window.location.pathname})
            // history.push('/payment/finishpayment?order_id=123452134&result=success')
        }
    }
    
    function toCart(){
        history.push('/cart')
    }

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }

    async function addCartAPI(){
        const signature = base64Sign("/cart/addcart");
        const dataParams = encryptData(`{"product_id": ${prod_id}, "quantity": ${totalItem}}`);
        const headers = headersWithToken(signature, access_token)
        await axios
        .post(BaseURL + '/cart/addcart',{data:dataParams},{headers:headers}).then(data=>{
            setVisible(true);
        }).catch(err => console.log(err));
    }

    const closedModal = () => {
        setVisible(false);
    }

    return (
        <div>
            <Layout style={{marginTop: 70}}>
                <Footer className="footerLayout" style={{marginLeft: -15}}>
                    <Row>                
                        <Col span={12} style={{paddingRight: 5}}>
                            <button className="btnBeliSekarang" onClick={() => buyNow()}>
                                <span className="spanBuyNow">Beli Sekarang</span>
                            </button>
                        </Col>
                        <Col span={12} style={{paddingLeft: 5}}>
                            <button className="btnKeranjang" onClick={() => addCart()}>
                                <img src={iconFeatherPlusWhite} alt="" width="24" style={{marginRight: 5}}/>
                                <span className="spanCart">Keranjang</span>
                            </button>
                        </Col>
                    </Row>
                </Footer>
            </Layout>
            {visible ? <Modal
                title="Produk ini berhasil ditambahkan
                ke dalam keranjang belanja"
                visible={visible}
                // onOk={closedModal}
                onCancel={closedModal}
                className="modalSuccess" 
                style={{borderTopLeftRadius: 15, width: '490px !important', borderTopRightRadius: 15}}
                footer={
                <div>
                    <OtherProduct prod_id={parseInt(prod_id)} cat_id={parseInt(prodDetail.category.mcat_id)}/>
                </div>
                }            
                >
                <table style={{width: '90%', margin: '0 auto'}}>
                    <tbody>
                        <tr>
                            <td className="prodImg" style={{width: 0}}>
                                <img src={prodDetail.product_image} alt="" style={{width: 48, height: 48}}/>
                            </td>
                            <td style={{verticalAlign: 'inherit', float: 'left', marginTop: 28}}>
                                <div style={{marginBottom: 20, marginLeft: 15}}>
                                    <div className="prodName">{prodDetail.product_name}</div>
                                    <div className="satberatName">{prodDetail.product_weight} {prodDetail.product_weight_name}</div>
                                </div>
                            </td>
                            <td style={{whiteSpace: 'nowrap', textAlign: 'right', marginTop: 20}}>
                                <div className="totalWeight">{totalItem} {prodDetail.product_unit_name}</div>
                                <div className="satberatName">
                                {
                                    prodDetail.product_promo_price !== null ? <div className='disc-product' style={{float: 'right'}}>
                                        <span className="real-price" style={{marginRight: 10}}>{ convertToRupiah(prodDetail.product_price) }</span><span>
                                    {convertToRupiah(prodDetail.product_promo_price)} / {prodDetail.product_unit_name}</span>
                                    </div> : <p>{convertToRupiah(prodDetail.product_price)} / {prodDetail.product_unit_name}</p>
                                }
                                </div>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
                <div className="div-btn">
                    <button className="btnLihatKeranjang" onClick={() => toCart()}>
                        Lihat Keranjang
                    </button>
                </div>
                <div className="break-page"></div>
                </Modal> : null}
        </div>
    )
}

export default FooterProduk
