import { IS_LOADING_PROFILE_DETAIL } from "../../ActionType/actionTypes";

export function isLoadingProfileDetail(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_PROFILE_DETAIL, 
            payload: {
                isLoadingProfileDetail: param
            }
        })
    }
}