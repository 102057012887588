import { IS_LOADING_GETCATLAYER2LIST1_CAT } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingGetCatLayer2List1: true
}

const isLoadingGetCatLayer2List1Reducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_GETCATLAYER2LIST1_CAT) {
        return { ...state, isLoadingGetCatLayer2List1: action.payload.isLoadingGetCatLayer2List1 }
    } else {
        return state
    }
}

export default isLoadingGetCatLayer2List1Reducer