import { FETCH_DATA_MOSTBUYPROD_HOME } from "../ActionType/actionTypes"

const initialState = {
    mostBuyProds: []
}

const mostBuyProdsReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_MOSTBUYPROD_HOME) {
        return { ...state, mostBuyProds: action.payload.mostBuyProds }
    } else {
        return state
    }
}

export default mostBuyProdsReducer