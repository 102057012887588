import { IS_LOADING_PRODUCTLIST2_CAT } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingProductList2: true
}

const isLoadingProductList2Reducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_PRODUCTLIST2_CAT) {
        return { ...state, isLoadingProductList2: action.payload.isLoadingProductList2 }
    } else {
        return state
    }
}

export default isLoadingProductList2Reducer