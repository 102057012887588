import { FETCH_DATA_HISTORYORDER_DETAIL, IS_LOADING, IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS, FETCH_PRODUCTS_OTHER_SUCCESS } from "../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../function/base64Sign";
import { getToken } from "../../utils/setSessionUser";
import encryptData from "../../function/encryptData";
import {BaseURL} from '../../function/helpers'

export const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const fetchProdsSuccess = dt => {
    return{
        type: FETCH_PRODUCTS_OTHER_SUCCESS,
        payload: dt
    }
}

const backToLoading = () => {
    return {
        type: IS_NEW_LOADING
    }
}

export const fetchProductsOther = (url, data) => {
    return (dispatch) => {
        dispatch(fetchUsersRequest())
        const signature = base64Sign(url)
        const dataParams = encryptData(data);
        const access_token = getToken()
        const headers = {
            'Content-Type' : 'application/json',
            'sign' : signature,
            'access_token' : access_token
        }

        axios.post(BaseURL + url, {data : dataParams}, {headers: headers})
        .then(productsOther => {
            dispatch(fetchProdsSuccess(productsOther.data.response_data))            
        }
        ).catch(error => {
            console.log(error);
        })
    }
}