import React, {useEffect}from 'react'
import {Row, Col} from  'antd'
import './TopUpEzeeTunai.css'
import {useHistory} from 'react-router-dom'
import {fetchEzeeTunaiBankList} from '../../redux/ActionCreators/EzeeTunai/ezeeTunaiGetBankListActions'
import { connect } from 'react-redux'
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import arrowIcon from '../../Asset/Icon/arrow.png'

function TopUpEzeeTunai({bankList, fetchEzeeTunaiBankList}) {

    useEffect(() => {
        fetchEzeeTunaiBankList('/ezeeTunai/topupbanklist')
    },[])

    let history = useHistory();

    if(bankList.loading){
        return(
            <LoadingModal/>
        )
    }

    let fromPage = window.location.search;
    fromPage = fromPage.replace('?from=', '');
    
    function ProcessTopUp(id){
        history.push(fromPage === 'checkout' ? "/EzeeTunai/ProcessTopUp?from=checkout" : "/EzeeTunai/ProcessTopUp", {bankId : id})
    }

    function navigateTopUpHeadOffice(){
        history.push("/EzeeTunai/ProcessTopUpHeadOffice");
    }

    return (
       <div>
            <div style={{paddingTop: 70, marginLeft: 25, marginRight: 25}}>
            <div style={{textAlign: 'center', paddingTop: '10%'}}>
                <span className='head-2'>Top Up Saldo ezeeTunai</span>
            </div>
            <div style={{marginBottom: '15%'}}>
                <span className='head-2 title' style={{float: 'left', marginTop: '12%'}}>Bank Transfer</span>
                <br/>
            </div>
            <div style={{marginBottom: -20}}>
                <Row>
                    <Col span={24}>
                        <table style={{width: '100%'}} id="list-payment">
                            <thead></thead>
                            {
                                bankList.bankList.map((item, i) => {
                                    return(
                                        <tbody key={i}>
                                            <tr key={i} style={{cursor: 'pointer'}} onClick={() => ProcessTopUp(item.bank_id)} id={item.bank_id}>
                                                <td style={{width: 15}} key={i}>
                                                    <img src={item.bank_logo} alt="" style={{width: 55, marginBottom: 3}}/>
                                                </td>
                                                <td>{item.bank_name}</td>
                                                <td>
                                                    <img src={arrowIcon} alt="" style={{height: 32, float: 'right'}}/>
                                                </td>
                                            </tr>
                                        </tbody>
                                    )                                  
                                })
                            }
                        </table>
                    </Col>
                </Row>
            </div>
            {/* <div style={{marginBottom: '15%'}}>
                <br/>
            </div>
            <div>
                <Row>
                    <Col span={24}>
                        <table style={{width: '100%'}} id="list-payment">
                            <thead></thead>
                            <tbody>
                                <tr style={{cursor: 'pointer'}} onClick={() => navigateTopUpHeadOffice()}>
                                    <td style={{width: 15}}>
                                        <img src="https://apid.ezeelink.co.id/resources/paymenttypeicon/ezeelink.png" alt="" style={{width: 55, marginBottom: 3}}/>
                                    </td>
                                    <td>Ezeelink Head Office</td>
                                    <td>
                                        <img src={arrowIcon} alt="" style={{height: 32, float: 'right'}}/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                </Row>
            </div>             */}
        </div>
       </div>
    )
}

const mapStateToProps = (state) => {
    return{
        bankList : state.ezeeTunaiGetBankListReducer
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        fetchEzeeTunaiBankList : (url) => dispatch(fetchEzeeTunaiBankList(url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopUpEzeeTunai)
