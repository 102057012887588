import { FETCH_DATA_CATEGORY_HOME } from "../ActionType/actionTypes"

const initialState = {
    categories: []
}

const categoriesReducer = ( state=initialState, action ) => {
    if (action.type === FETCH_DATA_CATEGORY_HOME) {
        return { ...state, categories: action.payload.categories }
    } else {
        return state
    }
}

export default categoriesReducer