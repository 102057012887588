import React, {useState, useEffect}from 'react'
import {Modal, InputNumber, Row, Col} from 'antd'
import './AddCartModal.css'
import './SuccessAddCartModal.css'
import { useFetchWithoutToken } from '../../../customHooks/useFetch';
import plusIconEnable from '../../../Asset/Icon/plus_icon_enable.svg';
import plusIconDisable from '../../../Asset/Icon/plus_icon_disabled.svg';
import minusIconEnable from '../../../Asset/Icon/minus_icon_enable.svg';
import minusIconDisable from '../../../Asset/Icon/minus_icon_disabled.svg';
import OtherProduct from '../../HomePage/OtherProduct';
import { getToken } from '../../../utils/setSessionUser';
import encryptData from '../../../function/encryptData'
import base64Sign from '../../../function/base64Sign';
import axios from 'axios'
import { useHistory, Link} from 'react-router-dom';
import warningIcon from '../../../Asset/Icon/warning-cart.png';
import { ModalAddProductLoading, ModalAddProductFooterLoading} from '../../skeletonLoading/loadingTag'
import $ from 'jquery'
import {nFormatter, BaseURL, headersWithoutToken, headersWithToken} from '../../../function/helpers'
import iconFeatherPlusWhite from '../../../Asset/Icon/feather_plus_white.svg'
import iconFeatherMinusCircleDisable from '../../../Asset/Icon/feather_minus-circle-disable.svg'
import iconFeatherMinusCircleEnable from '../../../Asset/Icon/feather_minus-circle-enable.svg'
import iconFeatherPlusCircleEnable from '../../../Asset/Icon/feather_plus-circle-enable.svg'
import iconFeatherPlusCircleDisable from '../../../Asset/Icon/feather_plus-circle-disable.svg'

function AddCartModal({showModal, closedModal, productId}) {
    const [ counter, setCounter ] = useState(1);    
    const [prodDetail, setProdDetail] = useState({});
    const [loading, setLoading] = useState(true);
    const [ visibleMain, setVisibleMain ] = useState(false);
    const [ visible, setVisible ] = useState(false);
    const [ totalProduct, setTotalProduct] = useState(0);
    const [ totalProductPrice, setTotalProductPrice ] = useState(0);
    var access_token = getToken();
    let history = useHistory();
    

    useEffect(() => {
        if(access_token !== null){
            getProdDetail();
        }else{
            history.push("/login");
        }
    } , []);

    function convertToRupiah(money) {
        return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
    }

    async function getProdDetail() {        
        try{
            const signature = base64Sign("/product/getproductdetail");
            const dataParams = encryptData(`{"product_id":${productId}}`);
            const headers = headersWithoutToken(signature);
            const dataProds = await axios.post(BaseURL + '/product/getproductdetail',{data: dataParams},{headers : headers});
            setProdDetail(dataProds.data.response_data.results);
            setLoading(false);
        }catch(error){
            console.log(error);
        }
    }

    function addCart(){
        const signature = base64Sign("/cart/addcart");
        const dataParams = encryptData(`{"product_id": ${productId}, "quantity": ${counter}}`);
        const headers = headersWithToken(signature, access_token)
        axios
        .post(BaseURL + '/cart/addcart',{data:dataParams},{headers:headers}).then(data=>{
            
        })
        .catch(err => console.log(err));
    }


    const openModalAdd = () => {
        setVisibleMain(true)
    }

    const openModalSuccess = () => {
        const ProductPrice = document.getElementById("harga-total").value;
        const ProductAmount = document.getElementById("inpCartField").value;
        addCart();
        setTotalProduct(ProductAmount);
        setTotalProductPrice(ProductPrice)
        setVisible(true);
        
    }

    function goToCart() {
        history.push("/cart");
    }

    let plusCart = () => { 
        setCounter(parseInt(counter) + 1); 
    }
    let minusCart = () => { 
        setCounter(parseInt(counter) - 1);
     }
    if (counter <= 1) { minusCart = () => { setCounter(1) } }

    function countPrice(){
        let qty = $('#inpCartField').val();
        if(qty < 1){qty = 1}
        if(prodDetail.max_buy !== null){
            if(qty > prodDetail.max_buy){
                qty = prodDetail.max_buy;
            }
        }

        if(prodDetail.remaining_stock !== null){
            if(qty > prodDetail.remaining_stock){
                qty = prodDetail.remaining_stock;
            }
        }

        setCounter(qty);
    }

    function navigateToCart(){
        let url = window.location.pathname;
        if(url === '/cart'){
            setVisible(false);
            window.location.reload()
        }else{
            history.push("/cart");
        }
    }
    
    if(loading) {
        return(
            <div className="container">
            <div className="div-modal-addCard">
                <Modal
                title="Atur Produk"
                visible={showModal}
                // onOk={closedModal}
                onCancel={closedModal}
                className="modalAddCart" 
                style={{borderRadius: 15, width: "490px !important"}}
                footer={
                    <div>
                        <Row>
                            <Col span={24}>
                                <ModalAddProductFooterLoading/>
                            </Col>
                        </Row>

                    </div>
                }           
                >
                <ModalAddProductLoading/>
                </Modal>
            </div>
        </div> 
        )
    }
    
    var IconDis = $("#imgPlus");
    if(counter >= prodDetail.max_buy){
        // $("#alertMaxQty").css('visibility','visible');
        // IconDis.attr('src',plusIconDisable);
        // IconDis.prop("disabled",true);
    }

    if(counter < prodDetail.max_buy){
        $("#alertMaxQty").css('visibility','hidden');
        IconDis.attr('src',plusIconEnable);
        IconDis.prop("disabled",true);
    }

    return (
        <div className="container">
            <div className="div-modal-addCard">
                <Modal
                title="Atur Produk"
                visible={visible ? openModalAdd : showModal}
                // onOk={closedModal}
                onCancel={closedModal}
                className="modalAddCart" 
                style={{borderRadius: 15, width: "490px !important"}} 
                footer={
                <div>
                    <Row>
                        <Col span={12}>
                            <div>
                                <p className="p-harga">Harga</p><br/>
                                <p id="harga-total">{prodDetail.product_promo_price !== null ? convertToRupiah(counter * prodDetail.product_promo_price) : convertToRupiah(counter * prodDetail.product_price)}</p>
                            </div>
                        </Col>
                        <Col span={12} style={{paddingTop: 15}}>
                            <button className="btnKeranjang" onClick={() => openModalSuccess(prodDetail.product_id)}>
                                <img src={iconFeatherPlusWhite} alt="" width="24" style={{marginRight: 5}}/>
                                <span className="spanCart">Keranjang</span>
                            </button>
                        </Col>
                    </Row>
                </div>
                }            
                >
                <table style={{width: '90%', margin: '0 auto', }}>
                    <tbody>
                        <tr>
                            <td className="prodImg" style={{width: 100}}>
                                <img src={prodDetail.product_image} alt="" style={{width: 80, height: 80}}/>
                            </td>
                            <td style={{verticalAlign: 'inherit', float: 'left', marginTop: 12}}>
                                <div className="prodName">{prodDetail.product_name}</div>
                                <div className="satberatName">{prodDetail.product_weight} {prodDetail.product_weight_name}</div>
                                {
                                    prodDetail.product_promo_percent == null
                                    ? <></>                                    
                                    : <><div className="disc-product">
                                        <span className="percent-disc">{ prodDetail.product_promo_percent }%</span>
                                        <span className="real-price">{ convertToRupiah(prodDetail.product_price) }</span>
                                    </div>                                    
                                    </>
                                }
                                <div className="prodhargaJualenable">{prodDetail.product_promo_price !== null ? convertToRupiah(prodDetail.product_promo_price) : convertToRupiah(prodDetail.product_price)} / {prodDetail.product_unit_name}</div>
                                <Row>
                                    <Col span={24}>
                                    {
                                        prodDetail.product_terjual !== null && prodDetail.product_terjual !== 0 ?                                         
                                            <span className='sp-danger' style={{fontSize: 10}}>Terjual: <span style={{color: 'black'}}>{nFormatter(prodDetail.product_terjual)}</span></span>
                                        : null
                                    }
                                    {
                                        prodDetail.remaining_stock !== null ?                                         
                                            <span className='sp-danger product-sold-and-stock' style={{fontSize: 10}}> | Stok Tersisa <span style={{color: 'black'}}>{prodDetail.remaining_stock}</span></span>
                                        : null
                                    }
                                    </Col>
                                </Row>                                
                            </td>
                        </tr>
                        <tr>
                            <td></td>
                            <td style={{whiteSpace: 'nowrap', textAlign: 'right'}}>
                                {counter < 2 ? <img src={iconFeatherMinusCircleDisable} alt="" style={{ marginBottom: 3, marginRight: 1 }} id="imgMinus" onClick={minusCart} /> : <img src={iconFeatherMinusCircleEnable} alt="" style={{ marginBottom: 3, marginRight: 1 }} id="imgMinus" onClick={minusCart} className="imgMinusProduct"/>}
                                <InputNumber type="number" className="inpQuantity" min={1} id="inpCartField" value={counter} onKeyUp={() => countPrice()}/>
                                {
                                        prodDetail.remaining_stock !== null ? [
                                            (counter < prodDetail.remaining_stock ? [
                                                (prodDetail.max_buy !== null) ? [
                                                    (counter <= prodDetail.max_buy ?  
                                                        <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} className="imgPlusProduct"/> : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" /> )
                                                ] : <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} className="imgplusProduct" />
                                            ] : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" />)
                                        ] : (prodDetail.max_buy !== null) ? [
                                                (counter < prodDetail.max_buy ?  
                                                    <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} className="imgPlusProduct"/> : <img src={iconFeatherPlusCircleDisable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" /> )
                                        ] : <img src={iconFeatherPlusCircleEnable} alt="" style={{ marginBottom: 3, marginLeft: 5 }} id="imgPlus" onClick={plusCart} className="imgPlusProduct" />
                                }
                            </td>
                        </tr>
                        {
                            prodDetail.remaining_stock !== null ? [
                                (counter < prodDetail.remaining_stock ? [
                                    (prodDetail.max_buy !== null) ? [
                                        (counter <= prodDetail.max_buy ?  
                                            null : 
                                            <tr id={"alertMaxQty"}  >
                                                <td colSpan={3} style={{padding: '10px 0'}} className="sp-danger"><img src={warningIcon} alt=""/>  Produk telah mencapai batas pembelian.</td>
                                            </tr>)
                                    ] : null
                                ] :
                                <tr id={"alertMaxQty"}  >
                                    <td colSpan={3} style={{padding: '10px 0'}} className="sp-danger"><img src={warningIcon} alt=""/>  Produk telah mencapai batas pembelian.</td>
                                </tr>)
                            ] : (prodDetail.max_buy !== null) ? [
                                    (counter === prodDetail.max_buy ?  
                                        <tr id={"alertMaxQty"}  >
                                            <td colSpan={3} style={{padding: '10px 0'}} className="sp-danger"><img src={warningIcon} alt=""/>  Produk telah mencapai batas pembelian.</td>
                                        </tr> : null
                                         )
                                ] : null
                        }
                    </tbody>
                </table>
                </Modal>
            </div>
            {visible ? <Modal
                title="Produk ini berhasil ditambahkan
                ke dalam keranjang belanja"
                visible={visible}
                // onOk={closedModal}
                onCancel={closedModal}
                className="modalSuccess" 
                style={{borderTopLeftRadius: 15, width: '490px !important', borderTopRightRadius: 15}}
                footer={
                <div>
                    <OtherProduct prod_id={parseInt(productId)} cat_id={parseInt(prodDetail.category.mcat_id)}/>
                </div>
                }            
                >
                <table style={{width: '90%', margin: '0 auto'}}>
                    <tbody>
                        <tr>
                            <td className="prodImg" style={{width: 0}}>
                                <img src={prodDetail.product_image} alt="" style={{width: 48, height: 48}}/>
                            </td>
                            <td style={{verticalAlign: 'inherit', float: 'left', marginTop: 28}}>
                                <div style={{marginBottom: 20, marginLeft: 15}}>
                                    <div className="prodName">{prodDetail.product_name}</div>
                                    <div className="satberatName">{prodDetail.product_weight} {prodDetail.product_weight_name}</div>                                                                        
                                </div>
                            </td>
                            <td style={{whiteSpace: 'nowrap', textAlign: 'right', marginTop: 20}}>
                                <div className="totalWeight">{totalProduct} {prodDetail.product_unit_name}</div>
                                <div className="satberatName">
                                {
                                    prodDetail.product_promo_price !== null ? <div className='disc-product' style={{float: 'right'}}>
                                        <span className="real-price" style={{marginRight: 10}}>{ convertToRupiah(prodDetail.product_price) }</span><span>
                                    {convertToRupiah(prodDetail.product_promo_price)} / {prodDetail.product_unit_name}</span>
                                    </div> : <p>{convertToRupiah(prodDetail.product_price)} / {prodDetail.product_unit_name}</p>
                                }
                                </div>
                            </td>
                        </tr>                        
                    </tbody>
                </table>
                <div className="div-btn" onClick={() => navigateToCart()}>
                    <Link>
                        <button className="btnLihatKeranjang">
                            Lihat Keranjang
                        </button>
                    </Link>
                </div>
                <div className="break-page"></div>
                </Modal> : null}
        </div>
    )
}

export default AddCartModal
