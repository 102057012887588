import { IS_LOADING_PRODUCTLIST1_CAT } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingProductList1: true
}

const isLoadingProductList1Reducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_PRODUCTLIST1_CAT) {
        return { ...state, isLoadingProductList1: action.payload.isLoadingProductList1 }
    } else {
        return state
    }
}

export default isLoadingProductList1Reducer