import React, { useState, useEffect } from 'react'
import {NavbarWithArrowBack} from '../components/Properties/Navbar/Navbar'
import './DataPribadi.css'
import { Layout, Modal, Spin, message } from 'antd'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProfileDetail } from '../redux/ActionCreators/profileDetailActions';
import axios from 'axios';
import encryptData from '../function/encryptData';
import base64Sign from '../function/base64Sign';
import { getToken } from '../utils/setSessionUser';
import { FETCH_DATA_PROFILE_DETAIL } from '../redux/ActionType/actionTypes';
import $ from 'jquery';
import {BaseURL} from '../function/helpers'
import emailVerifiedSuccess from '../Asset/Icon/dashboard_email_verified_success.png'
import profilePictefault from '../Asset/Icon/profile_default.png'
import iconAccountVerified from '../Asset/Icon/dashboard_account_verified.png'
import iconAlertInfoBack from '../Asset/Icon/alert_info_back.png'
import iconCarbonCheckmarkOutline from '../Asset/Icon/carbon_checkmark-outline.svg'
import imageIcon from '../Asset/Icon/image_icon.png'
import iconArrow from '../Asset/Icon/arrow.png'
import deleteIcon from '../Asset/Icon/delete_icon.png'
import calendarIcon from '../Asset/Icon/feather_calendar.png'
import DatePicker from 'react-mobile-datepicker';
import { MdError } from 'react-icons/md'

const { Footer } = Layout

function DataPribadi() {
    const dispatch = useDispatch()

    const profileDetail = useSelector(state => state.profileDetailReducer.profileDetail)
    const isLoadingProfDetail = useSelector(state => state.isLoadingProfileDetailReducer.isLoadingProfileDetail)
    
    const [ isEdit, setIsEdit ] = useState(false)
    const [ isVisible, setIsVisible ] = useState(false)
    const [ verifiedEmail, setVerifiedEmail ] = useState(false)
    const [inputHandle, setInputHandle] = useState({
        nama: profileDetail.customer_name,
        birthDate: profileDetail.date_of_birth,
        gender: profileDetail.customer_gender,
        email: profileDetail.customer_email,
        phoneNumber: profileDetail.customer_mobile,
        photoProfile: profileDetail.customer_photo,
    })
    const [image, setImage] = useState(null)
    const [ preview, setPreview ] = useState(null)
    const [isVisibleDatePicker, setIsVisibleDatePicker] = useState(false);
    const [errorName, setErrorName] = useState(false);
    const [errorBirthDate, setErrorBirthDate] = useState(false);
    const [errorGender, setErrorGender] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [errorHP, setErrorHP] = useState(false);
    const [isRegisteredHP, setIsRegisteredHP] = useState(false)
    const [errorRegisteredHP, setErrorRegisteredHP] = useState('');
    const [isDeletePict, setIsDeletePict] = useState(false)

    useEffect(() => {
        dispatch( fetchProfileDetail("/profile/getprofile") )
    }, [dispatch])

    function handleChange(e) {
        setInputHandle({
            ...inputHandle,
            [e.target.name] : e.target.value
        })
    }

    function modalPhotoHandler(e) {
        setIsVisible(true)
    }

    function modalCancelHandler() {
        setIsVisible(false)
        setIsVisibleDatePicker(false)
    }

    function verifyEmailHandler() {
        const signature = base64Sign("/profile/verifyemail")
        const access_token = getToken()
        const headers = {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
        }
        axios.post(
            BaseURL + "/profile/verifyemail",
            { data: "" }, 
            { headers: headers }
        )
            .then(verified => {
                if(verified.data.response_data.results.message === "SUCCESS") {
                    setVerifiedEmail(true)
                }
            })
            .catch(error => console.log(error))
    }

    function cancelVerifiedEmail() {
        setVerifiedEmail(false)
    }

    function openFile() {
        $('#input').click();
    }

    function deletePreview() {
        setPreview(profilePictefault)
        setIsDeletePict(true)
    }

    function uploadImage(e) {
        setImage(e.target.files[0])
        const objectUrl = URL.createObjectURL(e.target.files[0])
        setPreview(objectUrl)
    }

    function formattedDate(dt){
        var date = new Date(dt * 1000).toDateString();
        return date;
    }

    async function saveDataProfile(image, name, birthDate, gender, email, phoneNumber) {
        if (name === undefined) {
            name = profileDetail.customer_name
        } else if (name.length === 0) {
            setErrorName(true)
            return
        }
        if (birthDate === undefined) {
            birthDate = profileDetail.date_of_birth
        } else if (birthDate === 0) {
            setErrorBirthDate(true)
            return
        }
        if (gender === undefined) {
            gender = profileDetail.customer_gender
        } else if (gender.length === 0) {
            setErrorGender(true)
            return
        }
        if (email === undefined) {
            email = profileDetail.customer_email
        } else if (email.length === 0) {
            setErrorEmail(true)
            return
        }
        if (phoneNumber === undefined) {
            phoneNumber = profileDetail.customer_mobile
        }else if (phoneNumber.length === 0) {
            setErrorHP(true)
            return
        }
        try {
            const param = encryptData(`{
                    "name": "${name}",
                    "birth_date": "${moment(formattedDate(birthDate)).format('YYYY-MM-DD')}",
                    "gender": "${gender}",
                    "email": "${email}",
                    "phone_number": "${phoneNumber}"
                }`)
            const signature = base64Sign("/profile/saveprofile");
            const signatureImage = base64Sign("/profile/uploadphoto");
            const signatureDeleteImage = base64Sign("/profile/deletephoto");
            const access_token = getToken()
            const headers = 
            {
            'Content-Type':'application/json',
            'sign' : signature,
            'access_token' : access_token
            }

            const headersImage = 
            {
            'Content-Type':'multipart/form-data',
            'sign' : signatureImage,
            'access_token' : access_token
            }

            const headersDelete = 
            {
            'Content-Type':'application/json',
            'sign' : signatureDeleteImage,
            'access_token' : access_token
            }

            if(image !== null && !isDeletePict || image !== null && isDeletePict) {
                let formData = new FormData()
                formData.append('image', image)
                const imageUploaded = await axios.post(BaseURL + '/profile/uploadphoto', formData, {headers : headersImage})
                if (imageUploaded.status === 200) {
                    dispatch({
                        type: FETCH_DATA_PROFILE_DETAIL,
                        payload: {
                            profileDetail: imageUploaded.data.response_data.results
                        }
                    })
                }else{
                    if(imageUploaded.response.data.response_data.error_id === '0002'){
                        message.error({
                            content: imageUploaded.response.data.response_data.error_text,
                            className: 'snackbar-error-data-pribadi',
                            duration: 4
                        })
                    }
                    return
                }
            } else if (image === null && isDeletePict) {
                const deleteProfilePhoto = await axios.post(BaseURL + '/profile/deletephoto', { data: "" }, { headers: headersDelete })
            }

            const updatedProfile = await axios.post(BaseURL + '/profile/saveprofile',{data: param},{headers : headers})            
            if (updatedProfile.status === 200) {
                dispatch({
                    type: FETCH_DATA_PROFILE_DETAIL,
                    payload: {
                        profileDetail: updatedProfile.data.response_data.results
                    }
                })
                message.success({
                    content: "Data berhasil diperbaharui.",
                    className: 'snackbar-success-data-pribadi',
                    duration: 4
                })
                setErrorName(false)
                setErrorBirthDate(false)
                setErrorGender(false)
                setErrorEmail(false)
                setErrorHP(false)
                setIsEdit(false)
                return
            } else {
                if (updatedProfile.response.data.response_data.error_id === '0002') {
                    message.error({
                        content: updatedProfile.response.data.response_data.error_text,
                        className: 'snackbar-error-data-pribadi',
                        duration: 4
                    })
                } else if (updatedProfile.response.data.response_data.error_id === '0036') {
                    setIsRegisteredHP(true)
                    setErrorRegisteredHP(updatedProfile.response.data.response_data.error_text)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    function acceptDate(newValue){
        setInputHandle({
            ...inputHandle,
            birthDate : newValue.getTime()/1000
        })
        setIsVisibleDatePicker(false);
    }

    const monthMap = {
        '1': 'Januari',
        '2': 'Februari',
        '3': 'Maret',
        '4': 'April',
        '5': 'Mei', 
        '6': 'Juni',
        '7': 'Juli',
        '8': 'Agustus',
        '9': 'September',
        '10': 'October',
        '11': 'November',
        '12': 'Desember',
    };

    const dateConfig = {        
        'date': {
            format: 'DD',
            caption: 'Day',
            step: 1,
        },
        'month': {
            format: value => monthMap[value.getMonth() + 1],
            caption: 'Mon',
            step: 1,
        },
        'year': {
            format: 'YYYY',
            caption: 'Year',
            step: 1,
        }
    };

    if(isLoadingProfDetail) {
        return(
            <div className="container body-content mobile-view" style={{ minHeight: 'calc(100vh - 50px)', overflowX: 'visible' }}>
                <NavbarWithArrowBack />
                <div className="spin-load" style={{ display: 'flex', justifyContent: 'center' }}>
                    <Spin size="large" />
                </div>
                {/* <h1>Loading</h1> */}
            </div>
        )
    }

    // window.onbeforeunload = function() {
    //     return "Dude, are you sure you want to leave? Think of the kittens!";
    // }

    return (
        <div className="container body-content mobile-view" style={{ minHeight: 'calc(100vh - 50px)', overflowX: 'visible' }}>
            <NavbarWithArrowBack link="/back" />
            <div className="profileLayer1"></div>
            <div className="profilLayer2">
                <div className="profilePict">
                    {
                        (profileDetail.customer_photo === null && preview === null) ?
                        <>
                            <img className="profileDefault" style={{ borderRadius: '50%', border: '2px solid #fff' }} src={profilePictefault} alt="" width="80px" height="80px" />
                            {
                                (isEdit) &&
                                <div type="button" onClick={modalPhotoHandler} className="uploadPhoto_icon" style={{ display: 'inline-flex', position: 'absolute', marginTop: 50, marginLeft: -20 }}></div>
                            }
                        </> :
                        (profileDetail.customer_photo === null && preview) ?
                        <>
                            <img className="profileDefault" style={{ borderRadius: '50%', border: '2px solid #fff' }} src={ preview } alt="" width="80px" height="80px" /> 
                            {
                                (isEdit) &&
                                <div type="button" onClick={modalPhotoHandler} className="uploadPhoto_icon" style={{ display: 'inline-flex', position: 'absolute', marginTop: 50, marginLeft: -20 }}></div>
                            }
                        </> :
                        (profileDetail.customer_photo && preview) ?
                        <>
                            <img className="profileDefault" style={{ borderRadius: '50%', border: '2px solid #fff' }} src={ preview } alt="" width="80px" height="80px" /> 
                            {
                                (isEdit) &&
                                <div type="button" onClick={modalPhotoHandler} className="uploadPhoto_icon" style={{ display: 'inline-flex', position: 'absolute', marginTop: 50, marginLeft: -20 }}></div>
                            }
                        </> :
                        <>
                            <img className="profileDefault" style={{ borderRadius: '50%', border: '2px solid #fff' }} src={ profileDetail.customer_photo } alt="" width="80px" height="80px" />
                            {
                                (isEdit) &&
                                <div type="button" onClick={modalPhotoHandler} className="uploadPhoto_icon" style={{ display: 'inline-flex', position: 'absolute', marginTop: 50, marginLeft: -20 }}></div>
                            }
                        </>
                    }
                </div>
                <span className="username">
                    { profileDetail.customer_name }
                    {
                        (profileDetail.is_verify_email) &&
                        <span style={{ marginLeft: 3 }}>
                            <img src={iconAccountVerified} alt="" width="20" height="20" />
                        </span> 
                    }
                </span>
                <span className="membersince" id="memberSince">member sejak { moment(formattedDate(profileDetail.created_date)).format('MMMM yy') }</span>
            </div>
            {
                (!profileDetail.is_verify_email) &&
                <div className="col-11" style={{ marginTop: 17, marginLeft: 20, padding: '0px 10px' }}>
                    <div className="box-alert-info">
                        <img src={iconAlertInfoBack} alt="" width="33px" height="31px" />
                        <p>
                            Harap verifikasi email untuk keamanan transaksi serta mendapatkan update terbaru mengenai pesananmu
                        </p>
                    </div>
                </div>
            }
            <div style={{ marginLeft: 30 }}>
                <form style={{ position: 'relative', zIndex: 0 }}>
                    <div className="form-grups">
                        <label htmlFor="name">Nama</label>
                        <input className="form-control" value={(inputHandle.nama === undefined) ? profileDetail.customer_name : inputHandle.nama} onChange={handleChange} disabled={(!isEdit) ? "disabled" : ""} type="text" name="nama" style={{ width: '93%', height: 40, marginTop: -2 }} />
                        {
                            (errorName) ?
                            <div className='error-text-address' style={{ margin: '5px 0px 10px' }}>
                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Nama tidak boleh kosong</span>
                            </div> :
                            <div style={{ color: '#C4C4C4', fontSize: 12, fontFamily: 'Open Sans', marginTop: -5 }}>
                                Contoh: John Doe
                            </div>
                        }
                    </div>
                    <div className="form-grups">
                        <label style={{ marginTop: 10, fontSize: 12, fontFamily: 'Montserrat' }} htmlFor="birthOfDate">Tanggal Lahir</label>
                        <input onClick={() => setIsVisibleDatePicker(true)} className="form-control" value={ (inputHandle.birthDate) ? moment(formattedDate(inputHandle.birthDate)).format('DD MMMM YYYY') : moment(formattedDate(profileDetail.date_of_birth)).format('DD MMMM YYYY') } onChange={handleChange} disabled={(!isEdit) ? "disabled" : ""} type="text" name="birthDate" style={{ width: '93%', height: 40, marginTop: -2 }} />
                        <div className="div-calendar-data-pribadi" >
                            <span>
                                <img src={calendarIcon} alt=""/>
                            </span>
                        </div>
                        {
                            (errorBirthDate) &&
                            <div className='error-text-address' style={{ margin: '5px 0px 10px' }}>
                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Tanggal lahir tidak boleh kosong</span>
                            </div>
                        }
                    </div>
                    <div className="form-grups">
                        <label>Jenis Kelamin</label>
                        <div style={{ padding: '5px 0 7px' }}>
                            <div className="radio-inline">
                                <input disabled={(!isEdit) ? "disabled" : ""} checked={(inputHandle.gender === "M" || (inputHandle.gender === undefined && profileDetail.customer_gender === "M"))} onChange={handleChange} style={{ marginTop: 3 }} type="radio" name="gender" value="M" />
                                Pria
                            </div>
                            <div className="radio-inline" style={{ marginLeft: 15 }}>
                                <input disabled={(!isEdit) ? "disabled" : ""} checked={(inputHandle.gender === "F" || (inputHandle.gender === undefined && profileDetail.customer_gender === "F"))} onChange={handleChange} style={{ marginTop: 3 }} type="radio" name="gender" value="F" />
                                Wanita
                            </div>
                        </div>
                        {
                            (errorGender) &&
                            <div className='error-text-address' style={{ margin: '5px 0px 10px' }}>
                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Jenis kelamin tidak boleh kosong</span>
                            </div>
                        }
                    </div>
                    <div className="row col-12 form-grups" style={{ marginLeft: 'calc(var(--bs-gutter-x) * -.5)', marginRight: 'calc(var(--bs-gutter-x) * -.5)' }}>
                        <div className="col-9">
                            <label htmlFor="email">
                                Email
                                {
                                    (profileDetail.is_verify_email) ?
                                    <span className="verified" style={{ verticalAlign: 'middle', marginLeft: 5 }}>
                                        <img src={iconCarbonCheckmarkOutline} alt="" width="16px" height="16px" />
                                        Terverifikasi
                                    </span> :
                                    <span className="unverified" style={{ verticalAlign: 'sub', marginLeft: 10 }}>
                                    </span>
                                }
                            </label>
                            <input className="form-control" value={profileDetail.customer_email} disabled type="text" name="email" style={{ width: '93%', height: 40, marginTop: 2 }} />
                            {
                                (errorEmail) ?
                                <div className='error-text-address' style={{ margin: '5px 0px 10px' }}>
                                    <MdError style={{ fontSize: 20, float: 'left' }} />
                                    <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>Email tidak boleh kosong</span>
                                </div> :
                                <div style={{ marginTop: -7, color: '#C4C4C4', fontSize: 12, fontFamily: 'Open Sans' }}>
                                    Contoh: ezeepasar@ezeepasar.com
                                </div>
                            }
                        </div>
                        <div className="col-3" style={{ width: '30%', marginTop: 38, marginLeft: -27 }}>
                            <input onClick={verifyEmailHandler} type="button" style={{ fontSize: 12, backgroundColor: "#5cb85c", borderColor: "#4cae4c" }} className="btn btn-sm btn-success" value="Verifikasi" 
                            disabled={(!isEdit || profileDetail.is_verify_email) ? 'disabled' : "" } />
                        </div>
                    </div>
                    <div className="form-grups" style={{ marginTop: 10 }}>
                        <label htmlFor="phone">No. Telepon</label><br />
                        <input className="form-control" value={(inputHandle.phoneNumber === undefined) ? profileDetail.customer_mobile : inputHandle.phoneNumber} onChange={handleChange} disabled type="number" name="phoneNumber" style={{ width: '93%', height: 40, marginTop: -2, border: '1px solid #C4C4C4' }} />
                        {
                            (errorHP) ?
                            <div className='error-text-address' style={{ margin: '5px 0px 10px' }}>
                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>No. Telepon tidak boleh kosong</span>
                            </div> :
                            (isRegisteredHP) ?
                            <div className='error-text-address' style={{ margin: '5px 0px 10px' }}>
                                <MdError style={{ fontSize: 20, float: 'left' }} />
                                <span style={{ marginLeft: 5, verticalAlign: 'middle' }}>{errorRegisteredHP}</span>
                            </div> :
                            <div style={{ color: '#C4C4C4', fontSize: 12, fontFamily: 'Open Sans', marginBottom: 70 }}>
                                Contoh: 08987654321
                            </div>
                        }
                    </div>
                </form>
                <div style={{ marginLeft: -30 }}>
                    <Layout>
                        <Footer className="footerLayout">
                            {
                                (isEdit) ?
                                <button onClick={() => saveDataProfile(image, inputHandle.nama, inputHandle.birthDate, inputHandle.gender, profileDetail.customer_email, inputHandle.phoneNumber)}>
                                    Simpan Data
                                </button> :
                                <button onClick={() => setIsEdit(true)}>
                                    Ubah Data
                                </button>
                            }
                        </Footer>
                    </Layout>
                </div>
            </div>
            {
                (profileDetail.customer_photo === "https://api.ezeelink.co.id/resources/ezeepasar/CustomerPhoto/profile_default.png" || profileDetail.customer_photo === null) ?
                    <Modal
                        title="Ubah Foto Profil"
                        visible={isVisible}
                        onCancel={modalCancelHandler}
                        className="modalDataPribadi"
                        footer={null}
                    >
                        <div className="modal-table">
                            <table>
                                <tbody>
                                    <tr>
                                        <td type="file" className="td1">
                                            <div type="button" onClick={() => openFile()}>
                                                <img src={imageIcon} alt="" width="21px" height="19px" />
                                            </div>
                                        </td>
                                        <td className="td2">
                                            <div type="button" onClick={() => openFile()}>
                                                <input onChange={uploadImage} id="input" type="file" accept="image/png, image/gif, image/jpeg" style={{ display: 'block', visibility: 'hidden', width: 0, height: 0 }} />
                                                Ubah Foto Profil
                                            </div>
                                        </td>
                                        <td className="td3">
                                            <div type="button" onClick={() => openFile()} style={{ marginLeft: 20 }}>
                                                <img src={iconArrow} alt="" width="25px" height="25px" />
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Modal> :
                    <Modal
                        title="Ubah Foto Profil"
                        visible={isVisible}
                        onCancel={modalCancelHandler}
                        className="modalDataPribadi"
                        style={{ left: '0.5%' }}
                        footer={null}
                    >
                    <div className="modal-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td type="file" className="td1">
                                        <div type="button" onClick={() => openFile()}>
                                            <img src={imageIcon} alt="" width="21px" height="19px" />
                                        </div>
                                    </td>
                                    <td className="td2" style={{ borderBottom: '1px solid #ddd' }}>
                                        <div type="button" onClick={() => openFile()}>
                                            <input onChange={uploadImage} id="input" type="file" accept="image/png, image/gif, image/jpeg" style={{ display: 'block', visibility: 'hidden', width: 0, height: 0 }} />
                                            Ubah Foto Profil
                                        </div>
                                    </td>
                                    <td className="td3" style={{ borderBottom: '1px solid #ddd' }}>
                                        <div type="button" onClick={() => openFile()}>
                                            <img src={iconArrow} alt="" width="25px" height="25px" />
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td className="td1">
                                        <div type="button" onClick={() => deletePreview()}>
                                            <img src={deleteIcon} alt="" width="20px" height="23px" />
                                        </div>
                                    </td>
                                    <td className="td2">
                                        <div type="button" onClick={() => deletePreview()}>
                                            Hapus Foto Profil
                                        </div>
                                    </td>
                                    <td className="td3">
                                        <div type="button" onClick={() => deletePreview()}></div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </Modal>
            }
            <Modal
                visible={verifiedEmail}
                onCancel={cancelVerifiedEmail}
                footer={null}
                className="modalVefiedEmailVerif"
            >
                <div>
                    <img src={emailVerifiedSuccess} alt="" width="85px" height="85px" />
                </div>
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#3DB54A" }}>
                    Email Verifikasi Berhasil Dikirim
                </div>
                <div style={{ fontSize: 10 }}>
                    Silahkan periksa email Anda untuk melakukan verifikasi email
                </div>
                <button onClick={cancelVerifiedEmail} className="btn" style={{ backgroundColor: "#3DB54A", color: "#fff", marginTop: 40, padding: "10px 50px", outline: 'unset' }}>Oke</button>
            </Modal>
            <div className="div-modal-delivery-method">
                <Modal
                    title="Tanggal Lahir"
                    visible={isVisibleDatePicker}
                    // onOk={closedModal}
                    onCancel={modalCancelHandler}
                    className="modalAddCart modal-delivery-method" 
                    style={{borderRadius: 15}}
                    footer={null}  
                    >
                    <table style={{width: '100%', margin: '0 auto'}} className="tbl-delivery-method">
                        <tbody>
                            <tr> 
                                <td>
                                <DatePicker
                                    value={new Date(profileDetail.date_of_birth * 1000)}
                                    isOpen={false}
                                    isPopup={false}
                                    onSelect={acceptDate}
                                    onCancel={null}
                                    cancelText={false}
                                    showHeader={false}
                                    dateFormat={["DD", "MM", "YYYY"]}
                                    confirmText="Pilih Tanggal"
                                    dateConfig={dateConfig}
                                    // showFooter={true}
                                    theme="default"
                                    // className="datePickerDeliveryMethod"
                                    style={{marginBottom: '112px'}}
                                    // min={new Date(minDatePicker)}
                                    // max={new Date(maxDatePicker)}
                                />
                                </td>
                            </tr>                     
                        </tbody>
                    </table>
                    <br/>
                    <br/>
                </Modal>
            </div>
        </div>
    )
}

export default DataPribadi
