import React, { useEffect } from 'react'
import {NavbarCloseOnlyForMenu} from '../components/Properties/Navbar/Navbar'
import Body from '../components/MenuPage/Body'

function Menu() {
    return (
        <div className="container body-content mobile-view" style={{ minHeight: 'calc(100vh - 50px) important', overflowX: 'visible' }}>
            <NavbarCloseOnlyForMenu link="/back"/>
            <Body />
        </div>
    )
}

export default Menu
