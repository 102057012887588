import { FETCH_PRODUCT_SEARCH, IS_NEW_LOADING, FETCH_USERS_REQUEST, FETCH_USERS_SUCCESS } from "../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../function/base64Sign";
import { getToken } from "../../utils/setSessionUser";
import encryptData from "../../function/encryptData";
import {BaseURL, headersWithToken} from '../../function/helpers'

export const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const fetchProdsSuccess = dt => {
    return{
        type : FETCH_PRODUCT_SEARCH,
        payload : dt
    }
}

const  backToLoading = () => {
    return { 
        type : IS_NEW_LOADING
    }
}

export const fetchProductsSearch = (url, data) => {
    return (dispatch) => {
        dispatch(fetchUsersRequest())
        const signature = base64Sign(url)
        const dataParams = encryptData(data);
        const access_token = getToken()
        const headers  = headersWithToken(signature, "")
        axios.post(BaseURL + url, {data: dataParams}, {headers: headers})
        .then(productsSearch => {
            dispatch(fetchProdsSuccess(productsSearch.data.response_data.results))
        })
        .catch(error => {
            console.log(error);
        })
    }
}