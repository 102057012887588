import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Row, Col, Card, Button } from 'antd'
import AddCartModal from '../PopUpModal/HomePage/AddCartModal';
import { CardProductOtherLoading, TitleProdOtherLoading, CardProdLoading} from '../skeletonLoading/loadingTag'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {fetchOtherProductList} from '../../redux/ActionCreators/getOtherProductActions'
import {nFormatter} from '../../function/helpers'
import $ from 'jquery'

function convertToRupiah(money) {
    return new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits: 0 }).format(money)
}
function OtherProduct({prod_id, cat_id}) {
    const otherProds = useSelector(state => state.getOtherProductReducer.getOtherProduct);
    const loading = useSelector(state => state.isLoadingProductOtherReducer.isLoadingProductOther);
    const [ visible, setVisible ] = useState(false);
    const [ prodID, setProdId] = useState('')
    let history = useHistory();
    const dispatch = useDispatch()
    let isContainPromo;
    
    function openModalWithData(id) {
        if(id === undefined){
            setVisible(false);
        }
        else
        {
            setVisible(true)
            const prodID = id;
            setProdId(prodID);
        }
    }

    useEffect(() => {  
        openModalWithData();
        dispatch(fetchOtherProductList(`{"product_id": ${prod_id}}`, "/product/getproductother"));
    }, [dispatch])

    const productDetail = (prodId, prodName) => {
        const productId = prodId;
        const productName = prodName;
        history.push({pathname: `/produk/${productId}/${productName.replace(/\//g, " ")}`, state: {result: productId}})
    }

    if(loading) {
        const loops = [1,2,3]
        return (
            <div style={{ marginTop: 50}}>
                <div style={{ marginTop: 25 }} className='cardprodloadingdiv'>  
                <TitleProdOtherLoading className='titleProdLoading'/>   
                    <Row style={{}}>
                        <Col span={24} style={{ marginTop: -8, justifyContent: 'center' }}>
                            <Col span={24} style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6, flexWrap: 'nowrap', overflowX: 'auto' }}>
                                <div className="card-body" style={{ display: 'flex', marginBottom: 8, marginLeft: 15}}>
                                    {
                                        loops.map(loop => {
                                            return(
                                                <CardProdLoading key={loop}/>
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                        </Col>
                    </Row>
                </div>
            </div>
        )
    }

    let isPromo = otherProds.find(q => q.product_promo_percent !== null);
    if(isPromo !== undefined){
        isContainPromo = true;
    }else{
        isContainPromo = false;
    }

    return (
        <div>
            <Row style={{ marginTop: -15 }}>
                <Col span={24} style={{ marginTop: 15, fontSize: 20, fontWeight: 'bold', fontFamily: 'Montserrat, sans-serif', textAlign: 'left', paddingLeft: 20 }}>
                    <span>Produk Lainnya</span>
                    <Link to={{ pathname: `/ProductListCategory/${otherProds[0].category.mcat_id}`, state: { layerKey1: 1 } }} className="link_product" style={{ float: "right" }} >Lihat Semua</Link>
                </Col>
                <Col span={24} style={{ textAlign: 'left', paddingLeft: 20, fontSize: 10, fontFamily: 'Montserrat', color: '#848484' }}>
                    Semua produk pilihan spesial untuk kamu
                </Col>
            </Row>
            <Row style={{marginLeft:-15}}>
                <Col span={24} style={{ marginTop: -8, justifyContent: 'center', marginBottom: 40 }}>
                    <Col span={24} style={{ marginTop: 13, paddingTop: 6, paddingBottom: 6 ,flexWrap: 'nowrap', overflowX: 'auto' }}>
                        <div className="card-body" style={{ display: 'flex', marginBottom: 8, marginLeft: 15}}>
                            {
                                otherProds.map((product, i) => {
                                    return (
                                        <div onClick={() => productDetail(product.product_id, product.product_name)} key={i}>
                                            <Card
                                                key={ product.product_id }
                                                hoverable
                                                style={isContainPromo ? {height : 280} : null}
                                                className='card-products'
                                            >
                                                {
                                                    product.product_stat_stock_id === 2
                                                    ? <><img style={{ borderRadius: 4, marginLeft: 10}} alt="img" width="80" height="80" src={ product.product_image } /><div className="sold-out"></div><div className="sold-out-text" style={{textAlignLast: 'center'}}>Habis</div></>
                                                    : <img style={{ borderRadius: 4, marginLeft: 10}} alt="img" width="80" height="80" src={ product.product_image } />
                                                }
                                                <p className="text-product1">{ product.product_name }</p>
                                                <p className="text-product2">{ product.product_weight } { product.product_weight_name }</p>
                                                {
                                                    product.product_promo_percent == null
                                                    ? <p className="text-product3">{ convertToRupiah(product.product_price) } / { product.product_unit_name }</p>
                                                    : <><div className="disc-product">
                                                        <span className="percent-disc">{ product.product_promo_percent }%</span>
                                                        <span className="real-price">{ convertToRupiah(product.product_price) }</span>
                                                    </div>
                                                    <p className="text-product3">{ convertToRupiah(product.product_promo_price) } / { product.product_unit_name }</p>
                                                    </>
                                                }
                                                {
                                                    product.product_terjual !== null && product.product_terjual !== 0 ? 
                                                    <div>
                                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10, float: 'left'}}>Terjual: <span style={{color: 'black'}}>{nFormatter(product.product_terjual)}</span></span>
                                                    </div> :
                                                    <div>
                                                        <span className='sp-danger product-sold-and-stock' style={{fontSize: 10,visibility: 'hidden', float: 'left'}}>hidden</span>
                                                    </div>
                                                } 
                                                {
                                                    product.product_stat_stock_id === 2
                                                    ? <div onClick={(event) => event.stopPropagation()}><button className="btn-pesan-sold" style={isContainPromo ? product.product_promo_percent === null ? {marginTop: 46} : null : null} shape="round">Pesan</button></div>
                                                    : <div onClick={(event) => event.stopPropagation()}><Button className="btn-pesan" style={isContainPromo ? product.product_promo_percent === null ? {marginTop: 46} : null : null} shape="round" onClick={() =>openModalWithData(product.product_id)}>Pesan</Button></div>
                                                }
                                            </Card>
                                        </div>
                                    )
                                })
                            }                        
                        </div>
                    </Col>
                </Col>
            </Row>
            {
                visible ? <AddCartModal showModal={visible} setModal={setVisible} closedModal={() => setVisible(false)} productId={prodID}/> : null
            }
        </div>
    )
}

export default OtherProduct
