import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import {Row, Col, Layout} from 'antd';
import { checkoutUserSession } from '../../utils/setSessionUser';
import encryptData from '../../function/encryptData'
import base64Sign from '../../function/base64Sign';
import axios from 'axios';
import { getToken } from '../../utils/setSessionUser';
import {BaseURL, headersWithToken} from '../../function/helpers'
const {Footer} = Layout
function FooterCart({totalItem, harga, item}) {
    
    let history = useHistory();
    let access_token = getToken();

    function convertToRupiah(money) {   
        return new Intl.NumberFormat('id-ID', { style: 'decimal', currency: 'IDR'}).format(money)
    }

    
    async function BuyNow() {
        const signature = base64Sign("/cart/buynow");
        const dataParams = encryptData(`{"product_id": 0, "qty": 0}`)
        const headers = headersWithToken(signature, access_token);
        axios
        .post(BaseURL + '/cart/buynow',{data:dataParams},{headers:headers}).then(data=>{
        }).catch(err => console.log(err));

    }

    function navigateOnePageheckout(){

        BuyNow(); 
        checkoutUserSession.setTotalItem(item)
        checkoutUserSession.setTotalPrice(harga)
        history.push('/checkout');
    }
    return (
        <Layout style={{marginTop: 35}}>
            <Footer className="footerLayout" style={{fontFamily: 'Montserrat', height: 70, marginLeft: -25}}>
                <Row>                
                    <Col span={14} style={{paddingRight: 5, paddingTop: 10}}>
                        <span style={{color: '#848484', fontSize: 14}}>Total Belanja ( <span style={{color: 'black', fontWeight: 'bold'}}>{item}  Item</span> )</span><br/>
                        <span style={{color: '#F79226', fontSize: 15, fontWeight: 'bold'}}>Rp{convertToRupiah(harga)}</span>
                    </Col>
                    <Col span={10} style={{paddingLeft: 5, paddingTop: 10}}>
                        {/* <Link to="/checkout" state={{totalPrice: harga}} style={{color: "#fff", backgroundColor: 'transparent'}} className="spanCartLink"> */}
                            <button className="btnBuy" onClick={() => navigateOnePageheckout()} disabled={item === 0 ? true : false}>
                                    <div>
                                        <span className="spanCart">
                                            Beli Sekarang
                                        </span>
                                    </div>
                            </button>
                        {/* </Link> */}
                    </Col>
                </Row>
            </Footer>
        </Layout>
    )
}

export default FooterCart
