import React from 'react'
import { Row, Col } from 'antd'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'
import pictAgenReferral from '../Asset/Icon/agen_referral.svg'
import agenReferralCara1 from '../Asset/Icon/agen_referral_cara1.svg'
import agenReferralCara2 from '../Asset/Icon/agen_referral_cara2.svg'
import agenReferralCara3 from '../Asset/Icon/agen_referral_cara3.svg'
import agenReferralCara4 from '../Asset/Icon/agen_referral_cara4.svg'
import agenReferralCara5 from '../Asset/Icon/agen_referral_cara5.svg'
import iconwhatsappGrey from '../Asset/Icon/whatsapp_grey.svg'
import agenReferralGabung from '../Asset/Icon/agen_referral_gabung.svg'

function Agen({isWebview}) {

    function learnMoreHandler() {
        window.open("https://www.ezeepasar.com/Document/FAQ_rev2[10348].pdf", "_blank")
    }

    return (
        <div className="container body-content mobile-view" style={{ textAlign: 'center', height: 2542 }}>
            {isWebview ? null : <NavbarWithMenuIcon link="/back"/>}
            <div style={{ marginTop: 70, paddingTop: 10 }}>
                <span className="head-2">Yuk Ikutan</span>
                <br />
                <span className="head-2">Program Agen</span>
            </div>
            <Row>
                <Col span={24} style={{ marginTop: 35 }}>
                    <img src={pictAgenReferral} alt="agenReferral" width="75%" />
                </Col>
            </Row>
            <Row>
                <Col span={24} style={{ marginTop: 35 }}>
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                        Ayo ikutan Program Agen ezeePasar.
                        <br />
                            Biar nggak kehilangan kesempatan untuk
                        <br />
                            mendapat income tambahan dari tiap transaksi
                        <br />
                            yang menggunakan Kode Agen mu.
                        <br />
                            Caranya Praktis, Biayanya Gratis!
                    </span>
                </Col>
            </Row>
            <div style={{ marginTop: 35 }}>
                <span className="head-2">Panduan Mengikuti</span>
                <br />
                <span className="head-2">Program Agen</span>
            </div>
            <div style={{ marginTop: 15 }}>
                <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>Cek cara selengkapnya berikut ini:</span>
            </div>
            <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <img style={{ width: 140, marginTop: 55, verticalAlign: 'middle' }} src={agenReferralCara1} alt="cara1" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', marginTop: 40, paddingLeft: 20 }}>
                    <span style={{ color: '#393939', fontSize: 14, fontWeight: 700 }}>
                        Daftar Sekarang
                        <br />
                        untuk dapat
                        <br />
                        Kode Agen
                    </span>
                    <br />
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                        Klik “Daftar” untuk mengisi form registrasi di
                        <br />
                        <a href="https://ezeepasar.com/Agen" style={{ color: '#3DB54A', fontWeight: 700 }}>https://ezeepasar.com/Agen </a> 
                        <br />
                        untuk mendapatkan Kode Agen
                        <br />
                        dan ikut Program Agen ezeePasar
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <img style={{ width: 140, marginTop: 55, verticalAlign: 'middle' }} src={agenReferralCara2} alt="cara2" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', marginTop: 40, paddingLeft: 20 }}>
                    <span style={{ color: '#393939', fontSize: 14, fontWeight: 700 }}>
                        Bagikan kode
                        <br />
                        Agenmu dan
                        <br />
                        dapatkan komisi 
                        <br />
                        hingga 15%
                    </span>
                    <br />
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                        Setiap transaksi di ezeePasar
                        <br />
                        yang menggunakan Kode
                        <br />
                        Agen mu akan memberikanmu 
                        <br />
                        komisi hingga 15% untuk 
                        <br />
                        setiap transaksinya
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <img style={{ width: 140, marginTop: 55, verticalAlign: 'middle' }} src={agenReferralCara3} alt="cara3" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', marginTop: 40, paddingLeft: 20 }}>
                    <span style={{ color: '#393939', fontSize: 14, fontWeight: 700 }}>
                        Pemberi maupun 
                        <br />
                        Pengguna, Semua 
                        <br />
                        dapat Untungnya 
                    </span>
                    <br />
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                        Pengguna Kode Agen 
                        <br />
                        darimu akan mendapatkan 
                        <br />
                        diskon 40.000 dari total
                        <br />
                        transaksi pertamanya di ezeePasar + Free Ongkir 
                        <br />
                        area DKI Jakarta (*s&k berlaku)
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <img style={{ width: 140, marginTop: 55, verticalAlign: 'middle' }} src={agenReferralCara4} alt="cara4" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', marginTop: 40, paddingLeft: 20 }}>
                    <span style={{ color: '#393939', fontSize: 14, fontWeight: 700 }}>
                        Cukup share, 
                        <br />
                        Tanpa perlu stock 
                        <br />
                        dan Langsung dikirim
                    </span>
                    <br />
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                        Dengan Kode Agen 
                        <br />
                        ezeePasar kamu akan dapat 
                        <br />
                        dukungan dan materi 
                        <br />
                        jualan, cukup share Kode 
                        <br />
                        Agen tanpa perlu stok
                        <br />
                        dan biarkan ezeePasar yg antar 
                        <br />
                        langsung ke teman / 
                        <br />
                        komunitasmu
                    </span>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <img style={{ width: 140, marginTop: 55, verticalAlign: 'middle' }} src={agenReferralCara5} alt="cara5" />
                </Col>
                <Col span={12} style={{ textAlign: 'left', marginTop: 40, paddingLeft: 20 }}>
                    <span style={{ color: '#393939', fontSize: 14, fontWeight: 700 }}>
                        Daftar Gratis 
                        <br />
                        Benefit Nggak 
                        <br />
                        Habis-Habis
                    </span>
                    <br />
                    <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                        Cara daftarnya mudah dan 
                        <br />
                        tanpa biaya, tinggal klik Daftar di
                        <br />
                        <a href="https://ezeepasar.com/Agen" style={{ color: '#3DB54A', fontWeight: 700 }}>https://ezeepasar.com/Agen</a>
                        <br />
                        untuk mendapatkan kode 
                        <br />
                        Agen. Setelah Akun 
                        <br />
                        Agen mu jadi, langsung 
                        <br />
                        berkesempatan untuk dapat 
                        <br />
                        komisi berkali-kali!
                    </span>
                </Col>
            </Row>
            <div style={{ textAlign: 'center', marginTop: 35 }}>
                <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                    Segera daftar sekarang di Program Agen ezeePasar,
                    <br />
                    mumpung kesempatan masih terbuka.
                </span>
            </div>
            <div style={{ marginTop: 40 }}>
                <button onClick={learnMoreHandler} style={{ width: '90%', height: 48, borderRadius: 8, fontSize: 14, fontWeight: 700, color: '#FFFFFF', backgroundColor: '#5cb85c', borderColor: '#4cae4c' }}>
                    Pelajari Lebih Lanjut
                </button>
            </div>
            <div style={{ marginTop: 40 }}>
                <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                    Bila masih memerlukan info bisa menghubungi kami via
                </span>
            </div>
            <div style={{ marginTop: 40 }}>
                <span>
                    <img src={iconwhatsappGrey} alt="waIcon" />
                    <span style={{ color: '#848484', fontSize: 13, fontWeight: 'bold' }}>&nbsp;+62817844931</span>
                </span>
            </div>
            <div style={{ marginTop: 40 }}>
                <span style={{ fontSize: 12, fontWeight: 400, color: '#848484' }}>
                atau email kami &nbsp;
                <a href="mailto:info@ezeepasar.com" style={{ color: '#3DB54A', fontWeight: 700 }}>info@ezeepasar.co.id</a>
                </span>
            </div>
            <div style={{ marginTop: 40 }}>
                <span className="head-2">
                    Slot Pendaftaran
                    <br />
                    Terbatas!
                </span>
            </div>
            <div style={{ marginTop: 20 }}>
                <img src={agenReferralGabung} alt="joinReferral" width="300px" />
            </div>
        </div>
    )
}

export default Agen