import { IS_LOADING_PRODUCT_OTHER } from "../../ActionType/actionTypes";

export function isLoadingProductOther(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_PRODUCT_OTHER, 
            payload: {
                isLoadingProductOther: param
            }
        })
    }
}