import React, {useEffect, useState}from 'react'
import { Row, Col, Button } from 'antd'
import {useHistory, Link} from 'react-router-dom'
import './EzeeTunai.css'
import {fetchEzeeTunaiBalance} from '../../redux/ActionCreators/EzeeTunai/ezeeTunaiGetBalanceActions'
import {fetchEzeeTunaiSuccessActivation} from '../../redux/ActionCreators/EzeeTunai/ezeeTunaiGetSuccessActivation'
import { connect } from 'react-redux'
import {EzeeTunaiCardBalanceLoading} from '../skeletonLoading/loadingTag'
import currencyFormatter from 'currency-formatter'
import { getToken } from '../../utils/setSessionUser'
import ezeetunaiIconHome from '../../Asset/Icon/logo/ezeetunai-home.svg'
import EzeeTunaiUnactiveModal from '../../components/PopUpModal/HomePage/EzeeTunaiUnactiveModal' 
import EzeeTunaiSuccessActivation from '../../components/PopUpModal/HomePage/EzeeTunaiSuccessActivation'
import {isdeniednotification} from '../../function/helpers'

function EzeeTunai({ ezeeTunaiData, ezeeTunaiActivationStatus, fetchEzeeTunaiBalance, fetchEzeeTunaiSuccessActivation, flag}) {
    const isdenied = isdeniednotification();

    let token = getToken();
    let history = useHistory();
    const [ezeeTunaiActive, setEzeeTunaiActive] = useState(false)
    const [ezeeTunaiActivation, setEzeeTunaiActivation] = useState(false)
    const [ezeeTunaiSuccessActivation, setEzeeTunaiSuccessActivation] = useState(true)

    useEffect(() => {
        if(token === null){
            setEzeeTunaiActive(false);
            setEzeeTunaiActivation(false)
        }else{
            setEzeeTunaiActive(true);
            if(flag === '1'){
                setEzeeTunaiActivation(false)
            }else{
                setEzeeTunaiActivation(true)
            }
            if(!isdenied){
                setEzeeTunaiActivation(true)
            }
            fetchEzeeTunaiBalance('/ezeetunai/getbalance')
            fetchEzeeTunaiSuccessActivation('/ezeetunai/getenvironmentinfoezeetunai')
        }
    }, [])

    if(ezeeTunaiActive){
        if(ezeeTunaiData.loading){
            return(
                <div className='ez-tunai-dv' style={{height: 83}}>
                    <EzeeTunaiCardBalanceLoading/>                
                </div>
            )
        }
    }

    // console.log(ezeeTunaiActivationStatus.data.message);
    
    function navigateTopUp(){
        history.push("/EzeeTunai/TopUp")
    }

    return (
        <div className="ez-tunai-dv">
           {
               isdenied ? [
                   (!ezeeTunaiData.getBalance.is_active ? <EzeeTunaiUnactiveModal isOpen={ezeeTunaiActivation} onClosed={() => setEzeeTunaiActivation(false)}/> : null)
               ] : null
           }
           
            <Row style={{ padding: '14px 21px', borderRadius: 8 }}>
                <Col span={12} style={{textAlign: 'left', lineHeight: '20px', padding: "7px"}}>
                    {
                        ezeeTunaiData.getBalance.is_active ? 
                        <>
                        <img src="https://www.ezeepasar.com/Images/Icon_ezeeTunai.svg" alt="" width="43" height="43" style={{ float : 'left', marginTop : 3, marginRight : 5, marginLeft: 10, padding: 5 }} />
                            <span className="sub-title title" style={{ marginTop : 15, marginBottom : 5 }}>
                                Saldo
                            </span>
                            <span className="title">{currencyFormatter.format(ezeeTunaiData.getBalance.saldo, {code: 'IDR'})}</span>
                        </>
                        :
                        <>
                            <span className="title-deact" style={{ marginTop : 15, marginBottom : 5 }}>
                                Aktifkan ezeeTunai untuk pembayaran tanpa ribet
                            </span>
                            <Link to={token === null ? "/login" : "/EzeeTunai/HowToActivation"} rel="noopener noreferrer">
                                <Button className="btn" type="primary" style={{ marginTop: 10, backgroundColor: '#5CB85C', borderRadius: 5, borderColor: 'transparent', width: 108, height: 33, fontSize: 13, fontWeight: 'bold', textDecoration: 'none solid rgb(255 255 255)' }}>
                                    Aktivasi
                                </Button>
                            </Link>
                        </>
                        
                    }
                </Col>            
                <Col span={12}>
                    {
                        ezeeTunaiData.getBalance.is_active ? 
                        <>
                            <Button className="btn" onClick={() => navigateTopUp()} type="primary" style={{ marginLeft: 5, backgroundColor: '#5CB85C', borderRadius: 5, borderColor: 'transparent', width: 108, height: 33, fontSize: 13, fontWeight: 'bold', textDecoration: 'none solid rgb(255 255 255)', float: 'right', marginTop: 14}}>
                                Top Up
                            </Button>
                        </>
                        :
                        <>
                            <img src={ezeetunaiIconHome} alt="" style={{float: 'right'}} />
                        </>
                    }
                </Col>
            </Row>            

            {
                ezeeTunaiActivationStatus.data.message === 'Success' ? 
                <EzeeTunaiSuccessActivation isOpen={ezeeTunaiSuccessActivation} onClosed={() => setEzeeTunaiSuccessActivation(false)}/> : null
            }
        </div>
    )
}

const mapStateToProps = state => {
    return{
        ezeeTunaiData : state.ezeeTunaiGetBalanceReducer,
        ezeeTunaiActivationStatus : state.ezeeTunaiGetSuccessActivationReducer
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchEzeeTunaiBalance: (url) => dispatch(fetchEzeeTunaiBalance(url)),
        fetchEzeeTunaiSuccessActivation: (url) => dispatch(fetchEzeeTunaiSuccessActivation(url))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EzeeTunai)
