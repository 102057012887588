import React, {useState, useEffect}from 'react'
import './HistoryOrder.css'
import {Row, Col, Modal, Card} from 'antd'
import {Link, useHistory, useParams} from 'react-router-dom'
import {getToken} from '../../utils/setSessionUser'
import base64Sign from '../../function/base64Sign';
import encryptData from '../../function/encryptData'
import axios from 'axios';
import LoadingModal from '../PopUpModal/Loading/LoadingModal'
import moment from 'moment';
import iconFilter from '../../Asset/Icon/icon_filter_history.png';
import historyKosong from '../../Asset/Icon/history_kosong.png'
import InfiniteScroll from 'react-infinite-scroller';
import Loader from 'react-loader-spinner'  
import ModalFilter from './FilterModal';
import {HeadTitlePage, CardHistoryOrder} from '../skeletonLoading/loadingTag'
import RePurchaseModal from './RePurchaseModal';
import {BaseURL, headersWithToken} from '../../function/helpers';
import { useDispatch, useSelector } from 'react-redux'
import { fetchCategory } from '../../redux/ActionCreators/categoryActions'

function HistoryOrder() {
    const [listhistoryData, setListHistoryData] = useState([])
    const [listOrderItems, setListOrderItems] = useState([])
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(0);
    const [nextPageNumber, setNextPageNumber] = useState(1);
    const [visibleFilter, setVisibleFilter] = useState(false);
    const [visibleRepurchase, setVisibleRepurchase] = useState(false);
    const [isLoadMore, setIsLoadMore] = useState(true);
    const [isFilter, setIsFilter] = useState({status: false, statusId : [], dateFrom: "", dateTo: "", dateId: 1});
    const dispatch = useDispatch()
    const [statusId, setStatusId] = useState([]);
    const categories = useSelector(state => state.categoriesReducer.categories)
    const isLoadingCategoryHome = useSelector(state => state.isLoadingCategoryReducer.isLoadingCategoryHome)
    let access_token = getToken();
    let history = useHistory();


    useEffect(() => {
        if(listhistoryData.length < 1){
            getListHistory();
        }
        else if(isFilter.status === true){
            getListHistory();
        }

        if(listhistoryData.length > 0){
            dispatch( fetchCategory("/dashboard/getcategory") )
        }
    },[isFilter, dispatch])

    async function getListHistory() {
        const signature = base64Sign("/customer/getlisthistory");
        let sttsID = [];
        let page;
        let dateFrom;
        let dateTo;
        let dateId
        if(nextPageNumber === null){
            page = 1;
        }

        if(isFilter.status === true){
            sttsID = isFilter.statusId;
            if(pageNumber === 0){
                page = 1;
                setPageNumber(1);
            }else{
                page = nextPageNumber;
            }
            dateId = isFilter.dateId;
        }else{
            sttsID =  [2,7,99,4,10,9];
            dateId = 1;
            page = nextPageNumber;
        }

        if(isFilter.dateFrom === "" && isFilter.dateTo === ""){
            dateFrom = "";
            dateTo = "";
        }else{
            dateFrom = isFilter.dateFrom;
            dateTo = isFilter.dateTo;
        }
        const dataParams = encryptData(`{"statusID": [${sttsID}], "dateID": ${dateId}, "date_from": "${dateFrom}", "date_to": "${dateTo}", "page": ${page}, "row_per_page": 10}`);
        const headers =
        {
        'Content-Type':'application/json',
        'sign' : signature,
        'access_token' : access_token
        }
        
        axios
        .post(BaseURL + '/customer/getlisthistory',{data:dataParams},{headers:headers}).then(data=>{
            const dataList = data.data.response_data;
            if(isFilter.status){
                if(page === 1){                    
                    setListHistoryData(dataList.results);
                }else{

                    setListHistoryData(listhistory => [...listhistory, ...dataList.results]);
                }
            }else{
                if(nextPageNumber !== 1){

                    setListHistoryData(listhistory => [...listhistory, ...dataList.results]);
                }else{
                    setListHistoryData(dataList.results);
                }
                setIsFilter({status: false, statusId : [], dateFrom: "", dateTo: "", dateId: 1, pageNumber: 0 });
            }
            setNextPageNumber(dataList.next_page);
            setLoading(false)
        }).catch(err => console.log(err));
    
    }

    if(loading || isLoadingCategoryHome){
        let loops = [1,2,3];
        return(                        
            <div style={{paddingLeft: 15, paddingRight: 15, marginTop: 90}}>                
                <div>
                    <HeadTitlePage/>
                </div>
                <br/>
                {
                    loops.map(loop => {
                        return(
                            <CardHistoryOrder/>
                        )
                    })
                }
            </div>
        )
    }

    function loadMoreData(){
        if(nextPageNumber === null){
            setIsLoadMore(false);
        }else{
            setIsLoadMore(true);
            getListHistory();
        }
    }

    function formattedDate(dt){
        var date = new Date(dt * 1000).toDateString();
        return date;
    }

    function convertToRupiah(money) {   
        return new Intl.NumberFormat('id-ID', { style: 'decimal', currency: 'IDR'}).format(money)
    }

    function repurchaseProduct(id, flag){  
        let orderItems = [];      
        orderItems = listhistoryData.find((el) => {
            return el.tord_id === id;
        })        
        setListOrderItems(orderItems);
        if(flag){
            setVisibleRepurchase(true);
        }else{
            addCart(id);
        }
    }

    function addCart(id){
        const signature = base64Sign("/customer/reorder");
        const dataParams = encryptData(`{"order_id": ${id}}`);
        const headers = headersWithToken(signature, access_token)
        axios
        .post(BaseURL + '/customer/reorder',{data:dataParams},{headers:headers}).then(data=>{
            if(data.status === 200){
                history.push('/cart')
            }
        }).catch(err => console.log(err));
    }

    function showModal(){
        setVisibleFilter(true);
    }

    function receiveDataFromParent(statusID, datePickerFrom, datePickerTo, dateId){
        setLoading(true);
        setIsFilter({status: true, statusId: statusID, dateFrom: datePickerFrom, dateTo: datePickerTo, dateId: dateId});
        setPageNumber(0);
        setIsLoadMore(true);
        setVisibleFilter(false);
    }

    function linkToDetailPage(id){
        history.push({pathname: `/RiwayatPesananDetail/${id}`})
    }

    function repaymentProduct(id, paymentId, linkGopay){
        if(paymentId === 104){
            history.push('/payment/shopeepay',{isFromHistoryOrder: true, order_id : id, payment_id: paymentId})
        }else if(paymentId === 101){
            history.push('/payment/gopay',{isFromHistoryOrder: true, tord_id : id, payment_id: paymentId, tokenSnap: linkGopay})
        }else if(paymentId === 103){

        }else{

        }
    }

    return (
        <div style={{marginTop: 90}}>
            <span className='span-title-head'>Riwayat Pesanan</span>
           {
               listhistoryData.length > 0 ? <>
            <div id="MainListHistory">
            <InfiniteScroll
                pageStart={0}
                loadMore={loadMoreData}
                hasMore={isLoadMore}                
                loader={<div className="loader" key={0}>
                <Loader
                    type="ThreeDots"
                    color="#3DB54A"
                    height={50}
                    width={50}
                />
            </div>}>
                 {
                    listhistoryData.map(listhistory => {
                        return(
                            <>
                            <div className='row'>
                                {listhistory.tord_status_id === 4 ? 
                                <>
                                    <div className='header-card proceed'>
                                        <div className='header-card-text-div'>
                                            <p className='header-card-text' style={{marginTop: 10}}>{listhistory.mordstat_desc}</p>
                                        </div>
                                    </div>
                                </> : listhistory.tord_status_id === 2 ? 
                                <div className='header-card waiting'>
                                    <div className='header-card-text-div'>
                                        <p className='header-card-text' style={{marginTop: 10}}>{listhistory.mordstat_desc}</p>
                                    </div>
                                </div> : listhistory.tord_status_id === 7 ?
                                 <div className='header-card sent'>
                                    <div className='header-card-text-div'>
                                        <p className='header-card-text' style={{marginTop: 10}}>{listhistory.mordstat_desc}</p>
                                    </div>
                                </div> : listhistory.tord_status_id === 99 ?
                                 <div className='header-card expired'>
                                    <div className='header-card-text-div'>
                                        <p className='header-card-text' style={{marginTop: 10}}>{listhistory.mordstat_desc}</p>
                                    </div>
                                </div> : listhistory.tord_status_id === 10 ?
                                 <div className='header-card expired'>
                                    <div className='header-card-text-div'>
                                        <p className='header-card-text' style={{marginTop: 10}}>{listhistory.mordstat_desc}</p>
                                    </div>
                                </div> :
                                 <div className='header-card expired'>
                                    <div className='header-card-text-div'>
                                        <p className='header-card-text' style={{marginTop: 10}}>{listhistory.mordstat_desc}</p>
                                    </div>
                                </div>
                                }
                                <div className='box-body'>
                                    <div style={{height: 'auto'}}>
                                        <Row className='div-row-body'>
                                            <Col span={12}>
                                                <div className='div-content-header'>
                                                    <span>Tanggal Transaksi</span>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='div-content-header' style={{float: 'right'}}>
                                                    <span><b>{moment(formattedDate(listhistory.tord_crtdt)).format("DD MMM YYYY")}</b></span>
                                                </div>
                                            </Col>
                                            <hr style={{width: '100%', borderColor: '#E5E5E5', height: 1}}/>
                                        </Row>
                                        <Row>
                                            <Col span={6}>
                                                {/* <img src={listhistory.order_items[0].torditem_prod_image} alt="" style={{width: 48, height: 48}}/> */}
                                                {
                                                    listhistory.order_items[0].mprod_mstatstok_id === 2 ? 
                                                    <>
                                                    <div className='sold-out-product-history-order'>
                                                        <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                                                            <div className="imgProduk">    
                                                                    <><img src={listhistory.order_items[0].torditem_prod_image} alt="" style={{width: 48, height: 48}} /><span className='text-sold-out'>Habis</span> </>                                                        
                                                            </div>
                                                        </Row>
                                                    </div>
                                                    </> : 
                                                    <>
                                                        <Row style={{ justifyContent: 'center',marginTop: '13%' }}>
                                                            <div className="imgProduk">    
                                                                <img src={listhistory.order_items[0].torditem_prod_image} alt="" style={{width: 48, height: 48}} />                                                   
                                                            </div>
                                                        </Row>
                                                    </>
                                                }
                                            </Col>
                                            <Col span={18} className='div-product-name'>
                                                <span className='span-product-name'>{listhistory.order_items[0].torditem_prod_name}</span><br/>
                                                {
                                                    listhistory.order_items.length > 1 ? <span className='span-product-more-than-one'>(+{listhistory.order_items.length - 1} Produk Lainnya)</span> : null
                                                }
                                            </Col>
                                            <hr style={{width: '100%', borderColor: '#E5E5E5', height: 1}}/>
                                        </Row>
                                        <Row className='div-row-body' style={{marginBottom: 20}}>
                                            <Col span={12}>
                                                <div className='div-content-footer' style={{textAlign: 'left'}}>
                                                    <span>Total Pembayaran</span><br/>
                                                    <span className='price-tag'>Rp{convertToRupiah(listhistory.tord_grand_total)}</span>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <div className='div-content-footer' style={{float: 'right'}}>
                                                    <span className='a-detail' onClick={() => linkToDetailPage(listhistory.tord_id)}><b>Lihat Detail</b></span>
                                                </div>
                                            </Col>
                                        </Row>
                                        {listhistory.tord_status_id === 7 || listhistory.tord_status_id === 9 || listhistory.tord_status_id === 10 ? 
                                        <>
                                            <Row>
                                                <Col span={24} style={{paddingBottom: 10}}>
                                                    {
                                                        listhistory.order_items[0].mprod_mstatstok_id === 2 ? 
                                                        <>
                                                            <button className="btn-repurchase" disabled>Beli Lagi</button>                                                                
                                                        </> : 
                                                        [
                                                            listhistory.order_items.length > 1 ?  
                                                            <button className="btn-repurchase" onClick={() => repurchaseProduct(listhistory.tord_id, true)} >Beli Lagi</button> 
                                                            : <button className="btn-repurchase" onClick={() => repurchaseProduct(listhistory.tord_id, false)} >Beli Lagi</button>
                                                        ]
                                                    }                                                    
                                                </Col>
                                            </Row>
                                        </>
                                        : listhistory.tord_status_id === 2 ? 
                                        <>
                                            <Row>
                                                <Col span={24} style={{paddingBottom: 10}}>
                                                    {
                                                        listhistory.tord_pay_type_id === 101? 
                                                        <button className="btn-repurchase" onClick={() => repaymentProduct(listhistory.tord_id, listhistory.tord_pay_type_id, listhistory.link_payment_HTTP)}>Ulangi Pembayaran</button>
                                                        : listhistory.tord_pay_type_id === 100 ? null :
                                                        <button className="btn-repurchase" onClick={() => repaymentProduct(listhistory.tord_id, listhistory.tord_pay_type_id)}>Ulangi Pembayaran</button>
                                                    }
                                                </Col>
                                            </Row>                                        
                                        </> : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                        )
                    })
                }
            </InfiniteScroll>
            </div>
            <div className='fixed-filter'>
                <Row>
                    <Col span={24}>
                        <input type="image" src={iconFilter} alt="" style={{width: 'auto'}} onClick={() => showModal()}/>
                    </Col>
                </Row>
            </div>
            {
                visibleRepurchase ? <RePurchaseModal showModal={visibleRepurchase} closeModal={() => setVisibleRepurchase(false)} data={listOrderItems.order_items}/> : null
            }
            {
                visibleFilter ? <ModalFilter show={visibleFilter} closedModal={() => setVisibleFilter(false)} receiveDataFromParent={receiveDataFromParent}/> : null
            }</> : 
            <div style={{paddingTop: '12.5%'}}>
                <img src={historyKosong} alt=""/>
                <br/>
                <br/>
                <p style={{color: '#393939', fontWeight: 600, fontSize: '16px', lineHeight: '12px'}}>Belum ada Pesanan</p>
                <p className='title-history-pesanan'>Semua pesanan Anda akan ditampilkan disini. Yuk, cobain belanja produk di ezeePasar!</p>
                <br/>
                <Link to={`/ProductListCategory/${categories[0].mcat_id}`}><button className='ez-btn-green btn-shop-now'>Belanja Sekarang</button></Link>
            </div>
           }
        </div>
    )
}

export default HistoryOrder
