import React from 'react'
import Header2 from '../components/Header2'
import './KebijakanPrivasi.css'
import {NavbarWithMenuIcon} from '../components/Properties/Navbar/Navbar'

function KebijakanPrivasi({isWebview}) {
    return (
        <div className="container body-content mobile-view">            
            {isWebview ? null : <NavbarWithMenuIcon link="/back"/>}
            <div className="maintext" style={{ marginTop: 70, paddingTop: 10 }}>
                <p style={{ color: '#393939', fontFamily: 'Montserrat', fontSize: 15, fontWeight: 600, textAlign: 'left', paddingBottom: 7 }}>
                    Kebijakan Privasi ezeePasar
                </p>
                <text style={{ fontSize: 12, color: '#848484', textAlign: 'left' }}>
                    <p>
                        Kebijakan Privasi ini merupakan komitmen dari ezeePasar untuk menghargai dan melindungi setiap informasi pribadi Pengguna situs www.ezeePasar.com . ezeePasar hanya akan mengumpulkan informasi yang diperlukan dan yang relevan dengan transaksi antara ezeePasar dengan Pengguna layanan ezeePasar.
                    </p>
                    <p>
                        Pengguna dapat mengunjungi ezeePasar dan menjelajahinya tanpa harus memberikan informasi pribadi. Informasi pribadi akan dibutuhkan saat Pengguna melakukan pendaftaran , menggunakan layanan pembayaran dan pengiriman, atau berinteraksi dengan layanan pelanggan ezeePasar.
                    </p>
                    <p>
                        Kebijakan Privasi ini mungkin di ubah dan/atau diperbaharui dari waktu ke waktu tanpa pemberitahuan sebelumnya. ezeePasar menyarankan agar Anda membaca secara seksama dan memeriksa halaman Kebijakan Privasi ini dari waktu ke waktu untuk mengetahui perubahan apapun.
                    </p>
                    <p>
                        Dengan menggunakan layanan ezeePasar, Pengguna menyatakan bahwa setiap data Pengguna merupakan data yang benar dan sah, serta telah membaca, memahami dan menyetujui pengumpulan dan penggunaan data pribadi Pengguna oleh ezeePasar sebagaimana diuraikan di bawah ini.
                    </p>
                </text>
            </div>
            <div className="maintext" style={{ marginTop: 'unset' }}>
                <p style={{ color: '#393939', fontFamily: 'Montserrat', fontSize: 15, fontWeight: 600, textAlign: 'left', paddingBottom: 7 }}>
                    Pengumpulan Informasi Pengguna
                </p>
                <text style={{ fontSize: 12, color: '#848484', textAlign: 'left' }}>
                    <p>
                        1. Ketika Pengguna membuat akun di ezeePasar, atau memberikan informasi pribadi Pengguna melalui platform ezeePasar, informasi Pengguna yang kami kumpulkan dapat meliputi:
                    </p>
                    <ul style={{ marginLeft: -15,listStyleType: 'none', textAlign: 'left' }}>
                        <li>Nama</li>
                        <li>Email</li>
                        <li>Nomor Ponsel</li>
                        <li>Rekening Bank</li>
                        <li>Alamat Pengiriman</li>
                        <li>Dan lain-lain</li>
                    </ul>
                    <p style={{ marginBottom: 20 }}>
                        2. Pada saat Pengguna menghubungi ezeePasar, maka ezeePasar memiliki, menyimpan informasi dan/atau isi komunikasi antara ezeePasar dengan Pengguna.
                    </p>
                    <p>
                        3.	ezeePasar dapat mencatat informasi komputer dan browser Pengguna, termasuk diantaranya:
                    </p>
                    <ul style={{ marginLeft: -15,listStyleType: 'none', textAlign: 'left' }}>
                        <li>Alamat IP</li>
                        <li>Cookie ezeePasar</li>
                        <li>Piranti Lunak dan Piranti Keras</li>
                        <li>Halaman yang diakses</li>
                        <li>Dan lain-lain</li>
                    </ul>
                </text>
            </div>
            <div className="maintext" style={{ marginTop: 'unset' }}>
                <p style={{ color: '#393939', fontFamily: 'Montserrat', fontSize: 15, fontWeight: 600, textAlign: 'left', paddingBottom: 7 }}>
                    Penggunaan Informasi
                </p>
                <text style={{ fontSize: 12, color: '#848484', textAlign: 'left' }}>
                    <p>
                        ezeePasar dapat menggunakan sebagian maupun seluruh informasi Pengguna sebagai upaya peningkatan produk dan layanan.
                    </p>
                    <p>
                        Informasi / data Pengguna dapat dipergunakan untuk kebutuhan transaksi di ezeePasar atau kebutuhan internal ezeePasar diantaranya untuk riset pasar, promosi, penawaran, maupun informasi lain, dimana ezeePasar dapat menghubungi Pengguna melalui email, surat, telepon, sms, atau media elektronik lainnya.
                    </p>
                    <p>
                        ezeePasar dapat menghubungi Pengguna melalui email, surat, telepon, sms, atau media elektronik lainnya termasuk namun tidak terbatas, untuk membantu dan/atau menyelesaikan proses transaksi pembelian yang dilakukan oleh Pengguna.
                    </p>
                    <p>
                        Dalam keadaan tertentu, ezeePasar mungkin perlu untuk menggunakan ataupun mengungkapkan data Pengguna untuk tujuan penegakan hukum atau untuk pemenuhan persyaratan hukum dan peraturan yang berlaku, termasuk dalam hal terjadinya sengketa atau proses hukum antara Pengguna dan ezeePasar. Dalam hal ini Pengguna setuju untuk tidak melakukan tuntutan apapun terhadap ezeePasar untuk pengungkapan informasi pribadi Pengguna.
                    </p>
                </text>
            </div>
            <div className="maintext" style={{ marginTop: 'unset' }}>
                <p style={{ color: '#393939', fontFamily: 'Montserrat', fontSize: 15, fontWeight: 600, textAlign: 'left', paddingBottom: 7 }}>
                    Cookie
                </p>
                <text style={{ fontSize: 12, color: '#848484', textAlign: 'left' }}>
                    <p>
                        ezeePasar dapat mengimplementasikan "cookie" atau fitur lain yang memungkinkan untuk mengumpulkan atau berbagi informasi yang akan membantu ezeePasar memperbaiki Platform dan Layanan yang kami tawarkan, atau membantu ezeePasar menawarkan layanan serta fitur baru. “Cookie” adalah pengenal yang kami alihkan ke komputer atau perangkat mobile Pengguna yang memungkinkan ezeePasar mengenali komputer atau perangkat Pengguna dan memberi tahu mengenai bagaimana dan kapan Layanan atau Platform digunakan atau dikunjungi, oleh berapa banyak orang, dan untuk melacak aktifitas di dalam Platform kami. Kami dapat menautkan informasi cookie ke data pribadi. Cookie juga tertaut ke informasi mengenai item apa yang sudah dipilih untuk halaman dan pembelian yang dilihat Pengguna. Cookie juga digunakan untuk menyampaikan konten spesifik sesuai minat Pengguna dan untuk memantau penggunaan Layanan kami.
                    </p>
                    <p>
                        Walaupun secara otomatis perangkat komputer Pengguna akan menerima cookie, Pengguna dapat menentukan pilihan untuk melakukan modifikasi melalui pengaturan / setting browser Pengguna yaitu dengan memilih untuk menolak cookies.  Harap diperhatikan bahwa dengan melakukan ini, Pengguna mungkin tidak dapat menggunakan fungsionalitas penuh Platform ezeePasar.
                    </p>
                </text>
            </div>
            <div className="maintext" style={{ marginTop: 'unset' }}>
                <p style={{ color: '#393939', fontFamily: 'Montserrat', fontSize: 15, fontWeight: 600, textAlign: 'left', paddingBottom: 7 }}>
                    Kerahasiaan Data/Informasi
                </p>
                <text style={{ fontSize: 12, color: '#848484', textAlign: 'left' }}>
                    <p>
                        ezeePasar akan melindungi seluruh informasi / data Pengguna yang dikumpulkan di platform ezeePasar sebaik mungkin melalui perangkat keamanan yang digunakan ezeePasar secara elektronik. ezeePasar menerapkan berbagai langkah pengamanan dan berusaha untuk memastikan keamanan data pribadi Pengguna
                    </p>
                    <p>
                        ezeePasar tidak menjamin keamanan data pribadi dan/atau informasi lainnya yang diberikan Pengguna kepada Pihak Ketiga. ezeePasar juga tidak bertanggung jawab terhadap pertukaran data yang dilakukan sendiri antar Pengguna.
                    </p>
                    <p>
                        Kerahasiaan akun, sandi/password merupakan tanggung jawab masing-masing Pengguna. ezeePasar tidak bertanggung jawab atas kerugian yang timbul akibat kelalaian Pengguna dalam menjaga kerahasiaan sandi/password akun ezeePasar.
                    </p>
                </text>
            </div>
            <div className="maintext" style={{ marginTop: 'unset' }}>
                <p style={{ color: '#393939', fontFamily: 'Montserrat', fontSize: 15, fontWeight: 600, textAlign: 'left', paddingBottom: 7 }}>
                    Lain-lain
                </p>
                <text style={{ fontSize: 12, color: '#848484', textAlign: 'left' }}>
                    <p>
                        Untuk pertanyaan terkait kebijakan privasi ini mohon menghubungi ezeePasar melalui &nbsp;
                        <a href="mailto:info@ezeepasar.com" style={{ color: '#3DB54A' }}>info@ezeepasar.com</a>
                    </p>
                    <p>
                        Terakhir kali diubah: 15 Desember 2020
                    </p>
                </text>
            </div>
        </div>
    )
}

export default KebijakanPrivasi
