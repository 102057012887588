import { IS_LOADING_RECOMENDPROD_HOME } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingRecomendProdHome: true
}

const isLoadingRecomendProdReducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_RECOMENDPROD_HOME) {
        return { ...state, isLoadingRecomendProdHome: action.payload.isLoadingRecomendProdHome }
    } else {
        return state
    }
}

export default isLoadingRecomendProdReducer