import React from 'react'
import {Modal} from 'antd'
import FailedIcon from '../../../Asset/Icon/failed-payment.jpg'

function ErrorPaymentModal({isOpen, onClosed, data, isListAddr}) {

  return (
    <div>
        <div className="center-modal">
            <Modal className="modalProgress" id="modalProgress" visible={isOpen} onClosed={onClosed} footer={null} closable={false} style={{width: '330px !important'}} >
                <div style={{textAlign: 'center'}}>
                    <div>
                        <img src={FailedIcon} alt="" style={{width: 120, height: 120, marginBottom: 25}}/>
                    </div>
                    <div style={{fontSize: 18, fontWeight: 600, marginBottom: 20, color: 'black'}}>{isListAddr ? <span>Jarak diluar jangkauan</span> : <span>Pembayaran Gagal :(</span>}</div>
                    <div style={{fontSize: 12, fontWeight: 500, marginBottom: 20}}>{data}</div>       
                    <div>
                        {/* <Link to="/checkout"> */}
                            <button className="ez-btn-green btn-lihat-pesanan" onClick={onClosed}>
                                Tutup
                            </button>
                        {/* </Link> */}
                    </div>             
                </div>
            </Modal>
        </div>
    </div>
  )
}

export default ErrorPaymentModal