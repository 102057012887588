import React, {useState, useEffect}from 'react'
import {NavbarLogoOnly} from '../../components/Properties/Navbar/Navbar'
import GopayPayment from '../../components/Payment/GopayPayment'
function Gopay() {
    
    return (
        <div className="container body-content mobile-view center-page">
            <NavbarLogoOnly/>
            <GopayPayment/>
        </div>
    )
}

export default Gopay
