import React, {useState} from 'react'
import {Modal} from 'antd'
import './LoadingModal.css'
import spinner from '../../../Asset/Icon/spinner/spinner.svg'

function LoadingModal() {

    const [visible, setVisible] = useState(true)

    return (
        <div>
            <div className="center-modal">
                <Modal className="modalProgress" id="modalProgress" visible={visible} footer={null} closable={false} style={{marginTop: '10%', width: '330px !important'}} >
                    <div style={{textAlign: 'center'}}>
                        <div style={{fontSize: 14, fontWeight: 600, marginBottom: 10}}>Sedang dalam proses</div>
                        <div style={{fontSize: 10}}>Kami sedang memproses.</div>
                        <div style={{fontSize: 10}}>Mohon menunggu</div>
                        <img src={spinner}style={{marginTop: 10}} alt=""/>
                    </div>
                </Modal>
            </div>
        </div>
    )
}

export default LoadingModal
