import React from 'react'
import { Layout } from 'antd'
const { Footer } = Layout
function FooterNotificationDetail({title}) {
    return (
        <Layout style={{marginTop: 70}}>
            <Footer className="footerLayout">
                <button style={{width: '100%'}}>
                    {title}
                </button>
            </Footer>
        </Layout>
    )
}

export default FooterNotificationDetail
