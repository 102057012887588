import React from 'react';
import SearchPage from '../components/SearchPage/SearchPage';
import {NavbarSearchPage} from '../components/Properties/Navbar/Navbar'

function Search() {

  return (
      <div className='container body-content mobile-view'>
          <NavbarSearchPage link="/" />
      </div>
  )
}

export default Search;
