import { IS_LOADING_CATEGORY_HOME } from "../../ActionType/actionTypes";

export function isLoadingCategoryHome(param) {
    return (dispatch) => {
        dispatch({
            type: IS_LOADING_CATEGORY_HOME, 
            payload: {
                isLoadingCategoryHome: param
            }
        })
    }
}