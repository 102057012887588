import React, { useEffect, useState } from 'react'
import Header from '../components/MenuPage/HeaderWithoutClose'
import { Input, Layout, Modal } from 'antd'
import encryptData from '../function/encryptData'
import base64Sign from '../function/base64Sign'
import { getToken } from '../utils/setSessionUser'
import axios from 'axios'
import { BaseURL } from '../function/helpers'
import CarbonWarningFilledRed from '../Asset/Icon/carbon_warning-filled_red.png'
import { useHistory, useParams } from 'react-router-dom'
import { NavbarLogoOnly, NavbarWithMenuIcon } from '../components/Properties/Navbar/Navbar'
import './EmailChangePassword.css'
import errorIcon from '../Asset/Icon/error_icon.svg'
import clarityCheckCircleLine from '../Asset//Icon/clarity_check-circle-line.png'

const { Footer } = Layout

function EmailChangePassword({ location }) {
    
    const history = useHistory()
    const validateSignature = location.search.split("=")[1].slice(0,(location.search.split("=")[1].length - 6))
    const validateEmail = location.search.split("=")[2]
    const [newPass, setNewPass] = useState('')
    const [confirmPass, setConfirmPass] = useState('')
    const [isFailed, setIsFailed] = useState(false)
    const [isNotValid, setIsNotValid] = useState(false)
    const [isErrorConfirmNewPass, setIsErrorConfirmNewPass] = useState(false)
    const [isSuccess, setIsSuccess] = useState(false)

    useEffect(() => {
        function validateResetPassword() {
            const param = encryptData(`{"signature": "${validateSignature}", "email": "${validateEmail}"}`)
            const signature = base64Sign("/profile/validateresetpassword")
            const access_token = getToken()
            const headers = {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token
            }
            axios.post(BaseURL + "/profile/validateresetpassword", { data: param }, { headers: headers })
                .then(results => {
                    if (results.status === 200) {
                        setIsFailed(false)
                    }
                })
                .catch(error => {
                    console.log(error);
                    if (error.response.data.response_data.error_id === "0001") {
                        setIsFailed(true)
                    }
                })
        }
        validateResetPassword()
    }, [])
    
    function closeModal() {
        setIsSuccess(false)
    }

    function goToHome() {
        history.push("/")
        setIsSuccess(false)
    }

    function saveChangePassword(sign, email, newPass, confirmNewPass) {
        let validasi = 0
        const besarKecil = /[A-Za-z]/g;
        const numbers = /[0-9]/g;
        const spChar = /[/\W|_/g]/g;

        if (newPass !== confirmNewPass) {
            setIsErrorConfirmNewPass(true)
            return
        } else {
            setIsErrorConfirmNewPass(false)
        }
        if (newPass.match(besarKecil) && newPass.length >= 8) {
            validasi += 1
        }
        if (newPass.match(numbers)) {
            validasi += 1
        }
        if (newPass.match(spChar)) {
            validasi += 1
        }
        if (validasi < 2) {
            setIsNotValid(true)
        } else{
            const param = encryptData(`{"signature": "${sign}", "email": "${email}", "password": "${newPass}"}`)
            const signature = base64Sign("/profile/resetpassword")
            const access_token = getToken()
            const headers = {
                'Content-Type':'application/json',
                'sign' : signature,
                'access_token' : access_token
            }
            axios.post(BaseURL + "/profile/resetpassword", { data: param }, { headers: headers })
                .then(successChangePassword => {
                    if (successChangePassword.data.response_data.error_id === "0000") {
                        setIsSuccess(true)
                    }
                })
                .catch(error => {
                    console.log(error);
                })
        }

    }

    return (
        <div className="App container body-content mobile-view">
            <NavbarWithMenuIcon />
            {
                (isFailed) ?
                <div className='main-forgot-password-email' style={{ marginTop: '3em', marginLeft: -10 }}>
                    <div style={{ textAlign: 'center', fontSize: 12, fontFamily: 'Montserrat', color: '#848484' }}>
                        <img src={CarbonWarningFilledRed} style={{ width: 80, marginTop: '10em', marginBottom: '2em' }} />
                        <h6 style={{ color: 'red', fontWeight: 'bold' }}>Invalid Key URL</h6>
                        <p style={{ color: 'black', fontSize: 10, fontFamily: 'Montserrat' }}>Please visit the forgot password page again to have a new key generated</p>
                    </div>
                    <Layout>
                        <Footer className="footerLayout-email-change-password">
                            <button onClick={() => history.push("/")}>
                                Kembali ke Homepage
                            </button>
                        </Footer>
                    </Layout>
                </div> :
                <div className="row" style={{ margin: '68px 9px 0px', zIndex: 0, paddingTop: 70 }}>
                    <p style={{ fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600, paddingLeft: 14, marginBottom: -2, display: 'flex' }}>
                        Lupa Kata Sandi
                    </p>
                    <div className="col-12 form-grup">
                        <label style={{ marginLeft: 3, display: 'flex', marginTop: 5, marginBottom: 7 }}>Masukkan Kata Sandi Baru</label>
                        <Input.Password onChange={e => setNewPass(e.target.value)} className="form-control-email-change-password" placeholder="Kata Sandi Baru" autoComplete="new-password" />
                        {
                            (isNotValid) ?
                            <div className="col-12 form-grup" style={{ marginTop: 'unset' }}>
                                <label className="placeholders" style={{ color: 'rgb(238, 46, 44)', fontSize: 10, display: 'flex', marginLeft: 14 }}>Kata sandi minimal 8-20 karakter. 2 kombinasi antara huruf, angka, simbol.</label>
                            </div> :
                            <div className="col-12 form-grup" style={{ marginTop: 'unset' }}>
                                <label className="placeholders" style={{ color: '#848484', fontSize: 10, display: 'flex', marginLeft: 14 }}>Kata sandi minimal 8-20 karakter. 2 kombinasi antara huruf, angka, simbol.</label>
                            </div>
                        }
                    </div>
                    <div className="col-12 form-grup">
                        <label style={{ marginLeft: 3, display: 'flex', marginTop: 5, marginBottom: 7 }}>Konfirmasi Kata Sandi Baru</label>
                        <Input.Password onChange={e => setConfirmPass(e.target.value)} className="form-control-email-change-password" placeholder="Kata Sandi Baru" autoComplete="new-password" />
                        {
                            isErrorConfirmNewPass &&
                            <div className="error-txt" style={{ display: 'flex', marginTop: 10, marginLeft: 14 }}>
                                Kata sandi tidak sama
                            </div>
                        }
                    </div>
                    <div style={{ marginLeft: -21 }}>
                        <Layout>
                            <Footer className="footerLayout-email-change-password">
                                <button onClick={() => saveChangePassword(validateSignature, validateEmail, newPass, confirmPass)}>
                                    Simpan Data
                                </button>
                            </Footer>
                        </Layout>
                    </div>
                </div>
            }
            <Modal
                visible={isSuccess}
                onCancel={closeModal}
                footer={null}
                className="modalVefiedPassword"
            >
                <div>
                    <img src={clarityCheckCircleLine} alt="" width="95px" height="95px" />
                </div>
                <div style={{ marginTop: 20, fontSize: 14, fontWeight: 600, marginBottom: 10, color: "#393939" }}>
                    Kata Sandi Berhasil Diubah
                </div>
                <div style={{ fontSize: 10 }}>
                    Kata sandi kamu berhasil diubah.
                </div>
                <div style={{ fontSize: 10 }}>
                    Akses akunmu dengan kata sandi baru.
                </div>
                <button onClick={goToHome} className="btn" style={{ backgroundColor: "#3DB54A", color: "#fff", marginTop: 15, marginLeft: '-11%', fontSize: 12, padding: "10px 50px", outline: 'unset', width: '125%' }}>Kembali ke Home</button>
            </Modal>
        </div>
    )
}

export default EmailChangePassword