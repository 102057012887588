import React from 'react'
import { NavbarCloseOnly } from '../components/Properties/Navbar/Navbar.js'
import NotFoundImage from "../Asset/Icon/404.svg"
import "./NoMatch.css"
import { useHistory } from 'react-router-dom'

function NoMatch() {

    const history = useHistory()

    return (
        <div className="container body-content mobile-view" style={{ textAlign: 'center', height: 2542 }}>
            <NavbarCloseOnly link="/back" />
            <div style={{ paddingTop: 175 }}>
                <img src={NotFoundImage} alt="NotFound" />
            </div>
            <div style={{ marginTop: 15 }}>
                <button onClick={() => history.push("/")} className='btn ez-btn-green-no-match' style={{ width: '80%', height: 50, marginTop: 15, borderRadius: 8, fontWeight: 'bold', fontSize: 14, alignItems: 'center' }}>
                    Kembali ke HomePage
                </button>
            </div>
        </div>
    )
}

export default NoMatch