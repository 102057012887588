import React from 'react';
import {NavbarCloseOnly} from '../../components/Properties/Navbar/Navbar'
import HowToEzeeTunaiAct from '../../components/EzeeTunai/HowToEzeeTunaiAct';

function HowToEzeetunaiAct() {
  return (
    <div className="container body-content mobile-view">
        <NavbarCloseOnly link="/"/>
        <HowToEzeeTunaiAct/>
    </div>    
  );
}

export default HowToEzeetunaiAct;
