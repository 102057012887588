import { 
    FETCH_EZEETUNAI_BALANCE, 
    IS_NEW_LOADING, 
    FETCH_USERS_REQUEST
 } from "../../ActionType/actionTypes";
import axios from "axios";
import base64Sign from "../../../function/base64Sign";
import { getToken } from "../../../utils/setSessionUser";
import {BaseURL, headersWithToken} from '../../../function/helpers'

export const fetchUsersRequest = () => {
    return {
        type: FETCH_USERS_REQUEST
    }
}

const backToLoading = (loads) => {
    return {
        type: IS_NEW_LOADING,
        payload: loads
    }
}

const fetchUsersSuccess = datas => {
    return{
        type: FETCH_EZEETUNAI_BALANCE,
        payload: datas
    }
}

    export const fetchEzeeTunaiBalance = (url) => {
        return (dispatch) => {
            dispatch(fetchUsersRequest())
            const signature = base64Sign(url)
            const access_token = getToken()
            const headers = headersWithToken(signature, access_token)            
            axios.post(BaseURL + url, {data: ''}, {headers: headers})
            .then(ezeeTunaiData => {
                dispatch(fetchUsersSuccess(ezeeTunaiData.data.response_data.results))
            },
                dispatch(backToLoading())
            ).catch(error => {
                console.log(error);
            })            
        }
    }