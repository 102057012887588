import { IS_LOADING_GETCATLIST1_CAT } from "../../ActionType/actionTypes";

const initialState = {
    isLoadingGetCatList1: true
}

const isLoadingGetCatList1Reducer = (state = initialState, action) => {
    if (action.type === IS_LOADING_GETCATLIST1_CAT) {
        return { ...state, isLoadingGetCatList1: action.payload.isLoadingGetCatList1 }
    } else {
        return state
    }
}

export default isLoadingGetCatList1Reducer