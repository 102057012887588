import React from 'react'
import './NotificationPermission.css'
import {Row, Col} from 'antd'
import ezeePasarIcon from  '../../Asset/Icon/logo/logo_ezeepasar_only.png'

function NotificationPermission({NotificationSnackbar}) {

    function snackbarButton(param){
        NotificationSnackbar(param)
    }

    return (
        <div id="snackbar">
            <Row>
                <Col span={6}>
                    <div className='iconEzeePasar'>
                        <img src={ezeePasarIcon} style={{width: 90, height: 90}} alt=""/>
                    </div>
                </Col>
                <Col span={18}>
                    <div className='info-notif'>
                        <span>Dapatkan informasi promo menarik dan aktivitas pembelianmu dengan mengaktifkan notifikasi ezeePasar</span>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <div style={{textAlign: 'right'}}>
                        <button className='preventNotificationButton' onClick={() => snackbarButton(0)}>Nanti saja</button>
                        <button className='acceptNotificationButton' onClick={() => snackbarButton(1)}>Aktifkan sekarang</button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default NotificationPermission
