import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Header2 from '../components/Header2';
import { BiTargetLock } from 'react-icons/bi'
import { FiMapPin } from 'react-icons/fi'
import SearchIcon from '../Asset/Icon/feather_search.svg'
// import Autocomplete from 'react-google-autocomplete';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from 'react-places-autocomplete';
import { GOOGLE_MAP_KEY } from './FormAddress';
import Geocode from 'react-geocode';
import { Modal } from 'antd'
import unKnownLocation from '../Asset/Icon/unknown-location.svg'
import './SearchAddress.css'

function SearchAddress() {
    const history = useHistory()
    let location = useLocation()
    // console.log(location, 'location');
    const [address, setAddress] = useState('')
    const [suggest, setSuggest] = useState([])
    const [coordinates, setCoordinates] = useState({
        lat: null,
        lng: null
    })
    const [isCurrentLoc, setIsCurrentLoc] = useState(false)

    function handleChange(value) {
        setAddress(value)
    }

    function handleSelect(value) {
        const mainText = value.split(",")[0]
        geocodeByAddress(value)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setCoordinates(latLng)
                history.push({ pathname: "/FormAddress", state: {addressId: location.state.addressId ,lat : latLng.lat, lng : latLng.lng, isFromCheckout: location.state.isFromCheckout, mainText: mainText, isFromSearchAddress: true} })
            })
            .catch(error => console.error(error, 'ini error geocode'))
    }

    function currentLocHandle() {
        navigator.permissions.query({ name: 'geolocation' })
            .then(result => {
                if (result.state === 'granted') {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(position => {
                            Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
                                .then(response => {
                                    setSuggest([{
                                        description: response.results[0].formatted_address,
                                        formattedSuggestion: {
                                            mainText: response.results[0].formatted_address.split(",")[0],
                                            secondaryText: response.results[0].formatted_address.split(",").slice(1).toString()
                                        }
                                    }])
                                })
                        })
                    }
                } else {
                    setIsCurrentLoc(true)
                }
            })
            .catch(error => console.log(error))
    }

    function activatedLocation() {
        navigator.permissions.query({ name: 'geolocation' })
            .then(result => {
            })
            .catch(error => console.log(error))
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    Geocode.fromLatLng(position.coords.latitude, position.coords.longitude)
                        .then(response => {
                            setSuggest([{
                                description: response.results[0].formatted_address,
                                formattedSuggestion: {
                                    mainText: response.results[0].formatted_address.split(",")[0],
                                    secondaryText: response.results[0].formatted_address.split(",").slice(1).toString()
                                }
                            }])
                            setIsCurrentLoc(false)
                        })
                })
            }
    }

    return (
        <div className="container body-content mobile-view">
            <Header2 />
            <div className='main-address top-page-address'>
                <div className='form' style={{ marginTop: 70 }}>
                    <div>
                        <PlacesAutocomplete
                            value={address}
                            onChange={(value) => handleChange(value)}
                            onSelect={handleSelect}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                <div className='form-grup search-address-field'>
                                    <img className='search-icon-address' style={{ width: 38, height: 40 }} src={ SearchIcon } alt='' />
                                    <input {...getInputProps({
                                            placeholder: 'Tulis nama jalan / perumahan / gedung',
                                            className: 'form-control',
                                            id: 'search-field'
                                        })}
                                    />
                                    <article style={{ padding: '0px 14px 0px' }}>
                                        <label onClick={() => currentLocHandle()} className='current-location' htmlFor='location' style={{ marginBottom: '1.5em', marginTop: 10 }}>
                                            <BiTargetLock style={{ fontSize: 25, verticalAlign: 'text-top', color: '#848484' }} />
                                            <span className='desc'>Lokasi saat ini</span>
                                        </label>
                                        <div className='list-location'>                                          
                                            {suggestions.length !== 0 ? setSuggest(suggestions) : null}
                                            {suggest.map(suggestion => {
                                                const className = 'item-search'
                                                return (
                                                    <div key={suggestion.index} {...getSuggestionItemProps(suggestion, { className })}>
                                                        <FiMapPin style={{ fontSize: 20, color: '#848484', verticalAlign: 'top' }} />
                                                        <span className='title-search'>{suggestion.formattedSuggestion.mainText}</span>
                                                        <p className='address-search'>{suggestion.formattedSuggestion.secondaryText}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </article>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </div>
                </div>
            </div>
            <Modal
                visible={isCurrentLoc}
                onCancel={() => setIsCurrentLoc(false)}
                footer={null}
                className="modalVefiedFormAddress"
            >
                <div style={{ marginTop: 30, display: 'flex', justifyContent: 'center' }}>
                    <img src={unKnownLocation} alt="" width="110px" height="100px" />
                </div>
                <div style={{ marginTop: 5, fontSize: 18, fontWeight: 600, marginBottom: 5, color: "#393939", display: 'flex', justifyContent: 'center' }}>
                    Lokasi Anda tidak terdeteksi
                </div>
                <p style={{ font: 'normal normal 13px/18px Open Sans', width: '67%', margin: '0px auto', display: 'flex', justifyContent: 'center' }}>
                    Kami tidak dapat mengakses lokasi Anda.
                </p>
                <p style={{ font: 'normal normal 13px/18px Open Sans', width: '67%', margin: '0px auto', display: 'flex', justifyContent: 'center' }}>
                    Aktifkan GPS untuk menggunakan fitur ini
                </p>
                <div>
                    <div className='row' style={{ position: 'relative', bottom: -15, width: '110%', maxWidth: 480, display: 'table', background: 'white', boxShadow: '0px -5px 10px rgb(0 0 0 / 5%)', marginLeft: -21, marginTop: '8%' }}>
                        <div className='col-12' style={{ padding: 0 }}>
                            <button onClick={() => activatedLocation()} className='btn btn-lg btn-success-current-loc' style={{ backgroundColor: '#3DB54A', margin: '17px 5%', display: 'block', width: '90%', font: 'normal bold 14px/12px Montserrat', padding: '15px 0', }}>
                                Aktifkan Lokasi GPS
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )
}

export default SearchAddress
