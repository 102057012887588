import React from 'react'
import { Modal } from 'antd'
import reqtimeoutImage from '../../../Asset/Icon/reqtimeout.svg'
import './RequestTimeOutModal.css'

function RequestTimeOutModal({ isOpen, onClosed }) {
    
    return (
        <Modal
            visible={isOpen}
            onCancel={onClosed}
            footer={null}
            className="modalVefiedRequestTimeOut"
        >
            <div style={{ marginTop: 20, display: 'flex', justifyContent: 'center' }}>
                <img src={reqtimeoutImage} alt="" />
            </div>
            <div>
                <div className='row' style={{ position: 'relative', bottom: -15, width: '110%', maxWidth: 480, display: 'table', background: 'white', boxShadow: '0px -5px 10px rgb(0 0 0 / 5%)', marginLeft: -21, marginTop: '8%' }}>
                    <div className='col-12' style={{ padding: 0 }}>
                        <button onClick={() => window.location.reload()} className='btn btn-lg btn-success-current-loc' style={{ backgroundColor: '#3DB54A', margin: '17px 5%', display: 'block', width: '90%', font: 'normal bold 14px/12px Montserrat', padding: '15px 0', }}>
                            Coba Lagi
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default RequestTimeOutModal